import styled from "styled-components";
import { isEmpty } from "lodash";

const StyledButton = styled.button`
  min-width: 81px;
  padding: 11px 14px;
  border-radius: 43px;
  gap: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
`;

const NumberBox = styled.div`
  min-width: 13px;
  min-height: 13px;
  padding: 0px 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  font-weight: 600;
  line-height: 11px;
  text-align: center;
  letter-spacing: 0em;
`;

const SecondaryLineButton = ({
  text,
  number,
  rightElement,
  onClickButton,
  isDisable,
  ...props
}) => {
  return (
    <StyledButton
      className="border border-primary-light dark:border-primary-dark text-onSecondary-light dark:text-onSecondary-dark"
      onClick={() => (onClickButton ? onClickButton() : null)}
      {...props}
    >
      {text}
      {number && (
        <NumberBox className="bg-primary-light dark:bg-primary-dark">
          {number}
        </NumberBox>
      )}
      {!isEmpty(rightElement) && rightElement}
    </StyledButton>
  );
};

export default SecondaryLineButton;
