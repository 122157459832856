import {
  Box,
  Center,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { first, isEmpty } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import FilledButton from "../components/FilledButton";
import Page from "../components/Page";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { queryGet } from "../utils/queryClient";
import { saveData } from "../utils/saveData";
import { useArtistQueryAll } from "../components/api/queries/useArtistQuery";
import { setEvent } from "../utils/ga";

async function initializedPhotoCards(artistId) {
  return fetch(baseUrl() + `/photo_cards/initialized`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      artistId: artistId,
    }),
  }).then((data) => data.json());
}

export default function SelectArtistsPageForUnlike() {
  const queryClient = useQueryClient();

  const artists = useArtistQueryAll();

  const onFinishedInitialized = (isSuccess) => {
    if (isSuccess) {
      queryClient.clear();
    } else {
      alert(t("userProfilePage.errorIntializePhotoCardsAlert"));
    }
  };

  const onArtistSelected = async (artist) => {
    setEvent({
      category: "PhotoCard",
      action: "Select Initialize PhotoCards",
      label: "User Profile Page",
    });
    if (window.confirm(t("userProfilePage.initializePhotoCardsAlert"))) {
      setEvent({
        category: "PhotoCard",
        action: "Initialize PhotoCards",
        label: "User Profile Page",
      });
      initializedPhotoCards(artist.artistId).then((r) =>
        onFinishedInitialized(r.success)
      );
    }
  };
  return (
    <Page title={t("selectArtistPage.pageName")}>
      <Text
        className="text-main-light-2 dark:text-main-dark-2"
        fontSize="26px"
        fontWeight={700}
        lineHeight={"120%"}
        whiteSpace={"pre-wrap"}
      >
        {t("selectArtists.initArtistTitle")}
      </Text>
      <Text
        className="text-main-light-4 dark:text-main-dark-4"
        fontSize="15px"
        mt="14px"
        whiteSpace={"pre-wrap"}
      >
        {t("selectArtists.initArtistDescription")}
      </Text>
      <Grid
        templateColumns="repeat(2, 1fr)"
        paddingTop={"29px"}
        paddingBottom={"96px"}
        gap={3}
      >
        {artists &&
          artists
            .filter((a) => !!a.likedDate)
            .map((artist) => {
              return (
                <GridItem rowSpan={1} key={artist.name}>
                  <Box>
                    <Stack>
                      <Center>
                        <Box>
                          <Wrap>
                            <WrapItem position={"relative"}>
                              {
                                // #region 아티스트 아이콘
                              }
                              <Image
                                className="bg-background-light dark:bg-background-dark"
                                src={artist.pictureUrl}
                                borderRadius="full"
                                width={"154px"}
                                height={"154px"}
                                pl="4px"
                                alt={artist.name}
                                onClick={() => {
                                  onArtistSelected(artist);
                                }}
                                loading={"lazy"}
                              />
                              {
                                // #endregion
                              }
                            </WrapItem>
                          </Wrap>
                        </Box>
                      </Center>
                      <Text
                        className="text-center text-main-light-2 dark:text-main-dark-2"
                        paddingTop="16px"
                        mt="0px!important"
                        fontSize="17px"
                        fontWeight={400}
                        lineHeight="20.4px"
                      >
                        {artist.name}
                      </Text>

                      <Text
                        className="text-center text-main-light-4 dark:text-main-dark-4"
                        paddingTop="4px"
                        mt="0px!important"
                        fontSize="13px"
                        fontWeight={400}
                        lineHeight="15.6px"
                      >
                        {t("selectArtists.photoCardsCount", {
                          count: artist.collectedPhotoCardsCount,
                        })}
                      </Text>
                    </Stack>
                  </Box>
                </GridItem>
              );
            })}
      </Grid>
    </Page>
  );
}
