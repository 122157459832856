import React, { useMemo, useState } from "react";

import styled from "styled-components";

import { Spacer } from "@chakra-ui/react";
import { t } from "i18next";
import { find, isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import addTags from "../../components/api/mutations/addTags";
import useDistributorsQuery from "../../components/api/queries/useDistributorsQuery";
import RoundedBadge from "../../components/badges/RoundedBadge";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PrimaryInput from "../../components/input/PrimaryInput";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CloseIcon } from "../../icons/xmark.fill.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import { useQueryParams } from "../../utils/useQueryParams";
import appendTags from "../../components/api/mutations/appendTags";
import { useNavigate } from "react-router-dom";

const ConfirmText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
`;

const TopContainer = styled.div`
  margin-top: calc(56px + env(safe-area-inset-bottom));
`;

const TagsWrapper = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  /* FIXME : border-bottom: 1px solid #d9d9d9; */
`;

const TagsList = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 0px 24px;
  flex-wrap: wrap;
`;

const TagInputWrapper = styled.div`
  margin-bottom: 16px;
  padding: 16px 24px;
`;

const TagsListWrapper = styled.div`
  padding: 0px 24px;
`;

const TagRow = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: row;
`;

const TagText = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  align-content: center;
`;

const Circle = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  display: flex;
  align-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 2px;
`;

export default function AddTagInputPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { queryParams, _ } = useQueryParams();

  const [query, setQuery] = useState();
  const [selectedTags, setSelectedTags] = useState([]);

  const distributors = useDistributorsQuery();
  const tags = [];
  // const tags = useTagsQuery({taggableType: queryParams.get("taggableType")});

  const combinedTags = useMemo(() => {
    // distributors와 tags 배열을 병합
    return [...(distributors || []), ...(tags || [])];
  }, [distributors, tags]);

  // 필터링된 태그를 메모이제이션
  const filteredTags = useMemo(() => {
    if (isEmpty(query)) return [];
    return combinedTags.filter((tag) => tag.name.includes(query));
  }, [query, combinedTags]);

  // query와 일치하는 태그가 있는지 여부를 메모이제이션
  const isQueryInTags = useMemo(() => {
    return combinedTags.some((tag) => tag.name === query);
  }, [query, combinedTags]);

  const onChangedInputText = (text) => {
    setQuery(text);
  };

  const onAddTagItem = (tag) => {
    const tempTags = [...selectedTags];
    const isExisted = find(tempTags, (tempTag) => tempTag.name === tag.name);
    const isAddedDistribotor = find(
      tempTags,
      (tempTag) => !!tempTag.distributorId
    );
    if (isAddedDistribotor && !!tag.distributorId) {
      return;
    } else if (!isExisted) {
      tempTags.push(tag);
      setSelectedTags(tempTags);
    }
  };

  const onRemoveTagItem = (tag) => {
    const updatedTags = selectedTags.filter(
      (selectedTag) => selectedTag !== tag
    );
    setSelectedTags(updatedTags);
  };

  const onConfirmedAddTag = async () => {
    const newDistributor = selectedTags.find((tag) => !!tag.distributorId);
    if (!isEmpty(newDistributor)) {
      console.log(newDistributor);
    }

    const newTags = selectedTags.filter((tag) => !!tag.tagId);
    if (!isEmpty(newTags)) {
      if (queryParams.get("isAppend")) {
        const response = await appendTags({
          taggableId: queryParams.get("taggableId"),
          taggableType: queryParams.get("taggableType"),
          tagNames: newTags.map((newTag) => newTag.name),
        });
        if (response && response.ok) {
          queryClient.invalidateQueries(["photoCardV1"]);
          navigate(-1);
        }
      } else {
        const response = await addTags({
          taggableId: queryParams.get("taggableId"),
          taggableType: queryParams.get("taggableType"),
          tagNames: newTags.map((newTag) => newTag.name),
        });
        if (response && response.ok) {
          queryClient.invalidateQueries(["photoCardV1"]);
          navigate(-1);
        }
      }
    }
  };

  return (
    <>
      <StickyHeader
        title={"정보 추가"}
        showBackButton={true}
        showTitle={true}
        rightContent={
          !isEmpty(selectedTags) && (
            <ConfirmText
              className="text-pure-black dark:text-pure-white"
              onClick={() => onConfirmedAddTag()}
            >
              {t("confirm")}
            </ConfirmText>
          )
        }
        onClickBackButton={() => navigate(-1)}
      />
      <TopContainer>
        {!isEmpty(selectedTags) && (
          <TagsWrapper className="border-b-[1px] border-[#D9D9D9] dark:border-[#262626]">
            <TagsList>
              {selectedTags.map((tag) => {
                return (
                  <RegularSolidButton
                    key={tag.tagId || tag.distributorId}
                    paddingY={"6px"}
                    paddingX={"8px"}
                    borderRadius={"12px"}
                    onClick={() => onRemoveTagItem(tag)}
                    text={tag.name}
                    icon={
                      <Circle className="bg-pure-white dark:bg-pure-black">
                        <CloseIcon
                          className="fill-primary-light dark:fill-primary-dark"
                          width={"10px"}
                          height={"10px"}
                        />
                      </Circle>
                    }
                  />
                );
              })}
            </TagsList>
          </TagsWrapper>
        )}
        <TagInputWrapper>
          <PrimaryInput
            placeholder={"ex) 쥬얼버전 또는 핫도그포카"}
            onChangeText={(e) => onChangedInputText(e.target.value)}
          />
        </TagInputWrapper>
      </TopContainer>
      <TagsListWrapper>
        {
          // 필터된 태그가 있을 때만 리스트 출력
          filteredTags.length > 0 &&
            filteredTags.map((tag) => {
              return (
                <TagRow key={tag.tagId} onClick={() => onAddTagItem(tag)}>
                  <TagText>
                    {HighlightedTagName({ tag: tag, query: query })}
                  </TagText>
                  {tag.category === "distributors" ? (
                    <RoundedBadge
                      bgColor={"#F72ED21A"}
                      textColor={"#F72ED2"}
                      text={"🛍️ 판매처"}
                      alignContent={"center"}
                      marginLeft={"6px"}
                      marginY={"6px"}
                      paddingY={"4px"}
                      paddingX={"7px"}
                    />
                  ) : (
                    <RoundedBadge
                      className="bg-surface-light dark:bg-surface-dark text-main-light-4 dark:text-main-dark-4"
                      text={`${tag.taggingsCount}개`}
                      alignContent={"center"}
                      marginLeft={"6px"}
                      marginY={"6px"}
                      paddingY={"4px"}
                      paddingX={"7px"}
                    />
                  )}
                  <Spacer />
                  <SecondaryButton text={"추가"} />
                </TagRow>
              );
            })
        }
        {
          // query가 존재하고, 기존 태그에 완전히 동일한 이름이 없을 때만 추가 버튼 표시
          query && !isQueryInTags && (
            <TagRow onClick={() => onAddTagItem({ name: query, tagId: query })}>
              {`"${query}" 추가하기`}
            </TagRow>
          )
        }
      </TagsListWrapper>
    </>
  );
}

const HighlightedTagName = ({ tag, query }) => {
  if (!query) return <span>{tag.name}</span>;

  // query에 해당하는 부분을 bold 처리
  const parts = tag.name.split(new RegExp(`(${query})`, "gi"));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === query.toLowerCase() ? (
          <b key={index}>{part}</b>
        ) : (
          <span key={index}>{part}</span>
        )
      )}
    </>
  );
};
