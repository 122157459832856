import { t } from "i18next";
import { useState } from "react";
import styled from "styled-components";
import { Trans } from "react-i18next";

import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";

const EventBox = styled.div`
  position: relative;
  padding: 20px;
  align-items: center;
  gap: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
`;

const EventYearWrapper = styled.div`
  gap: 6px;
  display: flex;
  flex-direction: row;
`;

const EventImage = styled.img`
  width: 92px;
  height: 92px;
  flex-shrink: 0;
  border-radius: 6px;
`;

const EventTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
`;

const EventTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  word-break: break-all;
`;
const EventDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  word-break: break-all;
`;

const EventBadgeWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const EventBadge = styled.div`
  padding: 4px 7px;
  gap: 10px;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
`;

export const YEAR_EVENTS_ACTIVITY = ["membership", "lightstick", "seasons"];

export const IMAGE_EVENTS_ACTIVITY = ["album", "offline", "content"];

const getFirstNumber = (string) => {
  return string.match(/\d+/)?.[0];
};

const EventYearText = styled.div`
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
`;

const EventActivityBox = ({
  event,
  activityCategory,
  handleSelectEvent,
  isShowBadge = true,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const onSelectedEvent = (eventId) => {
    setIsSelected(true);
    handleSelectEvent(eventId);
  };

  const EmptyImagePath = () => {
    switch (activityCategory) {
      case "album":
        return "/assets/backgrounds/emptyEvent/empty_bg_album.png";
      case "offline":
        return "/assets/backgrounds/emptyEvent/empty_bg_offline.png";
      case "content":
        return "/assets/backgrounds/emptyEvent/empty_bg_content.png";
      default:
        return "/assets/backgrounds/emptyEvent/empty_bg_album.png";
    }
  };

  const Desciprtion = (event) => {
    if (event.category === "album") {
      let description = `${event.countryEmoji || ""} ${
        event.albumType || ""
      }`.trim();
      if (event.albumVolume) {
        description = t("ReportPhotoCardEventsPage.albumDescription", {
          description: description,
          volume: event.albumVolume,
        });
      }
      if (event.albumEdition) {
        return `${description} • ${event.albumEdition}`;
      }
      return description;
    }
  };

  if (YEAR_EVENTS_ACTIVITY.includes(activityCategory)) {
    return (
      <EventYearWrapper
        key={event.eventId}
        onClick={() => onSelectedEvent(event.eventId)}
      >
        <EventYearText
          className="text-disabled-light dark:text-disabled-dark"
          isSelected={isSelected}
        >
          {`${getFirstNumber(event.name)}`}
        </EventYearText>
        <LikeShapeIcon
          width={"42px"}
          height={"42px"}
          className={
            isSelected
              ? "text-primary-light dark:text-primary-dark"
              : "text-disabled-light dark:text-disabled-dark"
          }
        />
      </EventYearWrapper>
    );
  } else if (IMAGE_EVENTS_ACTIVITY.includes(activityCategory)) {
    return (
      <EventBox
        className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
        key={event.eventId}
        onClick={() => onSelectedEvent(event.eventId)}
      >
        <EventImage
          src={event.pictureUrl ? event.pictureUrl : EmptyImagePath()}
        />
        <EventTextColumn>
          <EventDescription className="text-main-light-4 dark:text-main-dark-4">
            {Desciprtion(event)}
          </EventDescription>
          <EventTitle className="text-pure-black dark:text-pure-white">
            {event.name}
          </EventTitle>
          {event.localizedName && event.name !== event.localizedName && (
            <EventDescription className="text-main-light-4 dark:text-main-dark-4">
              {event.localizedName}
            </EventDescription>
          )}
        </EventTextColumn>
        {isShowBadge && event.eventId !== "NewEventId" && (
          <EventBadgeWrapper>
            <EventBadge
              className={
                event.visibility
                  ? "bg-surface-light dark:bg-surface-dark text-main-light-4 dark:text-main-dark-4"
                  : "bg -[rgba(255, 0, 0, 0.04)] text-[rgba(255, 58, 58, 1)]"
              }
            >
              {event.visibility
                ? event.categoryName
                : t("ReportSearchEventPage.pending")}
            </EventBadge>
          </EventBadgeWrapper>
        )}
      </EventBox>
    );
  } else {
    return (
      <EventBox
        className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
        key={event.eventId}
        onClick={() => onSelectedEvent(event.eventId)}
        style={{ minHeight: isShowBadge ? "96px" : "" }}
      >
        <EventTextColumn>
          <EventTitle className="text-pure-black dark:text-pure-white">
            {event.eventId === "NewEventId" ? (
              <Trans
                i18nKey="ReportSearchEventPage.newAddEvent"
                components={{
                  bold: (
                    <span className="text-primary-light dark:text-primary-dark" />
                  ),
                }}
                values={{ eventName: event.name }}
              />
            ) : (
              event.name
            )}
          </EventTitle>
          {event.localizedName && event.name !== event.localizedName && (
            <EventDescription className="text-main-light-4 dark:text-main-dark-4">
              {event.localizedName}
            </EventDescription>
          )}
        </EventTextColumn>
        {isShowBadge && event.eventId !== "NewEventId" && (
          <EventBadgeWrapper>
            <EventBadge
              className={
                event.visibility
                  ? "bg-surface-light dark:bg-surface-dark text-main-light-4 dark:text-main-dark-4"
                  : "bg -[rgba(255, 0, 0, 0.04)] text-[rgba(255, 58, 58, 1)]"
              }
            >
              {event.visibility
                ? event.categoryName
                : t("ReportSearchEventPage.pending")}
            </EventBadge>
          </EventBadgeWrapper>
        )}
      </EventBox>
    );
  }
};

export default EventActivityBox;
