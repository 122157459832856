import React from "react";

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
} from "@chakra-ui/react";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import PrimaryButton from "../buttons/PrimaryButton";
import { t } from "i18next";

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  margin-top: 36px;
`;

const Description = styled.div`
  padding-top: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`;

const SkipText = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`;

export default function ShowTradingProfileBottomDrawer({
  isOpen,
  onClose,
  onUpdateUserPreferences,
}) {
  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            },
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: "",
          }}
        >
          <DrawerBody paddingX={"20px"} maxHeight={"320px"}>
            <Title className="text-pure-black dark:text-pure-white">
              {t("ShowTradingProfileBottomDrawer.title")}
            </Title>
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {t("ShowTradingProfileBottomDrawer.description")}
            </Description>
            <PrimaryButton
              onClick={() => onUpdateUserPreferences()}
              text={t("ShowTradingProfileBottomDrawer.openButton")}
              paddingY={"16px"}
              marginTop={"36px"}
            />
            <SkipText
              className="text-sub-light-1 dark:text-sub-dark-1"
              onClick={() => onClose()}
            >
              {t("ShowTradingProfileBottomDrawer.skipButton")}
            </SkipText>
          </DrawerBody>
          <DrawerFooter
            className="safeAreaMarginBottom"
            width={"100%"}
            paddingTop={"10px"}
            paddingBottom={"0px"}
            paddingX={"20px"}
          ></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
