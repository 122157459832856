import { Divider, TabPanel, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import usePurchasingPhotoCardsQuery from "../../components/api/queries/usePurchasingPhotoCardsQuery";
import DisabledBadge from "../../components/badges/DisabledBadge";
import SecondayBadge from "../../components/badges/SecondayBadge";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import EmptyCase from "../../components/errors/EmptyCase";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import TradingPhotoCardBox from "../../components/tradings/TradingPhotoCardBox";
import { navigateReviewPath, reviewText } from "../../utils/TradingHelper";
import BoxTabs from "./BoxTabs";

const CounterInfoWrapper = styled.div`
  padding-top: 18px;
  padding-bottom: 12px;
`;

const Counter = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const PurchasingChatBoxWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const PurchasingChatBox = styled.div`
  border-radius: 12px;
  padding: 16px;
  align-items: center;
`;

const TradingPhotoCardBoxWrapper = styled.div`
  padding-top: 12px;
`;

const userProfileTextStyle = {
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18px",
  textAlign: "left",
};

export default function PurchasingPhotoCardsTab() {
  const navigate = useNavigate();
  const [tradingStatus, setTradingStatus] = useState("ongoing");
  const [paginatedPhotoCards, setPaginatedPhotoCards] = useState();
  const [meta, setMeta] = useState();

  const { data, isFetching, fetchNextPage, hasNextPage } =
    usePurchasingPhotoCardsQuery(tradingStatus);

  const handlePaginatedPhotoCards = (paginatedData) => {
    const photoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedPhotoCards(photoCards);
  };

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPhotoCards(data);
      setMeta(first(data.pages)?.meta);
    }
  }, [data]);

  const onSubTabSelected = (index) => {
    setTradingStatus(index === 0 ? "ongoing" : "completed");
  };

  const navigateReview = (tradingPhotoCard) => {
    navigate(navigateReviewPath(tradingPhotoCard));
  };

  return (
    <TabPanel padding={0}>
      <BoxTabs
        tabLabels={[
          t("myPhotoCards.tradingStatusSelectorOngoing"),
          t("myPhotoCards.tradingStatusSelectorCompleted"),
        ]}
        onTabSelected={(index) => onSubTabSelected(index)}
      />
      {isUndefined(paginatedPhotoCards) ? (
        <>
          <CounterInfoWrapper>
            <Counter>{t("myPhotoCards.totalCount", { count: "-" })}</Counter>
          </CounterInfoWrapper>
          <GridSkeletons
            gridSize={1}
            skeletonSize={1}
            skeletonHeight={"109px"}
          />
        </>
      ) : isEmpty(paginatedPhotoCards) ? (
        <EmptyCase
          emoji={"😳"}
          title={t("myPhotoCards.purchasingPhotoCardsTabEmptyCaseTitle")}
          buttonText={t("myPhotoCards.purchasingPhotoCardsTabEmptyCaseButton")}
          onClickButton={() =>
            navigate("/tradings/photo_cards?tabIndex=0")
          }
        />
      ) : (
        <>
          <CounterInfoWrapper>
            <Counter>
              {t("myPhotoCards.totalCount", {
                count: meta?.pagination?.totalCount,
              })}
            </Counter>
          </CounterInfoWrapper>
          <Virtuoso
            useWindowScroll
            style={{ height: "100%" }}
            endReached={loadMore}
            overscan={480}
            data={paginatedPhotoCards}
            itemContent={(_, e) => {
              return (
                <PurchasingChatBoxWrapper key={e.tradingPhotoCardId}>
                  <PurchasingChatBox className="border-[1px] border-disabled-light dark:border-disabled-dark">
                    <div
                      className="flex"
                      style={{ paddingBottom: "12px", alignItems: "center" }}
                      onClick={() =>
                        navigate(`/artists/${e.artistId}/chats/${e.chatRoomId}`)
                      }
                    >
                      <div>
                        <ProfileAvatar
                          widthAndHeight={"28px"}
                          profileImageUrl={
                            e.userProfile.thumbnailUrl ||
                            e.userProfile.pictureUrl
                          }
                        />
                      </div>
                      <div
                        className="flex-grow"
                        style={{ paddingLeft: "10px" }}
                      >
                        <Text style={userProfileTextStyle}>
                          {e.userProfile.nickname}
                        </Text>
                      </div>
                      <div className="flex-none">
                        {e.category === "sale" ? (
                          <SecondayBadge
                            text={t(
                              "myPhotoCards.purchasingPhotoCardsTabSaleBadge"
                            )}
                            fontWeight={600}
                            fontSize={"11px"}
                            paddingX={"7px"}
                            paddingY={"4px"}
                          ></SecondayBadge>
                        ) : (
                          <DisabledBadge
                            className="bg-surface-light dark:bg-surface-dark"
                            text={t(
                              "myPhotoCards.purchasingPhotoCardsTabEmptyExchangeBadge"
                            )}
                            fontWeight={600}
                            fontSize={"11px"}
                            paddingX={"7px"}
                            paddingY={"4px"}
                            borderRadius={"30px"}
                          ></DisabledBadge>
                        )}
                      </div>
                    </div>
                    <Divider />
                    <TradingPhotoCardBoxWrapper>
                      <TradingPhotoCardBox
                        tradingPhotoCard={e}
                        showPrice={true}
                        onClickBox={() =>
                          navigate(
                            `/artists/${e.artistId}/chats/${e.chatRoomId}`
                          )
                        }
                      />
                      {tradingStatus === "completed" &&
                        !isEmpty(e.receivedReviews) && (
                          <div
                            className="flex"
                            style={{ paddingTop: "14px", paddingRight: "6px" }}
                          >
                            <div
                              className="flex-1"
                              onClick={() => navigateReview(e)}
                            >
                              <SecondaryButton
                                text={reviewText(e)}
                                paddingY={"11px"}
                              />
                            </div>
                          </div>
                        )}
                    </TradingPhotoCardBoxWrapper>
                  </PurchasingChatBox>
                </PurchasingChatBoxWrapper>
              );
            }}
          />
        </>
      )}
    </TabPanel>
  );
}
