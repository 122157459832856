import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  Center,
  Stack,
  Text,
  FormControl,
  Input,
  Spinner,
  FormErrorMessage,
} from "@chakra-ui/react";
import { validate } from "react-email-validator";
import baseUrl from "../utils/baseUrl";
import Page from "../components/Page";
import { t } from "i18next";

const getUserLocale = () => {
  const primaryLanguage =
    navigator.languages && navigator.languages.length > 0
      ? navigator.languages[0]
      : null;
  const browserLanguage = navigator.language || null;
  const userLanguage = navigator.userLanguage || null;

  if (primaryLanguage && primaryLanguage !== "unknown") {
    return primaryLanguage;
  }

  if (browserLanguage && browserLanguage !== "unknown") {
    return browserLanguage;
  }

  if (userLanguage && userLanguage !== "unknown") {
    return userLanguage;
  }

  return null; // 모든 값이 유효하지 않으면 null 반환
};

async function requestVerificationCode(email, is_promotion_agreed) {
  return fetch(baseUrl() + "/auth/request_verification_code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      locale: getUserLocale(),
      is_promotion_agreed: is_promotion_agreed,
      is_terms_agreed: true,
    }),
  }).then((data) => data.json());
}

export default function RequestVerificationCodePage() {
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const isPromotionAgreed =
    currentQueryParams.get("isPromotionAgreed") === "true";

  const handleInputChange = (e) => setInput(e.target.value);
  const isError =
    input &&
    (!validate(input) ||
      input.endsWith(".con") ||
      input.endsWith("gamil.com") ||
      input.replace(/\s/g, "") !== input);

  const postEmail = async () => {
    if (isError)
      return alert(t("requestVerificationCodePage.emailErrorMessage"));
    setIsLoading(true);
    const response = await requestVerificationCode(input, isPromotionAgreed);
    setIsLoading(false);
    if (response && response.success) {
      navigate("/login_with_code", {
        state: {
          email: response.email,
        },
      });
    } else {
      setErrorMessage(response.errorMessage);
    }
  };

  return (
    <Page title={""}>
      <Stack spacing={0}>
        <Text
          className="whitespace-pre-line"
          fontSize="2xl"
          fontWeight={700}
          lineHeight={"120%"}
        >
          {t("requestVerificationCodePage.title")}
        </Text>
      </Stack>
      <Text
        className="whitespace-pre-line text-main-light-4 dark:text-main-dark-4"
        fontSize="15px"
        mt={2}
      >
        {t("requestVerificationCodePage.description")}
      </Text>
      <FormControl isInvalid={isError} mt={"44px"}>
        <Input
          className="bg-secondary-light dark:bg-secondary-dark
          hover:bg-secondary-light dark:hover:bg-secondary-dark"
          type="email"
          borderRadius={"12px"}
          value={input}
          onChange={handleInputChange}
          variant="filled"
          paddingY={6}
          placeholder={t("requestVerificationCodePage.placeHolder")}
        />
        {!isError ? (
          input === "" ? (
            <></>
          ) : (
            <></>
          )
        ) : errorMessage !== null && errorMessage ? (
          <FormErrorMessage fontSize={"13px"}>{errorMessage}</FormErrorMessage>
        ) : (
          <FormErrorMessage fontSize={"13px"}>
            {t("requestVerificationCodePage.emailErrorMessage")}
          </FormErrorMessage>
        )}
      </FormControl>
      <Center mt={10}>
        <Box
          className={`text-white bottomButton ${
            input !== ""
              ? "bg-primary-light dark:bg-primary-dark text-white"
              : "bg-disabled-light dark:bg-disabled-dark text-onDisabled-light dark:text-onDisabled-dark"
          }`}
          as="button"
          fontWeight={700}
          paddingY={"16px"}
          disabled={input === ""}
          borderRadius={56}
          minWidth={"335px"}
          minHeight={"52px"}
          onClick={postEmail}
        >
          {isLoading ? (
            <Spinner
              thickness="4px"
              speed="0.65s"
              size="sm"
              className="border-[4px] !border-label-light dark:!border-label-dark !border-t-primary-light dark:!border-t-primary-dark"
            />
          ) : (
            <>{t("next")}</>
          )}
        </Box>
      </Center>
    </Page>
  );
}
