import React from "react";

import { Image, Box, useColorMode } from "@chakra-ui/react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { usePreventScroll } from "../../utils/UIUtils";
import { t } from "i18next";

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  text-align: center;
  color: ${(props) =>
    props.colorMode === "dark" ? "main.dark.2" : "main.light.2"};
  white-space: pre-wrap;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: ${(props) =>
    props.colorMode === "dark" ? "main.dark.4" : "main.light.4"};
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Card = styled.div`
  padding: 10px 20px;
  border-radius: 24px;
  background: ${(props) =>
    props.colorMode === "dark"
      ? "linear-gradient(91.59deg, rgba(255, 255, 255, 0.1) 31.85%, rgba(255, 255, 255, 0.05) 102.92%)"
      : "linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%)"};
  border: 1px solid;
  border-image-source: ${(props) =>
    props.colorMode === "dark"
      ? "linear-gradient(134.41deg, rgba(255, 255, 255, 0.2) 5.69%, rgba(255, 255, 255, 0) 95.22%)"
      : "linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%)"};
  box-shadow: ${(props) =>
    props.colorMode === "dark"
      ? "0px 0px 24px 0px rgba(255, 255, 255, 0.1) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.2)"
      : "0px 0px 24px 0px rgba(255, 255, 255, 0.3) inset, 0px 4px 10px 0px rgba(0, 0, 0, 0.04)"};
`;

const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 20px;
  border-bottom: ${(props) => props.bottomBorder};
`;

const TextIconWrapper = styled.div`
  background: ${(props) =>
    props.colorMode === "dark" ? "surface.dark" : "surface.light"};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  place-content: center;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RowTextWrapper = styled.div`
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
`;

const RowTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  color: ${(props) =>
    props.colorMode === "dark" ? "main.dark.1" : "main.light.1"};
`;

const RowDescription = styled.div`
  padding-top: 6px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  color: ${(props) =>
    props.colorMode === "dark" ? "main.dark.3" : "main.light.3"};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  padding: 18px;
  padding-bottom: 10px;
`;

export default function FeedIntroPage() {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();

  const onConfirm = () => {
    localStorage.setItem("showFeedIntroPage", false);
    navigate("/feed");
  };

  usePreventScroll();
  return (
    <>
      <Box position="relative" height="100vh" overflow="hidden">
        <Box
          position="absolute"
          overflow="hidden"
          zIndex={1}
          backgroundColor={colorMode === "dark" ? "pure.black" : "pure.white"}
          height={"100vh"}
          minW={"calc(min(100vw, var(--chakra-sizes-prose)))"}
        />
        <Box
          position="absolute"
          bottom="0"
          overflow="hidden"
          zIndex={2}
          minW={"xl"}
          maxW={"xl"}
        >
          <Image
            src="/assets/backgrounds/_bg_notice_btm.png"
            objectFit="cover"
          />
        </Box>
        <Box
          position="absolute"
          height="100vh"
          overflow="hidden"
          zIndex={3}
          minW={"calc(min(100vw, var(--chakra-sizes-prose)))"}
          maxW={"xl"}
        >
          <Page
            title={t("feedIntroPage.title")}
            showBackButton={false}
            noGutter={true}
            noPadding={true}
            backgroundColor={"rgba(0, 0, 0, 0)"}
            themeColor={colorMode === "dark" ? "pure.black" : "pure.white"}
          >
            {
              <Box
                h={"100vh"}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <Columns>
                  <Title colorMode={colorMode}>
                    {t("feedIntroPage.introTitle")}
                  </Title>
                  <Image
                    src="/assets/icons/icon_feed_talks.png"
                    height={"119px"}
                    style={{ marginTop: "24px", marginBottom: "30px" }}
                  />
                  <Card colorMode={colorMode}>
                    <CardRow bottomBorder="1px solid rgba(0, 0, 0, 0.03)">
                      <TextIconWrapper colorMode={colorMode}>
                        {"👻"}
                      </TextIconWrapper>
                      <RowTextWrapper>
                        <RowTitle colorMode={colorMode}>
                          {t("feedIntroPage.firstRowTitle")}
                        </RowTitle>
                        <RowDescription colorMode={colorMode}>
                          {t("feedIntroPage.firstRowDescription")}
                        </RowDescription>
                      </RowTextWrapper>
                    </CardRow>
                    <CardRow bottomBorder="1px solid rgba(0, 0, 0, 0.03)">
                      <TextIconWrapper colorMode={colorMode}>
                        {"📣"}
                      </TextIconWrapper>
                      <RowTextWrapper>
                        <RowTitle colorMode={colorMode}>
                          {t("feedIntroPage.secondRowTitle")}
                        </RowTitle>
                        <RowDescription colorMode={colorMode}>
                          {t("feedIntroPage.secondRowDescription")}
                        </RowDescription>
                      </RowTextWrapper>
                    </CardRow>
                    <CardRow>
                      <TextIconWrapper colorMode={colorMode}>
                        {"💖"}
                      </TextIconWrapper>
                      <RowTextWrapper>
                        <RowTitle colorMode={colorMode}>
                          {t("feedIntroPage.thirdRowTitle")}
                        </RowTitle>
                        <RowDescription colorMode={colorMode}>
                          {t("feedIntroPage.thirdRowDescription")}
                        </RowDescription>
                      </RowTextWrapper>
                    </CardRow>
                  </Card>
                  <ButtonWrapper>
                    <PrimaryButton
                      text={t("confirm")}
                      width={"100%"}
                      paddingY={"16px"}
                      onClick={() => onConfirm()}
                    />
                  </ButtonWrapper>
                  <a href="/pocapan_terms.html">
                    <Description colorMode={colorMode}>
                      {t("feedIntroPage.confirmFeedAgreement")}
                    </Description>
                  </a>
                </Columns>
              </Box>
            }
          </Page>
        </Box>
      </Box>
    </>
  );
}
