import React, { useEffect, useState } from "react";

import { Box, Center, Input, InputGroup, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import styled from "styled-components";

import useSearchUserProfiles from "../../components/api/queries/SearchUserProfiles"; // 팔로우 API 호출
import useUserQuery from "../../components/api/queries/useUserQuery";
import StickyHeader from "../../components/StickyHeader";
import { SocialUserProfileList } from "../../components/userProfile/SocialUserProfileList";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";

const PageWrapper = styled.div`
  margin-top: calc(64px + env(safe-area-inset-top));
`;
const InputWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  align-items: center;
  padding: 0px 11px;
  width: 100%;
  margin: 0px 8px 0px 12px;
`;

const UserProfileSearchPage = () => {
  const [searchInput, setSearchInput] = useState("");
  const [searchNickname, setSearchNickname] = useState(null);
  const [userProfiles, setUserProfiles] = useState([]);

  const user = useUserQuery();
  const { data, fetchNextPage, hasNextPage, isFetching } =
    useSearchUserProfiles(searchNickname);

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedUserProfiles(data);
    }
  }, [data]);

  const handlePaginatedUserProfiles = (paginatedData) => {
    const profiles = paginatedData.pages?.flatMap((page) =>
      page.userProfiles.map((profile) => profile)
    );
    setUserProfiles(profiles);
  };

  const handleHistoryBack = useHandleHistoryBack();

  const isSearchInputValid = (searchInput) => {
    return !isEmpty(searchInput) && searchInput.length >= 1;
  };

  const handleSearch = async () => {
    if (isSearchInputValid(searchInput)) {
      setSearchNickname(searchInput);
    }
  };

  const onFollowed = (userProfileId, isFollowed) => {
    const updatedProfiles = userProfiles.map((profile) =>
      profile.userProfileId === userProfileId
        ? { ...profile, isFollowed: !isFollowed }
        : profile
    );
    // 새로운 Profiles로 업데이트
    setUserProfiles(updatedProfiles);
  };

  return (
    <>
      <StickyHeader
        title={t("UserProfileSearchPage.pageName")}
        transparent={true}
        headerContainer={
          <>
            <ArrowLeftIcon
              className="fill-pure-black dark:fill-pure-white"
              onClick={handleHistoryBack}
            />
            <InputWrapper className="bg-secondary-light dark:bg-secondary-dark">
              <InputGroup border={"transparent"}>
                <Input
                  className="text-main-light-2 dark:text-main-dark-2"
                  _focus={{ border: "transparent", boxShadow: "none" }}
                  _active={{ border: "transparent" }}
                  _hover={{ border: "transparent" }}
                  placeholder={t("UserProfileSearchPage.searchPlaceHolder")}
                  outline={"none"}
                  fontSize={"15px"}
                  paddingLeft={"3px"}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </InputGroup>
            </InputWrapper>
            <Box
              borderRadius={"8px"}
              className={
                !isSearchInputValid(searchInput)
                  ? "bg-secondary-light dark:bg-secondary-dark"
                  : "bg-primary-light dark:bg-primary-dark"
              }
              padding={"8px"}
              onClick={handleSearch}
            >
              <SearchIcon
                className="fill-onPrimary"
                width={"24px"}
                height={"24px"}
              />
            </Box>
          </>
        }
      />
      <PageWrapper>
        {isFetching ? (
          <Center paddingTop="24px">
            <Spinner
              size="sm"
              className="text-primary-light dark:text-primary-dark"
            />
          </Center>
        ) : (
          // ) : isEmpty(data) ? (
          //   <EmptyCase title={"닉네임을 입력해서 검색해주세요."} emoji={"🔍"} />
          // ) : isEmpty(data?.pages?.flatMap(page => page.userProfiles)) ? (
          //   <EmptyCase title={"해당 유저를 찾을 수 없습니다."} emoji={"🙂"} />
          // ) : ()
          <SocialUserProfileList
            currentUserProfileId={user?.baseUserProfile?.userProfileId}
            userProfiles={userProfiles || []}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isFetching={isFetching}
            onFollowed={onFollowed}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default UserProfileSearchPage;
