import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function AddMatchedTradingPhotoCard({artistId, photoCardId, sourceId, targetId}) {
  // Validate that at least one of photoCardId or sourceId is provided
  if (!photoCardId && !sourceId) {
    throw new Error("Either photoCardId or sourceId must be provided.");
  }

  return fetch(baseUrl() + `/api/v1/matched_trading_photo_cards`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      matched_trading_photo_card: {
        artist_id: artistId,
        photo_card_id: photoCardId,
        source_id: sourceId,
        target_id: targetId,
      },
    }),
  });
}
