import { Box, Flex, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FollowButton } from "../buttons/FollowButton";
import MutualMembersBottomDrawer from "../drawer/MutualMembersBottomDrawer";
import InfiniteScroll from "../InfiniteScroll";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import { Trans } from "react-i18next";

export const SocialUserProfileList = ({
  currentUserProfileId,
  userProfiles,
  hasNextPage,
  fetchNextPage,
  isFetching,
  onFollowed,
}) => {
  const navigate = useNavigate();
  const [mutualMembers, setMutualMembers] = useState([]);
  const [isShowMutualMemberBottomSheet, setIsShowMutualMemberBottomSheet] =
    useState(false);

  const navigateToUserProfile = (userProfileId) => {
    navigate(`/feed/user_profile/${userProfileId}`);
  };

  const isMine = (userProfileId) => {
    return currentUserProfileId === userProfileId;
  };

  const showMutualMemberBottomSheet = (members) => {
    setMutualMembers(members);
    setIsShowMutualMemberBottomSheet(true);
  };

  return (
    <>
      {
        <InfiniteScroll
          items={userProfiles || []}
          hasNextPage={hasNextPage}
          loadMore={fetchNextPage}
          isFetching={isFetching}
          renderItem={(item) => (
            <Box key={item.userProfileId} width={"100%"}>
              <Flex align="center" paddingX="24px" paddingY="16px">
                <ProfileAvatar
                  profileImageUrl={item.thumbnailUrl || item.pictureUrl}
                  widthAndHeight={"42px"}
                  svgWidthAndHeight={"28px"}
                  padding={"7px"}
                  onClick={() => navigateToUserProfile(item.userProfileId)}
                />
                <Flex gap={3} align="center" flex={1} marginLeft="12px">
                  <Flex
                    flex={1}
                    gap={"2px"}
                    minWidth={0}
                    direction={"column"}
                    onClick={() => navigateToUserProfile(item.userProfileId)}
                  >
                    <Text
                      className="text-main-light-1 dark:text-main-dark-1"
                      fontSize="15px"
                      lineHeight={"18px"}
                      fontWeight={500}
                      noOfLines={1}
                    >
                      {item.nickname}
                    </Text>
                    {!isEmpty(item.mutualMembers) &&
                      !isMine(item.userProfileId) && (
                        <Text
                          className="text-main-light-4 dark:text-main-dark-4"
                          fontSize={"13px"}
                          lineHeight={"15px"}
                          noOfLines={1}
                          onClick={() =>
                            showMutualMemberBottomSheet(item.mutualMembers)
                          }
                        >
                          {item.mutualMembers.length <= 2 ? (
                            <Trans
                              i18nKey="UserProfileSearchPage.mutualBias"
                              components={{ bold: <strong /> }}
                              values={{
                                members: item.mutualMembers.join(", "),
                              }}
                            />
                          ) : (
                            <Trans
                              i18nKey="UserProfileSearchPage.mutualBiasMore"
                              components={{ bold: <strong /> }}
                              values={{
                                members: `${item.mutualMembers
                                  ?.slice(0, 2)
                                  .join(", ")}`,
                                count: item.mutualMembers?.length - 2,
                              }}
                            />
                          )}
                        </Text>
                      )}
                  </Flex>
                  {!isMine(item.userProfileId) && (
                    <Flex shrink={0}>
                      {FollowButton({
                        currentUserProfileId: currentUserProfileId,
                        targetUserProfile: item,
                        onFollowed: onFollowed,
                      })}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </Box>
          )}
        />
      }
      {isShowMutualMemberBottomSheet && (
        <MutualMembersBottomDrawer
          isOpen={isShowMutualMemberBottomSheet}
          onClose={() => setIsShowMutualMemberBottomSheet(false)}
          members={mutualMembers}
        />
      )}
    </>
  );
};
