import React, { useState } from "react";

import { t } from "i18next";
import styled from "styled-components";
import { first } from "lodash";

import CommonDialog from "../../components/alerts/CommonDialog";
import AddEvent from "../../components/api/mutations/AddEvent";
import useArtistEventsQuery from "../../components/api/queries/useArtistEventsQuery";
import PrimaryInput from "../../components/input/PrimaryInput";
import { ReportPhotoCardBreadcrumb } from "../../components/report/ReportPhotoCardBreadcrumb";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";
import useArtistQuery from "../../components/api/queries/useArtistQuery";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";
import EventActivityBox, {
  YEAR_EVENTS_ACTIVITY,
} from "../../components/event/EventActivityBox";
import { Input, InputGroup } from "@chakra-ui/react";

const ReportPhotoCardBreadcrumbWrapper = styled.div`
  padding: calc(54px + env(safe-area-inset-top)) 24px 10px 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 0px 24px 10px 24px;
`;

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  margin-top: 22px;
`;

const ArtistDebutedDesciprtion = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  padding: 0px 24px 10px 24px;
`;

const AddEventEventButton = styled.div`
  margin-top: 13px;
  border-radius: 12px;
  background: ${LightColors.purewhite};
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 0px 14px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 20px;
  color: ${LightColors.primary};
`;

const AddEventEventText = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
`;
const InputWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  margin: 6px 24px 0px 24px;
  align-items: center;
  padding: 2px 11px;
`;

export default function ReportPhotoCardEventsPage() {
  const navigate = useNavigate();
  const [eventName, setEventName] = useState();
  const [showAddEventDialog, setShowAddEventDialog] = useState(false);
  const { queryParams, updateQueryParams } = useQueryParams();

  const artist = useArtistQuery(queryParams.get("artistId"));

  const events = useArtistEventsQuery({
    artistId: queryParams.get("artistId"),
    category: queryParams.get("activity"),
  });

  const ActivityTitle = () => {
    switch (queryParams.get("activity")) {
      case "album":
        return t("ReportPhotoCardEventsPage.albumTitle");
      case "content":
        return t("ReportPhotoCardEventsPage.contentTitle");
      case "offline":
        return t("ReportPhotoCardEventsPage.offlineTitle");
      case "ADs":
        return t("ReportPhotoCardEventsPage.adsTitle");
      default:
        return t("ReportPhotoCardEventsPage.defaultTitle");
    }
  };

  const handleSelectEvent = (eventId) => {
    updateQueryParams({ eventId }, "/report-photo-card/card-categories");
  };

  const handleSearchEvent = () => {
    updateQueryParams({}, "/report-photo-card/search-events");
  };

  const handleAddEvent = () => {
    setEventName(null);
    setShowAddEventDialog(true);
  };

  const onConfirmAddEvent = async () => {
    const response = await AddEvent({
      artistId: queryParams.get("artistId"),
      name: eventName,
      category: queryParams.get("activity"),
    });
    if (response && response.ok) {
      const responseBody = await response.json();
      const eventId = responseBody.event.eventId;
      if (eventId) {
        setShowAddEventDialog(false);
        updateQueryParams({ eventId }, "/report-photo-card/card-categories");
      }
    }
  };

  return (
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showTitle={true}
        showBackButton={true}
        onClickBackButton={() => navigate(-1)}
      />
      <ReportPhotoCardBreadcrumbWrapper>
        <ReportPhotoCardBreadcrumb artistId={"newjeans"} />
      </ReportPhotoCardBreadcrumbWrapper>
      <Title>{ActivityTitle()}</Title>
      {artist && YEAR_EVENTS_ACTIVITY.includes(queryParams.get("activity")) && (
        <ArtistDebutedDesciprtion className="text-main-light-4 dark:text-main-dark-4">
          <Trans
            i18nKey="ReportPhotoCardEventsPage.debutedDescription"
            components={{ bold: <strong /> }}
            values={{
              artistName: artist.name ?? "-",
              debutedYear: first(artist.debutedAt.split("-")),
            }}
          />
        </ArtistDebutedDesciprtion>
      )}
      <InputWrapper
        className="bg-secondary-light dark:bg-secondary-dark"
        onClick={() => handleSearchEvent()}
      >
        <SearchIcon
          className="fill-placeholder-light dark:fill-placeholder-dark"
          width={"24px"}
          height={"24px"}
        />
        <InputGroup border={"transparent"}>
          <Input
            paddingLeft={"3px"}
            readOnly={true}
            _focus={{ border: "transparent", boxShadow: "none" }}
            _active={{ border: "transparent" }}
            _hover={{ border: "transparent" }}
            outline={"none"}
            placeholder={t("ReportSearchEventPage.placeHolder")}
            fontSize={"15px"}
          />
        </InputGroup>
      </InputWrapper>
      <ActivityBoxWrapper>
        {events?.map((event) => {
          return (
            <EventActivityBox
              key={event.eventId}
              event={event}
              activityCategory={queryParams.get("activity")}
              handleSelectEvent={handleSelectEvent}
            />
          );
        })}
        {/* {
          !YEAR_EVENTS_ACTIVITY.includes(queryParams.get("activity")) &&
          <AddEventEventButton onClick={() => handleAddEvent()}>
            <AddEventEventText>
              {t("ReportPhotoCardEventsPage.addManually")}
            </AddEventEventText>
          </AddEventEventButton>
        } */}
      </ActivityBoxWrapper>
      {showAddEventDialog && (
        <CommonDialog
          isOpen={showAddEventDialog}
          onClose={() => setShowAddEventDialog(false)}
          title={ActivityTitle()}
          description={t("ReportPhotoCardEventsPage.eventDialogTitle")}
          children={
            <PrimaryInput
              defaultValue={null}
              placeholder={t(
                "ReportPhotoCardEventsPage.eventDialogInputPlaceholder"
              )}
              onChangeText={(e) => setEventName(e.target.value)}
              style={{ marginTop: "16px" }}
            />
          }
          onClickDialogButton={() => onConfirmAddEvent()}
        />
      )}
    </>
  );
}
