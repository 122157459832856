import React from "react";
import { Box, Center, Text } from "@chakra-ui/react";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { ReactComponent as ShowAllCardIcon } from "../../icons/show.all.cards.svg";

export default function FabButton({ icon, text, onClickFabButton, ...props }) {
  return (
    <div className="flex flex-row-reverse">
      <Box
        className="bg-onSecondary-light dark:bg-onSecondary-dark bottomButtonOnBottomNav"
        borderRadius="full"
        marginRight={"16px"}
        onClick={() => onClickFabButton()}
        padding={"16px 18px 16px 18px"}
        height={"52px"}
        boxShadow={"0px 6px 10px 0px #2B1B8D57"}
        {...props}
      >
        <Center width={"full"} height={"full"}>
          {icon === "showAllCards" ? (
            <ShowAllCardIcon
              className="fill-white"
              width={"24px"}
              height={"24px"}
            />
          ) : (
            <PlusIcon className="fill-white" width={"24px"} height={"24px"} />
          )}
          {text && (
            <Text
              className="text-white"
              lineHeight={"20.4px"}
              fontWeight={600}
              fontSize={17}
              paddingLeft={"5px"}
            >
              {text}
            </Text>
          )}
        </Center>
      </Box>
    </div>
  );
}
