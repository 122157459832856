import React from "react";

import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  Spacer,
  Switch,
  Text,
} from "@chakra-ui/react";

import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowTriangleIcon } from "../../icons/arrowtriangle.down.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron.down.svg";
import ArtistTextSelector from "../selectors/ArtistTextSelector";
import { useArtistQueryLikedOnly } from "../api/queries/useArtistQuery";

const titleStyle = {
  paddingLeft: "7px",
  fontSize: "14px",
  fontWeight: 500,
};

const boxStyle = {
  borderRadius: "12px",
  height: "44px",
  padding: "12px 20px",
};

export default function SwitchToggleBar({
  text,
  checked,
  onToggled,
  onSelectedArtist,
}) {
  const artists = useArtistQueryLikedOnly();
  return (
    <Flex
      className="bg-surface-light dark:bg-surface-dark"
      align={"center"}
      style={boxStyle}
    >
      <Switch
        size="sm"
        defaultChecked={checked}
        colorScheme="primary"
        onChange={(e) => onToggled(e.target.checked)}
      />
      <Text style={titleStyle}> {text} </Text>
      <Spacer />
      {checked && (
        <ArtistTextSelector
          artists={artists}
          onSelected={(artist) => onSelectedArtist(artist)}
          alignDropdown="left"
          customTextAreaElementMultiple={(artist) => (
            <MenuButton
              className="text-primary-light dark:text-primary-dark"
              as={Button}
              colorScheme=""
              width={"fit-content"}
              padding={0}
              rightIcon={
                <Box display="flex" alignItems="center" justifyContent="center">
                  <ArrowTriangleIcon
                    width={"12px"}
                    height={"12px"}
                    className="fill-primary-light dark:fill-primary-dark"
                  />
                </Box>
              }
            >
              <Box display="flex" alignItems="center" justifyContent="center">
                <Text
                  className="text-primary-light dark:text-primary-dark"
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {artist?.name}
                </Text>
              </Box>
            </MenuButton>
          )}
          customTextAreaElementSingle={(artist) => (
            <DropdownTextOnly artist={artist} />
          )}
        />
      )}
    </Flex>
  );
}

// 크기, 위치 등을 맞추기 힘들어서 텍스트만 보이지만 메뉴를 활용한다
const DropdownTextOnly = ({ artist }) => {
  return (
    <Menu>
      <MenuButton
        className="text-pure-black dark:text-pure-white"
        height={"40px"}
        as={Button}
        colorScheme=""
        width={"fit-content"}
        padding={0}
        rightIcon={
          <Box
            height="40px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronDownIcon
              className="fill-main-light-3 dark:fill-main-dark-3"
              width={"22px"}
              height={"22px"}
              opacity={0}
            />
          </Box>
        }
      >
        <Box
          height="40px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Text
            fontSize={"26px"}
            fontWeight={700}
            className="text-main-light-1 dark:text-main-dark-1"
          >
            {artist?.name}
          </Text>
        </Box>
      </MenuButton>
    </Menu>
  );
};
