import React, { useEffect, useState } from "react";

import {
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  useColorMode,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import StickyHeader from "../components/StickyHeader";
import MatchedPhotoCardResponse from "../components/api/model/MatchedPhotoCardResponse";
import TradingPhotoCardResponse from "../components/api/model/TradingPhotoCardResponse";
import updateCollectedPhotoCard from "../components/api/mutations/legacy/updateCollectedPhotoCard";
import updateWishedPhotoCard from "../components/api/mutations/legacy/updateWishedPhotoCard";
import useMatchedTradingPhotoCardsQuery from "../components/api/queries/useMatchedTradingPhotoCardsQuery";
import usePhotoCardQuery from "../components/api/queries/usePhotoCardQuery";
import usePostsQuery from "../components/api/queries/usePostsQuery";
import useSalesTradingPhotoCardsQuery from "../components/api/queries/useSalesTradingPhotoCardsQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import ChangePhotoCardCollectedCountBottomSheet from "../components/bottomSheet/ChangePhotoCardCollectedCountBottomSheet";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import WarningButton from "../components/buttons/WarningButton";
import FullSizeImageViewer from "../components/chats/ImageViewer";
import PostsList from "../components/community/PostsList";
import LikeLineIcon from "../components/icons/LikeLineIcon";
import LikeShapeIcon from "../components/icons/LikeShapeIcon";
import { ReactComponent as ReportLikeIcon } from "../icons/report.like.svg";
import { TagsList } from "../components/photocard/TagsList";
import GridSkeletons from "../components/skeletons/GridSkeletons";
import MatchedTradingPhotoCardsHorizontalList from "../components/tradings/MatchedTradingPhotoCardsHorizontalList";
import PhotoCardFirstCaptorCard from "../components/tradings/PhotoCardFirstCaptorCard";
import SalesTradingPhotoCardProfileRowListCard from "../components/tradings/SalesTradingPhotoCardProfileRowListCard";
import { setEvent } from "../utils/ga";

const NavigationWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const PhotoCardSection = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: row;
  display: flex;
`;

const ImageWrapper = styled.div`
  flex: none;
`;

const EventName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const PhotoCardName = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const SectionWrapper = styled.div`
  padding-top: 32px;
  padding-left: 24px;
  padding-right: 24px;
`;

const Title = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const Description = styled.div`
  padding-top: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`;

const PostListWrapper = styled.div`
  margin-top: 12px;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
`;

const commonMenuStyle = {
  justifyContent: "end",
  fontSize: "14px",
  fontWeight: 600,
};

export default function PhotoCardDetailPage() {
  const { colorMode } = useColorMode();
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [photoCard, setPhotoCard] = useState();
  const [tradingPhotoCards, setTradingPhotoCards] = useState();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [matchedTradingPhotoCards, setMatchedTradingPhotoCards] = useState();
  const [
    showChangeCollectCountBottomSheet,
    setShowChangeCollectCountBottomSheet,
  ] = useState(false);
  const [isImageClicked, setIsImageClicked] = useState(false);

  const photoCardData = usePhotoCardQuery(params.photo_card_id);
  useEffect(() => {
    if (!isEmpty(photoCardData)) {
      setPhotoCard(photoCardData);
    }
  }, [photoCardData]);

  const { data: salesTradingPhotoCardsData } = useSalesTradingPhotoCardsQuery(
    params.photo_card_id
  );
  useEffect(() => {
    if (!isEmpty(salesTradingPhotoCardsData)) {
      handlePaginatedSalesTradingPhotoCardData(
        salesTradingPhotoCardsData.pages
      );
    }
  }, [salesTradingPhotoCardsData]);

  const handlePaginatedSalesTradingPhotoCardData = (newPaginatedData) => {
    const tradingPhotoCards = newPaginatedData?.flatMap((page) =>
      page.tradingPhotoCards.map(
        (tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard)
      )
    );
    setTradingPhotoCards(tradingPhotoCards);
  };

  const { data: matchedTradingPhotoCardsData } =
    useMatchedTradingPhotoCardsQuery(params.photo_card_id);
  useEffect(() => {
    if (!isEmpty(matchedTradingPhotoCardsData)) {
      handlePaginatedMachedTradingPhotoCardData(
        matchedTradingPhotoCardsData.pages
      );
    }
  }, [matchedTradingPhotoCardsData]);

  const handlePaginatedMachedTradingPhotoCardData = (newPaginatedData) => {
    const matchedPhotoCards = newPaginatedData?.flatMap((page) =>
      page.matchedPhotoCards.map(
        (tradingPhotoCard) => new MatchedPhotoCardResponse(tradingPhotoCard)
      )
    );
    setMatchedTradingPhotoCards(matchedPhotoCards);
  };

  const user = useUserQuery();
  const countryCode = localStorage.getItem("feedCountryCode");
  const {
    data: postsData,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = usePostsQuery({
    country: countryCode || user?.country,
    photoCardId: params.photo_card_id,
  });

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedPosts(posts);
  };

  const loadMorePosts = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(postsData)) {
      handlePaginatedPosts(postsData);
      // setMeta(first(data.pages)?.meta);
    }
  }, [postsData]);

  const toggleWishedState = async (isWished, card) => {
    setEvent({
      category: "PhotoCard",
      action: isWished ? "UnWished PhotoCard" : "Wished PhotoCard",
    });
    const response = await updateWishedPhotoCard(
      isWished,
      card.artistId,
      card.memberId,
      [card.photoCardId]
    );
    if (response.success) {
      invalidateQueries(card);
      setPhotoCard((prevData) => ({
        ...prevData,
        isWished: !isWished,
      }));
    }
  };

  const updateCollectedCount = async (card, collectedCount) => {
    setEvent({
      category: "PhotoCard",
      action:
        collectedCount > 0 ? "Collected PhotoCard" : "UnCollected PhotoCard",
    });
    const response = await updateCollectedPhotoCard(
      card.photoCardId,
      collectedCount
    );
    if (response.success) {
      invalidateQueries(card);
      setPhotoCard((prevData) => ({
        ...prevData,
        collectedCount: collectedCount,
      }));

      window.opener?.postMessage(
        {
          type: "update-collected-count-on-artist-photo-cards",
          params: {
            photoCardId: card.photoCardId,
            collectedCount: collectedCount,
          },
        },
        window.location.origin
      );
    }
  };

  const invalidateQueries = (photoCard) => {
    queryClient.invalidateQueries(["photoCardV1", photoCard.photoCardId]);
    queryClient.invalidateQueries([
      "photoCardEvents",
      photoCard.artistId,
      photoCard.memberId,
    ]);
  };

  const navigateHome = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate("/home", { replace: true });
    }
  };

  const isCollected = () => {
    if (photoCard) {
      return photoCard.collectedCount > 0;
    } else {
      return false;
    }
  };

  const navigatePhotoCardReport = (photoCardId) => {
    navigate(`/report/photo-card?photoCardId=${photoCardId}`);
  };

  return (
    <div
      className={
        colorMode === "dark" ? "darkGradientBackground" : "gradientBackground"
      }
    >
      <StickyHeader
        transparent={true}
        showBackButton={true}
        rightContent={
          photoCard && (
            <NavigationWrapper>
              <div
                style={{ paddingRight: "6px" }}
                onClick={() => navigatePhotoCardReport(photoCard.photoCardId)}
              >
                <ReportLikeIcon
                  width={"24px"}
                  height={"24px"}
                  className="fill-pure-black dark:fill-pure-white"
                />
              </div>
              <div
                onClick={() => toggleWishedState(photoCard.isWished, photoCard)}
              >
                {photoCard.isWished ? (
                  <LikeShapeIcon width={"24px"} height={"24px"} />
                ) : (
                  <LikeLineIcon width={"24px"} height={"24px"} />
                )}
              </div>
            </NavigationWrapper>
          )
        }
      />
      {/* <Page
        title={""}
        onHistoryBackClick={() => back()}
        navigationElement={photoCard && (
          <NavigationWrapper>
            <div onClick={() => toggleWishedState(photoCard.isWished, photoCard)}>
            {
              photoCard.isWished ?
              <LikeShapeIcon width={"24px"} height={"24px"} /> :
              <LikeLineIcon width={"24px"} height={"24px"} />
            }
            </div>
          </NavigationWrapper>
        )}
        noGutter={true}
        noPadding={true}
        style={isImageClicked ? {touchAction: "none"} : {}}
      > */}

      <PhotoCardSection>
        {isEmpty(photoCard) ? (
          <div className="w-full">
            <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128} />
          </div>
        ) : (
          <>
            <ImageWrapper>
              <Image
                key={photoCard.photoCardId}
                src={photoCard.largeThumbnailUrl || photoCard.pictureUrl}
                height={"120px"}
                maxWidth={"75px"}
                objectFit={"cover"}
                borderRadius="8px"
                boxShadow={
                  "0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(0, 0, 0, 0.08)"
                }
                onClick={() => setIsImageClicked(true)}
              />
            </ImageWrapper>
            <Stack
              alignItems={"start"}
              justify={"center"}
              paddingLeft={"16px"}
              className="flex-grow"
            >
              <EventName className="text-main-light-2 dark:text-main-dark-2">
                {t("photoCardDetailPage.title", {
                  memberName: photoCard.memberName,
                  categoryName: photoCard.categoryName,
                  categoryEmoji: photoCard.categoryEmoji,
                })}
              </EventName>
              <PhotoCardName className="text-main-light-3 dark:text-main-dark-3">
                {photoCard.eventName}
              </PhotoCardName>
              {/* <PhotoCardCategoryBadge>
                    {`${photoCard.categoryEmoji} ${photoCard.categoryName}`}
                  </PhotoCardCategoryBadge> */}
              {/* <EventName>{`${photoCard.eventName}`}</EventName> */}
              <TagsWrapper>
                <TagsList photoCard={photoCard} />
              </TagsWrapper>
            </Stack>
          </>
        )}
      </PhotoCardSection>
      <div>
        <SimpleGrid
          columns={2}
          paddingX={"24px"}
          paddingTop={"14px"}
          spacing={"8px"}
        >
          {isCollected() ? (
            <Menu>
              <MenuButton width={"100%"}>
                <SecondaryButton
                  borderRadius={"10px"}
                  paddingY={"14px"}
                  text={t("photoCardDetailPage.collectedCount", {
                    count: photoCard?.collectedCount,
                  })}
                />
              </MenuButton>
              <MenuList minWidth={"fit-content"}>
                <MenuItem
                  className="text-main-light-2 dark:text-main-dark-2"
                  style={commonMenuStyle}
                  onClick={() => updateCollectedCount(photoCard, 0)}
                >
                  {t("photoCardDetailPage.changeToNotCollected")}
                </MenuItem>
                <MenuItem
                  className="text-main-light-2 dark:text-main-dark-2"
                  style={commonMenuStyle}
                  onClick={() => setShowChangeCollectCountBottomSheet(true)}
                >
                  {t("photoCardDetailPage.changeCollectedCount")}
                </MenuItem>
                {/* <MenuItem style={commonMenuStyle} onClick={() => navigateTradingPhotoCard(photoCard.artistId)}>{"이 포카 양도하기"}</MenuItem> */}
              </MenuList>
            </Menu>
          ) : (
            <WarningButton
              borderRadius={"10px"}
              paddingY={"14px"}
              text={t("photoCardDetailPage.collectedCount", { count: 0 })}
              onClick={() => setShowChangeCollectCountBottomSheet(true)}
            />
          )}
          <PrimaryButton
            borderRadius={"10px"}
            paddingY={"14px"}
            text={t("photoCardDetailPage.tradeButton")}
            disabled={!isCollected()}
          />
        </SimpleGrid>
      </div>
      {photoCard && FirstCaptor(photoCard)}
      {isUndefined(matchedTradingPhotoCards) ? (
        <SectionWrapper>
          <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={6} />
          <MatchedTradingPhotoCardsHorizontalList
            matchedTradingPhotoCards={matchedTradingPhotoCards}
          />
        </SectionWrapper>
      ) : (
        !isEmpty(matchedTradingPhotoCards) && (
          <SectionWrapper>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("photoCardDetailPage.exchangeTradingPhotoCardTitle")}
            </Title>
            <MatchedTradingPhotoCardsHorizontalList
              matchedTradingPhotoCards={matchedTradingPhotoCards}
            />
          </SectionWrapper>
        )
      )}
      {isUndefined(tradingPhotoCards) ? (
        <SectionWrapper>
          <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={6} />
          <SalesTradingPhotoCardProfileRowListCard
            tradingPhotoCards={tradingPhotoCards}
          />
        </SectionWrapper>
      ) : (
        photoCard && (
          <SectionWrapper>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("photoCardDetailPage.saleTradingPhotoCardTitle", {
                fandom: photoCard.fandomName,
              })}
            </Title>
            <SalesTradingPhotoCardProfileRowListCard
              tradingPhotoCards={tradingPhotoCards}
            />
          </SectionWrapper>
        )
      )}
      <SectionWrapper>
        <Flex>
          <div style={{ flexGrow: "1" }}>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("photoCardDetailPage.feedTitle", {
                fandom: photoCard?.fandomName,
              })}
            </Title>
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {t("photoCardDetailPage.feedDescription")}
            </Description>
          </div>
          {!isEmpty(paginatedPosts) && (
            <SecondaryButton
              fontWeight={700}
              text={t("photoCardDetailPage.showAllFeed")}
              onClick={() => navigateHome()}
            />
          )}
        </Flex>
      </SectionWrapper>
      <PostListWrapper>
        <PostsList
          user={user}
          posts={paginatedPosts}
          loadMore={loadMorePosts}
          hasMore={hasNextPage}
        />
      </PostListWrapper>
      {/* </Page> */}
      {isImageClicked && (
        <FullSizeImageViewer
          imageUrl={photoCard.pictureUrl}
          onClosed={() => setIsImageClicked(false)}
        />
      )}
      {photoCard && (
        <ChangePhotoCardCollectedCountBottomSheet
          isShowChangeCollectCountBottomSheet={
            showChangeCollectCountBottomSheet
          }
          setIsShowChangeCollectCountBottomSheet={
            setShowChangeCollectCountBottomSheet
          }
          currentCollectedCount={
            photoCard.collectedCount > 0 ? photoCard.collectedCount : 1
          }
          updatedCollectedCount={(collectedCount) =>
            updateCollectedCount(photoCard, collectedCount)
          }
        />
      )}
    </div>
  );
}

const FirstCaptor = (photoCard) => {
  if (
    photoCard.artistId === "plave" &&
    photoCard.eventId === "08172fb0-80ee-4540-b2d5-8315913c2aa1"
  ) {
    return (
      <SectionWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("photoCardDetailPage.firstCaptorTitle")}
        </Title>
        <PhotoCardFirstCaptorCard
          name={"오동"}
          imagePath={"/assets/profiles/odong.png"}
          description={"x.com/zxzxeu"}
        />
      </SectionWrapper>
    );
  } else if (
    photoCard.artistId === "plave" &&
    ![
      "6b013439-77c7-4037-aec8-88dbd497b716",
      "c8b6abba-2354-4f49-b93c-28631d574d50",
    ].includes(photoCard.eventId)
  ) {
    return (
      <SectionWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("photoCardDetailPage.firstCaptorTitle")}
        </Title>
        <PhotoCardFirstCaptorCard
          name={"🦙배덕🐬"}
          imagePath={"/assets/profiles/baeduk.png"}
          description={"twitter.com/eejrl"}
        />
      </SectionWrapper>
    );
  } else if (!isEmpty(photoCard.firstCaptor)) {
    return (
      <SectionWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("photoCardDetailPage.firstCaptorTitle")}
        </Title>
        <PhotoCardFirstCaptorCard
          name={photoCard.firstCaptor.nickname}
          imagePath={photoCard.firstCaptor.thumbnailUrl}
        />
      </SectionWrapper>
    );
  } else {
    return <></>;
  }
};
