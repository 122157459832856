import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { filter, isEmpty } from "lodash";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import styled from "styled-components";

import usePhotoCardsQuery from "../../components/api/queries/usePhotoCardsQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import NewSaleTradingPhotoCard from "../../components/tradings/NewSaleTradingPhotoCard";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { fetchPaginatedData } from "../../utils/PaginationUtil";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import { useColorMode } from "@chakra-ui/react";

const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-bottom: 15px;
  padding-left: calc(16px + env(safe-area-inset-left));
  padding-right: calc(16px + env(safe-area-inset-right));
  z-index: 1000;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  padding: 0 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ListWrapper = styled.div`
  padding: 12px 24px 64px 24px;
`;

export default function AddSalesPriceTradingPhotoCardsPage() {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const { state } = useLocation();
  const handleHistoryBack = useHandleHistoryBack();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const category = currentQueryParams.get("category");
  const artistId = currentQueryParams.get("artistId");
  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  const { data: photoCardsData } = usePhotoCardsQuery({
    artistId: artistId,
    photoCardIds: state.photoCardIds,
    perPage: 1000,
  });

  useEffect(() => {
    if (!isEmpty(photoCardsData)) {
      setNewTradingPhotoCards(
        fetchPaginatedData(photoCardsData, "photoCards").map((photoCard) => {
          return {
            tradingPhotoCardId: uuid(),
            category: category,
            artistId: artistId,
            status: "wating",
            price: null,
            photoCard: photoCard,
          };
        })
      );
    }
  }, [artistId, photoCardsData, category]);

  const user = useUserQuery();

  const onDone = async () => {
    navigate(
      `/tradings/confirm_new_trading_photo_cards?artistId=${artistId}&category=sale`,
      {
        state: { newTradingPhotoCards: newTradingPhotoCards },
      }
    );
  };

  const onChangedItem = (item) => {
    const items = newTradingPhotoCards.map((tradingPhotoCard) => {
      if (tradingPhotoCard.tradingPhotoCardId === item.tradingPhotoCardId) {
        return { ...item, price: item.price };
      } else {
        return tradingPhotoCard;
      }
    });
    setNewTradingPhotoCards(items);
  };

  const onDeletedItem = (item) => {
    const items = filter(newTradingPhotoCards, (tradingPhotoCard) => {
      return tradingPhotoCard.tradingPhotoCardId !== item.tradingPhotoCardId;
    });
    setNewTradingPhotoCards(items);

    if (isEmpty(items)) {
      alert(t("ConfirmNewSalesTradingPhotoCardsPage.removeAllDialog"));
      handleHistoryBack();
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("ConfirmNewSalesTradingPhotoCardsPage.title")}</title>{" "}
        {/* Helmet을 사용하여 title 설정 */}
      </Helmet>
      <Header className="bg-background-light dark:bg-background-dark text-pure-white dark:text-pure-black">
        <LeftContainer>
          {
            <ArrowLeftIcon
              className="dark:fill-pure-white fill-pure-black"
              onClick={handleHistoryBack}
            />
          }
        </LeftContainer>
      </Header>
      <TitleWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("ConfirmNewSalesTradingPhotoCardsPage.title")}
        </Title>
        <Description className="text-sub-light-1 dark:text-sub-dark-1">
          {t("ConfirmNewSalesTradingPhotoCardsPage.description")}
        </Description>
      </TitleWrapper>
      <ListWrapper>
        <div>
          {newTradingPhotoCards.map((tradingPhotoCard) => {
            return (
              <div
                key={tradingPhotoCard.tradingPhotoCardId}
                style={{ marginBottom: "16px" }}
              >
                <NewSaleTradingPhotoCard
                  user={user}
                  tradingPhotoCard={tradingPhotoCard}
                  onChangedItem={(item) => onChangedItem(item)}
                  onDeletedItem={(item) => onDeletedItem(item)}
                />
              </div>
            );
          })}
        </div>
      </ListWrapper>
      <div
        className={`${
          colorMode === "dark" ? "darkBottomNavbar" : "bottomNavbar"
        } text-center bg-background-light dark:bg-background-dark`}
        style={{
          padding: "0px 24px calc(16px + env(safe-area-inset-bottom)) 24px",
          zIndex: 9999,
        }}
      >
        <PrimaryButton
          paddingY={"16px"}
          text={t("next")}
          zIndex={9999}
          onClick={() => onDone()}
        />
      </div>
    </>
  );
}
