// src/pages/KeywordGridPage.js
import { Grid, GridItem, VStack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { t } from "i18next";
import StickyHeader from "../../components/StickyHeader";
import AddBuddyPreference from "../../components/api/mutations/AddBuddyPreference";
import useUserQuery from "../../components/api/queries/useUserQuery";
import { LineButton } from "../../components/buttons/LineButton";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import YearPicker from "../../components/picker/YearPicker";
import PriamryRadio from "../../components/radio/PriamryRadio";

const Title = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  margin: 16px 0;
  padding-left: 24px;
  padding-right: 24px;
  white-space: pre-wrap;
`;

const SubTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  white-space: pre-wrap;
  padding: 32px 24px 16px 24px;
`;

const PageWrapper = styled.div``;

const GENDERS = ["male", "female", "other"];

const StepComponent = ({ step, currentStep, title, children }) => {
  return (
    <>
      {step >= currentStep && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.3, delay: 0.3 }}
          style={{ marginBottom: currentStep >= 3 ? "128px" : "0px" }}
        >
          {title && (
            <SubTitle className="text-main-light-1 dark:text-main-dark-1">
              {title}
            </SubTitle>
          )}
          {children}
        </motion.div>
      )}
    </>
  );
};

const SelectFilterFanBuddyPage = () => {
  const pageWrapperRef = useRef(null);

  const navigate = useNavigate();

  const [allowAgeGap, setAllowAgeGap] = useState(0);
  const [birthyear, setBirthyear] = useState("");
  const [gender, setGender] = useState("");
  const [onlyTheSameGender, setOnlyTheSameGender] = useState(null);
  const [selectedSameGenderFriends, setSelectedSameGenderFriends] =
    useState(null);
  const [step, setStep] = useState(0); // 단계 상태 추가
  const [isComplete, setIsComplete] = useState(false); // 완료 상태 추가

  const user = useUserQuery();

  const onSelectedYear = (year) => {
    setBirthyear(year);
    setStep(2); // 다음 단계로 이동
  };

  useEffect(() => {
    if (step >= 3) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [step]);

  const handleComplete = () => {
    const currentQueryParams = new URLSearchParams(window.location.search);
    const params = {
      allowAgeGap,
      birthyear,
      gender,
      onlyTheSameGender,
      mbti: currentQueryParams.get("mbti"),
      keywords: currentQueryParams.get("keywords"),
      showToast: true,
    };

    AddBuddyPreference({
      userProfileId: user.baseUserProfile?.userProfileId,
      mbti: currentQueryParams.get("mbti"),
      allowAgeGap: allowAgeGap,
      birthyear: birthyear,
      gender: gender,
      onlyTheSameGender: onlyTheSameGender,
      keywords: currentQueryParams.get("keywords"),
    }).then((response) => {
      if (response.ok) {
        const queryString = new URLSearchParams(params).toString();
        navigate(`/fan-buddy/show-fan-buddy-card?${queryString}`);
      }
    });
  };

  const genderText = (genderOption) => {
    if (genderOption === "male") {
      return "남성";
    } else if (genderOption === "female") {
      return "여성";
    } else {
      return "기타";
    }
  };

  return (
    <>
      <StickyHeader
        title={"덕메찾기"}
        alwaysShowTitle={true}
        showBackButton={true}
      />
      <motion.div
        initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {"어떤 덕메를\n추천해드릴까요?"}
        </Title>
      </motion.div>

      <PageWrapper ref={pageWrapperRef}>
        <StepComponent
          step={step}
          currentStep={0}
          title={"몇살 차이까지 괜찮나요?"}
        >
          {allowAgeGap !== null && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                whiteSpace: "nowrap",
                padding: "8px 24px",
              }}
            >
              <span
                className="text-main-light-3 dark:text-main-dark-3"
                style={{
                  minWidth: "36px",
                  maxWidth: "36px",
                  fontSize: "17px",
                  lineHeight: "20px",
                  marginRight: "20px",
                }}
              >
                {allowAgeGap === null
                  ? "상관없어요"
                  : allowAgeGap === 0
                  ? "동갑"
                  : `${allowAgeGap}살`}
              </span>
              <div
                style={{
                  marginRight: "12px",
                  position: "relative",
                  width: "100%",
                  height: "24px",
                  cursor: allowAgeGap === null ? "not-allowed" : "pointer",
                }}
              >
                <div
                  className="bg-surface-light dark:bg-surface-dark"
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "24px",
                    borderRadius: "12px",
                    top: "50%",
                    transform: "translateY(-50%)",
                  }}
                >
                  <div
                    className={
                      allowAgeGap === null
                        ? "bg-disabled-light dark:bg-disabled-dark"
                        : "bg-surface-light dark:bg-surface-dark"
                    }
                    style={{
                      position: "absolute",
                      height: "100%",
                      borderRadius: "12px",
                      width: `${Math.min((allowAgeGap / 12) * 100, 100)}%`,
                    }}
                  />
                </div>
                <input
                  type="range"
                  min={0}
                  max={12}
                  value={allowAgeGap || 0}
                  disabled={allowAgeGap === null}
                  onChange={(e) => {
                    setAllowAgeGap(parseInt(e.target.value));
                    setStep(1);
                  }}
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "24px",
                    opacity: 0,
                    cursor: allowAgeGap === null ? "not-allowed" : "pointer",
                    padding: "0",
                    margin: "0",
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                  }}
                />
                <div
                  className={`border-[1.5px] border-placeholder-light dark:border-placeholder-dark
                    ${
                      allowAgeGap === null
                        ? "bg-disabled-light dark:bg-disabled-dark"
                        : "bg-background-light dark:bg-background-dark"
                    }
                  `}
                  style={{
                    position: "absolute",
                    width: "24px",
                    height: "24px",
                    borderRadius: "50%",
                    top: "50%",
                    left: `${Math.min((allowAgeGap / 12) * 100, 100)}%`,
                    transform: "translate(-50%, -50%)",
                    pointerEvents: "none",
                  }}
                />
              </div>
            </div>
          )}

          <div style={{ padding: "10px 24px" }}>
            <PriamryRadio
              text="상관없어요"
              isChecked={allowAgeGap === null}
              onChanged={() => {
                setAllowAgeGap(allowAgeGap === null ? 0 : null); // NULL과 0 사이를 토글
                setStep(1); // 다음 단계로 이동
              }}
            />
          </div>
        </StepComponent>

        <StepComponent step={step} currentStep={1} title={"당신의 나이는요"}>
          <div style={{ padding: "0px 24px" }}>
            <YearPicker
              initYear={new Date().getFullYear() - 20}
              minYear={new Date().getFullYear() - 80}
              maxYear={new Date().getFullYear() - 1}
              postfix={"년생"}
              onSelectedYear={onSelectedYear}
            />
          </div>
        </StepComponent>

        <StepComponent
          step={step}
          currentStep={2}
          title={"당신의 성별도 알려주세요!"}
        >
          <div style={{ padding: "0px 24px" }}>
            <Grid templateColumns="repeat(3, 1fr)" gap={"6px"}>
              {GENDERS.map((genderOption) => (
                <LineButton
                  key={genderOption}
                  text={genderText(genderOption)}
                  onClick={() => {
                    setGender(genderOption);
                    setStep(3); // 다음 단계로 이동
                  }}
                  isSelected={gender === genderOption} // 선택 상태에 따라 스타일 변경
                  style={{
                    fontSize: "17px",
                    textAlign: "center",
                    justifyItems: "center",
                    borderRadius: "8px",
                    padding: "16px 0px",
                  }}
                />
              ))}
            </Grid>
          </div>
        </StepComponent>

        <StepComponent
          step={step}
          currentStep={3}
          title={"같은 성별의 친구만 소개받을꺼에요?"}
          style={{ marginBottom: "120px" }}
        >
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={"10px"}
            style={{ padding: "0px 24px" }}
          >
            <GridItem>
              <LineButton
                text="예"
                onClick={() => {
                  setOnlyTheSameGender(true);
                  setSelectedSameGenderFriends(true); // 선택된 상태 저장
                  setIsComplete(true); // 완료 상태 설정
                }}
                isSelected={selectedSameGenderFriends === true} // 선택 상태에 따라 스타일 변경
                style={{
                  fontSize: "17px",
                  maxWidth: "100%",
                  textAlign: "center",
                  justifyItems: "center",
                  borderRadius: "8px",
                  padding: "16px 0px",
                }}
              />
            </GridItem>
            <GridItem>
              <LineButton
                text="아니오"
                onClick={() => {
                  setOnlyTheSameGender(false);
                  setSelectedSameGenderFriends(false); // 선택된 상태 저장
                  setIsComplete(true); // 완료 상태 설정
                }}
                isSelected={selectedSameGenderFriends === false} // 선택 상태에 따라 스타일 변경
                style={{
                  maxWidth: "100%",
                  textAlign: "center",
                  justifyItems: "center",
                  borderRadius: "8px",
                  padding: "16px 0px",
                }}
              />
            </GridItem>
          </Grid>
        </StepComponent>

        {isComplete && (
          <VStack
            spacing={2}
            style={{
              position: "fixed",
              bottom: "calc(4px + env(safe-area-inset-bottom))",
              left: 0,
              right: 0,
              width: "100%",
              padding: "20px 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RegularSolidButton
              text={t("done")}
              onClick={handleComplete}
              style={{
                fontSize: "15px",
                fontWeight: 500,
                padding: "16px 20px",
                width: "calc(100% - 48px)",
                borderRadius: "8px",
              }}
            />
          </VStack>
        )}
      </PageWrapper>
    </>
  );
};

export default SelectFilterFanBuddyPage;
