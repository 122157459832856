import React, { useEffect, useState } from "react";

import { Box, SimpleGrid, Text, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { chunk, isEmpty } from "lodash";
import { Virtuoso } from "react-virtuoso";

import { getMemberIdAnyway } from "../../utils/etcUtils";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import { useArtistQueryLikedOnly } from "../api/queries/useArtistQuery";
import useExploreTradingPhotoCardsQuery from "../api/queries/useExploreTradingPhotoCardsQuery";
import LikedMembersList from "../lists/LikedMembersList";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";
import SwitchToggleBar from "./SwitchToggleBar";

export default function ExploreTabPanel() {
  const [onlySales, setOnlySales] = useState(false);
  const [currentMember, setCurrentMember] = useState();
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] = useState(
    []
  );
  const [selectedArtist, setSelectedArtist] = useState(null);

  const likedArtists = useArtistQueryLikedOnly();

  const handleChangedMember = (member) => {
    setCurrentMember(member);
  };

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map(
        (tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard)
      )
    );
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  };

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useExploreTradingPhotoCardsQuery(
      selectedArtist?.artistId,
      getMemberIdAnyway(currentMember),
      onlySales ? "sale" : null
    );

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  useEffect(() => {
    if (!likedArtists) return;
    if (likedArtists.length === 1) {
      setSelectedArtist(likedArtists[0]);
    }
  }, [likedArtists]);

  const title = t("tradingPhotoCardPage.exchangeTabTitle", {
    fandom: selectedArtist?.fandomName,
    saleType: onlySales
      ? t("tradingPhotoCardPage.sale")
      : t("tradingPhotoCardPage.exchange"),
  });
  if (!paginatedTradingPhotoCards) {
    return (
      <>
        <SwitchToggleBar
          text={t("tradingPhotoCardPage.salesSwitchTitle")}
          onToggled={(checked) => setOnlySales(checked)}
        />
        {selectedArtist && (
          <Text
            className="text-main-light-2 dark:text-main-dark-2"
            fontSize={"17px"}
            fontWeight={700}
            marginTop={"24px"}
          >
            {title}
          </Text>
        )}
        <Text
          className="text-main-light-4 dark:text-main-dark-4"
          fontSize={"14px"}
          fontWeight={400}
          paddingBottom={"10px"}
        >
          {t("tradingPhotoCardPage.salesSwitchDescription")}
        </Text>
        <Wrap spacing={"8px"} marginBottom={"12px"}>
          <LikedMembersList
            artistMembers={selectedArtist?.members}
            onChangeLikedMember={(member) => handleChangedMember(member)}
            addEntireOption={true}
          />
        </Wrap>
        <GridSkeletons
          gridSize={3}
          skeletonSize={5}
          skeletonHeight={"96px"}
          showText={true}
        />
      </>
    );
  }
  return (
    <>
      {(likedArtists?.length ?? 0) > 1 ? (
        <Box
          overflowX="scroll"
          overflowY="hidden"
          css={{
            "::-webkit-scrollbar": {
              display: "none", // 스크롤바 숨김
            },
          }}
          // maxWidth= "calc(var(--chakra-sizes-xl))"
          style={{ margin: "0px -24px" }}
          pb={"8px"}
        >
          <ArtistIconSelector
            onSelected={(artist) => {
              setSelectedArtist(artist);
            }}
          />
        </Box>
      ) : (
        <Box h={"10px"} />
      )}

      <SwitchToggleBar
        text={t("tradingPhotoCardPage.salesSwitchTitle")}
        onToggled={(checked) => setOnlySales(checked)}
      />
      {selectedArtist && (
        <Text
          className="text-main-light-2 dark:text-main-dark-2"
          fontSize={"17px"}
          fontWeight={700}
          marginTop={"24px"}
        >
          {title}
        </Text>
      )}
      <Text
        className="text-main-light-4 dark:text-main-dark-4"
        fontSize={"14px"}
        fontWeight={400}
        paddingBottom={"10px"}
      >
        {t("tradingPhotoCardPage.salesSwitchDescription")}
      </Text>
      <Wrap spacing={"8px"} marginBottom={"12px"}>
        <LikedMembersList
          artistMembers={selectedArtist?.members}
          onChangeLikedMember={(member) => handleChangedMember(member)}
          addEntireOption={true}
        />
      </Wrap>
      {paginatedTradingPhotoCards?.length > 0 ? (
        <div
          style={{ paddingBottom: "calc(56px + env(safe-area-inset-bottom))" }}
        >
          <Virtuoso
            useWindowScroll
            overscan={480}
            data={chunk(paginatedTradingPhotoCards, 3)}
            endReached={loadMore}
            style={{ height: "100px" }}
            itemContent={(_, chunkCards) => (
              <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
                {chunkCards.map((card) => {
                  return (
                    <ExploreTradingPhotoCard
                      key={card.tradingPhotoCardId}
                      tradingPhotoCard={card}
                    />
                  );
                })}
              </SimpleGrid>
            )}
          />
        </div>
      ) : (
        <>
          <EmptyExploreTradingPhotoCard />
        </>
      )}
    </>
  );
}
