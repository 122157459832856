import { Box, Center, Spinner } from "@chakra-ui/react";
import React from "react";

export default function FilledButton({
  text,
  filled = true,
  onClicked,
  isLoading = false,
  ...props
}) {
  return (
    <Box
      className={`text-center ${
        filled
          ? "text-onPrimary bg-primary-light dark:bg-primary-dark"
          : "text-primary-light dark:text-primary-dark bg-secondary-light dark:bg-secondary-dark"
      }`}
      onClick={onClicked}
      borderRadius={"52px"}
      lineHeight={"17px"}
      {...props}
    >
      <Center>
        {isLoading ? (
          <Spinner
            className="text-primary-light dark:text-primary-dark"
            size="sm"
            marginRight={"20px"}
          />
        ) : (
          <Center>
            <p>{text}</p>
          </Center>
        )}
      </Center>
    </Box>
  );
}
