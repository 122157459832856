import React, { useMemo } from "react";

import { Avatar, Box, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { first } from "lodash";
import { useNavigate } from "react-router";

import { dateTimeDistanceToNow } from "../../utils/DateUtil";
import { ellipsis } from "../../utils/TextUtil";
import VerifiedIcon from "../icons/VerifiedIcon";
import SecondaryButton from "../buttons/SecondaryButton";
import { t } from "i18next";

const profileImageStyle = {
  width: "38px",
  height: "38px",
  borderRadius: "34px",
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)",
};

const nicknameStyle = {
  fontWeight: 600,
  fontSize: "16px",
};

const sentAtStyle = {
  fontWeight: 500,
  fontSize: "12px",
  paddingLeft: "8px",
};

const messageStyle = {
  fontWeight: 500,
  fontSize: "15px",
};

const unSeenCountBoxStyle = {
  borderRadius: "30px",
  padding: "6px 8px",
  marginRight: "12px",
  fontSize: "13px",
  fontWeight: 600,
};

const photoCardImageStyle = {
  width: "37px",
  height: "58px",
  borderRadius: "6px",
};

export default function ChatRoomV1({
  chatRoom,
  onClick,
  hideImage = false,
  showSelectButton = false,
}) {
  const navigate = useNavigate();

  const unSeenCount = useMemo(() => {
    // TODO : Server API should be changed with proper UUID format
    const userProfileIdWithoutDashes = chatRoom.myProfile.userProfileId.replace(
      /-/g,
      ""
    );
    const myUnSeenCount = chatRoom.lastMessage?.unseenCounts?.find((x) =>
      x.hasOwnProperty(userProfileIdWithoutDashes)
    );
    if (myUnSeenCount) return first(Object.values(myUnSeenCount));
    return 0;
  }, [chatRoom]);

  const onClickChatRoom = () => {
    if (onClick) {
      onClick(chatRoom.chatRoomId);
    } else {
      navigate(
        `/artists/${chatRoom?.photoCard?.artistId}/chats/${chatRoom.chatRoomId}`
      );
    }
  };

  return (
    chatRoom && (
      <div
        className="flex items-center"
        key={chatRoom.chatRoomId}
        onClick={() => onClickChatRoom()}
      >
        <div className="flex-none">
          {chatRoom.traderProfile?.pictureUrl ? (
            <Image
              style={profileImageStyle}
              src={chatRoom.traderProfile?.pictureUrl}
              objectFit={"cover"}
              loading={"lazy"}
            />
          ) : (
            <Avatar style={profileImageStyle} />
          )}
        </div>
        <div className="grow" style={{ paddingLeft: "14px" }}>
          <VStack alignItems={"start"} spacing={0}>
            <HStack spacing={0}>
              <Text
                className={
                  "text-main-light-2 dark:text-main-dark-2 overflow-hidden"
                }
                style={nicknameStyle}
                noOfLines={1}
              >
                {ellipsis(chatRoom.traderProfile?.nickname, 15)}
              </Text>
              {chatRoom.traderProfile?.identified && (
                <VerifiedIcon
                  width={"14px"}
                  height={"14px"}
                  style={{ marginLeft: "2px" }}
                />
              )}
              <Text
                className={"text-main-light-4 dark:text-main-dark-4"}
                style={sentAtStyle}
              >
                {chatRoom.lastMessage
                  ? dateTimeDistanceToNow(chatRoom.lastMessage?.sentAt)
                  : "-"}
              </Text>
            </HStack>
            <Text
              className={
                "text-main-light-4 dark:text-main-dark-4 overflow-hidden"
              }
              style={messageStyle}
              noOfLines={1}
            >
              {chatRoom.lastMessage?.content}
            </Text>
          </VStack>
        </div>
        {unSeenCount > 0 && (
          <Box
            className="bg-[rgba(122, 89, 255, 0.1)] text-[rgba(122, 89, 255, 1)]"
            // FIXME : 다크 모드 색상 필요
            style={unSeenCountBoxStyle}
          >
            {unSeenCount}
          </Box>
        )}
        {!hideImage && chatRoom.subjectType === "MatchedTradingPhotoCard" && (
          <div className="flex-none">
            <Image
              style={photoCardImageStyle}
              src={chatRoom.photoCard.pictureUrl}
              loading={"lazy"}
            />
          </div>
        )}
        {!hideImage && chatRoom.subjectType === "TradingPhotoCard" && (
          <div className="flex-none">
            <Image
              style={photoCardImageStyle}
              src={chatRoom.photoCard.pictureUrl}
              loading={"lazy"}
            />
          </div>
        )}
        {showSelectButton && <SecondaryButton text={t("select")} />}
      </div>
    )
  );
}
