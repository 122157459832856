import {
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { inputPriceFormat } from "../../utils/NumberUtil";
import CommonDialog from "../alerts/CommonDialog";
import PrimaryInput from "../input/PrimaryInput";
import { t } from "i18next";
import { isNull } from "lodash";
import { extractPrice } from "../../utils/TextUtil";
import SecondaryButton from "../buttons/SecondaryButton";
import WarningButton from "../buttons/WarningButton";
import { isCompletedTrading } from "../../utils/TradingHelper";
import styled from "styled-components";

const itemBoxStyle = {
  padding: "10px",
  width: "100%",
  borderRadius: "12px",
};

const imageBoxStyle = {
  paddingLeft: "6px",
  marginTop: "6px",
  marginBottom: "6px",
  marginRight: "14px",
};

const imageStyle = {
  width: "48px",
  height: "75px",
  margin: "0px!important",
  borderRadius: "8px",
};

const eventNameStyle = {
  fontSize: "15px",
  lineHeight: "18px",
  height: "18px",
  fontWeight: 600,
  color: "#222",
  marginBottom: "4px",
};

const pocaNameStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  height: "17px",
  fontWeight: 500,
  color: "#666",
  marginBottom: "12px",
};

const badgeStyle = {
  marginTop: "12px!important",
  padding: "4px 8px",
  borderRadius: "4px",
  fontSize: "13px",
  fontWeight: 600,
  lineHeight: "16px",
};

const closeIconStyle = {
  width: "28px",
  height: "28px",
  borderRadius: "32px",
  padding: "6px",
  marginBottom: "16px",
};

const switchTitleStyle = {
  fontSize: "13px",
  fontWeight: 400,
};

const priceWrapperStyle = {
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  textAlign: "right",
  padding: "0px 6px",
};

const editTextStyle = {
  textAlign: "right",
  fontSize: "13px",
  fontWeight: "400",
  textDecorationLine: "underline",
  padding: "0px 10px",
};

const priceTextStyle = {
  textAlign: "right",
  fontSize: "17px",
  fontWeight: "500",
};

const inputStyle = {
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  textAlign: "right",
  borderWidth: 0,
  padding: "0px 32px",
};

const placeHolderStyle = {
  textAlign: "left",
  fontSize: "17px",
  lineHeight: "32px",
  fontWeight: 500,
};

const EventName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const PhotoCardName = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
`;

export default function MyTradingPhotoCard({
  tradingPhotoCard,
  onChangedItem,
  onDeletedItem,
  onCompletedItem,
  onToggledItem,
  confirmed = false,
  hideToggle = false,
  isShowBoxButton = false,
}) {
  const [price, setPrice] = useState(tradingPhotoCard.price);
  const [checked, setChecked] = useState(tradingPhotoCard?.category === "sale");

  const [isOpenExchangeDialog, setIsOpenExchangeDialog] = useState(false);
  const [isOpenSaleDialog, setIsOpenSaleDialog] = useState(false);
  const [isOpenPriceDialog, setIsOpenPriceDialog] = useState(false);

  useEffect(() => {
    setChecked(tradingPhotoCard?.category === "sale");
  }, [tradingPhotoCard]);

  const toggleSwitch = (e) => {
    if (confirmed) {
      checked ? setIsOpenExchangeDialog(true) : setIsOpenSaleDialog(true);
    } else {
      setChecked(e.target.checked);
      updateTradingPhotoCard(e.target.checked, e.target.checked ? price : null);
    }
  };

  const onClickEditText = () => {
    if (confirmed) {
      setIsOpenPriceDialog(true);
    }
  };

  const updatedPrice = (e) => {
    setPrice(inputPriceFormat(e.target.value));
    updateTradingPhotoCard(checked, e.target.value);
  };

  const updateTradingPhotoCard = (checked, price) => {
    onChangedItem({
      ...tradingPhotoCard,
      price: price,
      category: checked ? "sale" : "exchange",
    });
  };

  const onChangedToExchange = () => {
    onClose();
    updateTradingPhotoCard(false, null);
    setChecked(false);
    setPrice(null);
  };

  const onChangedToSale = () => {
    onClose();
    updateTradingPhotoCard(true, price);
    setChecked(true);
  };

  const onChangedPriceOnly = () => {
    onClose();
    setChecked(true);
    updateTradingPhotoCard(true, price);
  };

  const onClose = () => {
    setIsOpenExchangeDialog(false);
    setIsOpenPriceDialog(false);
    setIsOpenSaleDialog(false);
  };

  return (
    tradingPhotoCard && (
      <>
        {/* Toggle To ExchangeCategory Dialog */}
        <CommonDialog
          isOpen={isOpenExchangeDialog}
          onClose={onClose}
          title={t("myPhotoCards.chatRoomExchangeDialogTitle")}
          onClickDialogButton={onChangedToExchange}
        />

        {/* Toggle To SaleCategory Dialog */}
        <CommonDialog
          isOpen={isOpenSaleDialog}
          onClose={onClose}
          title={t("myPhotoCards.chatRoomSaleDialogTitle")}
          description={t("myPhotoCards.chatRoomSaleDialogDescription")}
          children={
            <PrimaryInput
              defaultValue={null}
              placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
              onChangeText={(e) => setPrice(e.target.value)}
              style={{ marginTop: "16px" }}
            />
          }
          onClickDialogButton={onChangedToSale}
        />

        {/* Change Price Dialog */}
        <CommonDialog
          isOpen={isOpenPriceDialog}
          onClose={onClose}
          title={t("myPhotoCards.changePriceDialogTitle")}
          description={t("myPhotoCards.changePriceDialogDescription")}
          children={
            <PrimaryInput
              defaultValue={extractPrice(price)}
              placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
              onChangeText={(e) => setPrice(e.target.value)}
              style={{ marginTop: "16px" }}
            />
          }
          onClickDialogButton={onChangedPriceOnly}
        />
        <Box
          key={tradingPhotoCard.tradingPhotoCardId}
          style={itemBoxStyle}
          borderWidth={1}
        >
          <div className="flex">
            <div className="flex-none" style={imageBoxStyle}>
              <Image
                style={imageStyle}
                src={tradingPhotoCard.photoCard.pictureUrl}
                loading={"lazy"}
              />
            </div>
            <div className="flex-grow">
              <VStack alignItems={"start"} spacing={0} paddingTop={"6px"}>
                <EventName className="text-main-light-2 dark:text-main-dark-2">
                  {t("photoCardDetailPage.title", {
                    memberName: tradingPhotoCard?.photoCard?.memberName,
                    categoryName: tradingPhotoCard?.photoCard?.categoryName,
                    categoryEmoji: tradingPhotoCard?.photoCard?.categoryEmoji,
                  })}
                </EventName>
                <PhotoCardName className="text-main-light-3 dark:text-main-dark-3">
                  {tradingPhotoCard?.photoCard?.eventName}
                </PhotoCardName>
                {/* <PhotoCardCategoryBadge>
                {`${photoCard.categoryEmoji} ${photoCard.categoryName}`}
              </PhotoCardCategoryBadge> */}
                {/* <EventName>{`${photoCard.eventName}`}</EventName> */}
                <TagsWrapper></TagsWrapper>
                {/* <Text noOfLines={1} style={eventNameStyle}>{((tradingPhotoCard?.photoCard?.eventName) || (tradingPhotoCard?.photoCard?.event?.name))}</Text>
              <Text noOfLines={1} style={pocaNameStyle} className="overflow-hidden">{((tradingPhotoCard?.photoCard?.eventSlug) || (tradingPhotoCard?.photoCard?.event?.slug))}</Text>
              <Badge style={badgeStyle}>
                {((tradingPhotoCard?.photoCard?.memberName) || (tradingPhotoCard?.member?.name || tradingPhotoCard?.photoCard?.member?.name))}
              </Badge> */}
              </VStack>
            </div>
            <div className="flex-none">
              <div className="flex justify-end">
                <VStack align={"end"}>
                  <CloseIcon
                    className="fill-onSecondary-light dark:fill-onSecondary-dark bg-secondary-light dark:bg-secondary-dark"
                    style={closeIconStyle}
                    onClick={() => onDeletedItem(tradingPhotoCard)}
                  />
                  {!hideToggle && (
                    <HStack marginTop={"16px!important"}>
                      <Text
                        className="text-textPrimary-light dark:text-textPrimary-dark"
                        style={switchTitleStyle}
                      >
                        {t("myPhotoCards.onlySale")}
                      </Text>
                      <Switch
                        isReadOnly={confirmed}
                        _focus={""}
                        marginX={"6px!important"}
                        size="sm"
                        colorScheme="primary"
                        isChecked={checked}
                        onTouchEnd={(e) => confirmed && toggleSwitch(e)}
                        onChange={(e) => !confirmed && toggleSwitch(e)}
                      />
                    </HStack>
                  )}
                </VStack>
              </div>
            </div>
          </div>
          {checked && (
            <VStack>
              <Divider
                paddingTop={"10px"}
                paddingBottom={"6px"}
                width={"96%"}
                zIndex={9999}
              />
              {confirmed ? (
                <div
                  className="w-full text-main-light-2 dark:text-main-dark-2"
                  style={priceWrapperStyle}
                >
                  <Flex justify={"end"}>
                    <Text
                      className="text-textPrimary-light dark:text-textPrimary-dark"
                      style={editTextStyle}
                      onClick={() => onClickEditText()}
                    >
                      {t("myPhotoCards.editPrice")}
                    </Text>
                    <Text
                      className="text-main-light-2 dark:text-main-dark-2"
                      style={priceTextStyle}
                    >
                      {isNull(price)
                        ? t("tradingPhotoCardPage.offerPrice")
                        : price}
                    </Text>
                  </Flex>
                </div>
              ) : (
                <InputGroup marginTop={"0px!important"}>
                  <Input
                    className="text-main-light-2 dark:text-main-dark-2 placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark"
                    style={inputStyle}
                    isDisabled={confirmed}
                    focusBorderColor={"#FFF"}
                    errorBorderColor={"#FFF"}
                    placeholder={t(
                      "myPhotoCards.chatRoomSaleDialogInputPlaceholder"
                    )}
                    _placeholder={placeHolderStyle}
                    value={price || ""}
                    marginLeft={price ? "0px" : "-24px"}
                    onChange={(e) => updatedPrice(e)}
                  />
                  {/* <InputRightElement>
                  <Text style={price ? inputStyle : placeHolderStyle}></Text>
                </InputRightElement> */}
                </InputGroup>
              )}
            </VStack>
          )}
          {isShowBoxButton && (
            <PhotoCardBoxButton
              tradingPhotoCard={tradingPhotoCard}
              onClickCompleted={() => onCompletedItem(tradingPhotoCard)}
              onClickToggleCategory={() => onToggledItem(tradingPhotoCard)}
            />
          )}
        </Box>
      </>
    )
  );
}

const PhotoCardBoxButton = ({
  tradingPhotoCard,
  onClickCompleted,
  onClickToggleCategory,
  onClickNavigateReview,
}) => {
  return (
    <div className="flex" style={{ paddingTop: "14px" }}>
      {isCompletedTrading(tradingPhotoCard) ? (
        <div
          className="flex-1"
          onClick={() => onClickNavigateReview(tradingPhotoCard)}
        >
          <SecondaryButton
            text={t("myPhotoCards.sentReviewButton")}
            paddingY={"11px"}
          />
        </div>
      ) : (
        <>
          <div className="flex-1" style={{ paddingRight: "8px" }}>
            <WarningButton
              text={t("myPhotoCards.chatRoomPageCompletedButton")}
              paddingY={"11px"}
              onClick={() => onClickCompleted(tradingPhotoCard)}
            />
          </div>
          <div
            className="flex-1"
            onClick={() => onClickToggleCategory(tradingPhotoCard)}
          >
            <SecondaryButton
              text={
                tradingPhotoCard.category === "exchange"
                  ? t("myPhotoCards.chatRoomPageConvertToSaleButton")
                  : t("myPhotoCards.chatRoomPageConvertToExchangeButton")
              }
              paddingY={"11px"}
            />
          </div>
        </>
      )}
    </div>
  );
};
