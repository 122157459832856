import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

export default function useReportedPhotoCardsQuery({user, onlyPending=true, artistId}) {
  const queryParams = new URLSearchParams();
  if (artistId) {
    queryParams.append("artist_id", artistId)
  }

  if (onlyPending) {
    queryParams.append("only_pending", onlyPending)
  }

  const { data: photoCards } = useQuery(
    ['reportedPhotoCards', onlyPending, artistId],
    () => queryGet(`/api/v1/photo_cards/reported?${queryParams.toString()}`), {
      select: (data) => data.photoCards,
      enabled: !isEmpty(user?.baseUserProfile),
    }
  )
  return photoCards
}
