import React from "react";

import Sheet from "react-modal-sheet";

import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";

import { Center, VStack } from "@chakra-ui/react";
import uuid from "react-uuid";
import styled from "styled-components";
import { setLocalStorageWithExpiry } from "../../utils/ExpiredLocalStorage";
import initializePortOne from "../../utils/PortOne";
import PrimaryButton from "../buttons/PrimaryButton";

const Title = styled.p`
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const Description = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  white-space: pre-line;
`;

const Box = styled.div`
  display: flex;
  padding: 8px 18px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  margin-top: 17px;
`;

const BoxItemWrapper = styled.div`
  display: flex;
  padding: 14px 0px;
  align-items: center;
  align-self: stretch;
`;

const NoneBorderBoxItemWrapper = styled.div`
  display: flex;
  padding: 14px 0px;
  align-items: center;
  align-self: stretch;
`;

const BoxEmojiWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 46px;
  width: 44px;
  height: 44px;
  font-size: 24px;
`;
const BoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 11px;
`;

const BoxTitle = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const BoxDescription = styled.p`
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
`;

const CloseText = styled.p`
  margin-top: 17px;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_MONTH = 1000 * 60 * 60 * 24 * 30;

export default function UserVerificationBottomSheet({
  isShowBottomSheet,
  setIsShowBottomSheet,
  redirectPath,
}) {
  const { origin, pathname, search } = window.location;

  function requestIdentityVerification() {
    const IMP = initializePortOne();

    IMP.certification({
      merchant_uid: `imp-${uuid()}`,
      min_age: 14,
      company: "포카판",
      popup: false,
      m_redirect_url: redirectPath
        ? redirectPath
        : `${origin}/identity_verification_callback?redirect_path=${encodeURIComponent(
            pathname + search
          )}`,
    });
    setIsShowBottomSheet(false);
  }

  const setExpiryAndCloseBottomSheet = (ttl) => {
    setLocalStorageWithExpiry("showVerificationBottomSheet", false, ttl);
    setIsShowBottomSheet(false);
  };

  return (
    <div>
      <Sheet
        isOpen={isShowBottomSheet}
        disableDrag={true}
        onClose={() => {}}
        springConfig={SPRING_CONFIG}
        detent="content-height"
        style={{ position: "sticky" }}
      >
        <Sheet.Container
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            position: "sticky",
          }}
        >
          <Sheet.Header
            className="bottomSheetHeader"
            style={{ paddingTop: "28px", paddingBottom: "16px" }}
          >
            <Center>
              <img
                src="/assets/icons/verfied.graphic.png"
                width={100}
                height={100}
                alt="verfied.graphic"
              />
            </Center>
          </Sheet.Header>
          <Sheet.Content
            disableDrag={true}
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
              position: "sticky",
            }}
          >
            <VStack spacing={"5px"}>
              <Title className="text-black dark:text-white">
                {"무료로 파란뱃지를 받아가세요!"}
              </Title>
              <Description
                className="text-main-light-4 dark:text-main-dark-4"
                dangerouslySetInnerHTML={{
                  __html: `본인 인증을 진행하고 파란체크를 받아가세요\n해당 기능은 <b>만 14세 이상</b>에게만 지원됩니다!`,
                }}
              />
            </VStack>
            <Box className="bg-surface-light dark:bg-surface-dark">
              <BoxItemWrapper className="border-b-[1px] border-separator-light-2 dark:border-separator-dark-2">
                <BoxEmojiWrapper className="bg-surface-light dark:bg-surface-dark">
                  ️⚡️
                </BoxEmojiWrapper>
                <BoxTextWrapper>
                  <BoxTitle className="text-pure-black dark:text-pure-white">
                    {"원하는 포카 더 빠르고 안전하게"}
                  </BoxTitle>
                  <BoxDescription className="text-main-light-4 dark:text-main-dark-4">
                    {"파란뱃지가 양도 성공률을 올려드려요!"}
                  </BoxDescription>
                </BoxTextWrapper>
              </BoxItemWrapper>
              <BoxItemWrapper className="border-b-[1px] border-separator-light-2 dark:border-separator-dark-2">
                <BoxEmojiWrapper className="bg-surface-light dark:bg-surface-dark">
                  💝
                </BoxEmojiWrapper>
                <BoxTextWrapper>
                  <BoxTitle className="text-pure-black dark:text-pure-white">
                    {"새로운 기능을 먼저 만나봐요"}
                  </BoxTitle>
                  <BoxDescription className="text-main-light-4 dark:text-main-dark-4">
                    {"파란 뱃지 유저에게 먼저 공개돼요!"}
                  </BoxDescription>
                </BoxTextWrapper>
              </BoxItemWrapper>
              <NoneBorderBoxItemWrapper>
                <BoxEmojiWrapper className="bg-surface-light dark:bg-surface-dark">
                  ️🍀
                </BoxEmojiWrapper>
                <BoxTextWrapper>
                  <BoxTitle className="text-pure-black dark:text-pure-white">
                    {"파란뱃지가 당신의 행운을 높여줘요"}
                  </BoxTitle>
                  <BoxDescription className="text-main-light-4 dark:text-main-dark-4">
                    {"이벤트 참여시 당첨 확률이 올라가요!"}
                  </BoxDescription>
                </BoxTextWrapper>
              </NoneBorderBoxItemWrapper>
            </Box>
            <PrimaryButton
              padding={"16px 34px"}
              marginTop={"16px"}
              text={"본인 인증 하기"}
              fontSize={"17px"}
              fontWeight={600}
              onClick={() => requestIdentityVerification()}
            />
            <div
              className="text-center"
              onClick={() => setExpiryAndCloseBottomSheet(ONE_MONTH)}
            >
              <CloseText className="text-sub-light-1 dark:text-sub-dark-1">
                {"다음에 할께요"}
              </CloseText>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={() => setExpiryAndCloseBottomSheet(ONE_DAY)} />
      </Sheet>
    </div>
  );
}
