import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as MatchingFillIcon } from "../../icons/matching.fill.svg";
import CreateMatchedTradingChatRoom from "../api/mutations/CreateMatchedTradingChatRoom";
import PrimaryButton from "../buttons/PrimaryButton";
import TradingPhotoCardInfo from "../tradings/TradingPhotoCardInfo";

const Title = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
`

const Description = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
`

export default function ExchangeMatchedTradingPhotoCardBottomDrawer({isOpen, onClose, matchedTradingPhotoCard}) {
  const navigate = useNavigate();

  const navigateChatRoom = () => {
    CreateMatchedTradingChatRoom(matchedTradingPhotoCard.matchedTradingPhotoCardId).then((r) => {
      if (r.success) {
        navigate(`/artists/${matchedTradingPhotoCard.artistId}/chats/${r.chatRoom.chatRoomId}`)
      }
    })
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px"
          }}
        >
          <DrawerHeader>
            <Title className="text-main-light-1 dark:text-main-dark-1">
              {t("ExchangeMatchedTradingPhotoCardBottomDrawer.title")}
            </Title>
          </DrawerHeader>
          <DrawerBody p={0}>
            <Box className="mt-[14px] mb-[20px] px-[24px] w-full">
              <div className="flex">
                <div className="flex-1 flex-col">
                  <Description className="mb-2 text-main-light-4 dark:text-main-dark-4">
                    {t("tradingPhotoCardPage.matchedTradingPhotoCardMine")}
                  </Description>
                  <TradingPhotoCardInfo tradingPhotoCard={matchedTradingPhotoCard.sourceCard} halfType={true}/>
                </div>
                <div className="flex items-center shrink pt-4" style={{paddingLeft: "6px", paddingRight: "6px"}}>
                  <MatchingFillIcon
                    className="fill-sub-light-1 dark:fill-sub-dark-1"
                    width={"16px"}
                  />
                </div>
                <div className="flex-1 flex-col">
                  <Description className="mb-2 text-main-light-4 dark:text-main-dark-4">
                    {t("tradingPhotoCardPage.matchedTradingPhotoCardTarget")}
                  </Description>
                  <TradingPhotoCardInfo tradingPhotoCard={matchedTradingPhotoCard.targetCard} halfType={true}/>
                </div>
              </div>
            </Box>
          </DrawerBody>
          <DrawerFooter className="flex flex-col safeAreaMarginBottom px-[24px!important]" width={"100%"} style={{padding: 0}}>
            <PrimaryButton
              text={t("ExchangeMatchedTradingPhotoCardBottomDrawer.sendButton")}
              width="100%"
              style={{padding: "16px 0px", fontSize: "17px"}}
              onClick={navigateChatRoom}
            />
            <Description className="mt-[14px] mb-4 text-main-light-3 dark:text-main-dark-3">
              {t("ExchangeMatchedTradingPhotoCardBottomDrawer.disclaimer")}
            </Description>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
