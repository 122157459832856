import React from "react";

import {
  Avatar,
  HStack,
  Image,
  Skeleton,
  SkeletonText,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { isNull } from "lodash";
import styled from "styled-components";
import { inputPriceFormat } from "../../utils/NumberUtil";
import removedEmojiEventName, { ellipsis } from "../../utils/TextUtil";
import WhiteBadge from "../badges/WhiteBadge";
import ProfileLineButton from "../buttons/ProfileLineButton";
import VerifiedIcon from "../icons/VerifiedIcon";
import { t } from "i18next";

const imageStyle = {
  width: "48px",
  height: "75px",
  margin: "0px!important",
  borderRadius: "6px",
};

const eventNameStyle = {
  fontSize: "14px",
  lineHeight: "18px",
  fontWeight: 500,
  marginBottom: "4px",
};

const pocaNameStyle = {
  fontSize: "12px",
  lineHeight: "17px",
  fontWeight: 500,
  noOfLines: 1,
};

const profileImageSize = {
  width: "16px",
  height: "16px",
};

const priceTextStyle = {
  fontSize: "15px",
  fontWeight: 600,
};

const badgeStyle = {
  padding: "3px 6px",
  fontSize: "11px",
  fontWeight: 600,
};

const TradingPhotoCardBoxWrapper = styled.div`
  display: flex;
`;
const ImageWrapper = styled.div`
  flex: none;
  align-self: center;
  padding-top: 6px;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  padding-left: 12px;
`;

const ContentRow = styled.div`
  display: flex;
`;
const StartContent = styled.div`
  flex-grow: 1;
`;

const EndContent = styled.div`
  flex: none;
`;

const ChatRoomButton = styled.div`
  min-width: 69px;
  min-height: 28px;
  padding: 6px 12px;
  border-radius: 32px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
`;

export default function TradingPhotoCardBoxByPhotoCard({
  photoCard,
  onClickBox,
  onClickImage,
  onClickContent,
  showChatRoomsCount = false,
  showProfile = false,
  showPrice = false,
  halfType = false,
}) {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");

  return photoCard ? (
    <TradingPhotoCardBoxWrapper onClick={onClickBox}>
      <ImageWrapper>
        <Image
          style={imageStyle}
          src={photoCard.pictureUrl}
          loading={"lazy"}
          onClick={onClickImage}
        />
      </ImageWrapper>
      <ContentWrapper onClick={onClickContent}>
        <ContentRow style={{ paddingBottom: "12px" }}>
          <StartContent>
            <div className="flex">
              <VStack
                className="flex-grow"
                align={"flex-start"}
                spacing={0}
                style={{ paddingTop: "6px", flexBasis: "min-content" }}
              >
                <Text
                  style={eventNameStyle}
                  noOfLines={1}
                  className="overflow-hidden text-main-light-1 dark:text-main-dark-1"
                >
                  {ellipsis(
                    removedEmojiEventName(photoCard.eventName),
                    halfType ? 10 : 20
                  )}
                </Text>
                <Text
                  style={pocaNameStyle}
                  noOfLines={1}
                  className="overflow-hidden text-main-light-3 dark:text-main-dark-3"
                >
                  {ellipsis(photoCard.photoCardId, halfType ? 10 : 20)}
                </Text>
              </VStack>
              {showChatRoomsCount && (
                <div className="flex-grow" style={{ paddingLeft: "4px" }}>
                  <ChatRoomButton className="text-textPrimary-light dark:text-textPrimary-dark bg-secondary-light dark:bg-secondary-dark">
                    {t("myPhotoCards.tradingPhotoCardBoxByPhotoCardBadge", {
                      count: photoCard.chatRoomsCount,
                    })}
                  </ChatRoomButton>
                </div>
              )}
            </div>
          </StartContent>
          <EndContent>
            {showProfile && (
              <div
                className="flex-shrink"
                style={{ paddingLeft: "6px", maxWidth: "135px" }}
              >
                <ProfileLineButton
                  leftIcon={
                    photoCard.userProfile.pictureUrl ? (
                      <Image
                        src={photoCard.userProfile.pictureUrl}
                        style={profileImageSize}
                        objectFit={"cover"}
                        borderRadius={"full"}
                      />
                    ) : (
                      <Avatar style={profileImageSize} />
                    )
                  }
                  rightIcon={
                    photoCard.userProfile.identified ? (
                      <div style={{ minWidth: "14px" }}>
                        <VerifiedIcon width={"14px"} height={"14px"} />
                      </div>
                    ) : (
                      <></>
                    )
                  }
                  text={photoCard.userProfile.nickname}
                />
              </div>
            )}
          </EndContent>
        </ContentRow>
        <ContentRow>
          <StartContent>
            <WhiteBadge
              style={badgeStyle}
              width={"fit-content"}
              text={photoCard.memberName}
            />
          </StartContent>
          <EndContent>
            {showPrice && (
              <Text
                className="text-textPrimary-light dark:text-textPrimary-dark"
                style={priceTextStyle}
              >
                {isNull(photoCard.price)
                  ? t("tradingPhotoCardPage.offerPriceWithEmoji")
                  : photoCard.price}
              </Text>
            )}
          </EndContent>
        </ContentRow>
      </ContentWrapper>
    </TradingPhotoCardBoxWrapper>
  ) : (
    <HStack align={"flex-start"}>
      <Skeleton
        width={"40px"}
        height={"62px"}
        startColor={startColor}
        endColor={endColor}
      />
      <VStack paddingTop={"2px"}>
        <SkeletonText
          noOfLines={2}
          width={"75px"}
          startColor={startColor}
          endColor={endColor}
        />
      </VStack>
    </HStack>
  );
}
