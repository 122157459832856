import React from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { ellipsis } from "../../utils/TextUtil";

export default function ProfileLineButton({
  text,
  leftIcon,
  rightIcon,
  disabled,
  ...props
}) {
  return (
    <Box
      className={`text-center border ${
        disabled
          ? "border-disabled-light dark:border-disabled-dark text-sub-light-2 dark:text-sub-dark-2 bg-disabled-light dark:bg-disabled-dark"
          : "border-separator-light-2 dark:border-separator-dark-2 text-main-light-2 dark:text-main-dark-2 bg-background-light dark:bg-background-dark"
      }`}
      borderRadius="32px"
      padding="7px 10px 7px 8px"
      fontWeight={600}
      fontSize="14px"
      maxW="135px"
      noOfLines={1}
      boxSizing="border-box"
      {...props}
    >
      <HStack spacing={0}>
        {leftIcon && <>{leftIcon}</>}
        {text && (
          <Text
            noOfLines={1}
            maxW="85px"
            paddingLeft={leftIcon ? "6px" : "0px"}
          >
            {ellipsis(text, 10)}
          </Text>
        )}
        {rightIcon && <div pl="2px">{rightIcon}</div>}
      </HStack>
    </Box>
  );
}
