import React, { useState } from "react";

import {
  HStack,
  Image,
  Skeleton,
  SkeletonText,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isNull } from "lodash";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.svg";
import { isCompletedTrading } from "../../utils/TradingHelper";
import { openNewTab } from "../../utils/UIUtils";
import CommonVerticalButtonDialog from "../alerts/CommonVerticalButtonDialog";
import updateTradingPhotoCards from "../api/mutations/updateTradingPhotoCards";
import usePhotoCardQuery from "../api/queries/usePhotoCardQuery";
import DisabledLineButton from "../buttons/DisabledLineButton";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import { TagsList } from "../photocard/TagsList";

const imageStyle = {
  width: "40px",
  height: "62px",
  margin: "0px!important",
  marginTop: "-1px",
  borderRadius: "6px",
};

const eventNameStyle = {
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: 500,
};

const pocaNameStyle = {
  fontSize: "12px",
  lineHeight: "14px",
  fontWeight: 500,
  noOfLines: 1,
  marginBottom: "4px",
};

const priceTextStyle = {
  fontSize: "17px",
  fontWeight: 600,
  lineHeight: "20px",
};

const TagContainer = styled.div`
  margin-top: 2px;
  width: fit-content;
`;

export default function ChatRoomTradingPhotoCardInfo({
  chatRoomId,
  tradingPhotoCard,
  tradingUserProfile,
  isMine = false,
  showProfile = false,
  showPrice = false,
  halfType = false,
  onSelectPrice,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isOpenCompletedDialog, setIsOpenCompletedDialog] = useState(false);

  const photoCard = usePhotoCardQuery(tradingPhotoCard.photoCardId);
  const navigatePhotoCardDetailPage = (photoCardId) => {
    const currentPath = window.location.pathname;
    const targetPath = `/photo_cards/${photoCardId}`;

    if (currentPath !== targetPath) {
      // TODO : Please be careful to open newtab infinitely
      openNewTab(targetPath);
    }
  };

  const onSelectedPrice = (price) => {
    if (onSelectPrice) {
      onSelectPrice(price);
    }
  };

  const onClickTradingStatus = async () => {
    if (isMine && !isCompletedTrading(tradingPhotoCard)) {
      setIsOpenCompletedDialog(true);
    }
  };

  const onChangedToCompleted = async () => {
    setIsOpenCompletedDialog(false);
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard?.tradingPhotoCardId,
      price: tradingPhotoCard.price,
      status: "sold_out",
    });

    if (response.ok) {
      queryClient.invalidateQueries(["chatRoom", chatRoomId]);
      navigate(
        `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/add_review?chatRoomId=${chatRoomId}&traderUserProfileId=${tradingUserProfile.userProfileId}`,
        { replace: true }
      );
    }
  };

  return photoCard ? (
    <div className="flex w-full">
      <div className="flex-none self-center">
        <Image
          style={imageStyle}
          src={photoCard.smallThumbnailUrl || photoCard.pictureUrl}
          objectFit={"cover"}
          loading={"lazy"}
          onClick={() => navigatePhotoCardDetailPage(photoCard.photoCardId)}
        />
      </div>
      <div
        className="grow pl-3 flex flex-col justify-center"
        style={{ flexBasis: "min-content" }}
      >
        <div className="flex flex-col gap-1">
          <Text
            style={eventNameStyle}
            noOfLines={1}
            className="overflow-hidden text-main-light-1 dark:text-main-dark-1"
          >
            {t("photoCardDetailPage.title", {
              memberName: photoCard.memberName,
              categoryName: photoCard.categoryName,
              categoryEmoji: photoCard.categoryEmoji,
            })}
          </Text>
          <Text
            style={pocaNameStyle}
            noOfLines={1}
            className="overflow-hidden text-main-light-3 dark:text-main-dark-3"
          >
            {photoCard?.eventName}
          </Text>
          {photoCard && (
            <TagContainer>
              <TagsList photoCard={photoCard} size={"xs"} />
            </TagContainer>
          )}
        </div>
      </div>
      <div
        className="shirnk"
        style={{ paddingLeft: "10px", marginTop: "-1px" }}
      >
        {(showProfile || showPrice) && (
          <VStack align={"end"} spacing={"16px"}>
            {isCompletedTrading(tradingPhotoCard) ? (
              <DisabledLineButton
                text={t("tradingPhotoCardChatRoom.completedTradingMenu")}
                disabled={true}
                style={{
                  padding: "6px 12px",
                  minHeight: "0px",
                  minWidth: "0px",
                }}
              />
            ) : isMine ? (
              <PrimaryButton
                text={t("tradingPhotoCardChatRoom.saleTradingMenu")}
                style={{ padding: "6px 9px 6px 12px" }}
                disabled={false}
                icon={
                  <ArrowRightIcon
                  className="fill-pure-white dark:fill-pure-black"
                    width={"14px"}
                    height={"14px"}
                  />
                }
                onClick={() => onClickTradingStatus()}
              />
            ) : (
              <SecondaryLineButton
                text={t("tradingPhotoCardChatRoom.purchasingTradingMenu")}
                style={{
                  padding: "6px 12px",
                  minHeight: "0px",
                  minWidth: "0px",
                }}
              />
            )}
            {showPrice && (
              <Text
                className="text-textPrimary-light dark:text-textPrimary-dark"
                style={priceTextStyle}
                onClick={() => onSelectedPrice(tradingPhotoCard.price)}
                decoration={
                  onSelectPrice && !isCompletedTrading(tradingPhotoCard)
                    ? "underline"
                    : null
                }
              >
                {isNull(tradingPhotoCard.price)
                  ? t("tradingPhotoCardPage.offerPriceWithEmoji")
                  : tradingPhotoCard.price}
              </Text>
            )}
          </VStack>
        )}
      </div>
      {isOpenCompletedDialog && (
        <CommonVerticalButtonDialog
          isOpen={isOpenCompletedDialog}
          onClose={() => setIsOpenCompletedDialog(false)}
          upButtonText={t("myPhotoCards.doneAndSendReview")}
          downButtonText={t("no")}
          title={t("myPhotoCards.chatRoomCompleteDialogTitle")}
          onClickDialogUpButton={onChangedToCompleted}
          onClickDialogDownButton={() => setIsOpenCompletedDialog(false)}
        />
      )}
    </div>
  ) : (
    <HStack align={"flex-start"}>
      <Skeleton
        width={"40px"}
        height={"62px"}
        startColor="rgba(0, 0, 0, 0.04)"
        endColor="rgba(0, 0, 0, 0.02)"
      />
      <VStack paddingTop={"2px"}>
        <SkeletonText
          noOfLines={2}
          width={"75px"}
          startColor="rgba(0, 0, 0, 0.04)"
          endColor="rgba(0, 0, 0, 0.02)"
        />
      </VStack>
    </HStack>
  );
}
