import React, { useEffect, useState } from "react";

import { Center, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";

import CommonDialog from "../../components/alerts/CommonDialog";
import updateUserProfileV1 from "../../components/api/mutations/updateUserProfileV1";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import AddUserProfileV1BottomSheet from "../../components/bottomSheet/AddUserProfileV1BottomSheet";
import ArrowRightIcon from "../../components/icons/ArrowRightIcon";
import PrimaryInput from "../../components/input/PrimaryInput";
import Page from "../../components/Page";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import LightColors from "../../constants/LightColors";
import { getArtistId } from "../../utils/etcUtils";

const ProfileImageChangeText = styled.div`
  margin-top: 16px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
`;

const SettingsRow = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  min-height: 66px;
`;

const SettingTitleColumn = styled.div`
  flex-grow: 1;
  align-content: center;
`;

const SettingTitle = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const SettingDescription = styled.p`
  padding-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${LightColors.textsublv1};
`;

const SettingValueText = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const ValueColumn = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
`;

export default function TradingUserProfileSettingsPage() {
  const params = useParams();
  const queryClient = useQueryClient();

  const [userProfile, setUserProfile] = useState();
  const defaultUserProfile = useUserProfileQuery(params["user_profile_id"]);

  useEffect(() => {
    if (!isEmpty(defaultUserProfile)) {
      setUserProfile(defaultUserProfile);
    }
  }, [defaultUserProfile]);

  const onUpdatedUserProfile = (userProfile) => {
    queryClient.invalidateQueries("userProfile");
    setUserProfile(userProfile);
  };

  return (
    <Page
      title={t("TradingUserProfileSettingsPage.pageTitle")}
      showTitle={true}
      showBackButton={true}
    >
      <ProfileImageSection
        userProfile={userProfile}
        onUpdatedUserProfile={onUpdatedUserProfile}
      />
      <NicknameRow
        userProfile={userProfile}
        onUpdatedUserProfile={onUpdatedUserProfile}
      />
      <DescriptionRow
        userProfile={userProfile}
        onUpdatedUserProfile={onUpdatedUserProfile}
      />
      <ManageTradingCardsRow />
      <ManageTradingHistoriesRow userProfileId={params["user_profile_id"]} />
      <ManageTradingReviewsRow />
      {<AddUserProfileV1BottomSheet userProfile={userProfile} />}
    </Page>
  );
}

const ProfileImageSection = ({ userProfile, onUpdatedUserProfile }) => {
  const { openFilePicker, filesContent } = useFilePicker({
    readAs: "DataURL",
    accept:
      "image/jpeg,image/png,image/webp,image/bmp,image/tiff,image/x-icon,image/heic",
    multiple: false,
  });

  useEffect(() => {
    const updateProfile = async () => {
      const dataUrl = first(filesContent)?.content;
      if (!isEmpty(dataUrl)) {
        const response = await updateUserProfileV1({
          userProfileId: userProfile.userProfileId,
          dataUrl: dataUrl,
        });
        if (response.ok) {
          const responseBody = await response.json();
          onUpdatedUserProfile(responseBody.userProfile);
        }
      }
    };
    updateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesContent]);

  return (
    <Center flexDirection={"column"} marginBottom={"20px"}>
      <ProfileAvatar
        profileImageUrl={userProfile?.thumbnailUrl}
        widthAndHeight={"72px"}
        svgWidthAndHeight={"36px"}
        padding={"18px"}
      />
      <ProfileImageChangeText
        className="text-primary-light dark:text-primary-dark"
        onClick={openFilePicker}
      >
        {t("TradingUserProfileSettingsPage.changeProfileImage")}
      </ProfileImageChangeText>
    </Center>
  );
};

const NicknameRow = ({ userProfile, onUpdatedUserProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [nickname, setNickname] = useState(userProfile?.nickname);

  const onConfirmed = async () => {
    if (!isEmpty(nickname)) {
      const response = await updateUserProfileV1({
        userProfileId: userProfile.userProfileId,
        nickname: nickname,
      });
      if (response.ok) {
        const responseBody = await response.json();
        onUpdatedUserProfile(responseBody.userProfile);
        setIsOpen(false);
      }
    }
  };

  return (
    <SettingsRow
      className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
      onClick={() => setIsOpen(true)}
    >
      <SettingTitleColumn>
        <SettingTitle className="text-main-light-1 dark:text-main-dark-1">
          {t("TradingUserProfileSettingsPage.nickname")}
        </SettingTitle>
      </SettingTitleColumn>
      <ValueColumn>
        <SettingValueText>{userProfile?.nickname}</SettingValueText>
      </ValueColumn>
      {isOpen && (
        <CommonDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={t("TradingUserProfileSettingsPage.changeNicknameDialogTitle")}
          children={
            <>
              <PrimaryInput
                defaultValue={nickname || userProfile?.nickname}
                maxLength={20}
                placeholder={t(
                  "TradingUserProfileSettingsPage.placeHolderNickname"
                )}
                onChangeText={(e) => setNickname(e.target.value)}
                style={{ marginTop: "16px" }}
              />
              <div style={{ textAlign: "right" }}>
                {(nickname || userProfile?.nickname) && (
                  <Text
                    value={nickname || userProfile?.nickname}
                    fontSize={"13px"}
                  >
                    {(nickname || userProfile?.nickname).length}/20
                  </Text>
                )}
              </div>
            </>
          }
          onClickDialogButton={() => onConfirmed()}
        />
      )}
    </SettingsRow>
  );
};

const DescriptionRow = ({ userProfile, onUpdatedUserProfile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [description, setDescription] = useState(userProfile?.description);

  const onConfirmed = async () => {
    if (!isEmpty(description)) {
      const response = await updateUserProfileV1({
        userProfileId: userProfile.userProfileId,
        description: description,
      });
      if (response.ok) {
        const responseBody = await response.json();
        onUpdatedUserProfile(responseBody.userProfile);
        setIsOpen(false);
      }
    }
  };

  return (
    <SettingsRow
      className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
      onClick={() => setIsOpen(true)}
    >
      <SettingTitleColumn>
        <SettingTitle className="text-main-light-1 dark:text-main-dark-1">
          {t("TradingUserProfileSettingsPage.description")}
        </SettingTitle>
      </SettingTitleColumn>
      <ValueColumn>
        {isEmpty(userProfile?.description) ? (
          <SettingValueText className="text-primary-light dark:text-primary-dark">
            {t("TradingUserProfileSettingsPage.add")}
          </SettingValueText>
        ) : (
          <SettingValueText className="text-main-light-1 dark:text-main-dark-1">
            {userProfile?.description}
          </SettingValueText>
        )}
      </ValueColumn>
      {isOpen && (
        <CommonDialog
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={t(
            "TradingUserProfileSettingsPage.changeDescriptionDialogTitle"
          )}
          children={
            <>
              <PrimaryInput
                defaultValue={description || userProfile?.description}
                maxLength={50}
                placeholder={t(
                  "TradingUserProfileSettingsPage.placeHolderDescription"
                )}
                onChangeText={(e) => setDescription(e.target.value)}
                style={{ marginTop: "16px" }}
              />
              <div style={{ textAlign: "right" }}>
                {(description || userProfile?.description) && (
                  <Text
                    value={description || userProfile?.description}
                    fontSize={"13px"}
                  >
                    {(description || userProfile?.description).length}/50
                  </Text>
                )}
              </div>
            </>
          }
          onClickDialogButton={() => onConfirmed()}
        />
      )}
    </SettingsRow>
  );
};

const ManageTradingCardsRow = () => {
  const navigate = useNavigate();
  return (
    <SettingsRow
      className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
      onClick={() =>
        navigate(`/artists/${getArtistId()}/my_trading_photo_cards`)
      }
    >
      <SettingTitleColumn>
        <SettingTitle className="text-main-light-1 dark:text-main-dark-1">
          {t("TradingUserProfileSettingsPage.manageTradingCards")}
        </SettingTitle>
      </SettingTitleColumn>
      <ValueColumn>
        <ArrowRightIcon
          width={"20px"}
          style={{ marginLeft: "4px", padding: "2px 4px" }}
          className="fill-sub-light-1 dark:fill-sub-dark-1"
        />
      </ValueColumn>
    </SettingsRow>
  );
};

const ManageTradingHistoriesRow = ({ userProfileId }) => {
  const navigate = useNavigate();
  return (
    <SettingsRow
      className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
      onClick={() => navigate(`/users/${userProfileId}/my_photo_cards_market`)}
    >
      <SettingTitleColumn>
        <SettingTitle className="text-main-light-1 dark:text-main-dark-1">
          {t("TradingUserProfileSettingsPage.manageTradingHistories")}
        </SettingTitle>
      </SettingTitleColumn>
      <ValueColumn>
        <ArrowRightIcon
          width={"20px"}
          style={{ marginLeft: "4px", padding: "2px 4px" }}
          className="fill-sub-light-1 dark:fill-sub-dark-1"
        />
      </ValueColumn>
    </SettingsRow>
  );
};

const ManageTradingReviewsRow = () => {
  return (
    <SettingsRow className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1">
      <SettingTitleColumn>
        <SettingTitle className="text-main-light-1 dark:text-main-dark-1">
          {t("TradingUserProfileSettingsPage.manageTradingReviews")}
        </SettingTitle>
      </SettingTitleColumn>
      <ValueColumn>
        <SettingValueText className="text-primary-light dark:text-primary-dark">
          {"Coming soon :)"}
        </SettingValueText>
        {/* <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} className="fill-sub-light-1 dark:fill-sub-dark-1"/> */}
      </ValueColumn>
    </SettingsRow>
  );
};
