import React, { useEffect, useState } from "react";

import { Image, Spinner, useColorMode } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { formatValue } from "react-currency-input-field";
import { Helmet } from "react-helmet-async";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";

import AddTradingPhotoCardsV2 from "../../components/api/mutations/AddTradingPhotoCardsV2.js";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import TradeMethodBottomDrawer from "../../components/drawer/TradeMethodBottomDrawer";
import StickyHeader from "../../components/StickyHeader";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.small.svg";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { intlConfig } from "../../utils/CurrencyUtil";

const Title = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: 12px 0px;
`;

const PriceText = styled.div`
  margin-top: 6px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(54px + env(safe-area-inset-top));
  padding-bottom: calc(128px + env(safe-area-inset-bottom));
`;

const PageRowContainer = styled.div`
  padding: 0px 24px 12px 24px;
  scroll-snap-align: start;
`;

const ImageContainer = styled.div`
  width: 75px;
  height: 75px;
  margin-left: 8px;
  position: relative;
  padding-top: 12px;
`;

const AddImageButton = styled.div`
  width: 75px;
  height: 75px;
  place-items: center;
  align-content: center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
`;

const StickyContainer = styled.div`
  position: sticky;
`;

const HorizontalScrollContainer = styled.div`
  padding-top: 8px;
  display: flex;
  overflow-x: auto; /* 수평 스크롤 가능 */
  scrollbar-width: none; /* Firefox에서 스크롤바 숨기기 */
  -ms-overflow-style: none; /* Internet Explorer 및 Edge에서 스크롤바 숨기기 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera에서 스크롤바 숨기기 */
  }
`;

const PriceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: ${({ index }) => (index > 0 ? "5px" : "0")};
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* FIXME : background: rgba(245, 245, 245, 1); */
  border-radius: 8px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 4px;
  border-radius: 32px;
  padding: 5px;
  margin-right: -12px;
  margin-top: -8px;
  cursor: pointer;
  display: inline-flex;
`;

const AttachedImage = styled.img`
  width: 75px;
  height: 75px;
  border-radius: 8px;
  object-fit: cover;
`;

const ImageCount = styled.div`
  font-size: 12px;
`;

const TextArea = styled.textarea`
  border-radius: 12px;
  width: 100%;
  padding: 20px;
  resize: vertical;
  height: 120px;
  outline: none;
`;

const TradeMethodTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
`;

const TradeMethodDescription = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
`;

export default function ConfirmNewSalesTradingPhotoCardsPage() {
  const { colorMode } = useColorMode();

  const { state } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const category = currentQueryParams.get("category");
  const artistId = currentQueryParams.get("artistId");
  const tradingPhotoCards = state?.newTradingPhotoCards;

  const [imageFiles, setImageFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedTradeMethod, setSelectedTradeMethod] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [description, setDescription] = useState("");

  const user = useUserQuery();

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
  });

  const handleAddImageClick = () => {
    if (imageFiles.length < 10) {
      openFilePicker();
    }
  };

  useEffect(() => {
    if (filesContent.length > 0) {
      setImageFiles(filesContent);
    }
  }, [filesContent]);

  const onDone = async () => {
    if (!isUploading && user?.tradingUserProfile) {
      setIsUploading(true);
      try {
        const tradingPhotoCardsData = tradingPhotoCards.map(
          (tradingPhotoCard) => ({
            artistId: artistId,
            photoCard: {
              memberId: tradingPhotoCard.photoCard.memberId,
              photoCardId: tradingPhotoCard.photoCard.photoCardId,
            },
            description: description,
            category: category,
            price: tradingPhotoCard.price,
            tradeMethod: selectedTradeMethod,
          })
        );

        const response = await AddTradingPhotoCardsV2(
          user.tradingUserProfile.userProfileId,
          tradingPhotoCardsData,
          imageFiles.map((imageFile) => imageFile.content)
        );

        if (response.ok) {
          queryClient.invalidateQueries("ExploreTradingPhotoCardsV3");
          navigate("/tradings/completed_add?isExchange=false", {
            replace: true,
          });
        }
      } catch (error) {
        console.error("Error adding trading photo cards:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleTradeMethodSelect = (method) => {
    setSelectedTradeMethod(method);
    setIsDrawerOpen(false);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <>
      <Helmet>
        <title>{t("ConfirmNewSalesTradingPhotoCardsPage.title")}</title>
      </Helmet>
      <StickyHeader
        title={t("ConfirmNewSalesTradingPhotoCardsPage.title")}
        showBackButton={true}
        alwaysShowTitle={true}
      />
      <PageWrapper>
        <PageRowContainer>
          <Title className="text-main-light-1 dark:text-main-dark-1">
            {t("ConfirmNewSalesTradingPhotoCardsPage.subTitleProduct")}
          </Title>
          {!isEmpty(tradingPhotoCards) && (
            <StickyContainer className="bg-background-light dark:bg-background-dark">
              <HorizontalScrollContainer>
                {tradingPhotoCards?.map((tradingPhotoCard, index) => {
                  return (
                    <PriceContainer
                      key={tradingPhotoCard.tradingPhotoCardId}
                      index={index}
                    >
                      <ImageWrapper className="bg-surface-light dark:bg-surface-dark">
                        <Image
                          src={tradingPhotoCard.photoCard.pictureUrl}
                          width={"40px"}
                          height={"63px"}
                          objectFit={"cover"}
                          loading={"lazy"}
                          borderRadius={"6px"}
                          marginRight={"0px!important"}
                        />
                      </ImageWrapper>
                      <PriceText className="text-textPrimary-light dark:text-textPrimary-dark">
                        {isEmpty(tradingPhotoCard.price)
                          ? t("tradingPhotoCardPage.offerPriceWithEmoji")
                          : formatValue({
                              value: tradingPhotoCard.price,
                              intlConfig: intlConfig(user),
                            })}
                      </PriceText>
                    </PriceContainer>
                  );
                })}
              </HorizontalScrollContainer>
            </StickyContainer>
          )}
        </PageRowContainer>
        <PageRowContainer style={{ paddingBottom: "0px" }}>
          <Title>
            {t("ConfirmNewSalesTradingPhotoCardsPage.subTitleDescription")}
          </Title>
        </PageRowContainer>
        <div className="w-full">
          <ScrollMenu wrapperClassName="padding-left-scroll-container fixed-height-scroll-container">
            <AddImageButton
              className="border border-separator-light-1 dark:border-separator-dark-1"
              onClick={handleAddImageClick}
            >
              <CameraIcon className="fill-black dark:fill-white" />
              <ImageCount className="text-sub-light-1 dark:text-sub-dark-1">
                {imageFiles.length}/10
              </ImageCount>
            </AddImageButton>
            {imageFiles.length > 0 &&
              imageFiles.map((file, index) => (
                <ImageContainer key={index}>
                  <AttachedImage
                    src={file.content}
                    alt={`Attached Image ${index + 1}`}
                  />
                  <CloseButton
                    className="bg-primary-light dark:bg-primary-dark"
                    onClick={() => {
                      const newFiles = [...imageFiles];
                      newFiles.splice(index, 1);
                      setImageFiles(newFiles);
                    }}
                  >
                    <CloseIcon
                      width={"16px"}
                      height={"16px"}
                      className="fill-onPrimary "
                    />
                  </CloseButton>
                </ImageContainer>
              ))}
          </ScrollMenu>
        </div>
        <PageRowContainer style={{ paddingTop: "16px" }}>
          <TextArea
            className="border border-separator-light-1 dark:border-separator-dark-1"
            placeholder={t(
              "ConfirmNewSalesTradingPhotoCardsPage.desciprtionPlaceholder"
            )}
            value={description}
            onChange={handleDescriptionChange}
          />
        </PageRowContainer>
        {/* <PageRowContainer>
          <RadioRow
            isChecked={isNewItem}
            text={t("미개봉 상품 입니다.")}
            onChanged={() => setIsNewItem(!isNewItem)}
          />
        </PageRowContainer> */}

        <PageRowContainer
          className="flex justify-between items-center"
          style={{ paddingTop: "24px" }}
          onClick={() => setIsDrawerOpen(true)}
        >
          <TradeMethodTitle className="text-main-light-1 dark:text-main-dark-1">
            {t("ConfirmNewSalesTradingPhotoCardsPage.subTitleTradeMethod")}
          </TradeMethodTitle>
          <div className="flex flex-row gap-[4px]">
            <TradeMethodDescription className="text-main-light-3 dark:text-main-dark-3">
              {t(`TradeMethod.${selectedTradeMethod || "any"}`)}
            </TradeMethodDescription>
            <ArrowRightIcon
              className="fill-sub-light-1 dark:fill-sub-dark-1"
              width={"20px"}
              height={"20px"}
            />
          </div>
        </PageRowContainer>
        {!isDrawerOpen && (
          <div
            className={`${
              colorMode === "dark" ? "darkBottomNavbar" : "bottomNavbar"
            } bg-background-light dark:bg-background-dark text-center`}
            style={{
              padding: "0px 24px calc(16px + env(safe-area-inset-bottom)) 24px",
              zIndex: 9999,
            }}
          >
            {isUploading ? (
              <Spinner
                size="md"
                className="text-primary-light dark:text-primary-dark"
              />
            ) : (
              <PrimaryButton
                paddingY={"16px"}
                text={t("done")}
                zIndex={9999}
                onClick={onDone}
              />
            )}
          </div>
        )}
      </PageWrapper>
      {isDrawerOpen && (
        <TradeMethodBottomDrawer
          user={user}
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          onSelectTradeMethod={handleTradeMethodSelect}
        />
      )}
    </>
  );
}

// const RadioRow = ({isChecked, text, onChanged, props}) => {
//   const { getInputProps } = useRadio(props);
//   const input = getInputProps();

//   return (
//     <div className="flex flex-row" as="label">
//       <input {...input} />
//       <PriamryRadio text={text} isChecked={isChecked} onChanged={onChanged}/>
//     </div>
//   );
// };
