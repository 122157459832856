import React from "react";
import { Box, Center } from "@chakra-ui/react";

export default function SecondaryButton({ text, icon, disabled, ...props }) {
  return (
    <Box
      className={`text-center ${
        disabled
          ? "bg-disabled-light dark:bg-disabled-dark text-sub-light-2 dark:text-sub-dark-2"
          : "text-onSecondary-light dark:text-onSecondary-dark bg-secondary-light dark:bg-secondary-dark"
      }`}
      borderRadius={"52px"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      fontWeight={500}
      fontSize={"14px"}
      alignContent={"center"}
      {...props}
    >
      <Center>
        <p>{text}</p>
        {icon && <>{icon}</>}
      </Center>
    </Box>
  );
}
