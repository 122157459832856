export const zhTW = {
  translation: {
    pocapan: "Pocapan",
    requestFailed: "請求失敗。",
    next: "下一步",
    done: "完成",
    save: "保存",
    delete: "刪除",
    confirm: "完成",
    cancel: "取消",
    login: "登入",
    logout: "登出",
    photoCardCount: "{{count}}個",
    cardsCount: "{{count}}張",
    entire: "全部",
    report: "舉報",
    send: "發送",
    megaphone: "擴音器",
    picture: "照片",
    photoCard: "小卡",
    poca: "小卡",
    anonymous: "匿名",
    title: "標題",
    comment: "評論",
    like: "喜歡",
    leave: "離開",
    entireMembers: "所有成員",
    sendMessage: "發送消息",
    accept: "接受",
    decline: "拒絕",
    yes: "是",
    no: "否",
    select: "選擇",
    follow: "关注",
    dofollow: "关注",
    followBack: "回关",
    doFollowBack: "回关",
    follower: "粉丝",
    following: "关注中",
    mutualFollowing: "互相关注",
    social: "社交",
    trade: "交易",
    tabs: {
      home: "首頁",
      collections: "小卡圖鑑",
      feed: "社群",
      pocapan: "小卡圖鑑",
      store: "商城",
      tradingPhotoCard: "貿易場",
      reportPhotoCard: "建議小卡",
      profile: "我的",
      notifications: "通知",
    },
    CollectionsHomePage: {
      RegisteredPhotoCards: "{{count}}張小卡",
      CollectedPhotoCards: "持有{{count}}張小卡",
      EditPhotoCardFabButton: "編輯已持有的小卡",
      ReportPhotoCardFabButton: "添加缺失的小卡",
      DummyBirthdayCafe:
        "查看生日咖啡館、拍攝地點和廣告牌等獨家線下場所的集合！",
      DummyGoods: "查看玩偶、鑰匙圈和相簿等商品的集合！",
      DummyFashion: "查看您最喜愛的服裝詳情集合！",
      DummyHomeMaster: "看看內容合集！",
      DummyDescription: "「POCAPAN」正在努力整理您的資料！完成後我們會通知您。",
      WatingButton: "開啟後接收通知",
      WatingCompleted: "已完成通知訂閱",
      WatingToast: "開啟後我們會通知您",
    },
    feedPage: {
      title: "社群",
      defaultProfile: "個人資料 🔒",
      activeProfile: "在這個社群使用<bold>{{nickname}}</bold>",
      emptyProfile: "創建社交資料",
      alertMarquee:
        "為了安全和愉快的交易，請使用貿易場進行小卡交易。簡單的交易帖子將被刪除，不另行通知！",
      onlyFandom: "僅查看粉絲圈的帖子",
      mutualFandom: "只查看與我相同的粉絲文",
      newPostNotification: "查看新帖子",
      uploadToastMessage: "上傳完成！",
      uploadToastShowPost: "查看",
      fandomFeed: "貿易場",
      searchPlaceholder: "搜尋好友",
      recentTab: "最近",
      followingTab: "追蹤中",
      friendshipTab: "交流区",
      marketplaceTab: "出售区",
      forSaleFabButton: "出售小卡",
      fanBuddyFabButton: "#找同好",
      questionFabButton: "問題",
      freeTalkFabButton: "#韩流聊天",
    },
    feedIntroPage: {
      title: "社區",
      introTitle: "現在你可以在Pocapan的\n社區中盡情玩耍！",
      firstRowTitle: "使用匿名功能秘密分享！",
      firstRowDescription: "只有同一個粉絲圈的人才能看到我的帖子！",
      secondRowTitle: "使用擴音器與大家分享好消息！",
      secondRowDescription: "你甚至可以用引用添加自己的想法！",
      thirdRowTitle: "使用Only Fandom功能在粉絲圈內分享！",
      thirdRowDescription: "[好友專用]功能也在準備中！",
      confirmFeedAgreement: "視為您已同意更新的條款。",
    },
    feedReportPage: {
      pageTitle: "舉報",
      menuInsult: "侮辱/謾罵",
      menuFalseInformation: "散布虛假信息",
      menuObscenity: "淫穢內容",
      menuOverPosting: "廣告/評論垃圾信息",
      menuPromotion: "推廣",
      menuOther: "其他",
      author: "作者",
      content: "帖子內容",
      selectReportReason: "請選擇舉報原因",
    },
    addPostPage: {
      pageTitle: "創建新帖子",
      title: "創建社交資料",
      titlePlaceHolder: "點擊此處添加標題",
      contentPlaceHolder: "你今天過得怎麼樣？",
      contentPicturesError: "上傳最多4張圖片，總大小限制50MB",
      disclaimer:
        "請避免留下可能讓他人不適的評論，例如粗話或侮辱性語言。如果被舉報，你的社區使用可能會受到限制。",
      onlyFandom: "僅對{{fandom}}可見",
      onlyFandomDescription: "設置為僅顯示選定粉絲圈！",
      addPhotoCard: "添加小卡",
      addPhotoCardBoard: "小卡圖鑑",
      friendsOnly: "好友(即將推出)",
    },
    postDetailPage: {
      pageTitle: "發布{{title}}",
      deleteMenu: "刪除",
      deletedDescription: "評論所在的原始帖子已被刪除。",
      postOnFeed: "也將此評論發布到社群中",
      alertDeletedPost: "原始帖子已被刪除。",
      commentPlaceHolder: "留下評論",
      sourcePostDescription: "評論<bold>{{post}}</bold>",
      followings: "<bold>{{count}}</bold> Followings",
      followers: "<bold>{{count}}</bold> Followers",
    },
    userFeedProfilePage: {
      pageTitle: "社交資料",
      postTab: "帖子",
      anonymousTab: "匿名",
      commentTab: "評論",
      navigateTradingProfile: "看看{{nickname}}的交易資料",
      menuChangeNickname: "修改暱稱",
      menuHiddenTradingProfile: "設為私密交易資料",
      menuOpenTradingProfile: "設為公開交易資料",
      anonymousOnlyToYou: "匿名標籤僅自己可見。",
    },
    addPhotoCardPage: { pageTitle: "添加小卡" },
    post: {
      emptyPost: "沒有可用帖子",
      emptyFollowerPost: "在這裡查看您關注的人的帖子！",
      megaphonedPost: "{{author}}正在使用擴音器",
      commentedPost: "評論{{content}}",
      deletedPost: "你評論的帖子已被刪除。",
      deletedCommentMenu: "刪除",
      confirmDelete: "確定要刪除嗎？",
      reportPostMenu: "舉報帖子",
      reportUserMenu: "舉報用戶",
      deletePostMenu: "刪除",
      onlyFandom: "Only\n{{fandom}}",
      reply: "回覆",
    },
    createFeedUserProfilePage: {
      pageTitle: "創建社交資料",
      title: "創建社交資料",
      description: "為了社群中的安全和隱私，只能使用社交資料進行交流！",
      nickNamePlaceHolder: "請輸入你的名字",
    },
    reportBottomSheet: {
      megaphone: "立即使用擴音器",
      unmegaphone: "取消擴音器",
      quotes: "添加引用和擴音器",
    },
    exploreTradingPhotoCardBottomSheet: {
      errorNoProfileToast: "請求失敗。請確保您的資料已創建。",
      tradingPhotoCardInfo: "交易卡詳情",
      sendMessage: "發送消息",
    },
    photoCardBottomNav: {
      collectedPhotoCardCount: "{{collectedCount}} / {{totalCount}}",
      collectedPhotoCardPercentage: "{{member}}的小卡{{percentage}}%",
      editModeButton: "編輯持有的小卡",
    },
    introPage: { startButton: "開始Pocapan！" },
    requestVerificationCodePage: {
      emailErrorMessage: "請確保電子郵件地址格式正確",
      title: "請提供您將用於登錄的電子郵件",
      description: "別擔心，不會與任何人分享！",
      placeHolder: "點擊此處輸入電子郵件",
    },
    loginWithCodePage: {
      title: "請輸入驗證碼",
      description: "已發送至{{email}}！",
      notReceivedMessage: "未收到驗證郵件？",
      sendAgainMessage: "重發",
      successSendAlert: "電子郵件已重新發送。",
      failedSendAlert: "重發失敗。請稍後再試。",
      verificationFailedAlert: "驗證失敗。請再檢查。",
    },
    welcomePage: {
      title: "很高興認識你！",
      description: "我會讓你的追星更方便。",
    },
    selectArtistPage: {
      pageName: "選擇藝術家",
      title: "請選擇一個藝術家",
      description: "如果我們收到足夠的請求，我們將添加不存在的團體！",
      errorAlert: "請選擇一個藝術家。",
      whoIsNext: "下一個是誰？",
    },
    selectMemberPage: {
      pageName: "選擇成員",
      title: "選擇你最喜歡的表情包",
      errorAlert: "請至少選擇一個成員。",
      next: "完成",
      confirmed: "成員更改完成",
    },
    photoCardPage: {
      pageName: "小卡圖鑑",
      title: "選擇你擁有的小卡",
      description: "未選擇的小卡將被標記為未擁有的小卡！",
      errorAlert: "請至少選擇一個成員。",
      navigationSkip: "稍後完成",
      navigationReport: "提供",
      navigationEdit: "編輯",
      confirmErrorAlert: "請至少選擇一張小卡。",
      listTitle: "小卡圖鑑",
      bannerTitle: "創建你的小卡圖鑑",
      showPhotoCardsOnFeed: "在社群中查看我擁有的小卡",
      manageArtist: "💖管理藝術家",
      emptyPhotoCards: "沒有可用的小卡圖鑑",
      showColorMode: "以彩色模式查看",
      discardAlertDialog: "你有未保存的更改。\n取消編輯？",
      likedArtistPhotoCardList: "{{artist}}的小卡",
      filter: "篩選",
    },
    checkPhotoCardBottomSheet: { title: "小卡列表", report: "提供" },
    emptyPhotoCard: {
      title: "勾選的小卡將顯示在這裡",
      description: "檢查你擁有的小卡！",
      startCollectPhotoCard: "去檢查",
    },
    myPhotoCardBottomSheet: {
      smallSize: "小視圖",
      middleSize: "中等視圖",
      largeSize: "大視圖",
      shareImageTwitterButton: "在Twitter上分享此圖片",
      collectedPhotoCards: "已收集的小卡",
      unCollectedPhotoCards: "未擁有的小卡",
      wishedPhotoCards: "想要的小卡",
      showPhotoCardName: "查看小卡名稱",
      createPhotoCardBoard: "創建小卡圖鑑",
      shareTwiiterText:
        "https://twitter.com/intent/tweet?text=1. 使用下載圖標保存圖片。%0A2. 如果你有iPhone，在'文件'應用中找到圖片。%0A3. 在Twitter上分享並向你的朋友炫耀。%0A粉絲們，Pocapan&url=pocapan.com",
    },
    reportPhotoCardPage: {
      pageName: "提供一張小卡",
      title: "提供一張小卡",
      categoryDescription: "這是什麼類型的小卡？",
      eventDescription: "這是什麼類型的小卡？",
      requiredMissingAlert: "請選擇全部。",
      reportDoneAlert: "謝謝！我們會盡快檢查並更新！",
      selectPlaceHolder: "點擊此處找到我們！",
      directlyInputOption: "直接輸入",
      reportDone: "提供",
      duplicatedAlert: "上傳照片...",
      categoryTitle: "你想報告什麼問題?",
      incorrect: "資訊錯誤",
      duplicated: "重複資訊",
      blurryOrWatermark: "照片模糊或有浮水印",
      unofficial: "非官方照片卡",
      other: "其他",
      contentTitle: "為什麼要報告呢?",
      placeHolderContent: "請輸入報告的原因",
      replacementPhotoTitle: "有替代照片嗎?",
    },
    ReportSearchEventPage: {
      placeHolder: "搜尋活動名稱",
      newAddEvent: `新建「<bold>{{eventName}}</bold>」+`,
      pending: "未批准",
      unknownEventText:
        "找不到您要找的活動名稱嗎？\n您可以直接輸入想要的名稱來新增！",
    },
    userProfilePage: {
      pageName: "我的Pocapan",
      title: "我的",
      myPhotoCard: "我的小卡",
      changeMember: "更改你的收集成員",
      initializePhotoCards: "重置我的小卡",
      notice: "通知",
      terms: "服務條款",
      privacy: "隱私政策",
      deleteAccount: "刪除賬戶",
      logoutConfirmAlert: "你想要登出嗎？",
      deleteAccountConfirmAlert: "刪除賬戶？",
      initializePhotoCardsAlert: "所有你的小卡將被重置。你確定要繼續嗎？",
      errorIntializePhotoCardsAlert: "請稍後再試。",
    },
    addTradingPhotoCardPage: {
      exchangePageName: "出售我的小卡",
      salesPageName: "交換我的小卡",
      pageName: "添加交易的小卡",
      title: "請註冊您想要交易的小卡",
      exchangeTitle: "請選擇您要提供交換的小卡",
      salesTitle: "請選擇要出售的小卡",
      searchInputPlaceholder: "按名稱或專輯名稱搜索",
      countButton: "{{count}} 添加",
      myPhotoCardTitle: "我的一張小卡",
      searchPhotoCardTitle: "所有小卡",
    },
    childLoginPage: {
      pageName: "監護人未授權使用限制",
      title: "因缺乏監護人同意而限制訪問",
      description:
        "對於14歲以下且無監護人同意的用戶，在獲得同意前訪問受限。監護人同意需要在5天內提供。如果未提供同意，賬戶將被刪除，所有信息將被銷毀。(限制日期: MM/DD/YYYY)",
      enableAccountGuide:
        "監護人同意通過電子郵件處理。請讓您的家長直接聯繫我們：pocapan.official@gmail.com",
      deleteAccount: "刪除賬戶",
      backToIntro: "返回首頁",
    },
    blockUserPage: {
      pageName: "訪問受限",
      title: "訪問受限",
      description:
        "由於多名用戶舉報，訪問受限。如有疑問，請發送電子郵件至pocapan.official@gmail.com",
    },
    requiredLoginPage: {
      pageName: "需要登錄",
      title: "需要登錄",
      description: "由於未知錯誤，你已被登出。請重新登錄以確保安全體驗。",
    },
    emptyTradingPhotoCard: {
      title: "{{nickname}}，請添加你想交易的小卡！",
      description: "Pocapan將幫助你找到交易匹配。",
      addTradingPhotoCard: "添加交易的小卡",
    },
    emptyExploreTradingPhotoCard: {
      title: "尚未發布任何小卡。",
      description: "僅顯示他人在交易的小卡",
      addTradingPhotoCard: "添加交易的小卡",
    },
    emptyWishedTradingPhotoCard: {
      title: "尚未想要的小卡！",
      description: "一目瞭然地收集和查看你想要的小卡。",
      addTradingPhotoCard: "添加交易的小卡",
    },
    emptyWishedMatchedTradingPhotoCard: {
      title: "尚未有想要的交易！",
      description: "當你對交易猶豫不決時使用此功能。",
      addTradingPhotoCard: "添加交易的小卡",
    },
    emptyMessages: { title: "尚未有消息！", button: "瀏覽交易的小卡" },
    forceToUpdate: {
      pageTitle: "💌 來自Pocapan的信 💌",
      title: "隨著應用更新，新的社群即將到來💖",
      button: "去更新",
      firstRowDescription:
        "大家好，我是波卡潘團隊！\n" +
        "我們推出 Pocapan 已經一年了。\n" +
        "我們要感謝大家對我們的關注\n" +
        "是你們一如既往的支持幫助我們走到今天。\n" +
        "波卡潘已經從一個照片卡片交流平台發展成為\n" +
        "以粉絲為中心的美德文化之家。",
      secondRowDescription: "我們正在開設一個新的社群，讓交流更加輕鬆自由。",
      thirdRowDescription: "我們誠摯地邀請您加入我們。",
    },
    tradingPhotoCardPage: {
      pageTitle: "貿易場",
      exchangeTabName: "交換匹配",
      salesTabName: "交易/購買列表",
      manageMyTradingPhotoCard: "我的交易小卡",
      marqueeText:
        "🔍停止搜索！通過貿易場輕鬆找到你的交易夥伴！Pocapan對交易問題不負責，請謹慎！",
      thisCardOnlyForSale: "選定的小卡被設置為僅出售卡。",
      tradingPhotoCardChatRooms: "與這張小卡的聊天室",
      noChatRoom: "目前沒有活躍的聊天室。",
      exchangeEventTradingPhotoCards: "同一事件的可交易小卡",
      emptyEventTradingPhotoCards: "尚無同一事件的小卡。",
      exchangeTradingPhotoCards: "可交易的小卡",
      addProfileBottomSheetTitle: "設置你的交易資料",
      tradingManagerBarTitle: "我的交易卡",
      tradingManagerBarSaleTitle: "我的出售小卡",
      tradingManagerBarExchangeTitle: "我的交換小卡",
      tradingManagerBarManage: "編輯",
      aggregatedPhotoCardCardTitle: "可供我交易",
      showUserProfilesBottomSheetTitle: "選擇要交易的{{fandom}}？",
      notAvailableExchangeTradingPhotoCards: "尚無可交易的小卡 🥲",
      offetSalesTradingPhotoCardsRow1: "也可以直接從{{fandom}}購買。",
      offetSalesTradingPhotoCardsRow2: "您也可以直接購買",
      offetSalesTradingPhotoCardsRow3: "",
      matchedTradingPhotoCardBottomDrawerTitle: "交易卡詳情",
      matchedTradingPhotoCardMine: "我有",
      matchedTradingPhotoCardTarget: "想要",
      addBlackListMenu: "不再接受這張卡的匹配",
      reportMenu: "舉報",
      reMatching: "再次尋找",
      exchange: "交易",
      sale: "出售",
      salesSwitchTitle: "顯示僅出售的小卡",
      exchangeTabTitle: "{{saleType}}的小卡由{{fandom}}出售",
      salesSwitchDescription: "發送直接消息以接收交易！",
      saleBadge: "出售",
      excludeMatching: "排除",
      allowMatching: "允許",
      offerPrice: "出價",
      offerPriceWithEmoji: "出價🧚‍♂️",
      tradingPhotoCardInfoBar: "我的交易卡",
      noProfileToast: "請求失敗。請確保您的資料已創建。",
      askPrice: "詢價",
      purchaseWithPrice: "{{price}} 購買",
      minimumPrice: "最低",
    },
    myTradingPhotoCardsPage: { pageName: "設置交易小卡" },
    tradingPhotoCardChatRoom: {
      pageTitle: "消息",
      noticeDeletedUser: "成員已被停用。如有進一步疑問，請聯繫Pocapan的郵件。",
      noticeLeftUser: "對方已結束聊天。",
      leaveChatRoomDialogTitle: "離開聊天室？",
      leaveChatRoomDialogDescription:
        "離開將結束交易，您將不再收到對方的消息。",
      leaveChatRoomDialogConfirm: "離開聊天室",
      saleTradingMenu: "銷售",
      purchasingTradingMenu: "銷售",
      completedTradingMenu: "交易完成",
    },
    photoCardDetailPage: {
      firstCaptorTitle: "這張卡的小卡精靈💖",
      collected: "擁有",
      collectedCount: "{{count}} 張小卡",
      notCollected: "未擁有",
      changeToNotCollected: "改為未擁有",
      changeCollectedCount: "設置數量",
      exchangeTradingPhotoCardTitle: "立即用以下卡片進行交易！",
      saleTradingPhotoCardTitle: "{{fandom}}正在出售的小卡",
      emptysaleTradingPhotoCards: "目前沒有人出售這張卡。",
      feedTitle: "{{fandom}}場",
      feedDescription: "想了解更多嗎？查看社群！",
      showAllFeed: "查看全部",
      firstCaptorBadge: "🧚首位捕獲者",
      changeCollectedBottomSheetTitle: "你有卡！",
      changeCollectedBottomSheetDescription: "請輸入你擁有的卡數量。",
      tradeButton: "交易",
      title: "{{memberName}的{{categoryName}}{{categoryEmoji}}",
    },
    myPhotoCards: {
      pageName: "交易歷史",
      totalCount: "總計{{count}}",
      tradingStatusSelectorOngoing: "正在進行的交易",
      tradingStatusSelectorCompleted: "已完成的交易",
      tradingPhotoCardBoxByPhotoCardBadge: "聊天室{{count}}",
      exchangingPhotoCardsTabName: "交易",
      exchangingPhotoCardsTabEmptyCaseTitle: "尚未交易任何小卡",
      exchangingPhotoCardsTabEmptyCaseButton: "去交易",
      exchangingPhotoCardsTabReviewNotYet:
        "為「交易」發送評價的功能仍在開發中。",
      sellingPhotoCardsTabName: "出售",
      sellingPhotoCardsTabEmptyCaseTitle: "尚未出售任何小卡",
      sellingPhotoCardsTabEmptyCaseButton: "去出售",
      purchasingPhotoCardsTabName: "購買",
      purchasingPhotoCardsTabEmptyCaseTitle: "尚未購買任何小卡",
      purchasingPhotoCardsTabEmptyCaseButton: "去購買",
      purchasingPhotoCardsTabSaleBadge: "出售中",
      purchasingPhotoCardsTabEmptyExchangeBadge: "交易中",
      chatRoomPageName: "活躍的聊天室",
      chatRoomPageSoldOutButton: "評價功能即將推出🖤",
      chatRoomPageCompletedButton: "已完成的交易",
      chatRoomPageConvertToSaleButton: "改為出售",
      chatRoomPageConvertToExchangeButton: "改為交易",
      chatRoomPageEmptyCase: "目前沒有活躍的聊天室",
      chatRoomExchangeDialogTitle: "改為僅交易？",
      chatRoomExchangeDialogDescription:
        "現有的活躍聊天室將關閉，對方會收到通知。對話仍可以繼續！",
      chatRoomCompleteDialogTitle: "這是一個已完成的交易嗎？",
      chatRoomCompleteDialogDescription: "評價功能即將推出！",
      chatRoomSaleDialogTitle: "改為僅出售？",
      chatRoomSaleDialogDescription:
        "現有的活躍聊天室將關閉。如果未輸入，將標記為“出價”。",
      chatRoomSaleDialogInputPlaceholder: "請輸入金額。",
      changePriceDialogTitle: "編輯價格？",
      changePriceDialogDescription: "如果未輸入價格，將顯示為“出價”。",
      onlySale: "出售",
      editPrice: "編輯",
      doneAndSendReview: "關閉並撰寫評價",
      sentReviewButton: "查看我給他人的評價",
      sendReviewButton: "💘寫交易評價💘",
      receivedReviewButton: "查看他人給我的評價",
    },
    tradingReportPage: {
      pageName: "舉報到Pocapan",
      title: "Pocapan舉報中心",
      descriptionFirstRow:
        "• 舉報首先由自動系統處理。對於重複舉報，管理員將進行審核並採取進一步行動。",
      descriptionSecondRow:
        "• 如果舉報導致法律問題，Pocapan不承擔任何責任。如有必要，可提供個人信息和聊天內容。",
      reportCode: "舉報代碼",
      reportDescription: "舉報詳情",
      reportDescriptionPlaceholder: "請輸入詳細信息。",
      reportDescriptionEmptyErrorMessage: "請輸入內容。",
      reportAttchment: "附件",
      reportAttchmentPlaceHolder: "請附加文件。",
      reportUserEmail: "舉報者的電子郵件地址",
      reportUserEmailEmptyErrorMessage: "請輸入電子郵件。",
      reportUserEmailFormatErrorMessage: "請檢查電子郵件格式。",
      reportAgreement: "我同意提供個人信息和審核聊天內容。",
      reportSubmit: "提交",
      successToastMessage: "舉報成功提交。",
      failedToastMessage: "請求失敗。請稍後再試。",
    },
    blacklistCards: {
      manage: "編輯",
      exclude: "設置排除的小卡",
      pageName: "設置排除的小卡",
      title: "設置排除的小卡",
      description: "選擇要從匹配中排除的卡。擁有的卡將自動排除。",
    },
    agreements: {
      baseAgreementBottomSheetTitle: "請查看並接受更新的服務條款。",
      baseAgreementBottomSheetDescription:
        "您有權拒絕，但拒絕可能會限制您對服務的訪問。",
      baseAgreementBottomSheetButton: "開始",
      baseAgreementAbove14: "請查看並接受更新的服務條款。",
      baseAgreementBottomAgreeAllButton: "全部同意",
      baseAgreementRequiredCheckBox: "（必填）",
      baseAgreementRequiredAgreement: "Pocapan條款和條件",
      baseAgreementOptionalCheckBox: "（可選）",
      baseAgreementPromotionalAgreement: "同意接收營銷信息",
    },
    errorBoundary: {
      title: "發生錯誤。",
      description: "請返回首頁重新嘗試。",
      button: "返回首頁",
    },
    newTradingPhotoCardsHorizontalCard: {
      authorName: "貿易場",
      authoredAt: "剛剛",
      newSales: "新上市的小卡🛍️",
      newExchanges: "新上市的交易小卡↔️",
    },
    myUserProfileList: {
      privateProfile: "社交資料",
      createPrivateProfile: "社交資料",
      tradingProfile: "交易資料",
      addProfile: "添加資料",
      changeProfile: "編輯社交資料",
    },
    profiles: {
      defaultButton: "開始",
      nickNameButton: "以{{nickname}}開始",
      hasBatchimNickNameButton: "以{{nickname}}開始",
      emptyNicknameErrorMessage: "請輸入昵稱。",
      duplicatedNickName: "這個昵稱已被使用。",
      failedToCreateAlert: "註冊失敗。",
      mergedNicknameToastMessage: "資料已合併。",
      title: "編輯社交資料",
      inputNickNamePlaceholder: "請輸入昵稱。",
      mergeProfilePageTitle: "合併資料",
      mergeProfileTitle: "多粉絲！請選擇一個資料。",
      mergeProfileDescription: "使用貿易場時無需再切換藝術家！",
      mergeProfileFirstDescriptionRow:
        "• 您可以在“我的 > 藝術家管理”中管理藝術家。",
      mergeProfileSecondDescriptionRow:
        "• 多資料支持將會在重新整理後重新推出。",
      mergeProfileThirdDescriptionRow: "• 現有的對話將反映更新後的昵稱。",
      chooseMergedProfile: "選擇",
      createNewNickname: "創建新昵稱",
    },
    notifications: {
      pageTitle: "通知",
      messagesTab: "消息",
      notificationsTab: "通知",
      notificationsTitle: "通知列表正在準備中。",
      notificationsDescription: "正在發送消息/評論/喜歡通知！",
      messageCategoryAll: "全部",
      messageCategorySelling: "出售",
      messageCategoryPurchasing: "購買",
      messageCategoryExchanging: "交易",
    },
    selectArtists: {
      initArtistTitle: "選擇要重置的藝術家",
      initArtistDescription: "僅擁有的小卡將被重置！交易記錄保持不變。",
      photoCardsCount: "{{count}} 張小卡",
    },
    tradingUserListPage: {
      pageName: "同一事件的小卡",
      title: "你想要與哪個{{fandom}}交易？",
      batchimTitle: "你想要與哪個{{fandom}}交易？",
      orRow: "或",
      saleSubTitleRow: "想要快速購買嗎？支付額外費用...",
      saleTitleFirstRow: "出售中",
      saleTitleSecondRow: "可用於{{fandom}}！",
    },
    addTradingPhotoCard: {
      editNewTradingTitle: "註冊完成😍",
      editNewTradingSubTitle: "這是僅出售嗎？",
      editNewTradingDescription: "如果僅出售，請輸入價格！",
      addButton: "添加",
      collectedBadge: "擁有",
      emptyCollectedCaseTitle: "沒有擁有的小卡。",
      emptyCollectedCaseDescription: "您可以搜索並交易未註冊的小卡！",
      emptyCollectedCaseButton: "註冊小卡",
    },
    addPhotoCard: {
      searchPlaceholder: "🔍 按名稱或專輯標題搜索",
      description: "只能添加一張小卡。",
    },
    chatRoom: {
      read: "已讀",
      copyMessageToClipboard: "消息已複製。",
      imageMenu: "照片",
      showTraderPhotoCards: "查看對方的交易小卡",
      placeHolder: "輸入消息",
      disabledPlaceHolder: "無法聊天",
      traderPhotoCardsBottomSheetTitle: "{{nickname}}的交易小卡",
      traderPhotoCardsBottomSheetEmptyCase: "沒有可交易的小卡。",
    },
    myCollectedPhotoCardsStat: {
      authorName: "{{nickname}}的小卡圖鑑",
      authoredAt: "剛剛",
      myCollectedPhotoCards: "我擁有的小卡",
      myCollectedPhotoCardsCount: "{{count}}",
      mostCollectedMember: "收集最多的成員",
      hideMenu: "從社群中隱藏",
      showAllButton: "查看全部",
    },
    myPage: {
      pageName: "我的",
      title: "我的",
      tradingsRow: "交易歷史",
      wishedRow: "我的願望清單",
      manageArtistsRow: "我的藝術家",
      manageMembersRow: "我的偏愛",
      managePocasRow: "我的小卡",
      manageAccount: "賬戶設置",
      settings: "設置",
      notice: "公告",
      report: "發送反饋",
    },
    manageArtistPage: {
      pageName: "我的藝術家",
      likedDays: "作為{{fandom}}粉絲{{likedDays}}天💖",
      dislikeArtist: "退出粉絲",
      likeArtist: "添加新粉絲",
      atLeastOneMoreArtistAlert: "你必須至少有一個藝術家。",
      pleaseCheckInvalidDateAlert: "請再檢查日期。",
      disLikeArtistDialogTitle: "謝謝你喜愛{{artist}}！",
      disLikeArtistDialogDescription: "你的信息已保存。隨時回來！",
      disLikeArtistDialogConfirm: "謝謝你喜愛{{artist}}！",
      disLikeArtistDialogCancel: "謝謝你喜愛{{artist}}！",
      changeStartLikedDateDialogTitle: "你想更改{{fandom}}的粉絲日期嗎？",
      changeStartLikedDateDialogConfirm: "更改",
      addLikedArtist: "添加",
    },
    manageMemberPage: { pageName: "我的偏愛" },
    myWishedPage: {
      pageName: "我的願望清單",
      wishedPhotoCardsTab: "小卡",
      wishedExchangesTab: "交易",
      wishedSalesTab: "出售",
      createPhotoCardBoard: "用願望清單創建小卡圖鑑",
      emptyWishedSalesTradingPhotoCardTitle: "尚無願望出售小卡！",
      emptyWishedSalesTradingPhotoCardDescription: "將你想購買的小卡添加到這裡",
    },
    manageAccountPage: {
      pageTitle: "賬戶設置",
      accountEmailTitle: "POCAPAN刪除賬戶",
      identityVerificationTitle: "獲取藍色徽章",
      identityVerificationDescription: "驗證你的身份以獲取藍色徽章！",
      identityVerificationDone: "已獲取",
      identityVerificationIncomplete: "未獲取",
      countryTitle: "訪問國家",
      countryDescription: "我們會幫你找到同一國家的粉絲",
      localeTitle: "服務語言",
      localeDescription: "設置服務語言",
      promotionTitle: "營銷通知",
      promotionAgreed: "啟用",
      promotionDisagreed: "禁用",
      initializePhotoCards: "重置小卡",
      deleteAccount: "刪除賬戶",
      logout: "登出",
      appVersion: "應用版本",
      identityVerificationDialogTitle: "用新號碼重新驗證？",
      identityVerificationDialogDescription:
        "{{phoneNumber}}\n{{certifiedAt}} 驗證完成",
      identityVerificationDialogYes: "是的，我的號碼已更改",
      identityVerificationDialogNo: "否，保持當前號碼",
    },
    emptyMatchedTradingPhotoCard: {
      title: "未找到匹配的小卡",
      description: "嘗試添加其他交易小卡！",
      reMatching: "再次搜索",
    },
    onGoingMatchingTradingPhotoCard: {
      title: "正在尋找需要您所選小卡的粉絲！",
      description: "一旦找到匹配，您將收到通知。",
    },
    changeCountry: {
      globalFeedTitle: "全球社群",
      globalCountyDescription: "{{country}}和國際粉絲帖子。",

      supportedCountyTitle: "{{country}}社群",
      supportedCountyDescription: "{{country}}粉絲互動的專屬空間！",

      unSupportedCountryTitle: "{{country}}社群 (即將推出)",
      unSupportedCountryDescription: "當更多{{country}}粉絲聚集時開放！",

      localeDrawerFirstRow: "請選擇 Pocapan 的語言",
      localeDrawerLastRow: "다른 언어는 계속 추가 됩니다!",

      countryDrawerFirstRow: "設置你的接入國家。\n訪問國家每週只能更改一次。",

      changingAlertTitle: "將接入國家更改為{{country}}嗎？",
      changingAlertDescription: "你可以在一週內再次更改。",

      notAllowedChangingAlertTitle: "無法更改接入國家。",
      notAllowedChangingAlertDescription:
        "你可以每週更改一次接入國家。如果需要緊急更改，請通過電子郵件聯繫我們。",
    },
    TradingUserProfilePage: {
      pageTitle: "交易資料",
      reviewsCount: "{{count}} 評價",
      reviewColorText: "接近<color>{{color}}</color>",
      mannerSignal: "禮貌燈號",
      saleTab: "正在出售的商品",
      exchangeTab: "正在交換的商品",
      saleEmptyCards: "沒有待售的小卡",
      exchangeEmptyCards: "沒有待交換的小卡",
      statTradingReviews: "評價",
      statLastLogin: "上次活躍",
      statResponseRate: "響應",
    },
    TradingUserProfileSettingsPage: {
      changeProfileImage: "更換個人圖片",
      pageTitle: "設置交易資料",
      add: "添加",
      nickname: "暱稱",
      changeNicknameDialogTitle: "編輯暱稱",
      placeHolderNickname: "請輸入暱稱。",
      description: "簡介",
      changeDescriptionDialogTitle: "編輯簡介",
      placeHolderDescription: "請輸入您的簡介。",
      manageTradingCards: "管理交易小卡",
      manageTradingHistories: "查看交易歷史",
      manageTradingReviews: "我的評價",
    },
    SelectTradingPhotoCardReviewPage: {
      pageTitle: "選擇要完成的小卡",
      title: "請選擇一個已完成貿易的小卡",
      skip: "還沒有小卡完成貿易",
    },
    SelectReviewChatRoomPage: {
      title: "請選擇與您進行貿易的人",
      dialogText: "小卡貿易已完成",
      skip: "我不想選擇",
    },
    ReviewIcon: {
      red: "不好",
      yellow: "好",
      green: "非常好",
    },
    ReviewColorText: {
      red: "紅色",
      yellow: "黃色",
      green: "綠色",
    },
    ReviewFeedbackOptions: {
      DetailedDescription: "🧸物品狀況與描述一致",
      IncludesBonus: "🎁 還附帶了額外物品",
      Friendly: "🫶 態度友好，舉止得體",
      QuickResponse: "⚡️ 回覆迅速",
      WannaTradeAgain: "🙌 一定會再次與他貿易",

      DifferentFromDescription: "😵‍💫 商品與描述不符",
      BadPackaging: "🕸️ 包裝狀況不佳",
      Unfriendly: "🧟‍♀️ 不友好，態度惡劣",
      SlowResponse: "🤐 他們看了我的聊天記錄後就不回覆了",
      UnfairCancellation: "🫥 單方面取消貿易",
    },
    AddTradingPhotoCardReviewPage: {
      title: "您與 {{nickname}} 的交易如何？",
      description: "請選擇一個方式標誌！對方看不到。",
      redReviewTitle: "你不喜歡什麼？",
      yellowReviewTitle: "好在哪裡？",
      greenReviewTitle: "最好的部分是什麼？",
      blacklistReviewRating: "我不想再和這個人打交道了。",
      reviewContentTitle: "您還有什麼想說的嗎？",
      reviewContentDescription: "留下評價，改善您自己的貿易之光！",
      reviewContentPlaceHolder: "輸入您想對對方說的話。",
      done: "已發送評價",
      doneDialogTitle: "感謝您撰寫評價。",
      doneDialogDescription: "多虧了您，Pocapan 將會更加和平！",
      notConfirmedTradingButton: "我以前從未與此人做過貿易。",
    },
    TradingPhotoCardReviewDetailPage: {
      sentTitle: "我向對方發送了評價",
      receivedTitle: "對方已收到您的評價！",
      confirm: "給對方留下評價",
      notConfirmedTradingButton: "我從未與此人做過貿易",
      rejctDialogTitle: "以前從未與{{nickname}}進行過貿易？",
    },
    TradingPhotoCardReviewsPage: {
      pageTitle: "評價列表",
      feedbackOptionsTitle: "大家說這個不錯！",
      reviewsTitle: "他人留下的評價",
      emptyReviews: "暫時還沒有評價",
    },
    TradingPhotoCardFilterBottomDrawer: {
      title: "篩選",
      identityVerifiedTitle: "본인 인증",
      identityVerifiedRadio: "본인 인증",
      categoryTitle: "小卡類型",
      eventTitle: "包含小卡的活動",
    },
    PhotoCardFilterBottomDrawer: {
      title: "篩選",
      categoryTitle: "小卡類型",
      eventTitle: "包含小卡的活動",
      initButton: "重置",
      defaultCategory: "普通卡",
      specialCategory: "未公開卡",
      mdCategory: "周邊卡",
      tradingCategory: "換卡",
      emptyCategory: "沒有符合所選類型的小卡。",
    },
    ExchangesTabPanel: {
      matchingSubTab: "可交換的小卡",
      chattingSubTab: "篩選",
      availablePoca: "匹配列表",
      chatRoomsBoxText: "有{{count}}個正在用這張小卡交易的聊天房間。",
      filter: "聊天列表",
    },
    SalesTabPanel: {
      purchaseSubTab: "購買小卡",
      saleSubTab: "我的出售小卡",
      filter: "篩選",
    },
    ConfirmNewSalesTradingPhotoCardsPage: {
      title: "請輸入銷售價格。",
      description: "如果未輸入價格，將顯示為“出價”。",
      removeAllDialog: "小卡已全部刪除。請重新選擇。",
      subTitleProduct: "要出售的小卡",
      subTitleDescription: "詳細內容",
      desciprtionPlaceholder: "在這裡輸入內容...",
      subTitleTradeMethod: "交易方式",
    },
    DoneTradingPhotoCardsPage: {
      exchangeTitle: "已註冊為交換小卡！",
      saleTitle: "已註冊為销售小卡！",
      description: "別錯過對方的消息！請開啟通知功能！",
    },
    TradingPhotoCardPageV2: {
      chatRoomCountCard: "聊天 {{count}}",
      exploreCard: "瀏覽",
      addCard: "添加",
      sellPhotoCardButton: "出售我的小卡",
      exchangePhotoCardButton: "交換我的小卡",
    },
    EventCategory: {
      album: { title: "💿專輯活動", description: "小卡隨專輯發行獲得" },
      offline: {
        title: "🎤官方線下活動",
        description: "演唱會／粉絲見面會／快閃活動小卡",
      },
      ADs: { title: "💄商業活動", description: "與藝人商業活動相關的小卡" },
      misc: {
        title: "🪪粉絲俱樂部 & 應援棒 & 季刊",
        description: "小卡來自粉絲俱樂部、應援棒、季刊",
      },
      content: {
        title: "📺媒體內容",
        description: "與內容、寫真書、雜誌等媒體相關的小卡",
      },
    },
    ReportPhotoCardActivitiesPage: {
      pageName: "新增缺失的小卡",
      title: "此小卡來自於哪個活動？",
      unknownActivity: "不確定",
    },
    ReportPhotoCardCandidatesPage: {
      pageName: "新增缺失的小卡",
      title: "下面的小卡有相同的嗎？",
      emptyTag: "無標籤",
      addManullay: "新增您的小卡",
    },
    PhotoCardCategory: {
      album: {
        default: {
          name: "💿 專輯小卡",
          description: "專輯內附的基礎小卡",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
      },
      offline: {
        default: {
          name: "💌 標準小卡",
          description: "正式線下活動獲得的小卡",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
        trading: {
          name: "🤝 交換小卡",
          description: "專門用於交換的小卡",
        },
      },
      ADs: {
        default: {
          name: "💌 標準小卡",
          description: "包含的基礎小卡。",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
      },
      content: {
        default: {
          name: "💌 標準小卡",
          description: "包含的基礎小卡。",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
      },
      misc: {
        default: {
          name: "💌 標準小卡",
          description: "包含的基礎小卡。",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
      },
      etc: {
        default: {
          name: "💌 標準小卡",
          description: "包含的基礎小卡。",
        },
        special: {
          name: "🍀 特典小卡",
          description: "來自購買商店或特殊地點的小卡",
        },
        md: {
          name: "🧸 MD小卡",
          description: "購買商品時隨附的小卡",
        },
        trading: {
          name: "🤝 交換小卡",
          description: "專門用於交換的小卡",
        },
      },
    },
    ReportPhotoCardCategoriesPage: {
      pageName: "新增缺失的小卡",
      title: "小卡類型是什麼？",
    },
    ReportPhotoCardConfirmPage: {
      pageName: "新增缺失的小卡",
      title: "小卡類型是什麼？",
      confirmedTitle: "新增小卡！",
      confirmedDescription: "小卡已新增，感謝 {{nickname}} 的協助！",
      completedTitle: "報告成功",
      completedDescription: "您將被登記為此小卡的首捕者🧚",
      tagTitle: "添加版本、商店、暱稱等資訊，讓其他粉絲更容易認出您！",
      tagDescription: "若有超過5個標籤，僅顯示使用最多的標籤。",
      tagInputPlaceholder: "例如：珠寶版或熱狗小卡",
      addTag: "新增 +",
    },
    ReportPhotoCardMiscActivitiesPage: {
      membershipName: "粉絲俱樂部/會員",
      seasonsName: "季刊",
      lightstickName: "應援棒",
      title: "此小卡來自於哪個活動？",
      unknownEvent: "不確定",
    },
    ReportPhotoCardEventsPage: {
      albumTitle: "這是哪張專輯？",
      contentTitle: "這是什麼內容？",
      offlineTitle: "這是哪個官方線下活動？",
      adsTitle: "這是哪個商業活動？",
      defaultTitle: "小卡何時發行？",
      debutedDescription:
        "{{artistName}} 的出道年份是 <bold>{{debutedYear}}</bold>。",
      addManually: "新增您的 +",
      eventDialogTitle: "請輸入專輯名稱。",
      eventDialogInputPlaceholder: "專輯、活動名稱...",
      albumDescription: "{{description}} #{{volume}}",
    },
    ReportPhotoCardSelectArtistPage: {
      title: "請選擇您要回報的藝人。",
    },
    ReportPhotoCardSelectMemberPage: {
      title: "這是哪位成員的照片卡？",
    },
    ReportPhotoCardUploadPhotoPage: {
      title: "上傳您的小卡！",
      description: "隨著更多粉絲上傳小卡，您將可以透過照片搜尋它們！",
      disclaimerRowLine1: "請移除卡套或貼紙。",
      disclaimerRowLine2: "拍攝照片時避免光線外溢。",
      disclaimerRowLine3: "粉絲將自動審核並新增！",
      addPhotoCardButton: "打開相機 / 相冊",
    },
    ShowTradingProfileBottomDrawer: {
      title: "想更快賣出照片卡？",
      description: "試試公開交易資料吧！",
      openButton: "立即公開",
      skipButton: "下次再說",
    },
    ReportPhotoCardBox: {
      title: "如果找不到你擁有的 {{memberName}} 的照片卡",
      description: "回報時可以獲得稱號🏆️",
      reportButton: "新增您的小卡",
    },
    UserProfileSearchPage: {
      pageName: "搜尋好友",
      searchPlaceHolder: "可以使用暱稱搜尋",
      mutualBias: "重疊的最愛： {{members}}",
      mutualBiasMore: "重疊的最愛： {{members}}和{{count}}人",
    },
    LikedUserProfilesPage: {
      pageName: "喜歡",
    },
    FollowersPage: {
      pageName: "粉絲",
    },
    FollowingsPage: {
      pageName: "關注中",
    },
    MutualMembersBottomDrawer: {
      title: "共同喜歡的藝人",
    },
    TradeMethod: {
      any: "無所謂",
      direct: "當面交易",
      standardDelivery: "快遞交易",
      slowDelivery: "편의점 택배",
    },
    TradingPhotoCardDetailPage: {
      sellerTitle: "賣家信息",
      bumpedAt: "提升 {{bumpedAt}}",
      edit: "修改",
      delete: "結束交易",
      bump: "重新提升",
      toastBumped: "已提升銷售貼。",
      toastSoldout: "交易已結束。",
      buttonSoldout: "此交易已結束。",
      reportTrading: "舉報此交易",
    },
    EditGroupSalesTradingPhotoCardsPage: {
      title: "修改出售商品",
      subTitleEditPrice: "修改價格",
    },
    TradingPhotoCardsByPhotoCardBottomDrawer: {
      title: "請選擇交易對象",
      averagePrice: "平均價",
      purchase: "購買매",
    },
    PendingPhotoCardsSection: {
      title: "等待審核的小卡",
      description: "未審核的小卡也可以隨時轉讓。",
      viewAll: "查看全部",
    },
    ReportedPhotoCardsPage: {
      title: "我舉報的小卡",
      disclaimerRowLine1: "已審核的小卡將顯示在小卡列表中。",
      disclaimerRowLine2: "如果其他粉絲舉報相同的小卡，將自動審核通過。",
      disclaimerRowLine3: "未審核的小卡也可以隨時轉讓。",
      disclaimerRowLine4: "小卡模糊或重複將不被批准。",
      pending: "审核中",
      approved: "已通过审核",
      rejected: "审核被拒绝",
    },
    ExchangeablePhotoCardBottomDrawer: {
      title: "對方的小卡",
      subtitle: "選擇我要交換的小卡",
      description: "只顯示我有而對方沒有的小卡！",
      disclaimer: "選定的小卡將自動註冊為交換小卡。",
    },
    ExchangeMatchedTradingPhotoCardBottomDrawer: {
      title: "交換",
      sendButton: "透過消息發送交換請求",
      disclaimer: "選定的小卡將自動註冊為交換小卡。",
    },
    MyHeartBoxBottomSheet: {
      title: "POCAPAN 全新改名！",
      description:
        "每個追星時刻都能累積愛心。\n更開心的追星生活，就從 FanHearts 開始！",
      earningTitle: "愛心這樣累積",
      earningDescription:
        "購買專輯、回報小卡、參與社群活動，\n每個追星行動都能獲得 FanHearts 獨有的愛心！",
      usageTitle: "愛心這樣使用",
      usageDescription:
        "參加活動、交換 Live 小卡、交換專輯等，\n全新功能即將推出！",
      closeButton: "關閉",
    },
  },
};
