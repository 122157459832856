import React, { useEffect, useState } from "react";

import {
  Center,
  Flex,
  Image,
  Input,
  Spacer,
  Text,
  Wrap,
  WrapItem,
  Box,
} from "@chakra-ui/react";
import { first, isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";
import FileResizer from "react-image-file-resizer";

import Page from "../../components/Page";
import addUserProfileV1 from "../../components/api/mutations/addUserProfileV1";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { usePreventScroll } from "../../utils/UIUtils";
import { t } from "i18next";

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  text-align: center;
  margin-top: 30px;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  white-space: pre-wrap;
  margin-top: 14px;
`;

const Columns = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Card = styled.div`
  padding: 26px 24px;
  margin-top: 18px;
  border-radius: 24px;
  background: linear-gradient(
    91.59deg,
    rgba(255, 255, 255, 0.5) 31.85%,
    rgba(255, 255, 255, 0.1) 102.92%
  );
  border: 1px solid;
  border-image-source: linear-gradient(
    134.41deg,
    rgba(255, 255, 255, 0.6) 5.69%,
    rgba(255, 255, 255, 0) 95.22%
  );
  box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.3) inset,
    0px 4px 10px 0px rgba(0, 0, 0, 0.04);
`;

const CameraIconWrapper = styled.div`
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  color: white;
  padding: 4px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 92px;
  padding: 18px;
  padding-bottom: 10px;
`;

export default function CreateFeedUserProfilePage() {
  const navigate = useNavigate();
  const [nickname, setNickname] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [imageUrl, setImageUrl] = useState();

  usePreventScroll();

  const { openFilePicker, plainFiles, filesContent } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        750,
        1328,
        "PNG",
        90,
        0,
        (uri) => {
          setImageUrl(uri);
        },
        "base64"
      );
    });

  useEffect(() => {
    const resizeAndSetDataUrls = async () => {
      if (isEmpty(plainFiles)) return;

      try {
        resizeFile(first(plainFiles));
      } catch (error) {
        console.error("Error resizing files:", error);
        setImageUrl(first(filesContent));
      }
    };
    resizeAndSetDataUrls();
  }, [plainFiles, filesContent]);

  const onConfirm = async () => {
    if (isEmpty(nickname)) return;
    const response = await addUserProfileV1("base", nickname, imageUrl);
    if (response.ok) {
      navigate(-1);
    }
  };

  return (
    <Box position="relative" height="100vh" overflow="hidden">
      <Box
        className="bg-background-light dark:bg-background-dark"
        position="absolute"
        overflow="hidden"
        zIndex={1}
        height={"100vh"}
        minW={"calc(min(100vw, var(--chakra-sizes-prose)))"}
      />
      <Box position="absolute" bottom="0" overflow="hidden" zIndex={2}>
        <Image src="/assets/backgrounds/_bg_notice_btm.png" objectFit="cover" />
      </Box>
      <Box
        position="absolute"
        height="100vh"
        overflow="hidden"
        zIndex={3}
        minW={"calc(min(100vw, var(--chakra-sizes-prose)))"}
        maxW={"xl"}
      >
        <Page
          className="bg-transparent"
          title={t("createFeedUserProfilePage.pageTitle")}
          showBackButton={true}
          leftElement={<></>}
          noGutter={true}
          noPadding={true}
          navigationElement={
            <CloseIcon
              className="fill-black dark:fill-white"
              width={"24px"}
              height={"24px"}
              onClick={() => navigate(-1)}
            />
          }
          minW={"calc(min(100vw, var(--chakra-sizes-prose)))"}
          maxW={"xl"}
        >
          {
            <Columns>
              <Image
                src="/assets/icons/icon_lock.png"
                width={"50px"}
                style={{ marginTop: "12vh" }}
              />
              <Title className="text-main-light-2 dark:text-main-dark-2">
                {t("createFeedUserProfilePage.title")}
              </Title>
              <Description className="text-main-light-4 dark:text-main-dark-4">
                {t("createFeedUserProfilePage.description")}
              </Description>
              <Card>
                <Columns>
                  <Wrap onClick={openFilePicker}>
                    <WrapItem className="relative">
                      <Center
                        className="bg-theirty-light dark:bg-theirty-dark"
                        width={"100px"}
                        height={"100px"}
                        borderRadius={"50%"}
                      >
                        {!isEmpty(imageUrl) ? (
                          <Image
                            width={"100px"}
                            height={"100px"}
                            objectFit={"cover"}
                            borderRadius={"full"}
                            src={imageUrl}
                          />
                        ) : (
                          <ProfileFillIcon
                            className="fill-white dark:fill-black"
                            width={"66px"}
                            height={"66px"}
                          />
                        )}
                        <CameraIconWrapper className="bg-primary-light dark:bg-primary-dark border-[2px] border-white dark:border-black">
                          <CameraIcon
                            className="fill-white dark:fill-black"
                            width={"24px"}
                            height={"24px"}
                          />
                        </CameraIconWrapper>
                      </Center>
                    </WrapItem>
                  </Wrap>
                  <div style={{ marginTop: "12px" }}>
                    <Input
                      className="bg-secondary-light dark:bg-secondary-dark hover:bg-secondary-light dark:hover:bg-secondary-dark"
                      variant="filled"
                      size={"lg"}
                      maxLength={20}
                      _focusVisible={true}
                      placeholder={t(
                        "createFeedUserProfilePage.nickNamePlaceHolder"
                      )}
                      value={nickname}
                      onChange={(e) => setNickname(e.target.value)}
                    />
                    <Flex>
                      <div>
                        {errorMessage && (
                          <Text className="text-warning" fontSize={"13px"}>
                            {errorMessage}
                          </Text>
                        )}
                      </div>
                      <Spacer />
                      <div>
                        {nickname && (
                          <Text value={nickname} fontSize={"13px"}>
                            {nickname.length}/20
                          </Text>
                        )}
                      </div>
                    </Flex>
                  </div>
                </Columns>
              </Card>
              <ButtonWrapper>
                <PrimaryButton
                  text={t("confirm")}
                  disabled={isEmpty(nickname)}
                  width={"100%"}
                  paddingY={"16px"}
                  onClick={() => !isEmpty(nickname) && onConfirm()}
                />
              </ButtonWrapper>
            </Columns>
          }
        </Page>
      </Box>
    </Box>
  );
}
