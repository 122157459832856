import React from "react";

import { t } from "i18next";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightSmall } from "../../icons/arrow.right.small.svg";
import { useQueryParams } from "../../utils/useQueryParams";

const ACTIVITIES = [
  {
    key: "membership",
    title: t("ReportPhotoCardMiscActivitiesPage.membershipName"),
  },
  { key: "seasons", title: t("ReportPhotoCardMiscActivitiesPage.seasonsName") },
  {
    key: "lightstick",
    title: t("ReportPhotoCardMiscActivitiesPage.lightstickName"),
  },
];

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: calc(54px + env(safe-area-inset-top)) 24px 10px 24px;
`;

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`;

const ActivityBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
`;

const ActivityTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`;

const IconBox = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  border-radius: 9999px;
`;

const IconImage = styled.img`
  width: 32px;
  height: 32px;
`;

const UnknownActivity = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 15px;
  font-weight: 600;
`;

const UnknownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  place-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
`;

export default function ReportPhotoCardMiscActivitiesPage() {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();
  const handleSelectActivity = (activity) => {
    if (activity === "misc") {
      // updateQueryParams({ activity }, '/report-photo-card/misc-activities');
    } else {
      updateQueryParams({ activity }, "/report-photo-card/events");
    }
  };

  return (
    <>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={true}
        showTitle={true}
        onClickBackButton={() => navigate(-1)}
      />
      <Title>{t("ReportPhotoCardMiscActivitiesPage.title")}</Title>
      <ActivityBoxWrapper>
        {ACTIVITIES.map((activity) => {
          return (
            <ActivityBox
              className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
              key={activity.key}
              onClick={() => handleSelectActivity(activity.key)}
            >
              <IconBox className="bg-surface-light dark:bg-surface-dark">
                <IconImage
                  src={`/assets/icons/report/ic_event_${activity.key}.png`}
                />
              </IconBox>
              <ActivityTitle className="text-pure-black dark:text-pure-white">
                {activity.title}
              </ActivityTitle>
            </ActivityBox>
          );
        })}
      </ActivityBoxWrapper>
      {/* <UnknownWrapper onClick={() => handleSelectActivity("etc")}>
        <UnknownActivity>
          {t("ReportPhotoCardMiscActivitiesPage.unknownEvent")}
        </UnknownActivity>
        <ArrowRightSmall width={"20px"} height={"20px"} fill={LightColors.textmainlv2}/>
      </UnknownWrapper> */}
    </>
  );
}
