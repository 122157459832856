import React from "react";

import { t } from "i18next";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import updateTradingPhotoCards from "../../components/api/mutations/updateTradingPhotoCards";
import { fetchRecentChatRoomQuery } from "../../components/api/queries/useChatRoomsQuery";
import { useTradingPhotoCardsAll } from "../../components/api/queries/useTradingPhotoCards";
import EmptyCase from "../../components/errors/EmptyCase";
import Page from "../../components/Page";
import TradingPhotoCardBoxByPhotoCard from "../../components/photocard/TradingPhotoCardBoxByPhotoCard";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightSmall } from "../../icons/arrow.right.small.svg";
import { ReactComponent as XMarkIcon } from "../../icons/xmark.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  white-space: pre-wrap;
  padding: 40px 10px 24px 24px;
`;

const TradingPhotoCardsWrapper = styled.div`
  padding: 0px 24px;
`;

const TradingPhotoCardV1Wrapper = styled.div`
  padding: 10px 10px 16px 16px;
  border-radius: 12px;
  margin-bottom: 16px;
`;

const BottomSkipWrapper = styled.div`
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`;

const SkipText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  white-space: pre-wrap;
`;

export default function SelectTradingPhotoCardReviewPage() {
  const navigate = useNavigate();
  const handleHistoryBack = useHandleHistoryBack();
  const tradingPhotoCards = useTradingPhotoCardsAll({ category: null });

  const onSelectedTradingPhotoCard = async (tradingPhotoCard) => {
    if (isEmpty(tradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: tradingPhotoCard.price,
      status: "sold_out",
    });

    if (response.ok) {
      if (tradingPhotoCard.chatRoomsCount > 1) {
        navigate(
          `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/select_chat_rooms?isShowCompletedDialog=true`
        );
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const recentChatRoom = await fetchRecentChatRoomQuery({
          tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
        });
        navigate(
          `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}/add_review?chatRoomId=${recentChatRoom.chatRoomId}&traderUserProfileId=${recentChatRoom.traderProfile.userProfileId}&isAddSkip=true`
        );
      }
    }
  };

  return (
    <Page
      title={t("SelectTradingPhotoCardReviewPage.pageTitle")}
      leftElement={
        <XMarkIcon className="fill-pure-black dark:fill-pure-white" />
      }
      onHistoryBackClick={handleHistoryBack}
      noPadding={true}
    >
      <Title className="text-main-light-1 dark:text-main-dark-1">
        {t("SelectTradingPhotoCardReviewPage.title")}
      </Title>

      <TradingPhotoCardsWrapper>
        {isEmpty(tradingPhotoCards) ? (
          <EmptyCase />
        ) : (
          tradingPhotoCards
            .filter((tradingPhotoCard) => tradingPhotoCard.chatRoomsCount > 0)
            .map((tradingPhotoCard) => {
              const convertedPhotoCard = {
                ...tradingPhotoCard.photoCard,
                price: tradingPhotoCard.price,
                chatRoomsCount: tradingPhotoCard.chatRoomsCount,
              };
              return (
                <TradingPhotoCardV1Wrapper className="border-[1px] border-separator-light-1 dark:border-separator-dark-1">
                  <TradingPhotoCardBoxByPhotoCard
                    photoCard={convertedPhotoCard}
                    showChatRoomsCount={true}
                    showPrice={true}
                    onClickBox={() =>
                      onSelectedTradingPhotoCard(tradingPhotoCard)
                    }
                  />
                </TradingPhotoCardV1Wrapper>
              );
            })
        )}
        <BottomSkipWrapper onClick={handleHistoryBack}>
          <Row style={{ alignItems: "center" }}>
            <SkipText className="text-main-light-3 dark:text-main-dark-3">
              {t("SelectTradingPhotoCardReviewPage.skip")}
            </SkipText>
            <ArrowRightSmall
              className="fill-main-light-3 dark:fill-main-dark-3"
              width={"20px"}
              height={"20px"}
            />
          </Row>
        </BottomSkipWrapper>
      </TradingPhotoCardsWrapper>
    </Page>
  );
}
