import { Box } from "@chakra-ui/react";

export default function WhiteBadge({ text, ...props }) {
  return (
    <Box
      bgColor={"white"}
      paddingX={"10px"}
      paddingY={"6px"}
      className="text-center !bg-surface-light dark:!bg-surface-dark text-main-light-2 dark:text-main-dark-2
      border-[1px] border-separator-light-2 dark:border-separator-dark-2"
      borderRadius={"4px"}
      fontWeight={500}
      fontSize={"12px"}
      lineHeight={"14px"}
      {...props}
    >
      {text}
    </Box>
  );
}
