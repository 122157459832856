import React from "react";
import styled from "styled-components";

import { Image } from "@chakra-ui/react";
import { ReactComponent as ProfileFillIconSvg } from "../../icons/profile.fill.svg";
import { ReactComponent as RefreshFillIconSvg } from "../../icons/refresh.fill.svg";

const AvatarWrapper = styled.div`
  border-radius: 50%;
  padding: 5px;
`;

const ImageWrapper = styled.div`
  border-radius: 32px;
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)";
`;

export default function AuthorToggleAvatar({
  isAnonymous,
  profileImageUrl,
  widthAndHeight = 24,
  svgWidthAndHeight = 24,
  ...props
}) {
  const RefreshIcon = () => {
    return (
      <div
        className="absolute border-[1px] bg-pure-white dark:bg-pure-black border-pure-white dark:border-pure-black"
        style={{
          zIndex: 1,
          right: "-1px",
          bottom: "-1px",
          width: "14px",
          height: "14px",
          borderRadius: "50%",
        }}
      >
        <div
          className="bg-primary-light dark:bg-primary-dark"
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
          }}
        >
          <RefreshFillIconSvg
            width={"12px"}
            height={"12px"}
            className="fill-primary-light dark:fill-primary-dark"
          />
        </div>
      </div>
    );
  };

  return isAnonymous ? (
    <ImageWrapper
      className="border-b-[1px] border-background-light dark:border-background-dark"
      style={{
        width: `${widthAndHeight}px`,
        height: `${widthAndHeight}px`,
        position: "relative",
      }}
    >
      <Image
        objectFit={"cover"}
        src={"/assets/icons/thumbnail.ghost.profile.png"}
        style={{ width: `${widthAndHeight}px` }}
      />
      {RefreshIcon()}
    </ImageWrapper>
  ) : profileImageUrl ? (
    <ImageWrapper
      className="border-b-[1px] border-background-light dark:border-background-dark"
      style={{
        width: `${widthAndHeight}px`,
        height: `${widthAndHeight}px`,
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${widthAndHeight - 2}px`,
          height: `${widthAndHeight - 2}px`,
          overflow: "hidden",
        }}
      >
        <Image
          borderRadius={"full"}
          objectFit={"cover"}
          src={profileImageUrl}
          style={{
            width: `${widthAndHeight - 2}px`,
            height: `${widthAndHeight - 2}px`,
          }}
        />
        {RefreshIcon()}
      </div>
    </ImageWrapper>
  ) : (
    <AvatarWrapper
      className="bg-theirty-light dark:bg-theirty-dark"
      style={{
        width: `${widthAndHeight}px`,
        height: `${widthAndHeight}px`,
        position: "relative",
        ...props,
      }}
    >
      <ProfileFillIconSvg
        className="fill-white"
        width={svgWidthAndHeight}
        height={svgWidthAndHeight}
      />
      {RefreshIcon()}
    </AvatarWrapper>
  );
}
