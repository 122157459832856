import React, { useEffect, useState } from "react";

import { Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce, isEmpty, isNull } from "lodash";

import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";
import AddMatchedTradingPhotoCard from "../api/mutations/AddMatchedTradingPhotoCard";
import ExchangeablePhotoCardBottomDrawer from "../drawer/ExchangeablePhotoCardBottomDrawer";
import ExchangeMatchedTradingPhotoCardBottomDrawer from "../drawer/ExchangeMatchedTradingPhotoCardBottomDrawer";
import VerifiedIcon from "../icons/VerifiedIcon";

const imageBoxStyle = {
  width: "100%",
  height: "96%",
  padding: "12px 0px",
  borderRadius: "20px",
};

const imageStyle = {
  width: "48px",
  height: "75px",
  borderRadius: "8px",
  objectFit: "cover",
};

const profileStyle = {
  paddingLeft: "2px",
  fontWeight: 500,
  lineHeight: "16px",
  fontSize: "13px",
};

const priceStyle = {
  fontSize: "15px",
  fontWeight: 600,
  lineHeight: "18px",
  marginBottom: "6px",
};

const requestedAtStyle = {
  fontSize: "11px",
  lineHeight: "13.2px",
  fontStyle: "normal",
  fontWeight: "400",
  whiteSpace: "pre",
};

const responseRateText = {
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
};

export default function ExploreTradingPhotoCard({tradingPhotoCard, hiddenNickname=false, hiddenChatStat=false, showEventName=false, onClickTradingPhotoCard, onShowBottomSheet}) {
  const [matchedTradingPhotoCard, setMatchedTradingPhotoCard] = useState()
  const [isShowExploreTradingPhotoCardBottomSheet, setIsShowExploreTradingPhotoCardBottomSheet] = useState(false)
  const [isShowMatchedTradingPhotoCardBottomSheet, setIsShowMatchedTradingPhotoCardBottomSheet] = useState(false)

  useEffect(() => {
    const isShowBottomSheet = (isShowExploreTradingPhotoCardBottomSheet || isShowMatchedTradingPhotoCardBottomSheet)
    if (onShowBottomSheet) {
      onShowBottomSheet(isShowBottomSheet)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowExploreTradingPhotoCardBottomSheet, isShowMatchedTradingPhotoCardBottomSheet]);


  const showBottomSheet = () => {
    if (onClickTradingPhotoCard) {
      onClickTradingPhotoCard(tradingPhotoCard);
    } else {
      if (tradingPhotoCard.category === "sale") {
        openNewTab(
          `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}?photoCardId=${tradingPhotoCard.photoCardId}`
        );
      } else {
        setIsShowExploreTradingPhotoCardBottomSheet(true);
      }
    }
  };

  const debouncedShowBottomSheet = debounce(showBottomSheet, 300);

  const navigateUserTradingProfile = (userProfile) => {
    const currentPath = window.location.pathname;
    const userProfilePath = `/trading/user_profile/${userProfile?.userProfileId}`;
    if (currentPath === userProfilePath) return;

    if (!isEmpty(userProfile?.userProfileId)) {
      openNewTab(userProfilePath);
    }
  }

  const onConfirmedPhotoCard = (photoCard) => {
    AddMatchedTradingPhotoCard({
      photoCardId: photoCard.photoCardId,
      targetId: tradingPhotoCard.tradingPhotoCardId,
    }).then((response) => {
      if (response.ok) {
        response.json().then(responseBody => {
          setMatchedTradingPhotoCard(responseBody.matchedTradingPhotoCard);
          setIsShowExploreTradingPhotoCardBottomSheet(false);
          setIsShowMatchedTradingPhotoCardBottomSheet(true);
        });
      }
    })
  }

  return (tradingPhotoCard &&
    <>
      <VStack
        spacing={0}
        align={"flex-start"}
        minHeight={"140px"}
        onClick={debouncedShowBottomSheet}
        key={tradingPhotoCard.tradingPhotoCardId}
      >
        <div className="relative w-full">
          {/* {
            tradingPhotoCard.category === "sale" && <div className="absolute top-0 left-0" style={{paddingLeft: "7px", paddingTop: "6px"}}>
              <RoundedBadge text={t("tradingPhotoCardPage.saleBadge")} bgColor={"white"} textColor={"primary"}/>
            </div>
          } */}
            <Flex
              className="bg-surface-light dark:bg-surface-dark"
              style={imageBoxStyle}
              justifyContent={"center"}
            >
              <Image
                style={imageStyle}
                src={
                  tradingPhotoCard.photoCard.smallThumbnailUrl ||
                  tradingPhotoCard.photoCard.pictureUrl
                }
              />
            </Flex>
          </div>
          {showEventName && (
            <div style={{ paddingTop: "6px" }}>
              <Text
                noOfLines={1}
                className="text-main-light-1 dark:text-main-dark-1"
                style={profileStyle}
              >
                {ellipsis(tradingPhotoCard.photoCard.eventName)}
              </Text>
            </div>
          )}
          {hiddenNickname ? (
            <div style={{ paddingTop: "4px" }}></div>
          ) : (
            <Flex
              direction={"row"}
              alignItems={"center"}
              paddingTop={"6px"}
              paddingBottom={"6px"}
              onClick={() =>
                navigateUserTradingProfile(tradingPhotoCard?.userProfile)
              }
            >
              {tradingPhotoCard?.userProfile?.identified ? (
                <VerifiedIcon width={"14px"} height={"14px"} />
              ) : (
                <ProfileFillIcon
                  className="fill-pure-black dark:fill-pure-white"
                  width={"14px"}
                  height={"14px"}
                />
              )}
              <Text
                className="text-main-light-1 dark:text-main-dark-1"
                noOfLines={1}
                style={profileStyle}
              >
                {ellipsis(tradingPhotoCard.userProfile.nickname)}
              </Text>
            </Flex>
          )}
          {tradingPhotoCard.category === "sale" && (
            <Text
              className="text-textPrimary-light dark:text-textPrimary-dark"
              style={priceStyle}
            >
              {isNull(tradingPhotoCard.price)
                ? t("tradingPhotoCardPage.offerPriceWithEmoji")
                : tradingPhotoCard.price}
            </Text>
          )}
          <HStack spacing={0}>
            <Text
              className="text-main-light-4 dark:text-main-dark-4"
              style={requestedAtStyle}
            >
              {tradingPhotoCard.requestedAt}
            </Text>
            {!hiddenChatStat &&
              !isEmpty(tradingPhotoCard.userProfile?.responseText) && (
                <>
                  <Text style={requestedAtStyle} px={"3px"}>{`•`}</Text>
                  <Text
                    className="text-primary-light dark:text-primary-dark"
                    style={responseRateText}
                  >{`${tradingPhotoCard.userProfile?.responseText}`}</Text>
                </>
              )}
          </HStack>
        </VStack>
        {
        isShowExploreTradingPhotoCardBottomSheet &&
        <ExchangeablePhotoCardBottomDrawer
          isOpen={isShowExploreTradingPhotoCardBottomSheet}
          onClose={() => setIsShowExploreTradingPhotoCardBottomSheet(false)}
          tradingPhotoCard={tradingPhotoCard}
          onConfirmedPhotoCard={onConfirmedPhotoCard}
        />
      }
      {
        isShowMatchedTradingPhotoCardBottomSheet && matchedTradingPhotoCard &&
        <>
          <ExchangeMatchedTradingPhotoCardBottomDrawer
            isOpen={isShowMatchedTradingPhotoCardBottomSheet}
            onClose={() => setIsShowMatchedTradingPhotoCardBottomSheet(false)}
            matchedTradingPhotoCard={matchedTradingPhotoCard}
          />
        </>
      }
    </>
  )
}
