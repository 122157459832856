import { Divider, Image } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import styled from "styled-components";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { intlConfig } from "../../utils/CurrencyUtil";
import { TagsList } from "../photocard/TagsList";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 12px;
`;

const PhotoCardName = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const EventName = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-top: 8px !important;
`;

const closeIconStyle = {
  width: "28px",
  height: "28px",
  borderRadius: "32px",
  padding: "6px",
  marginBottom: "16px",
};

// Start of Selection
const inputStyle = {
  width: "100%",
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "32px",
  textAlign: "right",
  borderWidth: 0,
  borderColor: "transparent",
  outline: "none",
  "&:focus": {
    borderColor: "transparent",
  },
  "&:placeholder": {
    textAlign: "left",
    fontSize: "17px",
    lineHeight: "32px",
    fontWeight: 500,
  },
};

export default function NewSaleTradingPhotoCard({
  user,
  tradingPhotoCard,
  onChangedItem,
  onDeletedItem,
  editMode = false,
  confirmed = false,
  hideToggle = false,
}) {
  const [price, setPrice] = useState(
    tradingPhotoCard.priceNumber || tradingPhotoCard.price
  );
  const [checked, setChecked] = useState(tradingPhotoCard?.category === "sale");

  useEffect(() => {
    setChecked(tradingPhotoCard?.category === "sale");
  }, [tradingPhotoCard]);

  const updatedPrice = (value) => {
    if (user?.country?.toUpperCase() === "KR") {
      if (Math.floor(value) <= 0) {
        value = null;
      } else if (Math.floor(value) <= 100) {
        value = Math.floor(value) * 100;
      }
    }
    setPrice(value);
    updateTradingPhotoCard(checked, value);
  };

  const updateTradingPhotoCard = (checked, price) => {
    onChangedItem({
      ...tradingPhotoCard,
      price: price,
      priceNumber: price,
      category: checked ? "sale" : "exchange",
    });
  };

  return (
    tradingPhotoCard && (
      <>
        <Box
          key={tradingPhotoCard.tradingPhotoCardId}
          className="border border-separator-light-1 dark:border-separator-dark-1"
        >
          <div className="flex flex-row gap-0">
            <Image
              width={"48px"}
              height={"75px"}
              borderRadius={"8px"}
              aspectRatio={"55/87"}
              src={tradingPhotoCard.photoCard.pictureUrl}
              objectFit={"cover"}
              loading={"lazy"}
            />
            <div className="flex flex-col flex-grow pl-[0.875rem] self-center">
              <PhotoCardName className="text-main-light-2 dark:text-main-dark-2">
                {t("photoCardDetailPage.title", {
                  memberName: tradingPhotoCard.photoCard.memberName,
                  categoryName: tradingPhotoCard.photoCard.categoryName,
                  categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji,
                })}
              </PhotoCardName>
              <EventName className="text-main-light-3 dark:text-main-dark-3">
                {tradingPhotoCard.photoCard.eventName}
              </EventName>
              {!isEmpty(tradingPhotoCard.photoCard.tags) &&
                !isEmpty(tradingPhotoCard.photoCard.distributors) && (
                  <TagsWrapper>
                    <TagsList photoCard={tradingPhotoCard.photoCard} />
                  </TagsWrapper>
                )}
            </div>
            {!editMode && (
              <div className="flex-grow flex justify-end -m-1.5">
                <CloseIcon
                  className="fill-primary-light dark:fill-primary-dark bg-secondary-light dark:bg-secondary-dark"
                  style={closeIconStyle}
                  onClick={() => onDeletedItem(tradingPhotoCard)}
                />
              </div>
            )}
          </div>
          <Divider
            paddingTop={"14px"}
            paddingBottom={"12px"}
            width={"100%"}
            zIndex={999}
          />
          <CurrencyInput
            className="bg-background-light dark:bg-background-dark text-main-light-1 dark:text-main-dark-1 placeholder-primary"
            style={inputStyle}
            disabled={confirmed}
            intlConfig={intlConfig(user)}
            allowNegativeValue={false}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            value={price}
            thousandSeparator={true}
            onValueChange={(e) => updatedPrice(e)}
          />
        </Box>
      </>
    )
  );
}
