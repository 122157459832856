import React, { useEffect, useState } from "react";

import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import Page from "../../components/Page";
import useRepostsQuery from "../../components/api/queries/useRepostsQuery";
import PostsList from "../../components/community/PostsList";
import { t } from "i18next";

export default function RepostsListPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [paginatedPosts, setPaginatedPosts] = useState();

  const { data, isFetching, fetchNextPage, hasNextPage } = useRepostsQuery({
    postId: params.post_id,
    onlyQuotes: true,
  });

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedPosts(posts);
  };
  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPosts(data);
      // setMeta(first(data.pages)?.meta);
    }
  }, [data]);

  const back = () => {
    navigate(-1);
  };

  return (
    <Page
      showBackButton={true}
      title={t("megaphone")}
      showTitle={true}
      onHistoryBackClick={() => back()}
      noPadding={true}
      style={{ paddingTop: "8px" }}
    >
      {paginatedPosts && (
        <PostsList posts={paginatedPosts} loadMore={loadMore} />
      )}
    </Page>
  );
}
