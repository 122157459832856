import React from "react";

import { Text, VStack } from "@chakra-ui/react";

import { t } from "i18next";

const emojiStyle = {
  fontSize: "50px",
};

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
  wordBreak: "keep-all",
  lineHeight: "22px",
};

const descriptionStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
};

export default function EmptyExploreTradingPhotoCard() {
  return (
    <VStack marginTop={"48px"} alignContent={"center"} marginBottom={"96px"}>
      <Text style={emojiStyle}>🙄</Text>
      <Text
        className="text-main-light-2 dark:text-main-dark-2"
        style={titleStyle}
      >
        {t("emptyExploreTradingPhotoCard.title")}
      </Text>
      <Text
        className="text-main-light-4 dark:text-main-dark-4"
        style={descriptionStyle}
      >
        {t("emptyExploreTradingPhotoCard.description")}
      </Text>
    </VStack>
  );
}
