import React, { useEffect, useState } from "react";

import {
  Box,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { Trans } from "react-i18next";
import Page from "../../components/Page";
import ToggleFollow from "../../components/api/mutations/ToggleFollow";
import updateUserPreferences from "../../components/api/mutations/updateUserPreferences";
import useCommentsQuery from "../../components/api/queries/useCommentsQuery";
import usePostsQuery from "../../components/api/queries/usePostsQuery";
import useUserProfileQuery from "../../components/api/queries/useUserProfileQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../../components/bottomSheet/AddUserProfileV1BottomSheet";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PostPreview from "../../components/community/PostPreview";
import PostsList from "../../components/community/PostsList";
import TabsListSelector from "../../components/community/TabsListSelector";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import ProfileAvatar from "../../components/thumbnails/ProfileAvatar";
import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import { ReactComponent as SettingIcon } from "../../icons/setting.svg";
import InfiniteScroll from "../../components/InfiniteScroll";
import MutualMembersBottomDrawer from "../../components/drawer/MutualMembersBottomDrawer";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px 16px 24px;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  padding-right: 12px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Description = styled.div`
  padding-top: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #b2a3ff;
`;

const AvatarWrapper = styled.div`
  flex-shrink: 1;
`;

const TabsListSelectorWrapper = styled.div`
  padding: 18px 24px 0px 24px;
`;

const FollowTextWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 11px;
`;

const FollowText = styled.div`
  font-size: 13px;
`;

const HolographyText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  text-shadow: 0px 0px 8px #fff;
`;

export default function UserFeedProfilePage() {
  const queryClient = useQueryClient();
  const params = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState();
  const [mutualMembers, setMutualMembers] = useState([]);
  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] =
    useState(false);
  const [isShowMutualMemberBottomSheet, setIsShowMutualMemberBottomSheet] =
    useState(false);

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const user = useUserQuery();
  const userProfile = useUserProfileQuery(params["user_profile_id"]);

  const onSelectedTab = (tab) => {
    setSelectedTab(tab);
  };
  const back = () => {
    const isNewTab = currentQueryParams.get("newTabOpen");
    if (isNewTab) {
      window.close();
    } else {
      navigate(-1);
    }
  };

  const invalidateUserProfile = () => {
    queryClient.invalidateQueries(["userProfile"]);
    queryClient.invalidateQueries(["user"]);

    window.opener?.postMessage(
      {
        type: "invalidated-user-profile",
        params: {
          userProfileId: params["user_profile_id"],
        },
      },
      window.location.origin
    );
  };

  const toggleFollow = async (isFollowed) => {
    const response = await ToggleFollow({
      isFollowed: isFollowed,
      currentUserProfileId: user?.baseUserProfile?.userProfileId,
      followUserProfileId: userProfile?.userProfileId,
    });
    if (response.ok) {
      invalidateUserProfile();
    }
  };

  const renderSelectedTab = () => {
    if (selectedTab?.id === "posts") {
      return (
        <RenderPosts
          user={user}
          userProfile={userProfile}
          selectedTab={selectedTab}
        />
      );
    } else if (selectedTab?.id === "anonymous") {
      return (
        <>
          <Box
            className="bg-surface-light dark:bg-surface-dark text-onDisabled-light dark:text-onDisabled-dark"
            textAlign={"center"}
            paddingY={"16px"}
            lineHeight={"16px"}
            fontSize={"13px"}
          >
            {t("userFeedProfilePage.anonymousOnlyToYou")}
          </Box>
          <RenderPosts
            user={user}
            userProfile={userProfile}
            selectedTab={selectedTab}
          />
        </>
      );
    } else if (selectedTab?.id === "comments") {
      return (
        <RenderComments
          user={user}
          userProfile={userProfile}
          selectedTab={selectedTab}
        />
      );
    }
    return <></>;
  };

  const showMutualMemberBottomSheet = (members) => {
    setMutualMembers(members);
    setIsShowMutualMemberBottomSheet(true);
  };

  return (
    <Page
      showBackButton={true}
      noPadding={true}
      title={t("userFeedProfilePage.pageTitle")}
      onHistoryBackClick={() => back()}
      navigationElement={
        userProfile?.isMine ? (
          <FeedProfileMenus
            setIsShowBaseProfileBottomSheet={setIsShowBaseProfileBottomSheet}
            showTradingProfile={userProfile?.showTradingProfile ?? false}
            invalidateUserProfile={invalidateUserProfile}
          />
        ) : (
          <></>
        )
      }
    >
      {userProfile ? (
        <Header>
          <TextWrapper>
            <Title className="text-main-light-1 dark:text-main-dark-1">
              {userProfile?.nickname}
            </Title>
            <FollowTextWrapper>
              <FollowText
                className="text-main-light-2 dark:text-main-dark-2"
                onClick={() =>
                  navigate(
                    `/feed/user_profile/${userProfile?.userProfileId}/followings`
                  )
                }
              >
                <b>{`${userProfile?.followingsCount} `}</b>
                {t("following")}
              </FollowText>
              <FollowText
                className="text-main-light-2 dark:text-main-dark-2"
                onClick={() =>
                  navigate(
                    `/feed/user_profile/${userProfile?.userProfileId}/followers`
                  )
                }
              >
                <b>{`${userProfile?.followersCount} `}</b>
                {t("follower")}
              </FollowText>
            </FollowTextWrapper>
          </TextWrapper>
          <AvatarWrapper
            onClick={() =>
              userProfile.isMine && setIsShowBaseProfileBottomSheet(true)
            }
          >
            <ProfileAvatar
              profileImageUrl={userProfile?.thumbnailUrl}
              widthAndHeight={"76px"}
              svgWidthAndHeight={"48px"}
              padding={"14px"}
            />
          </AvatarWrapper>
        </Header>
      ) : (
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={"76px"} />
      )}
      {!userProfile?.isMine && !isEmpty(userProfile?.mutualMembers) && (
        <Flex
          paddingX={"22px"}
          paddingBottom={"8px"}
          gap={"2px"}
          onClick={() =>
            showMutualMemberBottomSheet(userProfile?.mutualMembers)
          }
        >
          <Image
            width={"20px"}
            height={"20px"}
            src={"/assets/icons/like.shape.shine.png"}
          />
          <Text
            className="text-main-light-4 dark:text-main-dark-4"
            fontSize="12px"
            lineHeight={"20px"}
            noOfLines={1}
          >
            {userProfile?.mutualMembers?.length <= 2 ? (
              <Trans
                i18nKey="UserProfileSearchPage.mutualBias"
                components={{ bold: <strong /> }}
                values={{
                  members: userProfile?.mutualMembers
                    ?.map((member) => member.name)
                    ?.join(", "),
                }}
              />
            ) : (
              <Trans
                i18nKey="UserProfileSearchPage.mutualBiasMore"
                components={{ bold: <strong /> }}
                values={{
                  members: `${userProfile?.mutualMembers
                    ?.map((member) => member.name)
                    ?.slice(0, 2)
                    .join(", ")}`,
                  count: userProfile?.mutualMembers?.length - 2,
                }}
              />
            )}
          </Text>
        </Flex>
      )}
      {
        <SimpleGrid
          columns={
            userProfile?.isMine ? 1 : userProfile?.showTradingProfile ? 2 : 1
          }
          paddingX={"24px"}
          gap={"8px"}
        >
          {!isEmpty(userProfile) && !userProfile?.isMine && (
            <>
              {userProfile?.isFollowed ? (
                <SecondaryButton
                  text={t("following")}
                  icon={
                    <div style={{ paddingLeft: "4px" }}>
                      <CheckIcon
                        className="fill-primary-light dark:fill-primary-dark"
                        width={"16px"}
                        height={"16px"}
                      />
                    </div>
                  }
                  onClick={() => toggleFollow(userProfile?.isFollowed)}
                  style={{
                    borderRadius: "12px",
                    padding: "11px 0px 11px 8px",
                    lineHeight: "16px",
                  }}
                />
              ) : (
                <RegularSolidButton
                  text={t("follow")}
                  onClicked={() => toggleFollow(userProfile?.isFollowed)}
                  style={{
                    borderRadius: "12px",
                    padding: "11px 0px",
                    lineHeight: "16px",
                  }}
                />
              )}
            </>
          )}
          {userProfile?.showTradingProfile &&
            userProfile?.tradingUserProfileId && (
              <Box
                className="text-pure-black dark:text-pure-white"
                paddingY={"11px"}
                borderRadius={"12px"}
                backgroundImage={"/assets/backgrounds/banner_bg_holography.png"}
                onClick={() =>
                  navigate(
                    `/trading/user_profile/${userProfile.tradingUserProfileId}`
                  )
                }
              >
                <HolographyText className="text-pure-black">
                  {t("userFeedProfilePage.navigateTradingProfile", {
                    nickname: userProfile?.nickname || "-",
                  })}
                </HolographyText>
              </Box>
            )}
        </SimpleGrid>
      }
      {
        <TabsListSelectorWrapper>
          <TabsListSelector
            onSelectedTab={onSelectedTab}
            hiddenTabId={userProfile?.isMine ? "" : "anonymous"}
          />
        </TabsListSelectorWrapper>
      }
      {renderSelectedTab()}
      {user?.baseUserProfile && setIsShowBaseProfileBottomSheet && (
        <AddUserProfileV1BottomSheet
          title={t("myUserProfileList.changeProfile")}
          userProfile={user?.baseUserProfile}
          isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
          category={"base"}
          isMergeProfile={false}
          onCompleted={invalidateUserProfile}
        />
      )}
      {isShowMutualMemberBottomSheet && (
        <MutualMembersBottomDrawer
          isOpen={isShowMutualMemberBottomSheet}
          onClose={() => setIsShowMutualMemberBottomSheet(false)}
          members={mutualMembers}
        />
      )}
    </Page>
  );
}

const FeedProfileMenus = ({
  setIsShowBaseProfileBottomSheet,
  showTradingProfile,
  invalidateUserProfile,
}) => {
  const onToggleShowTradingProfile = async () => {
    const response = await updateUserPreferences({
      showTradingProfile: !showTradingProfile,
    });
    if (response && response.ok) {
      invalidateUserProfile();
    }
  };

  return (
    <Menu>
      <MenuButton
        minWidth={"0px"}
        height={"18px"}
        as={IconButton}
        variant=""
        aria-label="Options"
        paddingX={"0px!important"}
        icon={
          <SettingIcon
            className="fill-main-light-1 dark:fill-main-dark-1"
            width={"24px"}
            height={"24px"}
          />
        }
      />
      <MenuList>
        <MenuItem onClick={() => setIsShowBaseProfileBottomSheet(true)}>
          {t("userFeedProfilePage.menuChangeNickname")}
        </MenuItem>
        <MenuItem onClick={() => onToggleShowTradingProfile()}>
          {showTradingProfile
            ? t("userFeedProfilePage.menuHiddenTradingProfile")
            : t("userFeedProfilePage.menuOpenTradingProfile")}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

const RenderPosts = ({ user, userProfile, selectedTab }) => {
  const [paginatedPosts, setPaginatedPosts] = useState();

  const { data, isFetching, fetchNextPage, hasNextPage } = usePostsQuery({
    userProfileId: userProfile?.userProfileId,
    anonymous: selectedTab?.id === "anonymous",
  });

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((post) => post)
    );
    setPaginatedPosts(posts);
  };
  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPosts(data);
    }
  }, [data]);
  return (
    userProfile &&
    paginatedPosts && (
      <PostsList
        user={user}
        posts={paginatedPosts}
        loadMore={loadMore}
        hasMore={hasNextPage}
      />
    )
  );
};

const RenderComments = ({ user, userProfile }) => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useCommentsQuery({
    userProfileId: userProfile?.userProfileId,
  });

  const [paginatedComments, setPaginatedComments] = useState();

  const handlePaginatedComments = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.comments.map((comment) => comment)
    );
    setPaginatedComments(posts);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedComments(data);
    }
  }, [data]);

  return (
    userProfile &&
    paginatedComments && (
      <InfiniteScroll
        items={paginatedComments}
        loadMore={fetchNextPage}
        isFetching={isFetching}
        hasNextPage={hasNextPage}
        user={user}
        renderItem={(item) => (
          <div
            className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
            key={item.postId}
            style={{
              padding: "16px 24px",
            }}
          >
            <PostPreview user={user} post={item} />
          </div>
        )}
      />
    )
  );
};
