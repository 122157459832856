import React, { useEffect, useState } from "react";

import { motion } from "framer-motion";
import {
  Center,
  Flex,
  Image,
  SimpleGrid,
  Spacer,
  Spinner,
  Switch,
  useColorMode,
} from "@chakra-ui/react";
import { t } from "i18next";
import { chunk, isEmpty, isUndefined, throttle } from "lodash";
import EasyVirtualized from "react-easy-virtualized";
import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";
import styled from "styled-components";

import { ReactComponent as MultiProfileIcon } from "../../icons/member.change.fill.svg";
import { ReactComponent as ProfileIcon } from "../../icons/profile.fill.small.svg";
import useExploreTradingPhotoCardsQueryV3 from "../api/queries/useExploreTradingPhotoCardsQueryV3";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import TradingPhotoCardsByPhotoCardBottomDrawer from "../drawer/TradingPhotoCardsByPhotoCardBottomDrawer";
import HorizontalScrollMembers from "../lists/HorizontalScrollMembers";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";

const CardBox = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const ImageWrapper = styled.div`
  padding: 29px 48px;
  border-radius: 12px;
`;

const CategoryText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;

const SellersText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

const PriceText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  text-align: left;
`;

const FilterButtonText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

const ArtistIconSelectorWrapper = styled.div`
  padding-bottom: 8px;
`;

const TitleWrapper = styled.div`
  padding: 16px 24px 0px 24px;
`;

const MembersWrapper = styled.div`
  padding: 8px 0px 14px 0px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

const CardsWrapper = styled.div`
  padding: 0px 24px;
`;

export default function SalesTabPanelV2({ fabWrapperRef, onShowBottomSheet }) {
  return (
    <ExploreSaleTab
      fabWrapperRef={fabWrapperRef}
      onShowBottomSheet={onShowBottomSheet}
    />
  );
}

const ExploreSaleTab = ({ fabWrapperRef, onShowBottomSheet }) => {
  const queryClient = useQueryClient();
  const { colorMode } = useColorMode();

  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedPhotoCard, setSelectedPhotoCard] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] =
    useState([]);

  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);
  const [isTradingPhotoCardsDrawerOpen, setIsTradingPhotoCardsDrawerOpen] =
    useState(false);
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] =
    useState();

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  };

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useExploreTradingPhotoCardsQueryV3({
      artistId: selectedArtist?.artistId,
      memberIds: selectedMember?.memberId ?? [],
      eventIds: selectedEventIds,
      onlyIdentityVerified: onlyIdentityVerified,
      photoCardCategories: selectedPhotoCardCategories,
      category: "sale",
    });

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if (event.origin !== window.location.origin) return;
      if (isEmpty(event.data?.type)) return;

      if (event.data?.type === "refresh-sales-trading-photo-cards") {
        queryClient.invalidateQueries("ExploreTradingPhotoCardsV2");
        queryClient.invalidateQueries("ExploreTradingPhotoCardsV3");
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);
    return () => {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  useEffect(() => {
    if (isTradingPhotoCardsDrawerOpen || isFilteringDrawerOpen) {
      onShowBottomSheet(true);
    } else {
      onShowBottomSheet(false);
    }
  }, [onShowBottomSheet, isTradingPhotoCardsDrawerOpen, isFilteringDrawerOpen]);

  const initFilters = () => {
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
    setOnlyIdentityVerified(false);
  };

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    if (selectedArtist && selectedArtist.artistId !== artist.artistId) {
      initFilters();
    }
  };

  const onSelectedPhotoCard = (photoCard) => {
    setIsTradingPhotoCardsDrawerOpen(true);
    setSelectedPhotoCard(photoCard);
  };

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified);
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["ExploreTradingPhotoCardsV2"]);
    }
  };

  const isCheckedPhotoCardFilter = () => {
    return (
      onlyIdentityVerified ||
      !isEmpty(selectedPhotoCardCategories) ||
      !isEmpty(selectedEventIds)
    );
  };

  const onSelectedMember = (member) => {
    if (isEmpty(member)) {
      setSelectedMember(null);
    } else {
      setSelectedMember(member);
    }
  };

  const onOpenFilterDrawer = (isOpen) => {
    if (fabWrapperRef.current) {
      fabWrapperRef.current.style.visibility = isOpen ? "hidden" : "visible";
    }
    setIsFilteringDrawerOpen(isOpen);
  };

  return (
    <div>
      <ArtistIconSelectorWrapper className="border-b-[1px] border-surface-light dark:border-surface-dark">
        <ArtistIconSelector onSelected={(artist) => onSelectedArtist(artist)} />
      </ArtistIconSelectorWrapper>
      <TitleWrapper>
        <Flex align={"center"} marginY={"2px"}>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("tradingPhotoCardPage.exchangeTabTitle", {
              fandom: selectedArtist?.fandomName || "-",
              saleType: t("tradingPhotoCardPage.sale"),
            })}
          </Title>
          <Spacer />
          <SecondaryLineButton
            className={`border ${
              isCheckedPhotoCardFilter()
                ? "dark:border-primary-dark border-primary-light"
                : "border-[rgba(0, 0, 0, 0.05)]"
            }`}
            text={
              <FilterButtonText
                className={
                  isCheckedPhotoCardFilter()
                    ? "text-primary-light dark:text-primary-dark"
                    : "text-main-light-1 dark:text-main-dark-1"
                }
                isChecked={isCheckedPhotoCardFilter()}
              >
                {t("photoCardPage.filter")}
              </FilterButtonText>
            }
            rightElement={
              <Switch
                size={"sm"}
                alignContent={"center"}
                colorScheme={"primary"}
                isChecked={isCheckedPhotoCardFilter()}
                onChange={() => onOpenFilterDrawer(true)}
                style={{ "--switch-track-height": "0.6rem" }}
              />
            }
            style={{
              margin: "1.5px 0px",
              boxShadow: isCheckedPhotoCardFilter()
                ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)"
                : "",
              padding: "8px 14px",
              gap: "4px",
            }}
            onClickButton={() => onOpenFilterDrawer(true)}
          />
        </Flex>
      </TitleWrapper>
      <MembersWrapper>
        <HorizontalScrollMembers
          members={selectedArtist?.members}
          selectedMember={selectedMember}
          onSelectedMember={(member) => onSelectedMember(member)}
        />
      </MembersWrapper>
      <CardsWrapper>
        {isUndefined(paginatedTradingPhotoCards) ? (
          <GridSkeletons
            gridSize={2}
            skeletonSize={2}
            skeletonHeight={"156px"}
            showText={true}
          />
        ) : isEmpty(paginatedTradingPhotoCards) ? (
          <EmptyExploreTradingPhotoCard />
        ) : (
          <div
            style={{
              paddingBottom: "calc(56px + env(safe-area-inset-bottom))",
            }}
          >
            <EasyVirtualized
              useParentScrollElement={true}
              onLoadMore={loadMore}
              hasMore={hasNextPage}
              loader={
                <Center marginTop={"8px"}>
                  <Spinner
                    size="sm"
                    className="text-primary-light dark:text-primary-dark"
                    marginRight={"20px"}
                  />
                </Center>
              }
            >
              {chunk(paginatedTradingPhotoCards, 2).map(
                (chunkCards, chunkIndex) => (
                  <SimpleGrid key={chunkIndex} columns={2} spacing={"10px"}>
                    {chunkCards.map((card) => {
                      return (
                        <motion.div
                          key={card.photoCardId}
                          initial={{ opacity: 0, y: -1 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <CardBox
                            key={card.photoCardId}
                            onClick={() => onSelectedPhotoCard(card)}
                          >
                            <ImageWrapper className="bg-surface-light dark:bg-surface-dark">
                              <Image
                                src={card.mediumThumbnailUrl || card.pictureUrl}
                                width={"100%"}
                                aspectRatio={"55/87"}
                                borderRadius="8px"
                                loading={"lazy"}
                                objectFit={"cover"}
                                boxShadow={
                                  colorMode === "dark"
                                    ? "0px 0px 15px 0px rgba(255, 255, 255, 0.05)"
                                    : "0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
                                }
                              />
                            </ImageWrapper>
                            <CardText>
                              <CategoryText className="text-main-light-1 dark:text-main-dark-1">
                                <Trans
                                  i18nKey="photoCardDetailPage.title"
                                  values={{
                                    memberName: card.memberName,
                                    categoryName: card.categoryName,
                                    categoryEmoji: card.categoryEmoji,
                                  }}
                                />
                              </CategoryText>
                              <div className="flex flex-row items-center gap-[3px] ml-[-1px]">
                                {card.tradingCount > 1 ? (
                                  <MultiProfileIcon
                                    className="fill-pure-black dark:fill-pure-white"
                                    width={"18px"}
                                    height={"18px"}
                                  />
                                ) : (
                                  <ProfileIcon
                                    className="fill-pure-black dark:fill-pure-white"
                                    width={"18px"}
                                    height={"18px"}
                                  />
                                )}
                                <SellersText className="text-main-light-3 dark:text-main-dark-3">
                                  {card.sellerNames}
                                </SellersText>
                              </div>
                              {isEmpty(card.minPrice) ? (
                                <PriceText className="text-textPrimary-light dark:text-textPrimary-dark">
                                  {t(
                                    "tradingPhotoCardPage.offerPriceWithEmoji"
                                  )}
                                </PriceText>
                              ) : (
                                <PriceText className="text-textPrimary-light dark:text-textPrimary-dark">{`${t(
                                  "tradingPhotoCardPage.minimumPrice"
                                )} ${card.minPrice}`}</PriceText>
                              )}
                            </CardText>
                          </CardBox>
                        </motion.div>
                      );
                    })}
                  </SimpleGrid>
                )
              )}
            </EasyVirtualized>
          </div>
        )}
      </CardsWrapper>
      {isFilteringDrawerOpen && (
        <TradingPhotoCardFilterBottomDrawer
          isOpen={isFilteringDrawerOpen}
          onClose={() => onOpenFilterDrawer(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
          identityVerified={onlyIdentityVerified}
          artistId={selectedArtist?.artistId}
          eventIds={selectedEventIds}
          photoCardCategories={selectedPhotoCardCategories}
        />
      )}
      {isTradingPhotoCardsDrawerOpen && selectedPhotoCard && (
        <TradingPhotoCardsByPhotoCardBottomDrawer
          artistId={selectedArtist?.artistId}
          photoCard={selectedPhotoCard}
          isOpen={isTradingPhotoCardsDrawerOpen}
          onClose={() => setIsTradingPhotoCardsDrawerOpen(false)}
        />
      )}
    </div>
  );
};
