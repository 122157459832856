import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Image,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";
import { first, isEmpty, isNull } from "lodash";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import LightColors from "../../constants/LightColors";
import { ReactComponent as ProfileIcon } from "../../icons/profile.fill.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import RegularSolidButton from "../buttons/RegularSolidButton";
import InfiniteScroll from "../InfiniteScroll";
import { ListRenderer } from "../lists/ListRenderer";
import { TagsList } from "../photocard/TagsList";
import GridSkeletons from "../skeletons/GridSkeletons";
import VerifiedIcon from "../icons/VerifiedIcon";

// Styled Components
const DrawerTitleRow = styled.div`
  white-space: pre-wrap;
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
`;

const DrawerContentWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: calc(24px + env(safe-area-inset-bottom));
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const PhotoCardSection = styled.div`
  flex-direction: row;
  display: flex;
`;

const PhotoCardBox = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: 16px;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
  gap: 10px;
`;

const PhotoCardName = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
`;

const EventName = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin-top: 2px !important;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
  flex-wrap: wrap;
  margin-top: 6px !important;
`;

const EmptyText = styled.div`
  margin-top: 32px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;

const CloseWrapper = styled.button`
  padding: 7px;
  z-index: 2147483647;
  border-radius: 50%;
`;

const NicknameText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  margin-left: 2px;
`;

const DotText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  padding: 0px 2px;
`;

const ResponseText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;
`;

const AveragePriceTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 0px !important;
`;

const AveragePriceDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 0px !important;
`;

const PriceText = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const RequestedAtText = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left; ;
`;

const DeliveryBadge = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  /* FIXME : color: ${(props) =>
    props.textColor === "primary"
      ? LightColors.textprimary
      : LightColors.onLabel}; */
  padding: 4px 8px;
  border-radius: 4px;
`;

const TradingPhotoCardsByPhotoCardBottomDrawer = ({
  artistId,
  photoCard,
  isOpen,
  onClose,
  focusRef,
  onConfirm,
}) => {
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] =
    useState(undefined);
  const {
    data: tradingPhotoCardsData,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useExploreTradingPhotoCardsQueryV2({
    artistId: artistId,
    photoCardId: photoCard.photoCardId,
  });
  // const photoCardResponse = usePhotoCardQuery(photoCard.photoCardId);

  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  };

  useEffect(() => {
    if (!isEmpty(tradingPhotoCardsData)) {
      handlePaginatedTradingPhotoCards(tradingPhotoCardsData);
    }
  }, [tradingPhotoCardsData]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      finalFocusRef={focusRef}
    >
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          },
        }}
        style={{
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: "",
        }}
      >
        <DrawerHeader style={{ padding: "20px 24px 10px 24px" }}>
          <div className="flex flex-row items-center">
            <div className="flex-grow">
              <DrawerTitleRow className="text-main-light-1 dark:text-main-dark-1">
                {t("TradingPhotoCardsByPhotoCardBottomDrawer.title")}
              </DrawerTitleRow>
            </div>
            <CloseWrapper
              className="bg-surface-light dark:bg-surface-dark"
              onClick={() => onClose()}
            >
              <CloseIcon
                className="fill-main-light-1 dark:fill-main-dark-1"
                width={"19px"}
                height={"19px"}
              />
            </CloseWrapper>
          </div>
          <PhotoCardSection>
            {isEmpty(photoCard) ? (
              <div className="w-full">
                <GridSkeletons
                  gridSize={1}
                  skeletonSize={1}
                  skeletonHeight={128}
                />
              </div>
            ) : (
              <PhotoCardBox className="border-[1px] !border-separator-light-2 dark:!border-separator-dark-1">
                <Image
                  aspectRatio={"55/87"}
                  height={"63px"}
                  borderRadius={"5px"}
                  src={photoCard.smallThumbnailUrl || photoCard.pictureUrl}
                />
                <VStack
                  alignItems={"start"}
                  justify={"center"}
                  className={"flex-grow"}
                  gap={"0px"}
                >
                  <PhotoCardName className="text-main-light-2 dark:text-main-dark-2">
                    {t("photoCardDetailPage.title", {
                      memberName: photoCard.memberName,
                      categoryName: photoCard.categoryName,
                      categoryEmoji: photoCard.categoryEmoji,
                    })}
                  </PhotoCardName>
                  <EventName className="text-main-light-3 dark:text-main-dark-3">
                    {ellipsis(photoCard.eventName, 25)}
                  </EventName>
                  <TagsWrapper>
                    <TagsList photoCard={photoCard} size={"xs"} />
                  </TagsWrapper>
                </VStack>
                <VStack alignItems={"end"} justify={"center"} gap={"4px"}>
                  <AveragePriceTitle className="text-main-light-1 dark:text-main-dark-1">
                    {t("TradingPhotoCardsByPhotoCardBottomDrawer.averagePrice")}
                  </AveragePriceTitle>
                  <AveragePriceDescription className="text-textPrimary-light dark:text-textPrimary-dark">
                    {isEmpty(photoCard.avgPrice)
                      ? t("tradingPhotoCardPage.offerPriceWithEmoji")
                      : photoCard.avgPrice}
                  </AveragePriceDescription>
                </VStack>
              </PhotoCardBox>
            )}
          </PhotoCardSection>
        </DrawerHeader>
        <DrawerBody padding={0} maxHeight={"60vh"}>
          <DrawerContentWrapper>
            {
              <motion.div
                initial={{ opacity: 0, y: -3 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -3 }}
                transition={{ duration: 0.3 }}
              >
                <ListRenderer
                  data={paginatedTradingPhotoCards}
                  emptyComponent={
                    <EmptyText className="text-onDisabled-light dark:text-onDisabled-dark">
                      {t("PhotoCardFilterBottomDrawer.emptyCategory")}
                    </EmptyText>
                  }
                  skeletonComponent={
                    <GridSkeletons
                      gridSize={1}
                      skeletonSize={photoCard.tradingCount}
                      skeletonHeight={"102px"}
                      marginBottom={"12px"}
                      marginTop={"12px"}
                    />
                  }
                  renderList={(data) => (
                    <InfiniteScroll
                      items={data}
                      hasNextPage={hasNextPage}
                      loadMore={fetchNextPage}
                      isFetching={isFetching}
                      renderItem={(item) => (
                        <motion.div
                          key={item.tradingPhotoCardId}
                          initial={{ opacity: 0, y: -2 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -2 }}
                          transition={{ duration: 0.2 }}
                        >
                          <Row
                            onClick={() =>
                              openNewTab(
                                `/trading_photo_cards/${item.tradingPhotoCardId}?photoCardId=${item.photoCard.photoCardId}`
                              )
                            }
                          >
                            {isEmpty(item.smallThumbnailUrls) ? (
                              <div
                                className="bg-surface-light dark:bg-surface-dark"
                                style={{
                                  width: "98px",
                                  height: "98px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "12px",
                                }}
                              >
                                <Image
                                  src={
                                    item.photoCard.smallThumbnailUrl ||
                                    item.photoCard.pictureUrl
                                  }
                                  aspectRatio={"55/87"}
                                  height={"63px"}
                                  borderRadius={"5px"}
                                  loading={"lazy"}
                                  objectFit={"cover"}
                                  boxShadow={
                                    "0px 0px 0px 1px rgba(0, 0, 0, 0.03) inset"
                                  }
                                />
                              </div>
                            ) : (
                              <Image
                                src={first(item.smallThumbnailUrls)}
                                width={"98px"}
                                height={"98px"}
                                borderRadius={"12px"}
                                loading={"lazy"}
                                objectFit={"cover"}
                                boxShadow={
                                  "0px 0px 0px 1px rgba(0, 0, 0, 0.03) inset"
                                }
                              />
                            )}
                            <div className="flex flex-col flex-grow items-start py-2 gap-[8px] pl-3">
                              <div className="flex justify-center items-center">
                                {item.userProfile?.identified ? (
                                  <VerifiedIcon width="14px" height="14px" />
                                ) : (
                                  <ProfileIcon
                                    className="fill-onLabel-light dark:fill-onLabel-dark"
                                    width={"14px"}
                                    height={"14px"}
                                  />
                                )}
                                <NicknameText className="text-main-light-3 dark:text-main-dark-3">
                                  {ellipsis(item.userProfile.nickname, 7)}
                                </NicknameText>
                                {item?.userProfile.responseText && (
                                  <>
                                    <DotText className="text-label-light dark:text-label-dark">
                                      {"•"}
                                    </DotText>
                                    <ResponseText className="text-textPrimary-light dark:text-textPrimary-dark">{`${item?.userProfile.responseText}`}</ResponseText>
                                  </>
                                )}
                              </div>
                              <PriceText className="text-textPrimary-light dark:text-textPrimary-dark">
                                {isNull(item.price)
                                  ? t(
                                      "tradingPhotoCardPage.offerPriceWithEmoji"
                                    )
                                  : item.price}
                              </PriceText>
                              <RequestedAtText className="text-sub-light-1 dark:text-sub-dark-1">
                                {item.requestedAt}
                              </RequestedAtText>
                              {item.tradeMethod && (
                                <DeliveryBadge
                                  className="text-onLabel-light dark:text-onLabel-dark bg-surface-light dark:bg-surface-dark"
                                  px={"8px"}
                                  py={"4px"}
                                >
                                  {t(`TradeMethod.${item.tradeMethod}`)}
                                </DeliveryBadge>
                              )}
                            </div>
                            {!item.isMine && (
                              <RegularSolidButton
                                text={t(
                                  "TradingPhotoCardsByPhotoCardBottomDrawer.purchase"
                                )}
                                style={{ width: "max-content" }}
                              />
                            )}
                          </Row>
                        </motion.div>
                      )}
                    />
                  )}
                />
              </motion.div>
            }
          </DrawerContentWrapper>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default TradingPhotoCardsByPhotoCardBottomDrawer;
