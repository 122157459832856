import React, { useEffect, useState } from "react";

import {
  Box,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorMode,
  useTheme,
  Wrap,
} from "@chakra-ui/react";
import { first, isEmpty } from "lodash";
import Marquee from "react-fast-marquee";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { t } from "i18next";
import Page from "../components/Page";
import useArtistQuery from "../components/api/queries/useArtistQuery";
import {
  useMatchedPhotoCards,
  useTradingPhotoCardsAll,
} from "../components/api/queries/useTradingPhotoCards";
import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../components/bottomSheet/AddUserProfileV1BottomSheet";
import FabButton from "../components/buttons/FabButton";
import ChatRoom from "../components/chats/ChatRoom";
import EmptyCase from "../components/errors/EmptyCase";
import LikedMembersList from "../components/lists/LikedMembersList";
import { AggregatedPhotoCardCard } from "../components/photocard/AggregatedPhotoCardCard";
import GridSkeletons from "../components/skeletons/GridSkeletons";
import ExploreTabPanel from "../components/tradings/ExploreTabPanel";
import MatchedTabPanel from "../components/tradings/MatchTabPanel";
import TradingManagerBar from "../components/tradings/TradingManagerBar";
import UserProfileButton from "../components/userProfile/UserProfileButton";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow.left.svg";
import { getArtistId } from "../utils/etcUtils";
import { queryGet } from "../utils/queryClient";

const activeTabStyle = (colorMode, theme) => ({
  fontWeight: 700,
  borderBottom: "3px solid",
  borderColor:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  color:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  borderBox: "",
});

const marqueeStyle = {
  background: "linear-gradient(90deg, #FF45A9 0%, #FF6A6A 100%)",
  marginLeft: "-24px",
  width: "calc(100% + 48px)",
  height: "34px",
};

const subTitleStyle = {
  fontSize: "17px",
  fontWeight: 700,
};

const emptyBoxStyle = {
  width: "100%",
  height: "104px",
  borderRadius: "20px",
  // FIXME : background: "#F5F5F5",
  marginTop: "12px",
};

const emptyBoxTextStyle = {
  fontSize: "14px",
  fontWeight: 500,
};

const whiteButtonStyle = {
  borderRadius: "32px",
  padding: "6px 12px",
};

const whiteButtonTextStyle = {
  fontSize: "13px",
  fontWeight: 500,
};

const TRADING_CATEGORY = "trading";

export default function TradingPhotoCardPage() {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const artistId = getArtistId();

  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(
    currentQueryParams.get("tabIndex") || 0
  );
  const [selectedMember, setSelectedMember] = useState(null);

  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] =
    useState(false);
  // const [isShowVerificationBottomSheet, setIsShowVerificationBottomSheet] = useState(false);
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  // const [redirectPath, setRedirectPath] = useState();
  const [checkedInitialTab, setCheckedInitialTab] = useState(false);

  const artist = useArtistQuery(selectedTradingPhotoCard?.artistId);
  const user = useUserQuery();
  const tradingUserProfiles = useUserProfilesListQuery(TRADING_CATEGORY);

  useEffect(() => {
    if (tradingUserProfiles) {
      if (isEmpty(tradingUserProfiles)) {
        setIsShowProfileBottomSheet(true);
      } else if (tradingUserProfiles.length > 1) {
        navigate("/users/merge_profiles");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles]);

  // const isExpiredToShowVerificationBottomSheet = () => {
  //   const showVerificationBottomSheet = getLocalStorageWithExpiry("showVerificationBottomSheet");
  //   return showVerificationBottomSheet === true || isNull(showVerificationBottomSheet);
  // }

  // useEffect(() => {
  //   if(isEmpty(user)) return;

  //   if(!user.identified && isExpiredToShowVerificationBottomSheet()) {
  //     setIsShowVerificationBottomSheet(true);
  //   }
  // }, [user]);

  // #region 양도 포카 가져오기
  const tradingPhotoCards = useTradingPhotoCardsAll({ category: "exchange" });

  useEffect(() => {
    if (!!tradingPhotoCards && !selectedTradingPhotoCard) {
      setSelectedTradingPhotoCard(first(tradingPhotoCards));
    }
  }, [tradingPhotoCards, selectedTradingPhotoCard]);
  // #endregion

  // #region 교환 매칭에 들어왔을때 교환카드가 있지 않으면 판매되는 포카 페이지로
  useEffect(() => {
    if (checkedInitialTab) return;
    if (!tradingPhotoCards) return;

    setCheckedInitialTab(true);

    const initialTabIndex = currentQueryParams.get("tabIndex") || 0;
    if (initialTabIndex !== 0) return;

    if (isEmpty(tradingPhotoCards))
      navigate(`/tradings/photo_cards`);
  }, [tradingPhotoCards]);
  // #endregion

  const matchedTradingPhotoCards = useMatchedPhotoCards({
    artistId: selectedTradingPhotoCard?.artistId,
    tradingPhotoCardId: selectedTradingPhotoCard?.tradingPhotoCardId,
    memberId: selectedMember?.memberId,
  });

  const aggregatedEventQueryParams = new URLSearchParams();
  if (selectedTradingPhotoCard?.tradingPhotoCardId) {
    aggregatedEventQueryParams.append(
      "tradingPhotoCardId",
      selectedTradingPhotoCard?.tradingPhotoCardId
    );
  }

  const { data: aggregatedPhotoCards } = useQuery(
    [
      "aggregatedPhotoCards",
      selectedTradingPhotoCard?.artistId,
      aggregatedEventQueryParams.toString(),
    ],
    () =>
      queryGet(
        `/artists/${selectedTradingPhotoCard?.artistId}/aggregated_photo_cards_by_event?` +
          aggregatedEventQueryParams
      ),
    {
      enabled:
        !!selectedTradingPhotoCard?.artistId &&
        aggregatedEventQueryParams.has("tradingPhotoCardId") &&
        selectedTradingPhotoCard?.category === "exchange",
      select: (r) => r.aggregatedPhotoCards,
    }
  );

  const salesChatRoomParams = new URLSearchParams();
  salesChatRoomParams.append("artistId", artistId);
  if (selectedTradingPhotoCard?.tradingPhotoCardId) {
    salesChatRoomParams.append(
      "tradingPhotoCardId",
      selectedTradingPhotoCard?.tradingPhotoCardId
    );
  }

  const { data: salesChatRooms } = useQuery(
    ["salesChatRooms", salesChatRoomParams.toString()],
    () => queryGet(`/chat_rooms?` + salesChatRoomParams),
    {
      enabled:
        !!artistId &&
        salesChatRoomParams.has("tradingPhotoCardId") &&
        selectedTradingPhotoCard?.category === "sale",
      select: (r) => r.chatRooms,
    }
  );

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  };

  const onClickFabButton = () => {
    if (!user) return;

    if (user.tradingUserProfile) {
      navigate(`/artists/add_trading_photo_cards`);
    } else {
      setIsShowProfileBottomSheet(true);
    }
  };

  return (
    <div
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      } safeAreaPaddingExceptBottomBackground`}
    >
      <Page
        title={t("tradingPhotoCardPage.pageTitle")}
        showBackButton={false}
        themeColor={colorMode === "dark" ? "#0D0E12" : "#C6DBF8"}
        userProfile={user}
        style={{ paddingTop: "13px" }}
      >
        <SimpleGrid alignItems={"center"} columns={3}>
          <div style={{ zIndex: 2147483647 }} onClick={() => navigate(-1)}>
            <ArrowLeftIcon className="fill-black dark:fill-white" />
          </div>
          <Text
            className="text-center text-main-light-1 dark:text-main-dark-1"
            fontSize={"17px"}
            fontWeight={700}
          >
            {t("tradingPhotoCardPage.pageTitle")}
          </Text>
          <div className="flex justify-end">
            <UserProfileButton
              userProfile={user?.tradingUserProfile}
              onClickedButton={() =>
                isEmpty(tradingUserProfiles)
                  ? setIsShowProfileBottomSheet(true)
                  : navigate(
                      `/trading/user_profile/${user.tradingUserProfile?.userProfileId}`
                    )
              }
            />
          </div>
        </SimpleGrid>
        <Tabs
          isFitted
          index={parseInt(tabIndex)}
          paddingTop={"16px"}
          borderColor={"transparent"}
          colorScheme="primary"
          onChange={handleTabsChange}
        >
          <TabList
            className="border-b-[1px] !border-surface-light dark:!border-surface-dark"
            marginX={"-24px"}
            paddingX={"16px"}
          >
            <Tab
              _active={""}
              _selected={(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("tradingPhotoCardPage.exchangeTabName")}
            </Tab>
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("tradingPhotoCardPage.salesTabName")}
            </Tab>
          </TabList>
          <Marquee style={marqueeStyle}>
            <Text
              align={"center"}
              className="text-center"
              fontSize={"12px"}
              fontWeight={500}
              color={"#FFF"}
              whiteSpace={"pre-wrap"}
            >
              {t("tradingPhotoCardPage.marqueeText")}
            </Text>
          </Marquee>
          <TabPanels style={{ paddingBottom: "120px", paddingTop: "12px" }}>
            <TabPanel padding={0}>
              <TradingManagerBar
                tradingPhotoCards={tradingPhotoCards}
                onClickTradingPhotoCard={(tradingPhotoCard) =>
                  setSelectedTradingPhotoCard(tradingPhotoCard)
                }
              />
              {selectedTradingPhotoCard?.category === "sale" ? (
                <>
                  <Text
                    className="text-main-light-2 dark:text-main-dark-2"
                    style={subTitleStyle}
                    marginTop={"8px"}
                  >
                    {t("tradingPhotoCardPage.exchangeTradingPhotoCards")}
                  </Text>
                  <div
                    style={emptyBoxStyle}
                    className="bg-separator-light-1 dark:bg-separator-dark-1 flex flex-col items-center justify-center"
                  >
                    <Text
                      className="text-main-light-4 dark:text-main-dark-4"
                      style={emptyBoxTextStyle}
                    >
                      {t("tradingPhotoCardPage.thisCardOnlyForSale")}
                    </Text>
                    <Box
                      className="bg-pure-white dark:bg-pure-black border-[1px] border-separator-light-1 dark:border-separator-dark-1"
                      style={whiteButtonStyle}
                      marginTop={"12px"}
                    >
                      <Text
                        className="text-main-light-2 dark:text-main-dark-2"
                        style={whiteButtonTextStyle}
                        onClick={() =>
                          navigate(
                            `/artists/${artistId}/my_trading_photo_cards`
                          )
                        }
                      >
                        {t("tradingPhotoCardPage.manageMyTradingPhotoCard")}
                      </Text>
                    </Box>
                  </div>
                  <Text
                    className="text-main-light-2 dark:text-main-dark-2"
                    style={subTitleStyle}
                    marginTop={"28px"}
                  >
                    {t("tradingPhotoCardPage.tradingPhotoCardChatRooms")}
                  </Text>
                  {salesChatRooms && isEmpty(salesChatRooms) ? (
                    <EmptyCase
                      emoji={"😓"}
                      description={t("tradingPhotoCardPage.noChatRoom")}
                      props={{ marginTop: "32px" }}
                    />
                  ) : (
                    user && (
                      <div style={{ padding: "12px 0px" }}>
                        {salesChatRooms?.map((r) => {
                          return (
                            <div
                              key={r.chatRoomId}
                              style={{ paddingBottom: "12px" }}
                            >
                              <ChatRoom
                                userProfileId={
                                  user.tradingUserProfile?.userProfileId
                                }
                                chatRoom={r}
                                hideImage={true}
                              />
                            </div>
                          );
                        })}
                      </div>
                    )
                  )}
                </>
              ) : (
                <>
                  {aggregatedPhotoCards ? (
                    <>
                      <Text
                        className="text-main-light-2 dark:text-main-dark-2"
                        fontSize={"17px"}
                        fontWeight={700}
                        marginTop={"8px"}
                      >
                        {t(
                          "tradingPhotoCardPage.exchangeEventTradingPhotoCards"
                        )}
                      </Text>
                      {isEmpty(aggregatedPhotoCards) ? (
                        <div
                          style={emptyBoxStyle}
                          className="bg-separator-light-1 dark:bg-separator-dark-1 flex items-center justify-center"
                        >
                          <Text
                            className="text-main-light-4 dark:text-main-dark-4"
                            style={emptyBoxTextStyle}
                          >
                            {t(
                              "tradingPhotoCardPage.emptyEventTradingPhotoCards"
                            )}
                          </Text>
                        </div>
                      ) : (
                        <>
                          <div style={{ marginLeft: "-10px" }}>
                            <ScrollMenu>
                              {aggregatedPhotoCards?.map((photoCard) => {
                                return (
                                  <div
                                    key={photoCard.photoCardId}
                                    style={{
                                      paddingLeft: "10px",
                                      marginRight: "2px",
                                      paddingBottom: "12px",
                                      paddingTop: "12px",
                                    }}
                                  >
                                    <AggregatedPhotoCardCard
                                      artistId={
                                        selectedTradingPhotoCard?.artistId
                                      }
                                      tradingPhotoCardId={
                                        selectedTradingPhotoCard?.tradingPhotoCardId
                                      }
                                      aggregatedPhotoCard={photoCard}
                                    />
                                  </div>
                                );
                              })}
                            </ScrollMenu>
                          </div>
                        </>
                      )}
                      <Text
                        className="text-main-light-2 dark:text-main-dark-2"
                        fontSize={"17px"}
                        fontWeight={700}
                        marginTop={"28px"}
                      >
                        {t("tradingPhotoCardPage.exchangeTradingPhotoCards")}
                      </Text>
                    </>
                  ) : (
                    !isEmpty(tradingPhotoCards) && (
                      <div
                        style={{ paddingTop: "12px", paddingBottom: "12px" }}
                      >
                        <GridSkeletons
                          gridSize={2}
                          skeletonSize={1}
                          skeletonHeight={"96px"}
                        />
                      </div>
                    )
                  )}
                  <Wrap spacing={"8px"} marginBottom={"12px"}>
                    <LikedMembersList
                      artistMembers={artist?.members}
                      onChangeLikedMember={(member) =>
                        setSelectedMember(member)
                      }
                      addEntireOption={true}
                    />
                  </Wrap>
                  <MatchedTabPanel
                    tradingPhotoCards={tradingPhotoCards}
                    selectedTradingPhotoCard={selectedTradingPhotoCard}
                    matchedTradingPhotoCards={matchedTradingPhotoCards}
                    selectedMember={selectedMember}
                    userProfileNickName={user?.tradingUserProfile?.nickname}
                  />
                </>
              )}
            </TabPanel>
            <TabPanel padding={0}>
              <ExploreTabPanel />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Page>
      {isShowProfileBottomSheet && (
        <AddUserProfileV1BottomSheet
          title={t("tradingPhotoCardPage.addProfileBottomSheetTitle")}
          userProfile={user?.tradingUserProfile}
          isShowProfileBottomSheet={isShowProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
        />
      )}
      {/* {
      isShowVerificationBottomSheet &&
      <UserVerificationBottomSheet
        isShowBottomSheet={isShowVerificationBottomSheet}
        setIsShowBottomSheet={setIsShowVerificationBottomSheet}
        redirectPath={redirectPath}
      />
    } */}
      <FabButton
        width={"52px"}
        p={"10px!important"}
        onClickFabButton={() => onClickFabButton()}
        style={{
          marginBottom: "calc(3rem + env(safe-area-inset-bottom))",
        }}
      />
    </div>
  );
}
