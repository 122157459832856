import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { getArtistId } from "../../utils/etcUtils";
import { t } from "i18next";

const titleStyle = {
  fontSize: "14px",
  fontWeight: 500,
};

const buttonStyle = {
  fontSize: "13px",
  fontWeight: 500,
  padding: "6px 12px",
  lineHeight: "16px",
  borderRadius: "32px",
};

const boxStyle = {
  borderRadius: "12px",
  height: "44px",
  padding: "12px 20px",
  marginBottom: "18px",
};

export default function BlacklistManagerBar() {
  const artistId = getArtistId();
  const navigate = useNavigate();

  return (
    <Flex
      className="bg-surface-light dark:bg-surface-dark"
      align={"center"}
      style={boxStyle}
    >
      <Text style={titleStyle}>{t("blacklistCards.exclude")}</Text>
      <Spacer />
      <Box
        className="text-white dark:text-black bg-main-light-2 dark:bg-main-dark-2"
        style={buttonStyle}
        onClick={() =>
          navigate(`/artists/${artistId}/blacklist_trading_photo_cards`)
        }
      >
        {t("blacklistCards.manage")}
      </Box>
    </Flex>
  );
}
