import React, { useState } from "react";

import { Flex, HStack, Image, Text, VStack } from '@chakra-ui/react';

import { isEmpty } from "lodash";
import colors from "../../constants/colors";
import { ReactComponent as MatchingFillIcon } from "../../icons/matching.fill.svg";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { ellipsis } from "../../utils/TextUtil";
import MatchedTradingPhotoCardBottomSheet from "../bottomSheet/MatchedTradingPhotoCardBottomSheet";
import VerifiedIcon from "../icons/VerifiedIcon";
import MatchedTradingPhotoCardBottomDrawer from "../drawer/MatchedTradingPhotoCardBottomDrawer";

const imageBoxStyle = {
  width: "100%",
  padding: "24px 24px",
  background: "var(--gray-surface, rgba(0, 0, 0, 0.04))",
  borderRadius: "20px",
}

const imageStyle = {
  width: "40px",
  borderRadius: "6px",
}

const profileStyle = {
  paddingLeft: "2px",
  fontWeight: 500,
  color: "rgba(34, 34, 34, 1)",
  lineHeight: "16px",
  fontSize: "13px",
}
const requestedAtStyle = {
  color: "#999",
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
  whiteSpace: "pre"
}

const responseRateText = {
  color: `${colors.primary}`,
  fontSize: "11px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "13.2px",
}

export default function MatchedTradingPhotoCard({matchedTradingPhotoCard}) {
  const [isShowMatchedTradingPhotoCardBottomSheet, setIsShowMatchedTradingPhotoCardBottomSheet] = useState(false)

  return (matchedTradingPhotoCard &&
    <>
      <VStack
        spacing={0}
        minHeight={"140px"}
        align={"flex-start"}
        key={matchedTradingPhotoCard.matchedTradingPhotoCardId}
        onClick={() => setIsShowMatchedTradingPhotoCardBottomSheet(true)}
      >
        <Flex style={imageBoxStyle} justifyContent={"center"} align={"center"} gap={"5px"}>
          <Image style={imageStyle} src={matchedTradingPhotoCard.sourceCard.photoCard.pictureUrl}/>
          <MatchingFillIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"16px"}
          />
          <Image style={imageStyle} src={matchedTradingPhotoCard.targetCard.photoCard.pictureUrl}/>
        </Flex>
        <Flex direction={"row"} alignItems={"center"} paddingTop={"6px"} paddingBottom={"6px"}>
          {
            matchedTradingPhotoCard.targetCard.userProfile.identified ?
            <VerifiedIcon width={"14px"} height={"14px"} /> :
            <ProfileFillIcon width={"14px"} height={"14px"} fill={"rgba(34,34,34,1)"}/>
          }
          <Text noOfLines={1} style={profileStyle}>{ellipsis(matchedTradingPhotoCard.targetCard.userProfile.nickname, 20)}</Text>
        </Flex>
        <HStack spacing={0}>
          <Text style={requestedAtStyle}>{matchedTradingPhotoCard.targetCard.requestedAt}</Text>
          {
            !isEmpty(matchedTradingPhotoCard.targetCard.userProfile.chatStats?.responseText) && (
              <>
              <Text style={requestedAtStyle} px={"3px"}>{`•`}</Text>
              <Text style={responseRateText}>{`${matchedTradingPhotoCard.targetCard.userProfile.chatStats?.responseText}`}</Text>
              </>
            )
          }
        </HStack>
      </VStack>
      {/* <MatchedTradingPhotoCardBottomSheet
        artistId={matchedTradingPhotoCard.artistId}
        matchedTradingPhotoCard={matchedTradingPhotoCard}
        isShowBottomSheet={isShowMatchedTradingPhotoCardBottomSheet}
        setIsShowBottomSheet={setIsShowMatchedTradingPhotoCardBottomSheet}
      /> */}
      <MatchedTradingPhotoCardBottomDrawer
        isOpen={isShowMatchedTradingPhotoCardBottomSheet}
        onClose={() => setIsShowMatchedTradingPhotoCardBottomSheet(false)}
        artistId={matchedTradingPhotoCard.artistId}
        matchedTradingPhotoCard={matchedTradingPhotoCard}
      />
    </>
  )
}
