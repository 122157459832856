import React, { useEffect, useRef, useState } from "react";

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Radio,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import styled from "styled-components";

import Countries, { FindCountyByCountryCode } from "../../constants/Countries";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "../../utils/ExpiredLocalStorage";
import CommonVerticalButtonDialog from "../alerts/CommonVerticalButtonDialog";

const DrawerTitleRow = styled.div`
  padding-top: 24px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 12px;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
`;

const Row = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
`;

const SelectedText = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const UnSelectedText = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const RadioWrapper = styled.div`
  align-content: center;
  flex-shrink: 1;
`;

const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;

export default function SelectCountryBottomDrawer({
  isOpen,
  onClose,
  focusRef,
  defaultCountryCode,
  onConfirmedCountryCode,
}) {
  const selectedCountryRef = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState();
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [isShowNotAllowedAlertDialog, setIsShowNotAllowedAlertDialog] =
    useState(false);

  useEffect(() => {
    setSelectedCountry(FindCountyByCountryCode(defaultCountryCode));
    setTimeout(() => {
      if (selectedCountryRef.current) {
        selectedCountryRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 150);
  }, [defaultCountryCode]);

  const onSelectCountry = (country) => {
    if (isEmpty(getLocalStorageWithExpiry("IsChangedCountry"))) {
      setSelectedCountry(country);
      setIsShowConfirmDialog(true);
    } else {
      setIsShowNotAllowedAlertDialog(true);
    }
  };

  const onConfirmed = (country) => {
    localStorage.setItem("feedCountryCode", country.code);
    setLocalStorageWithExpiry("IsChangedCountry", new Date(), ONE_WEEK);
    setIsShowConfirmDialog(false);
    onConfirmedCountryCode(country.code);
    onClose();
  };

  const isSelectedCountryCode = (countryCode) => {
    return countryCode === selectedCountry?.code && !isEmpty(selectedCountry);
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        finalFocusRef={focusRef}
      >
        <DrawerOverlay />
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            },
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: "",
          }}
        >
          <DrawerTitleRow className="text-main-light-3 dark:text-main-dark-3">
            {t("changeCountry.countryDrawerFirstRow")}
          </DrawerTitleRow>
          <DrawerBody paddingX={"20px"} maxHeight={"320px"}>
            {Countries.map((country) => {
              return (
                <Row
                  key={country.code}
                  ref={
                    isSelectedCountryCode(country.code)
                      ? selectedCountryRef
                      : null
                  }
                  onClick={() => onSelectCountry(country)}
                >
                  <TextWrapper>
                    {isSelectedCountryCode(country.code) ? (
                      <SelectedText className="text-primary-light dark:text-primary-dark">
                        {country.name}
                      </SelectedText>
                    ) : (
                      <UnSelectedText className="text-main-light-1 dark:text-main-dark-1">
                        {country.name}
                      </UnSelectedText>
                    )}
                  </TextWrapper>
                  <RadioWrapper>
                    <Radio
                      colorScheme="primary"
                      isChecked={isSelectedCountryCode(country.code)}
                    />
                  </RadioWrapper>
                </Row>
              );
            })}
          </DrawerBody>
          <DrawerFooter
            className="safeAreaMarginBottom"
            width={"100%"}
            paddingTop={"10px"}
            paddingBottom={"0px"}
            paddingX={"20px"}
          ></DrawerFooter>
        </DrawerContent>
      </Drawer>
      {selectedCountry && isShowConfirmDialog && (
        <CommonVerticalButtonDialog
          isOpen={isShowConfirmDialog}
          onClose={() => setIsShowConfirmDialog(false)}
          title={t("changeCountry.changingAlertTitle", {
            country: selectedCountry.name,
          })}
          description={t("changeCountry.changingAlertDescription")}
          onClickDialogUpButton={() => onConfirmed(selectedCountry)}
          onClickDialogDownButton={() => setIsShowConfirmDialog(false)}
          upButtonText={t("yes")}
          downButtonText={t("no")}
        />
      )}
      {isShowNotAllowedAlertDialog && (
        <CommonVerticalButtonDialog
          isOpen={isShowNotAllowedAlertDialog}
          onClose={() => setIsShowNotAllowedAlertDialog(false)}
          title={t("changeCountry.notAllowedChangingAlertTitle")}
          description={t("changeCountry.notAllowedChangingAlertDescription")}
          onClickDialogUpButton={() => setIsShowNotAllowedAlertDialog(false)}
          upButtonText={t("yes")}
          isOneButton={true}
        />
      )}
    </>
  );
}
