import React, { useState } from "react";

import {
  Box,
  Tab,
  TabList,
  TabPanels,
  Tabs,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isInteger } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import CommonDialog from "../components/alerts/CommonDialog";
import deleteTradingPhotoCards from "../components/api/mutations/legacy/deleteTradingPhotoCards";
import updateTradingPhotoCards from "../components/api/mutations/updateTradingPhotoCards";
import { useTradingPhotoCardsAll } from "../components/api/queries/useTradingPhotoCards";
import EmptyCase from "../components/errors/EmptyCase";
import PrimaryInput from "../components/input/PrimaryInput";
import { ListRenderer } from "../components/lists/ListRenderer";
import Page from "../components/Page";
import GridSkeletons from "../components/skeletons/GridSkeletons";
import BlacklistManagerBar from "../components/tradings/BlacklistManagerBar";
import MyTradingPhotoCard from "../components/tradings/MyTradingPhotoCard";
import { extractPrice } from "../utils/TextUtil";

const buttonStyle = {
  fontSize: "14px",
  fontWeight: 600,
  padding: "6px 12px",
  borderRadius: "32px",
};

const activeTabStyle = (colorMode, theme) => ({
  fontWeight: 700,
  borderBottom: "3px solid",
  borderColor:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  color:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  borderBox: "",
});

export default function MyTradingPhotoCardsPage() {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currentQueryParams = new URLSearchParams(location.search);
  const [tabIndex, setTabIndex] = useState(
    parseInt(currentQueryParams.get("tabIndex")) || 0
  );

  const [rerender, setRerender] = useState(false);
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [isOpenExchangeDialog, setIsOpenExchangeDialog] = useState(false);
  const [isOpenSaleDialog, setIsOpenSaleDialog] = useState(false);
  const [price, setPrice] = useState(null);

  const tradingPhotoCards = useTradingPhotoCardsAll({
    category: tabIndex === 0 ? "exchange" : "sale",
  });

  const navigateToAddTradingPhotoCard = () => {
    // navigate(`/artists/add_trading_photo_cards`);
    navigate(
      `/tradings/add_trading_photo_cards?isExchange=${parseInt(tabIndex) === 0}`
    );
  };

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  };

  const onCloseDialog = () => {
    setIsOpenExchangeDialog(false);
    setIsOpenSaleDialog(false);
  };

  const invalidateItems = () => {
    queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
    queryClient.clear();
    setRerender(!rerender);
  };

  const toggleTradingPhotoCard = (tradingPhotoCard) => {
    if (tradingPhotoCard.category === "sale") {
      return {
        ...tradingPhotoCard,
        category: "exchange",
        price: null,
      };
    } else {
      return {
        ...tradingPhotoCard,
        category: "sale",
        price: extractPrice(price),
      };
    }
  };

  const handleUpdateAPIResponse = async (response) => {
    if (response.ok) {
      invalidateItems();
      onCloseDialog();
      const responseBody = await response.json();
      return responseBody.tradingPhotoCard;
    } else {
      return null;
    }
  };

  const onChangedItem = async (tradingPhotoCard) => {
    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: isInteger(tradingPhotoCard.price)
        ? tradingPhotoCard.price
        : extractPrice(tradingPhotoCard.price),
      category: tradingPhotoCard.category,
    });

    handleUpdateAPIResponse(response);
  };

  const onDeletedItem = (tradingPhotoCard) => {
    const response = deleteTradingPhotoCards(
      tradingPhotoCard.tradingPhotoCardId
    );
    response.then((r) => {
      if (r.success) {
        invalidateItems();
      }
    });
  };

  const onCompletedItem = async (tradingPhotoCard) => {
    if (isEmpty(tradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
      price: tradingPhotoCard.price,
      status: "sold_out",
    });
    const responseBody = await handleUpdateAPIResponse(response);
    if (responseBody && responseBody.chatRoomsCount > 0) {
      navigate(
        `/trading_photo_cards/${responseBody.tradingPhotoCardId}/select_chat_rooms`
      );
    }
  };

  const onToggledItem = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    if (tradingPhotoCard.category === "sale") {
      setIsOpenExchangeDialog(true);
    } else {
      setIsOpenSaleDialog(true);
    }
  };

  const tradingPhotoCardWithQuantity = (tradingPhotoCards) => {
    const multipleCards = [];
    tradingPhotoCards?.forEach((item) => {
      for (let i = 0; i < (item.quantity ?? 1); i++) {
        multipleCards.push(item);
      }
    });
    return multipleCards;
  };

  return (
    <div
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      } safeAreaPaddingExceptBottomBackground`}
    >
      <Page
        title={t("myTradingPhotoCardsPage.pageName")}
        showBackButton={true}
        themeColor={"white"}
        showTitle={true}
        navigationElement={
          <Box
            className="!bg-secondary-light dark:!bg-secondary-dark !text-onSecondary-light dark:!text-onSecondary-dark"
            style={buttonStyle}
            onClick={() => navigateToAddTradingPhotoCard()}
          >
            {t("addTradingPhotoCard.addButton")}
          </Box>
        }
      >
        <BlacklistManagerBar />
        <Tabs
          isFitted
          index={parseInt(tabIndex)}
          paddingTop={"2px"}
          borderColor={"transparent"}
          colorScheme="primary"
          onChange={handleTabsChange}
        >
          <TabList
            className="border-b-[1px] !border-surface-light dark:!border-surface-dark"
            marginX={"-24px"}
            paddingX={"16px"}
            borderBottom={"1px"}
          >
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("myPhotoCards.exchangingPhotoCardsTabName")}
            </Tab>
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("myPhotoCards.sellingPhotoCardsTabName")}
            </Tab>
          </TabList>
          <TabPanels style={{ paddingBottom: "120px", paddingTop: "16px" }}>
            {
              <ListRenderer
                data={tradingPhotoCardWithQuantity(tradingPhotoCards)}
                skeletonComponent={
                  <GridSkeletons
                    gridSize={1}
                    skeletonSize={5}
                    skeletonHeight={"128px"}
                  />
                }
                emptyComponent={
                  <EmptyCase
                    emoji={"😓"}
                    description={
                      tabIndex === 0
                        ? t("TradingUserProfilePage.exchangeEmptyCards")
                        : t("TradingUserProfilePage.saleEmptyCards")
                    }
                    props={{ marginTop: "128px" }}
                  />
                }
                renderList={(data) =>
                  data.map((t, index) => {
                    return (
                      <div
                        key={index}
                        style={{ paddingBottom: "8px", paddingTop: "8px" }}
                      >
                        <MyTradingPhotoCard
                          key={t.tradingPhotoCardId}
                          tradingPhotoCard={t}
                          onChangedItem={onChangedItem}
                          onDeletedItem={onDeletedItem}
                          onCompletedItem={onCompletedItem}
                          onToggledItem={onToggledItem}
                          confirmed={true}
                          hideToggle={true}
                          isShowBoxButton={true}
                        />
                      </div>
                    );
                  })
                }
              />
            }
          </TabPanels>
        </Tabs>
      </Page>
      {/* Toggle To ExchangeCategory Dialog */}
      <CommonDialog
        isOpen={isOpenExchangeDialog}
        onClose={onCloseDialog}
        title={t("myPhotoCards.chatRoomExchangeDialogTitle")}
        onClickDialogButton={() =>
          onChangedItem(toggleTradingPhotoCard(selectedTradingPhotoCard))
        }
      />

      {/* Toggle To SaleCategory Dialog */}
      <CommonDialog
        isOpen={isOpenSaleDialog}
        onClose={onCloseDialog}
        title={t("myPhotoCards.chatRoomSaleDialogTitle")}
        description={t("myPhotoCards.chatRoomSaleDialogDescription")}
        children={
          <PrimaryInput
            defaultValue={null}
            placeholder={t("myPhotoCards.chatRoomSaleDialogInputPlaceholder")}
            onChangeText={(e) => setPrice(e.target.value)}
            style={{ marginTop: "16px" }}
          />
        }
        onClickDialogButton={() =>
          onChangedItem(toggleTradingPhotoCard(selectedTradingPhotoCard))
        }
      />
    </div>
  );
}
