import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useNotificationsCountQuery from "../../components/api/queries/useNotificationsCountQuery";
import { ReactComponent as BellIcon } from "../../icons/bell.svg";

export const NotificationBadge = () => {
  const navigate = useNavigate();

  const unreadCount = useNotificationsCountQuery();

  const clickedNotification = () => {
    navigate("/notifications");
  };

  return (
    <Box
      className="bg-secondary-light dark:bg-secondary-dark"
      width={"33px"}
      height={"33px"}
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={"full"}
      onClick={() => clickedNotification()}
    >
      <BellIcon
        className="fill-onSecondary-light dark:fill-onSecondary-dark"
        width={19}
        height={19}
      />
      {unreadCount > 0 && (
        <Box
          className="absolute right-[-4px] top-[-4px] text-pure-white bg-[#FF2E37]"
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius="full"
          fontSize={"10px"}
          width="16px"
          height="16px"
        >
          {unreadCount}
        </Box>
      )}
    </Box>
  );
};
