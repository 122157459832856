import styled, { keyframes } from "styled-components";
import { ReactComponent as QuestionIcon } from "../../icons/question.circle.svg";
import { useState } from "react";
import MyHeartBottomSheet from "../bottomSheet/MyHeartBottomSheet";

const rotateGradientBorder = keyframes`
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
`;

const GradientBorderBox = styled.div`
  width: 100%;
  background: linear-gradient(
        99.67deg,
        #e441dd 0%,
        #4987ff 50%,
        #e102cf 100%,
        #5c3dfa 200%
      )
      border-box,
    linear-gradient(#fff, #fff) padding-box;
  background-size: 200% auto;
  animation: ${rotateGradientBorder} 3s linear infinite;
  border: 1.5px solid transparent;
  border-radius: 16px;
  padding: 1px;
  overflow: auto;
`;

export const MyHeartBox = () => {
  const [isShowBottomSheet, setIsShowBottomSheet] = useState(false);

  const handleBoxClick = () => {
    setIsShowBottomSheet(true);
  };

  const closeBottomSheet = () => {
    setIsShowBottomSheet(false);
  };

  return (
    <GradientBorderBox className="mt-4" onClick={handleBoxClick}>
      <section className="flex justify-between py-[18px] px-6 rounded-[15px] bg-background-light dark:bg-background-dark">
        <div className="flex gap-1 items-center">
          <p className="text-[13px] text-main-light-2 dark:text-main-dark-2">
            나의 하트
          </p>
          <span className="text-[13px] text-main-light-2 dark:text-main-dark-2 font-bold">
            2,424
          </span>
          <QuestionIcon className="fill-sub-light-3 dark:fill-sub-dark-3" />
        </div>
        <div className="rounded-[30px] bg-secondary-light dark:bg-secondary-dark py-1 px-[7px]">
          <p className="text-[11px] text-primary-light dark:text-primary-dark font-semibold">
            Coming Soon!
          </p>
        </div>
      </section>

      <MyHeartBottomSheet
        isShowBottomSheet={isShowBottomSheet}
        onClose={closeBottomSheet}
      />
    </GradientBorderBox>
  );
};
