import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  fonts: {
    heading: `'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "PingFang SC", "PingFang TC", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`,
    body: `'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "PingFang SC", "PingFang TC", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif`,
  },
  colors: {
    background: {
      light: "#FFFFFF",
      dark: "#0D0E12",
    },
    backgroundElevated: {
      light: "#FFFFFF",
      dark: "#151619",
    },
    surface: {
      light: "rgba(0, 0, 0, 0.04)",
      dark: "rgba(255, 255, 255, 0.06)",
    },
    pure: {
      black: "#222222",
      white: "#FFFFFF",
    },
    placeholder: {
      light: "rgba(0, 0, 0, 0.2)",
      dark: "rgba(255, 255, 255, 0.2)",
    },
    primary: {
      light: "#5C3DFA",
      dark: "#674AFC",
    },
    onPrimary: {
      light: "#FFFFFF",
      dark: "#FFFFFF",
    },
    secondary: {
      light: "rgba(92, 61, 250, 0.06)",
      dark: "rgba(44, 42, 70, 1)",
    },
    onSecondary: {
      light: "#5C3DFA",
      dark: "#8870FF",
    },
    theirty: {
      light: "#DCD6FA",
      dark: "#2C2A46",
    },
    onTheirty: {
      light: "#B2A3FF",
      dark: "#504D7F",
    },
    gray: {
      500: "#666666",
    },
    theme: {
      light: {
        gray: {
          0: "#FFFFFF",
          50: "#F6F6F6",
          100: "#E7E7E7",
          200: "#CBCBCB",
          300: "#B9B9B9",
          400: "#AAAAAA",
          500: "#9A9A9A",
          600: "#888888",
          700: "#666666",
          800: "#444444",
          900: "#222222",
          1000: "#000000",
        },
      },
      dark: {
        gray: {
          0: "#FFFFFF",
          50: "#FAFAFA",
          100: "#CFCFD0",
          200: "#AAABAC",
          300: "#7A7A7D",
          400: "#5F5F63",
          500: "#4D4D50",
          600: "#303030",
          700: "#222225",
          800: "#151619",
          900: "#0D0E12",
          1000: "#000000",
        },
      },
    },
    textPrimary: {
      light: "#382599",
      dark: "#998CD9",
    },
    main: {
      light: {
        1: "#222222",
        2: "#444444",
        3: "#666666",
        4: "#888888",
      },
      dark: {
        1: "#FAFAFA",
        2: "#CFCFD0",
        3: "#AAABAC",
        4: "#7A7A7D",
      },
    },
    sub: {
      light: {
        1: "#AAAAAA",
        2: "#B9B9B9",
        3: "#CBCBCB",
      },
      dark: {
        1: "#5F5F63",
        2: "#4D4D50",
        3: "#303030",
      },
    },
    disabled: {
      light: "#E7E7E7",
      dark: "#303030",
    },
    onDisabled: {
      light: "#B9B9B9",
      dark: "#4D4D50",
    },
    separator: {
      light: {
        1: "#F6F6F6",
        2: "#E7E7E7",
      },
      dark: {
        1: "#303030",
        2: "#151619",
      },
    },
    verified: "rgba(0, 178, 255, 1)",
  },
  components: {
    Menu: {
      parts: ["list", "item"],
      baseStyle: {
        list: {
          _dark: {
            bg: "backgroundElevated.dark",
          },
          _light: {
            bg: "backgroundElevated.light",
          },
        },
        item: {
          _dark: {
            bg: "backgroundElevated.dark",
            _hover: {
              bg: "secondary.light",
            },
            _active: {
              bg: "primary.dark",
            },
          },
          _light: {
            bg: "backgroundElevated.light",
            _hover: {
              bg: "secondary.light",
            },
            _active: {
              bg: "primary.light",
            },
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _dark: {
            bg: "rgba(0,0,0,0.5)t",
            _checked: {
              bg: "primary.dark",
            },
          },
          _light: {
            bg: "#CBD5E0",
            _checked: {
              bg: "primary.light",
            },
          },
        },
        thumb: {
          _dark: {
            bg: "rgba(0,0,0,0.5)",
          },
          _light: {
            bg: "white",
          },
        },
      },
    },
    Drawer: {
      parts: ["dialog", "header", "body", "footer"],
      baseStyle: {
        dialog: {
          _dark: {
            bg: "backgroundElevated.dark",
          },
          _light: {
            bg: "backgroundElevated.light",
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _dark: {
            borderColor: "rgba(0,0,0,0.5)",
            _checked: {
              bg: "primary.dark",
              borderColor: "primary.dark",
            },
          },
          _light: {
            borderColor: "#CBD5E0",
            _checked: {
              bg: "primary.light",
              borderColor: "primary.light",
            },
          },
        },
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? "background.dark" : "background.light",
        color:
          props.colorMode === "dark"
            ? "theme.dark.gray.50"
            : "theme.light.gray.900",
      },
    }),
  },
});

export default customTheme;
