import React from "react";
import { Textarea } from "@chakra-ui/react";

export default function InputArea({ onChangeText, ...props }) {
  return (
    <Textarea
      className="text-main-light-2 dark:text-main-dark-2 bg-secondary-light dark:bg-secondary-dark
      border-[1px] border-white dark:border-black focus:border-white dark:focus:border-black
      placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark"
      fontSize={"17px"}
      fontWeight={600}
      borderRadius={"12px"}
      onChange={(e) => onChangeText(e.target.value)}
      {...props}
    />
  );
}
