import {
  SimpleGrid,
  Skeleton,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { getGridSize, getPhotoCardHeight } from "../../utils/photoCardSize";

export default function EmptyPhotoCardEventSkeletonV2({ gridType = "lg" }) {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");

  return (
    <Stack width={"100%"}>
      <Skeleton
        width={"60%"}
        height="20px"
        startColor={startColor}
        endColor={endColor}
        borderRadius="9px"
      />
      <SimpleGrid
        columns={getGridSize(gridType)}
        spacing={"4px"}
        marginTop={"14px!important"}
      >
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
      </SimpleGrid>
      <Skeleton
        width={"45%"}
        height="20px"
        startColor={startColor}
        endColor={endColor}
        borderRadius="9px"
        marginTop={"24px!important"}
      />
      <SimpleGrid
        columns={getGridSize(gridType)}
        spacing={"4px"}
        marginTop={"14px!important"}
      >
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height={getPhotoCardHeight(gridType)}
          width={"100%"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
      </SimpleGrid>
    </Stack>
  );
}
