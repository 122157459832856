import React, { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Image,
  MenuButton,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useColorMode,
  useColorModeValue,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { findIndex, first, isEmpty, sortBy } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { FixedSizeList } from "react-window";

import FilledButton from "../components/FilledButton";
import Page from "../components/Page";
import CheckPhotoCardBottomSheet from "../components/bottomSheet/CheckPhotoCardBottomSheet";
import EmptyPhotoCard from "../components/photocard/EmptyPhotoCard";
import HomeBottomNav from "../layout/HomeBottomNav";

import { t } from "i18next";
import EmptyPhotoCardEventSkeleton from "../components/skeletons/EmptyPhotoCardEventSkeleton";
import { ReactComponent as ArrowTriangleDownIcon } from "../icons/arrowtriangle.down.svg";
// import { ReactComponent as ProfileFillIcon } from "../icons/profile.fill.svg";
import useRefreshJwtQuery from "../components/api/queries/useRefreshJwtQuery";
import FabButton from "../components/buttons/FabButton";
import ArtistTextSelector from "../components/selectors/ArtistTextSelector";
import {
  getArtistId,
  getArtistIdAnyway,
  getMemberId,
  getMemberIdAnyway,
} from "../utils/etcUtils";
import { queryGet } from "../utils/queryClient";
import saveData from "../utils/saveData";
import { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";

export default function ArtistHomePage() {
  const { colorMode } = useColorMode();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isCheckPhotoCardBottomSheetOpen, setIsCheckPhotoCardBottomSheetOpen] =
    useState(false);
  const artistId = getArtistIdAnyway(selectedArtist) || getArtistId();

  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");
  const primaryStartColor = useColorModeValue(
    "secondary.light",
    "secondary.dark"
  );
  const primaryEndColor = useColorModeValue("theirty.light", "theirty.dark");

  const artists = useArtistQueryLikedOnly();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const authToken = useRefreshJwtQuery();
  if (authToken?.jwt) {
    saveData("token", JSON.stringify(authToken?.jwt));
  }

  const changedMember = (member) => {
    saveData("memberId", getMemberIdAnyway(member));
    setSelectedMember(member);
  };

  const noticeQueryParams = new URLSearchParams();
  noticeQueryParams.append("artist_id", artistId);
  const { data: notices } = useQuery(
    ["notices", noticeQueryParams.toString()],
    () => queryGet(`/notices?` + noticeQueryParams),
    {
      staleTime: 0,
    }
  );

  const { data: artist } = useQuery(
    ["artist", artistId],
    () => queryGet(`/artists/${artistId}`),
    {
      enabled: !!artistId,
      select: (data) => data.artist,
      staleTime: 0,
    }
  );

  const { data: userProfile } = useQuery(
    ["userProfile", artistId],
    () => queryGet(`/artists/${artistId}/user_profile`),
    {
      enabled: !!artistId,
      staleTime: 0,
    }
  );

  // #region 유저 프로필 로딩
  // const userProfileQueryParams = new URLSearchParams();
  // const { data: artistUserProfile } = useQuery(
  //   ["userProfiles", params.artistId, userProfileQueryParams.toString()],
  //   () => queryGet(`/artists/${params.artistId}/user_profiles?` + userProfileQueryParams),
  //   {
  //     enabled: !!params.artistId,
  //     staleTime: 0,
  //   },
  // );
  // #endregion

  useEffect(() => {
    const memberId = getMemberId();
    if (memberId && !isEmpty(userProfile?.likedMembers)) {
      const foundMemberIndex = findIndex(
        userProfile.likedMembers,
        (member) => getMemberIdAnyway(member) === memberId
      );
      if (foundMemberIndex >= 0) {
        return setSelectedMember(userProfile.likedMembers[foundMemberIndex]);
      }
    }
    setSelectedMember(first(userProfile?.likedMembers));
  }, [userProfile]);

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards`
      ),
    {
      enabled: !!selectedMember,
      staleTime: 0,
    }
  );

  const navigatePhotoCardDetailPage = (photoCard, event) => {
    navigate(`/photo_cards/${photoCard.photoCardId}`);
  };

  const onConfirmedCollectPhotoCard = () => {
    setIsCheckPhotoCardBottomSheetOpen(false);
    invalidateQueries();
  };

  const navigatePhotoCardPage = (editable) => {
    navigate(`/artists/photo_cards_v2`);
  };

  const hasCollectedPhotoCardsInEvent = (e) => {
    return findIndex(e.photoCards, (p) => p.collectedCount > 0) > -1;
  };

  const collectedPhotoCardEvent = () => {
    return photoCardByEvent?.events.filter(
      (e) => hasCollectedPhotoCardsInEvent(e) > 0
    );
  };

  const invalidateQueries = () => {
    queryClient.invalidateQueries("userProfile");
    queryClient.invalidateQueries("artistPhotoCards");
  };

  const ratioHeight = () => {
    if (window.innerHeight < 680) return 1;
    if (window.innerHeight < 900) return 1.7;
    return 3;
  };

  const onChangeArtist = (artist) => {
    saveData("artist", artist.artistId);
    queryClient.clear();
    navigate(`/feed`);
  };

  return (
    <div
      className={`${
        colorMode === "light" ? "homeBackground" : "darkHomeBackground"
      } safeAreaPaddingExceptBottomBackground`}
    >
      {artist && (
        <Page
          title={t("artistHomePage.pageName", { artist: artist?.name })}
          showBackButton={false}
          themeColor={colorMode === "dark" ? "#0D0E12" : "#C6DBF8"}
          userProfile={userProfile}
        >
          <Stack spacing={3} paddingTop={"13px"}>
            {
              <Flex>
                <Text fontSize={"26px"} fontWeight={700} color={"#222"}>
                  포카판
                </Text>
                <Spacer />
              </Flex>
            }
            {
              <HStack marginTop={"26px!important"}>
                {notices?.map((notice) => {
                  return (
                    <Box
                      key={notice.noticeId}
                      maxW="sm"
                      bg="white"
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      paddingX={"24px"}
                      paddingY={"22px"}
                      borderColor={"rgba(255,255,255,0.7)"}
                      style={{
                        background:
                          "linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%)",
                        boxShadow:
                          "0px 4px 10px rgba(0, 0, 0, 0.04), inset 0px 0px 24px rgba(255, 255, 255, 0.3)",
                        backdropFilter: "blur(20px)",
                        borderRadius: "30px",
                      }}
                      onClick={() => (window.location.href = notice.linkUrl)}
                    >
                      <VStack spacing={0} align="start">
                        <Badge
                          fontSize={"11px"}
                          paddingX={"7px"}
                          paddingY={"2px"}
                          borderRadius={"4px"}
                          style={{
                            backgroundColor: "#5C3DFA",
                            color: "white",
                          }}
                        >
                          {notice.noticeType === "notice"
                            ? "공지"
                            : notice.noticeType}
                        </Badge>
                        <Text paddingTop={"8px"} as="b" lineHeight={"20px"}>
                          {notice.title}
                        </Text>
                        <Text
                          paddingTop={"4px"}
                          fontSize={"12px"}
                          lineHeight={"16px"}
                        >
                          {notice.description}
                        </Text>
                      </VStack>
                    </Box>
                  );
                })}
              </HStack>
            }
            {userProfile ? (
              <Flex paddingTop={"22px"} alignItems="center">
                <span>
                  <Text fontSize="17px" as="b">
                    {t("artistHomePage.title")}
                  </Text>
                  <Text
                    fontSize="17px"
                    color={"primary"}
                    as="b"
                    paddingLeft={"3px"}
                  >
                    {t("photoCardCount", {
                      count: userProfile?.collectedPhotoCardsCount,
                    })}
                  </Text>
                </span>
                <Spacer />
                {
                  // #region 아티스트 선택
                }
                <Box
                  className="text-center"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textColor="primary"
                  backgroundColor="secondary.light"
                  borderRadius="32px"
                  lineHeight="17px"
                  fontSize="14px"
                  fontWeight={500}
                  height={"33px"}
                >
                  <ArtistTextSelector
                    artists={artists}
                    onSelected={setSelectedArtist}
                    customTextAreaElementMultiple={(artist) => (
                      <MenuButton
                        as={Button}
                        width={"max-content"}
                        py={"8px"}
                        px={"12px"}
                        borderRadius="32px"
                        bgColor={"transparent"}
                        _hover={{ bgColor: "transparent" }}
                        _active={{ bgColor: "secondary.800" }}
                        rightIcon={
                          <div
                            style={{
                              marginLeft: "-0.5rem",
                              paddingLeft: "4px",
                            }}
                          >
                            <ArrowTriangleDownIcon
                              width={"14px"}
                              height={"14px"}
                              fill={"#5C3DFA"}
                            />
                          </div>
                        }
                      >
                        <Text fontSize={"14px"} fontWeight={500}>
                          {artist?.shortName || artist?.name}
                        </Text>
                      </MenuButton>
                    )}
                    customTextAreaElementSingle={(_) => <></>}
                    customExtraDropdownMenus={[
                      {
                        name: "💖 나의 아티스트",
                        onClick: () => {
                          navigate("/artists/manage");
                        },
                      },
                    ]}
                  />
                </Box>
                {
                  // #endregion
                }
              </Flex>
            ) : (
              <Skeleton
                marginTop={"30px!important"}
                height="14px"
                width={"100px"}
                startColor={startColor}
                endColor={endColor}
                borderRadius={"8px"}
              />
            )}
          </Stack>
          {!userProfile && (
            <HStack marginTop={"24px!important"}>
              <Skeleton
                height="33px"
                width="47px"
                borderRadius={"32px"}
                startColor={primaryStartColor}
                endColor={primaryEndColor}
              />
              <Skeleton
                height="33px"
                width="47px"
                borderRadius={"32px"}
                startColor={startColor}
                endColor={endColor}
              />
              <Skeleton
                height="33px"
                width="47px"
                borderRadius={"32px"}
                startColor={startColor}
                endColor={endColor}
              />
              <Skeleton
                height="33px"
                width="47px"
                borderRadius={"32px"}
                startColor={startColor}
                endColor={endColor}
              />
            </HStack>
          )}
          {userProfile?.collectedPhotoCardsCount > 0 ? (
            userProfile?.likedMembers?.length > 1 ? (
              <Wrap spacing={"8px"} paddingY="10px">
                {userProfile?.likedMembers?.map((member) => {
                  return (
                    <FilledButton
                      key={getMemberIdAnyway(member)}
                      minWidth={"37px"}
                      minHeight={"33px"}
                      paddingX={"12px"}
                      paddingY={"8px"}
                      borderRadius={"32px"}
                      fontSize={"14px"}
                      text={member.name}
                      fontWeight={500}
                      onClicked={() => changedMember(member)}
                      filled={
                        getMemberIdAnyway(selectedMember) ===
                        getMemberIdAnyway(member)
                      }
                    />
                  );
                })}
              </Wrap>
            ) : (
              <></>
            )
          ) : (
            userProfile?.likedMembers?.length <= 0 && (
              <EmptyPhotoCard
                artistId={getArtistIdAnyway(artist)}
                likedMembers={userProfile?.likedMembers}
              />
            )
          )}
          <div>
            {!userProfile && (
              <div style={{ marginTop: "30px" }}>
                <EmptyPhotoCardEventSkeleton />
              </div>
            )}
            {!isEmpty(collectedPhotoCardEvent()) ? (
              <FixedSizeList
                width="100%"
                height={280 * ratioHeight()}
                itemSize={140}
                itemCount={collectedPhotoCardEvent()?.length}
                style={{ paddingBottom: "120px" }}
                className="hiddenScrollBar"
              >
                {({ style, index }) => {
                  const e = collectedPhotoCardEvent()[index];
                  return (
                    <Stack
                      key={e?.event?.name}
                      marginBottom={"12px"}
                      style={style}
                    >
                      <Wrap
                        paddingTop={"10px"}
                        paddingBottom={"4px"}
                        align={"center"}
                        marginLeft={"-1px"}
                        spacing={0}
                      >
                        <WrapItem alignItems={"center"}>
                          <Text fontSize={"20px"} marginRight={"4px"}>
                            {e.event?.name?.slice(0, 2)}
                          </Text>
                          <Text fontSize={"13px"} fontWeight={500}>
                            {e.event?.name}
                          </Text>
                        </WrapItem>
                      </Wrap>
                      <div className="horizontalScroll hiddenScrollBar">
                        {sortBy(e.photoCards, (p) => {
                          return -p.collectedCount;
                        }).map((photoCard) => {
                          return (
                            <Image
                              key={photoCard.photoCardId}
                              src={photoCard.pictureUrl}
                              className={
                                photoCard.collectedCount > 0
                                  ? "grayscale-0"
                                  : "grayscale opacity-50"
                              }
                              height={"90px"}
                              width={"57px"}
                              borderRadius="8px"
                              onClick={() =>
                                navigatePhotoCardDetailPage(photoCard, e.event)
                              }
                            />
                          );
                        })}
                      </div>
                    </Stack>
                  );
                }}
              </FixedSizeList>
            ) : (
              userProfile?.likedMembers &&
              photoCardByEvent && (
                <EmptyPhotoCard
                  artistId={getArtistIdAnyway(artist)}
                  likedMembers={userProfile?.likedMembers}
                />
              )
            )}
            <FabButton
              icon="showAllCards"
              text="모두 보기"
              marginRight={"-8px"}
              onClickFabButton={() => navigatePhotoCardPage(false)}
            />
          </div>
        </Page>
      )}

      <HomeBottomNav tabIndex={0} artistId={getArtistIdAnyway(artist)} />

      <CheckPhotoCardBottomSheet
        artist={artist}
        isCheckPhotoCardBottomSheetOpen={isCheckPhotoCardBottomSheetOpen}
        setIsCheckPhotoCardBottomSheetOpen={setIsCheckPhotoCardBottomSheetOpen}
        onConfirmedCollectPhotoCard={onConfirmedCollectPhotoCard}
      />
    </div>
  );
}
