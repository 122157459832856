import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";

import Sheet from "react-modal-sheet";
import { SimpleGrid, Text } from "@chakra-ui/react";

import { queryGet } from "../../utils/queryClient";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import ArtistPhotoCardsPage from "../../pages/ArtistPhotoCardsPage";
import { useNavigate } from "react-router-dom";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import { t } from "i18next";
import { first } from "lodash";
import { getArtistIdAnyway, getMemberIdAnyway } from "../../utils/etcUtils";

export default function CheckPhotoCardBottomSheet({
  artist,
  isCheckPhotoCardBottomSheetOpen,
  setIsCheckPhotoCardBottomSheetOpen,
  onConfirmedCollectPhotoCard,
}) {
  const ref = useRef();
  const navigate = useNavigate();

  const [selectedMember, setSelectedMember] = useState();

  const { data: userProfile } = useQuery(
    ["userProfile"],
    () => queryGet(`/artists/${getArtistIdAnyway(artist)}/user_profile`),
    {
      enabled: !!artist && isCheckPhotoCardBottomSheetOpen,
    }
  );

  useEffect(() => {
    setSelectedMember(first(userProfile?.likedMembers));
  }, [userProfile]);

  const { data: photoCards } = useQuery(
    [
      "artistPhotoCards",
      getArtistIdAnyway(artist),
      getMemberIdAnyway(selectedMember),
    ],
    () =>
      queryGet(
        `/artists/${getArtistIdAnyway(artist)}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards`
      ),
    {
      enabled:
        !!getArtistIdAnyway(artist) &&
        !!selectedMember &&
        isCheckPhotoCardBottomSheetOpen,
      select: (r) => r?.events?.flatMap((e) => e.photoCards),
    }
  );

  return (
    <Sheet
      ref={ref}
      isOpen={isCheckPhotoCardBottomSheetOpen}
      onClose={() => setIsCheckPhotoCardBottomSheetOpen(false)}
      springConfig={SPRING_CONFIG}
    >
      <Sheet.Container
        className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <Sheet.Header className="py-4 px-4">
          <SimpleGrid columns={3} alignItems="center">
            <CloseIcon
              className="fill-black dark:fill-white"
              onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)}
            />
            <Text className="flex justify-center" as="b">
              {t("checkPhotoCardBottomSheet.title")}
            </Text>
            <div className="flex justify-end">
              <Text
                fontSize="sm"
                onClick={() =>
                  navigate("/report-photo-card/select-artist", {
                    state: {
                      artist: artist,
                      members: userProfile?.likedMembers,
                      showBackButton: true,
                      navBarVisible: false,
                    },
                  })
                }
              >
                {t("checkPhotoCardBottomSheet.report")}
              </Text>
            </div>
          </SimpleGrid>
        </Sheet.Header>
        <Sheet.Content disableDrag={true}>
          <ArtistPhotoCardsPage
            componentData={{
              artistId: getArtistIdAnyway(artist),
              members: userProfile?.likedMembers,
              selectedPhotoCards: photoCards?.filter(
                (p) => p?.collectedCount > 0
              ),
              selectedMember: selectedMember,
            }}
            onConfirmedCollectPhotoCard={onConfirmedCollectPhotoCard}
          />
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)}
      />
    </Sheet>
  );
}
