import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  GridItem,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

import { ReactComponent as CardFillIcon } from "../icons/card.fill.svg";
import { ReactComponent as CardIcon } from "../icons/card.svg";
import { ReactComponent as CartFillIcon } from "../icons/cart.fill.svg";
import { ReactComponent as CartIcon } from "../icons/cart.svg";
import { ReactComponent as FeedFillIcon } from "../icons/feed.fill.svg";
import { ReactComponent as FeedIcon } from "../icons/feed.svg";
import { ReactComponent as MatchingFillIcon } from "../icons/matching.fill.svg";
import { ReactComponent as MatchingIcon } from "../icons/matching.svg";

import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import useUserQuery from "../components/api/queries/useUserQuery";
import { ReactComponent as ProfileFillIcon } from "../icons/profile.fill.svg";
import { ReactComponent as ProfileIcon } from "../icons/profile.line.svg";

const ENABLED_COLOR = "fill-primary-light dark:fill-primary-dark";
const DISABLED_COLOR = "fill-main-light-4 dark:fill-main-light-4";

export default function HomeBottomNav({ tabIndex }) {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabIndex || 0);

  const user = useUserQuery();

  const isKorean = () => {
    return user && user.country === "KR";
  };

  useEffect(() => {
    setSelectedTabIndex(tabIndex);
  }, [tabIndex]);

  const setEnabledColor = (isEnabled) => {
    return isEnabled
      ? "text-primary-light dark:text-primary-dark"
      : "text-main-light-4 dark:text-main-dark-4";
  };

  const TABS = [
    {
      index: 0,
      inActiveIcon: (
        <CardIcon className={DISABLED_COLOR} width={24} height={24} />
      ),
      activeIcon: (
        <CardFillIcon className={ENABLED_COLOR} width={24} height={24} />
      ),
      name: t("tabs.collections"),
    },
    {
      index: 1,
      inActiveIcon: (
        <MatchingIcon className={DISABLED_COLOR} width={24} height={24} />
      ),
      activeIcon: (
        <MatchingFillIcon className={ENABLED_COLOR} width={24} height={24} />
      ),
      name: t("tabs.tradingPhotoCard"),
    },
    {
      index: 2,
      inActiveIcon: (
        <FeedIcon className={DISABLED_COLOR} width={24} height={24} />
      ),
      activeIcon: (
        <FeedFillIcon className={ENABLED_COLOR} width={24} height={24} />
      ),
      name: t("tabs.feed"),
    },
    {
      index: 3,
      inActiveIcon: (
        <CartIcon className={DISABLED_COLOR} width={24} height={24} />
      ),
      activeIcon: (
        <CartFillIcon className={ENABLED_COLOR} width={24} height={24} />
      ),
      name: t("tabs.store"),
    },
    {
      index: 4,
      inActiveIcon: (
        <ProfileIcon className={DISABLED_COLOR} width={24} height={24} />
      ),
      activeIcon: (
        <ProfileFillIcon className={ENABLED_COLOR} width={24} height={24} />
      ),
      name: t("tabs.profile"),
    },
  ];

  const onClickTabButton = (index) => {
    setSelectedTabIndex(index);
    if (selectedTabIndex === index) {
      return window.scrollTo({ top: 0, behavior: "smooth" });
    }
    switch (index) {
      case 0:
        return navigate(`/home`);
      case 1:
        return navigate(`/tradings/photo_cards`);
      case 2:
        return navigate(`/feed`);
      case 3: {
        const baseUrl = isKorean()
          ? "https://store.pocapan.com"
          : "https://storeglobal.pocapan.com";

        return (window.location.href = `${baseUrl}/?pocapan_user_id=${user?.userId}`);
      }
      case 4:
        return navigate(`/my`);
      default:
        return console.log("Nothing. Stay current page");
    }
  };

  return (
    <Box
      maxW={"xl"}
      className={`${
        colorMode === "dark" ? "darkBottomNavbar" : "bottomNavbar"
      } safeAreaPaddingBottom`}
      style={{ filter: "drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1))" }}
    >
      <Grid
        className="bg-background-light dark:bg-background-dark"
        templateColumns={`repeat(${TABS.length}, 1fr)`}
        alignItems={"center"}
        paddingStart="10px"
        paddingEnd="10px"
      >
        {TABS.map((tab) => {
          return (
            <GridItem
              className="hover:bg-background-light dark:hover:bg-background-dark"
              key={tab.index}
              paddingTop={"13px"}
              paddingBottom={"10px"}
              colSpan={1}
              onClick={() => onClickTabButton(tab.index)}
              style={{
                WebkitTapHighlightColor: "rgba(0,0,0,0)",
                outline: "none",
              }}
            >
              <Stack align="stretch" alignItems={"center"} spacing={"2px"}>
                {selectedTabIndex === tab.index
                  ? tab.activeIcon
                  : tab.inActiveIcon}
                <Text
                  className={setEnabledColor(selectedTabIndex === tab.index)}
                  fontSize={"11px"}
                  fontWeight={500}
                >
                  {tab.name}
                </Text>
              </Stack>
            </GridItem>
          );
        })}
      </Grid>
    </Box>
  );
}
