import React from "react";
import { Box, Center } from "@chakra-ui/react";

export default function DisabledLineButton({ text, disabled, ...props }) {
  return (
    <Box
      className="text-center border border-disabled-light dark:border-disabled-dark text-sub-light-2 dark:text-sub-dark-2"
      borderRadius="52px"
      lineHeight="17px"
      paddingX="12px"
      paddingY="8px"
      fontWeight={500}
      fontSize="14px"
      {...props}
    >
      <Center>{text}</Center>
    </Box>
  );
}
