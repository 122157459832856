import { Text, VStack } from "@chakra-ui/react";
import FilledButton from "../FilledButton";

const emojiStyle = {
  fontSize: "50px",
};

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
  wordBreak: "keep-all",
  lineHeight: "22px",
};

const descriptionStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
};

export default function EmptyCase({
  emoji,
  title,
  description,
  buttonText,
  onClickButton,
  props,
}) {
  return (
    <VStack
      marginTop={"128px"}
      alignContent={"center"}
      className="text-center"
      style={{ ...props }}
    >
      <Text style={emojiStyle}>{emoji}</Text>
      {
        <Text
          className="text-main-light-2 dark:text-main-dark-2"
          style={titleStyle}
        >
          {title}
        </Text>
      }
      {description && (
        <Text
          className="text-main-light-4 dark:text-main-dark-4"
          style={descriptionStyle}
        >
          {description}
        </Text>
      )}
      {buttonText && (
        <div style={{ paddingTop: "30px" }}>
          <FilledButton
            className="text-main-light-2 dark:text-main-dark-2 bg-surface-light dark:bg-surface-dark border border-surface-light dark:border-surface-dark"
            paddingX={"18px"}
            paddingY={"11px"}
            filled={false}
            fontWeight={600}
            fontSize={"14px"}
            text={buttonText}
            borderWidth={"1px"}
            onClicked={onClickButton}
          />
        </div>
      )}
    </VStack>
  );
}
