import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Stack,
  Text,
  FormControl,
  Input,
  Center,
  Box,
  useColorMode,
} from "@chakra-ui/react";
import { validate } from "react-email-validator";
import baseUrl from "../utils/baseUrl";
import Page from "../components/Page";
import { t } from "i18next";
import saveData from "../utils/saveData";
import _ from "lodash";
import { getArtistId } from "../utils/etcUtils";

async function requestVerificationCode(email) {
  return fetch(baseUrl() + "/auth/request_verification_code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  }).then((data) => data.json());
}

async function loginWithCode(email, verificationCode) {
  return fetch(baseUrl() + "/auth/login_with_verification_code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      verification_code: verificationCode,
    }),
  }).then((data) => data.json());
}

export default function LoginWithCodePage() {
  const { state } = useLocation();
  const { colorMode } = useColorMode();
  const [input, setInput] = useState("");
  // const [timerId, setTimerId] = useState();
  // const [countDownSecond, setCountDownSecond] = useState(300);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if(countDownSecond >= 300){
  //     const timerId = setInterval(()=> countDownSecond > 0 ? setCountDownSecond(c => c - 1) : setCountDownSecond(0), 1000);
  //     setTimerId(timerId);
  //   }
  // }, [countDownSecond]);

  const handleInputChange = (e) => setInput(e.target.value);
  const isError = input && !validate(input);

  const postVerificationCode = async () => {
    const response = await loginWithCode(state.email, input);
    if (response && response.success && response.jwt) {
      saveData("token", JSON.stringify(response.jwt));
      if (response.likedArtists.length > 0) {
        const localArtistId = getArtistId();
        var selectedArtistId =
          localArtistId || _.first(response.likedArtists).artistId;

        if (selectedArtistId !== localArtistId)
          saveData("artist", selectedArtistId);

        navigate("/welcome", {
          state: {
            redirectUrl: `/home`,
          },
        });
      } else {
        navigate(`/artists?isFirst=true`);
      }
    } else {
      alert(t("loginWithCodePage.verificationFailedAlert"));
    }
  };

  const reRequestVerificationCode = () => {
    requestVerificationCode(state.email).then((r) => {
      if (r.success) {
        alert(t("loginWithCodePage.successSendAlert"));
        // if(timerId) {
        //   clearInterval(timerId)
        // }
        // setCountDownSecond(300);
      } else {
        alert(t("loginWithCodePage.failedSendAlert"));
      }
    });
  };

  return (
    <Page title={""}>
      <Stack spacing={0}>
        <Text fontSize="2xl" fontWeight={700} lineHeight={"120%"}>
          {t("loginWithCodePage.title")}
        </Text>
      </Stack>
      <Text
        className="text-main-light-4 dark:text-main-dark-4"
        fontSize="15px"
        mt={2}
      >
        {t("loginWithCodePage.description", { email: state.email })}
      </Text>
      <FormControl isInvalid={isError} mt={5}>
        <Input
          className="bg-secondary-light dark:bg-secondary-dark
        hover:bg-secondary-light dark:hover:bg-secondary-dark"
          type="number"
          value={input}
          onChange={handleInputChange}
          variant="filled"
          borderRadius={"12px"}
          paddingY={6}
        />
      </FormControl>
      {/* <Flex className="justify-end pt-2 pr-1">
        <Text fontSize={"13px"} color={"#888888"}>{parseInt(countDownSecond / 60)}:{countDownSecond % 60 < 10 ? `0${countDownSecond % 60}` : countDownSecond % 60}</Text>
      </Flex> */}
      <Center mt={5}>
        <Text
          className="text-onSecondary-light dark:text-onSecondary-dark"
          fontSize={"13px"}
        >
          {t("loginWithCodePage.notReceivedMessage")}
          <span
            style={{ textDecoration: "underline", paddingLeft: "3px" }}
            onClick={() => reRequestVerificationCode()}
          >
            {t("loginWithCodePage.sendAgainMessage")}
          </span>
        </Text>
      </Center>
      <Center marginTop={"auto"}>
        <Box
          className={`text-white ${
            input !== ""
              ? "bg-primary-light dark:bg-primary-dark text-white"
              : "bg-disabled-light dark:bg-disabled-dark text-onDisabled-light dark:text-onDisabled-dark"
          }`}
          as="button"
          fontWeight={700}
          marginTop={10}
          paddingY={"16px"}
          disabled={input === ""}
          borderRadius={56}
          minWidth={"335px"}
          minHeight={"52px"}
          onClick={postVerificationCode}
        >
          {t("next")}
        </Box>
      </Center>
    </Page>
  );
}
