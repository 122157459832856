import React, { useEffect, useRef, useState } from "react";

import {
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import "react-tiny-fab/dist/styles.css";
import styled from "styled-components";

import { Helmet } from "react-helmet-async";
import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../components/bottomSheet/AddUserProfileV1BottomSheet";
import { PopupFabButton } from "../components/fab/PopupFabButton";
import ProfileAvatar from "../components/thumbnails/ProfileAvatar";
import ExchangesTabPanel from "../components/tradings/ExchangesTabPanel";
import SalesTabPanelV2 from "../components/tradings/SalesTabPanelV2";
import HomeBottomNav from "../layout/HomeBottomNav";
import { NotificationBadge } from "./badge/NotificationBadge";

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top));
  background-attachment: scroll;
  height: 100vh;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
`;

const activeTabStyle = (colorMode, theme) => ({
  fontWeight: 700,
  borderBottom: "3px solid",
  borderColor:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  color:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  borderBox: "",
});

const TRADING_CATEGORY = "trading";

export default function TradingPhotoCardPageV2() {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const fabWrapperRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(
    currentQueryParams.get("tabIndex") || 0
  );
  const [isShowProfileBottomSheet, setIsShowProfileBottomSheet] =
    useState(false);
  const [isShowTabBottomSheet, setIsShowTabBottomSheet] = useState(false);

  const user = useUserQuery();
  const tradingUserProfiles = useUserProfilesListQuery(TRADING_CATEGORY);

  useEffect(() => {
    if (tradingUserProfiles) {
      if (isEmpty(tradingUserProfiles)) {
        setIsShowProfileBottomSheet(true);
      } else if (tradingUserProfiles.length > 1) {
        navigate("/users/merge_profiles");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  };

  const onClickUserProfile = () => {
    if (isEmpty(tradingUserProfiles)) {
      setIsShowProfileBottomSheet(true);
    } else {
      navigate(
        `/trading/user_profile/${user.tradingUserProfile?.userProfileId}`
      );
    }
  };

  const onClickFabButton = (index) => {
    if (!user) return;

    if (user.tradingUserProfile) {
      navigate(`/tradings/add_trading_photo_cards?isExchange=${index === 1}`);
    } else {
      setIsShowProfileBottomSheet(true);
    }
  };

  const onShowBottomSheet = (isOpen) => {
    setIsShowTabBottomSheet(isOpen);
  };

  const isHideFabButton = () => {
    return isShowProfileBottomSheet || isShowTabBottomSheet;
  };

  return (
    <PageWrapper
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      }`}
    >
      <Helmet>
        <title>{t("tabs.tradingPhotoCard")}</title>
        <meta
          name="theme-color"
          content={colorMode === "dark" ? "#0D0E12" : "#FFFFFF"}
        />
      </Helmet>
      <Header user={user} onClickUserProfile={onClickUserProfile} />
      <Tabs
        isFitted
        index={parseInt(tabIndex)}
        borderColor={"transparent"}
        colorScheme="primary"
        onChange={handleTabsChange}
      >
        <TabList
          className="border-b !border-surface-light dark:!border-surface-dark"
          paddingX={"16px"}
          borderBottom={"1px"}
        >
          <Tab
            _active={""}
            _selected={activeTabStyle(colorMode, theme)}
            lineHeight={"19px"}
            paddingY={"13px"}
          >
            {t("tradingPhotoCardPage.salesTabName")}
          </Tab>
          <Tab
            _active={""}
            _selected={activeTabStyle(colorMode, theme)}
            lineHeight={"19px"}
            paddingY={"13px"}
          >
            {t("tradingPhotoCardPage.exchangeTabName")}
          </Tab>
        </TabList>
        {/* <Marquee style={marqueeStyle}>
          <Text
            align={"center"}
            className="text-center"
            fontSize={"12px"}
            fontWeight={500}
            color={"#FFF"}
            whiteSpace={"pre-wrap"}
          >
            {t("tradingPhotoCardPage.marqueeText")}
          </Text>
        </Marquee> */}
        <TabPanels style={{ paddingBottom: "12px", paddingTop: "16px" }}>
          <TabPanel padding={0}>
            {parseInt(tabIndex) === 0 && (
              <SalesTabPanelV2
                fabWrapperRef={fabWrapperRef}
                onShowBottomSheet={onShowBottomSheet}
              />
            )}
          </TabPanel>
          <TabPanel padding={0}>
            {
              parseInt(tabIndex) === 1 &&
              <ExchangesTabPanel fabWrapperRef={fabWrapperRef} onShowBottomSheet={onShowBottomSheet}/>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>
      {!isHideFabButton() && (
        <PopupFabButton
          buttonTexts={[
            t("TradingPhotoCardPageV2.sellPhotoCardButton"),
            t("TradingPhotoCardPageV2.exchangePhotoCardButton"),
          ]}
          onClickFabChildrenButton={onClickFabButton}
        />
      )}
      {isShowProfileBottomSheet && (
        <AddUserProfileV1BottomSheet
          title={t("tradingPhotoCardPage.addProfileBottomSheetTitle")}
          userProfile={user?.tradingUserProfile}
          isShowProfileBottomSheet={isShowProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowProfileBottomSheet}
        />
      )}
      <HomeBottomNav tabIndex={1} />
    </PageWrapper>
  );
}
const ProfileImageWrapper = styled.div`
  margin-left: 4px;
`;

const Header = ({ user, onClickUserProfile }) => {
  const userProfileImageUrl = (user) => {
    return (
      user?.tradingUserProfile?.thumbnailUrl ||
      user?.tradingUserProfile?.pictureUrl
    );
  };

  return (
    <Row style={{ padding: "12px 24px", alignItems: "center" }}>
      <Title className="text-main-light-1 dark:text-main-dark-1">
        {t("tradingPhotoCardPage.pageTitle")}
      </Title>
      <ProfileImageWrapper onClick={onClickUserProfile}>
        <ProfileAvatar
          profileImageUrl={userProfileImageUrl(user)}
          widthAndHeight={"24px"}
          svgWidthAndHeight={"18px"}
          padding={"3px"}
        />
      </ProfileImageWrapper>
      <Spacer />
      <NotificationBadge />
    </Row>
  );
};
