import React, { useRef, useState } from "react";

import {
  AbsoluteCenter,
  Image,
  Select,
  Spinner,
  Stack,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import Cropper from "react-perspective-cropper";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";
import ToastMessageBox from "../../components/alerts/ToastMessageBox";

import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import { LineButton } from "../../components/buttons/LineButton";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import { TagsList } from "../../components/photocard/TagsList";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import StepComponent from "../../components/steps/StepComponent";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as RefreshFillIcon } from "../../icons/refresh.fill.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import AddReport from "../../components/api/mutations/AddReport";

const DEFAULT_CV_PARAMS = {
  grayScale: false,
  th: false,
};

const ReportPhotoCardPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const photoCardId = currentQueryParams.get("photoCardId");

  const [isOpenCVLoading, setIsOpenCVLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [category, setCategory] = useState("");
  const [content, setContent] = useState("");
  const [dataUrl, setDataUrl] = useState(null);

  const cropperRef = useRef();

  const { openFilePicker, plainFiles, filesContent, clear } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  const reportData = {
    category: category,
    content: content,
    dataUrl: dataUrl,
  };

  React.useEffect(() => {
    if (filesContent.length > 0) {
      setIsOpenCVLoading(true);
      const interval = setInterval(() => {
        if (window.cv) {
          setIsOpenCVLoading(false);
          clearInterval(interval);
        }
      }, 100);
      // Load the selected image into the cropper
      setImageToCrop(filesContent[0].content);
    }
  }, [filesContent]);

  const convertBlobToDataURL = (blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setDataUrl(reader.result);
    };
    reader.readAsDataURL(blob);
  };

  const doCrop = async () => {
    try {
      const res = await cropperRef.current.done({
        preview: true,
        filterCvParams: DEFAULT_CV_PARAMS,
      });
      convertBlobToDataURL(res);
    } catch (e) {
      resetImage();
      toast({
        duration: 1500,
        render: () => <ToastMessageBox message={t("errorBoundary.title")} />,
      });
    }
  };

  const resetImage = () => {
    clear();
    setImageToCrop(null);
    setDataUrl(null);
  };

  const handleAddPhotoClick = () => {
    openFilePicker();
  };

  const handleSubmit = async () => {
    setIsUploading(true);

    const response = await AddReport({
      ...reportData,
      reportableId: photoCardId,
      reportableType: "PhotoCard",
    });

    setIsUploading(false);

    if (response.ok) {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("reportPhotoCardPage.reportDoneAlert")} />
        ),
      });
      setTimeout(() => {
        navigate(-1, { replace: true });
      }, 1000);
    }
  };

  return (
    <>
      {imageToCrop && isEmpty(dataUrl) ? (
        <RenderCropper
          cropperRef={cropperRef}
          imageToCrop={imageToCrop}
          dataUrl={dataUrl}
          resetImage={resetImage}
          // onChange={onChange}
          // onDragStop={onDragStop}
          // sendPhotoCard={sendPhotoCard}
          doCrop={doCrop}
          isOpenCVLoading={isOpenCVLoading}
        />
      ) : (
        <RenderReportForm
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          reportData={reportData}
          setReason={setContent}
          setCategory={setCategory}
          dataUrl={dataUrl}
          handleAddPhotoClick={handleAddPhotoClick}
          handleSubmit={handleSubmit}
        />
      )}
      {(isOpenCVLoading || isUploading) && (
        <AbsoluteCenter zIndex={9999}>
          <Spinner
            size="lg"
            className="text-primary-light dark:text-primary-dark"
          />
        </AbsoluteCenter>
      )}
    </>
  );
};

const PhotoCardContainer = styled.div`
  padding-top: calc(64px + env(safe-area-inset-top));
  padding-left: 24px;
  padding-right: 24px;
  flex-direction: row;
  display: flex;
`;

const ImageWrapper = styled.div`
  flex: none;
`;

const EventName = styled.p`
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const PhotoCardName = styled.div`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  text-align: left;
  margin-top: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  row-gap: 4px;
  flex-wrap: wrap;
`;

const REPORT_CATEGORIES = [
  "incorrect",
  "duplicated",
  "blurryOrWatermark",
  "unofficial",
  "other",
];

const PhotoCardSection = ({ photoCard }) => {
  if (isEmpty(photoCard)) {
    return (
      <div className="w-full">
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128} />
      </div>
    );
  } else {
    return (
      <PhotoCardContainer>
        <ImageWrapper>
          <Image
            key={photoCard.photoCardId}
            src={photoCard.largeThumbnailUrl || photoCard.pictureUrl}
            height={"120px"}
            maxWidth={"75px"}
            objectFit={"cover"}
            borderRadius="8px"
            boxShadow={
              "0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(0, 0, 0, 0.08)"
            }
          />
        </ImageWrapper>
        <Stack
          alignItems={"start"}
          justify={"center"}
          paddingLeft={"16px"}
          className="flex-grow"
        >
          <EventName className="text-main-light-2 dark:text-main-dark-2">
            {t("photoCardDetailPage.title", {
              memberName: photoCard.memberName,
              categoryName: photoCard.categoryName,
              categoryEmoji: photoCard.categoryEmoji,
            })}
          </EventName>
          <PhotoCardName className="text-main-light-3 dark:text-main-dark-3">
            {photoCard.eventName}
          </PhotoCardName>
          {/* <PhotoCardCategoryBadge>
            {`${photoCard.categoryEmoji} ${photoCard.categoryName}`}
          </PhotoCardCategoryBadge> */}
          {/* <EventName>{`${photoCard.eventName}`}</EventName> */}
          <TagsWrapper>
            <TagsList photoCard={photoCard} />
          </TagsWrapper>
        </Stack>
      </PhotoCardContainer>
    );
  }
};

const FormWrapper = styled.div`
  padding: 0px 24px;
`;

const INPUT_DEFAULT_STYLE = {
  fontSize: "16px",
  padding: "17px 16px",
  borderRadius: "12px",
  marginTop: "0px",
  fontWeight: 600,
};

// const INPUT_PLACEHOLDER_STYLE = {
//   color: "rgba(0, 0, 0, 0.20)",
// };

const RenderReportForm = ({
  reportData,
  currentStep,
  setCurrentStep,
  setReason,
  setCategory,
  handleAddPhotoClick,
  handleSubmit,
}) => {
  const toast = useToast();
  const location = useLocation();
  const navigate = useNavigate();
  const currentQueryParams = new URLSearchParams(location.search);
  const photoCard = usePhotoCardQuery(currentQueryParams.get("photoCardId"));

  const handleCategoryChange = (e) => {
    if (!isEmpty(e.target.value)) {
      setCurrentStep(2);
      setCategory(e.target.value);
    }
  };

  const handleReasonChange = (e) => {
    if (!isEmpty(e.target.value)) {
      setCurrentStep(2);
      setReason(e.target.value);
    }
  };

  return (
    <>
      <StickyHeader
        showBackButton={false}
        showTitle={true}
        alwaysShowTitle={true}
        title={t("reportPhotoCardPage.title")}
        leftContent={
          <CloseIcon
            className="fill-pure-black dark:fill-pure-white"
            width={"24px"}
            height={"24px"}
            onClick={() => navigate(-1)}
          />
        }
      />
      <PhotoCardSection photoCard={photoCard} />
      <StepComponent
        nextStep={1}
        currentStep={currentStep}
        title={t("reportPhotoCardPage.categoryTitle")}
        children={
          <FormWrapper>
            <Select
              className="text-primary-light dark:text-primary-dark !bg-secondary-light dark:!bg-secondary-dark"
              placeholder={t("reportPhotoCardPage.categoryTitle")}
              value={reportData.category}
              onChange={handleCategoryChange}
              borderRadius={"12px"}
              height={"54px"}
              fontSize={"17px"}
              border={"transparent"}
            >
              {REPORT_CATEGORIES.map((category) => {
                return (
                  <option value={category}>
                    {t(`reportPhotoCardPage.${category}`)}
                  </option>
                );
              })}
            </Select>
          </FormWrapper>
        }
      />

      <StepComponent
        nextStep={2}
        currentStep={currentStep}
        title={t("reportPhotoCardPage.contentTitle")}
        children={
          <FormWrapper>
            <Textarea
              className="hover:border-pure-white focus:border-pure-white dark:hover:border-pure-black hover:bg-secondary-light dark:hover:bg-secondary-dark bg-secondary-light dark:bg-secondary-dark placeholder-placeholder-light dark:placeholder-placeholder-dark"
              defaultValue={reportData.content}
              placeholder={t("reportPhotoCardPage.placeHolderContent")}
              style={INPUT_DEFAULT_STYLE}
              // FIXME : border-0px _hover={INPUT_HOVER_STYLE}
              onChange={handleReasonChange}
            />
          </FormWrapper>
        }
      />

      <StepComponent
        nextStep={3}
        currentStep={currentStep}
        title={t("reportPhotoCardPage.replacementPhotoTitle")}
        children={
          <FormWrapper>
            <LineButton
              text={t("ReportPhotoCardUploadPhotoPage.addPhotoCardButton")}
              paddingY={"16px"}
              onClick={handleAddPhotoClick}
              style={{ width: "100%" }}
            />
          </FormWrapper>
        }
      />

      {!isEmpty(reportData.dataUrl) && (
        <FormWrapper style={{ marginTop: "16px", justifyItems: "center" }}>
          <Image src={reportData.dataUrl} />
        </FormWrapper>
      )}

      {currentStep >= 2 && (
        <FormWrapper
          style={{ marginBottom: "calc(36px + env(safe-area-inset-bottom))" }}
        >
          <RegularSolidButton
            text={t("reportPhotoCardPage.reportDone")}
            onClick={handleSubmit}
            style={{ width: "100%", padding: "16px 24px", marginTop: "32px" }}
          />
        </FormWrapper>
      )}
    </>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

const CropperContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  border-radius: 7px;
`;

const ConfirmButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.29);
  padding-bottom: env(safe-area-inset-bottom);
`;

const RenderCropper = ({ cropperRef, imageToCrop, resetImage, doCrop }) => {
  return (
    <>
      <StickyHeader
        transparent={true}
        title={t("ReportPage.pageTitle")}
        leftContent={
          <RefreshFillIcon
            className="fill-pure-white dark:fill-pure-black"
            width={"24px"}
            height={"24px"}
            onClick={resetImage}
          />
        }
      />
      <Overlay className="bg-[rgba(0, 0, 0, 0.6)] dark:bg-[rgba(255, 255, 255, 0.6)]">
        <CropperContainer className="bg-background-light dark:bg-background-dark">
          <Cropper
            ref={cropperRef}
            image={imageToCrop}
            pointBgColor={LightColors.purewhite}
            pointBorder={""}
            lineColor={LightColors.purewhite}
            openCvPath={"/assets/opencv/opencv-3-4-13.min.js"}
            maxWidth={360}
            maxHeight={568}
          />
        </CropperContainer>
        <ConfirmButtonWrapper>
          <PrimaryButton
            marginY={"16px"}
            marginX={"24px"}
            paddingY={"16px"}
            text={t("confirm")}
            fontSize={"17px"}
            onClick={doCrop}
          />
        </ConfirmButtonWrapper>
      </Overlay>
    </>
  );
};

export default ReportPhotoCardPage;
