import React from "react";

import { SimpleGrid } from "@chakra-ui/react";
import { useQueryClient } from "react-query";

import "react-horizontal-scrolling-menu/dist/styles.css";

import { chunk, isEmpty, isNull, isUndefined } from "lodash";
import { Virtuoso } from "react-virtuoso";

import styled from "@emotion/styled";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyMatchedTradingPhotoCard from "./EmptyMatchedTradingPhotoCard";
import EmptyTradingPhotoCard from "./EmptyTradingPhotoCard";
import MatchedTargetTradingPhotoCard from "./MatchedTargetTradingPhotoCard";
import OnGoingMatchingTradingPhotoCard from "./OngoingMatchedTradingPhotoCard";
import { getMemberIdAnyway } from "../../utils/etcUtils";

const SkeletonWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`;

async function requestReMatching(memberId) {
  return fetch(baseUrl() + `/trading_photo_cards/rematching`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      member_id: memberId,
    }),
  }).then((data) => data.json());
}

export default function MatchedTabPanel({
  tradingPhotoCards,
  selectedTradingPhotoCard,
  matchedTradingPhotoCards,
  selectedMember,
  userProfileNickName,
}) {
  const queryClient = useQueryClient();

  const isOngoingMatching = () => {
    if (isEmpty(selectedTradingPhotoCard)) return false;
    return (
      isNull(selectedTradingPhotoCard.matchedCount) ||
      selectedTradingPhotoCard.matchedCount <= 0
    );
  };

  const onClickReMatch = async () => {
    const response = await requestReMatching(getMemberIdAnyway(selectedMember));
    if (response.success) {
      queryClient.invalidateQueries(["tradingPhotoCards"]);
      setTimeout(() => {
        queryClient.invalidateQueries(["matchedTradingPhotoCards"]);
      }, 1500);
    }
  };

  if (
    isUndefined(tradingPhotoCards) ||
    (selectedTradingPhotoCard?.category === "exchange" &&
      isUndefined(matchedTradingPhotoCards))
  ) {
    return (
      <SkeletonWrapper>
        <GridSkeletons
          gridSize={3}
          skeletonSize={3}
          skeletonHeight={"96px"}
          showText={true}
        />
      </SkeletonWrapper>
    );
  }

  if (isEmpty(tradingPhotoCards)) {
    return <EmptyTradingPhotoCard nickName={userProfileNickName} />;
  }

  return (
    <>
      {matchedTradingPhotoCards?.length > 0 ? (
        <div
          style={{ paddingBottom: "calc(56px + env(safe-area-inset-bottom))" }}
        >
          <Virtuoso
            useWindowScroll
            overscan={480}
            data={chunk(matchedTradingPhotoCards, 3)}
            style={{ height: "100px" }}
            itemContent={(index, chunkCards) => (
              <SimpleGrid columns={3} spacing={"7px"} marginBottom={"24px"}>
                {chunkCards.map((card) => {
                  return (
                    <MatchedTargetTradingPhotoCard
                      key={card.matchedTradingPhotoCardId}
                      matchedTradingPhotoCard={card}
                    />
                  );
                })}
              </SimpleGrid>
            )}
          />
        </div>
      ) : isOngoingMatching() ? (
        <OnGoingMatchingTradingPhotoCard nickname={userProfileNickName} />
      ) : (
        <EmptyMatchedTradingPhotoCard onClickReMatch={() => onClickReMatch()} />
      )}
    </>
  );
}
