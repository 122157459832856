import React, { useMemo } from "react";

import {
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined } from "lodash";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import styled from "styled-components";

import { openNewTab } from "../../utils/UIUtils";
import useExploreTradingPhotoCardsQuery from "../api/queries/useExploreTradingPhotoCardsQuery";
import GridSkeletons from "../skeletons/GridSkeletons";
import ExploreTradingPhotoCard from "../tradings/ExploreTradingPhotoCard";

const ExploreTradingPhotoCardListWrapper = styled.div`
  padding: 6px 0px;
`;

const ExploreTradingPhotoCardWrapper = styled.div`
  min-width: 120px;
  padding-left: 6px;
  padding-right: 6px;
`;

const headerStyle = {
  paddingTop: "24px",
  paddingBottom: "0px",
};

const titleStyle = {
  fontSize: "17px",
  fontWeight: 700,
};
export default function UserTradingPhotoCardsBottomDrawer({
  artistId,
  tradingUserProfile,
  isOpen,
  onClose,
  focusRef,
}) {
  const { data } = useExploreTradingPhotoCardsQuery(
    artistId,
    null,
    null,
    tradingUserProfile?.userProfileId,
    "recent",
    100
  );
  const tradingPhotoCards = useMemo(() => {
    return first(data?.pages)?.tradingPhotoCards;
  }, [data]);

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
        finalFocusRef={focusRef}
      >
        <DrawerOverlay />
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            },
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: "",
          }}
        >
          <DrawerHeader style={headerStyle}>
            <Center paddingY={"12px"}>
              <Text style={titleStyle}>
                {t("chatRoom.traderPhotoCardsBottomSheetTitle", {
                  nickname: tradingUserProfile?.nickname,
                })}
              </Text>
            </Center>
          </DrawerHeader>
          <DrawerBody paddingX={0} paddingBottom={"36px"}>
            <ExploreTradingPhotoCardListWrapper key={artistId}>
              {isUndefined(tradingPhotoCards) ? (
                <GridSkeletons
                  gridSize={1}
                  skeletonSize={1}
                  skeletonHeight={"140px"}
                  paddingX={"22px"}
                />
              ) : isEmpty(tradingPhotoCards) ? (
                <Center>
                  <Text
                    className="text-main-light-4 dark:text-main-dark-4"
                    fontWeight={500}
                    fontSize={"14px"}
                    paddingY={"60px"}
                  >
                    {t("chatRoom.traderPhotoCardsBottomSheetEmptyCase")}
                  </Text>
                </Center>
              ) : (
                <ScrollMenu>
                  {tradingPhotoCards?.map((tradingPhotoCard, index) => {
                    return (
                      <ExploreTradingPhotoCardWrapper
                        key={tradingPhotoCard.tradingPhotoCardId}
                        style={{ paddingLeft: index === 0 ? "20px" : "0px" }}
                      >
                        <ExploreTradingPhotoCard
                          key={tradingPhotoCard.tradingPhotoCardId}
                          tradingPhotoCard={tradingPhotoCard}
                          hiddenNickname={true}
                          onClickTradingPhotoCard={(tradingPhotoCard) =>
                            openNewTab(
                              `/photo_cards/${tradingPhotoCard.photoCardId}`
                            )
                          }
                        />
                      </ExploreTradingPhotoCardWrapper>
                    );
                  })}
                </ScrollMenu>
              )}
            </ExploreTradingPhotoCardListWrapper>
          </DrawerBody>
          <DrawerFooter
            className="safeAreaMarginBottom"
            width={"100%"}
            paddingTop={"10px"}
            paddingBottom={"0px"}
            paddingX={"20px"}
          ></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
