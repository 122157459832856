import React from "react";

import { t } from "i18next";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { Badge, Center, Image } from "@chakra-ui/react";
import { isEmpty, isUndefined } from "lodash";
import usePhotoCardQuery from "../../components/api/queries/usePhotoCardQuery";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";
import PrimaryInput from "../../components/input/PrimaryInput";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import useUserQuery from "../../components/api/queries/useUserQuery";

const TitleBox = styled.div`
  display: flex;
  margin-top: 64px;
  padding: 10px 24px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 100%;
  text-align: center;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  white-space: pre-wrap;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
`;

const PhotoCardBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 14px;
  margin: 15px 24px;
  align-items: center;
  border-radius: 10px;
`;

const PhotoCardTextColumn = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding-left: 12px;
  flex-grow: 1;
  gap: 6px;
`;

const ImageWrapper = styled.div`
  flex: none;
`;

const PhotoCardCategoryBadge = styled.span`
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  padding: 4px 7px;
  border-radius: 4px;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
`;

const EventName = styled.p`
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const ArtistMemberName = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;

const TagInfoBox = styled.div`
  border-radius: 12px;
  padding: 24px;
  margin: 24px 16px;
`;
const PointText = styled.div`
  color: ${LightColors.pureblack};
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  line-height: 34px;
`;

const TagInfoTitle = styled.div`
  margin-top: 4px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  white-space: pre-wrap;
`;

const TagInfoDescription = styled.div`
  margin-top: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  white-space: pre-wrap;
`;

const TagInputWrapper = styled.div`
  margin-top: 17px;
`;

const TagListWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  gap: 8px;
`;

export default function ReportPhotoCardConfirmPage() {
  const navigate = useNavigate();
  const { queryParams, updateQueryParams } = useQueryParams();

  const photoCard = usePhotoCardQuery(queryParams.get("photoCardId"));
  const isConfirmed = queryParams.get("isConfirmed");
  const user = useUserQuery();

  const navigateAddTags = () => {
    const taggableId = photoCard.photoCardId;
    const taggableType = "PhotoCard";
    updateQueryParams({ taggableType, taggableId }, "/tags/add");
  };

  const mergedTags = () => {
    const tags = photoCard.tags || [];
    const distributors = photoCard.distributors || [];
    return distributors.concat(tags).slice(0, 5);
  };

  const onConfirmedReport = () => {
    if (photoCard) {
      if (queryParams.get("newTabOpen")) {
        window.opener.postMessage(
          {
            type: "reported-new-photo-cards",
            params: { photoCardId: photoCard.photoCardId },
          },
          window.location.origin
        );
        window.close();
      } else {
        navigate(`/photo_cards/${photoCard.photoCardId}`);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <StickyHeader
        title={t("ReportPhotoCardConfirmPage.pageName")}
        showTitle={true}
      />
      <TitleBox>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {isConfirmed === "true"
            ? t("ReportPhotoCardConfirmPage.confirmedTitle")
            : t("ReportPhotoCardConfirmPage.completedTitle")}
        </Title>
        <Description className="text-main-light-4 dark:text-main-dark-4">
          {isConfirmed === "true"
            ? t("ReportPhotoCardConfirmPage.confirmedDescription", {
                nickname: user?.baseUserProfile?.nickname || "-",
              })
            : t("ReportPhotoCardConfirmPage.completedDescription")}
        </Description>
      </TitleBox>
      {isEmpty(photoCard) ? (
        <div className="w-full">
          <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight={128} />
        </div>
      ) : (
        <PhotoCardBox className="bg-surface-light dark:bg-surface-dark">
          <ImageWrapper>
            <Image
              key={photoCard.photoCardId}
              src={photoCard.pictureUrl}
              height={"75px"}
              maxWidth={"48px"}
              objectFit={"cover"}
              borderRadius="8px"
              // onClick={() => setIsImageClicked(true)}
            />
          </ImageWrapper>
          <PhotoCardTextColumn>
            <PhotoCardCategoryBadge className="text-pure-black dark:text-pure-white border-[1px] border-separator-light-1 dark:border-separator-dark-1">
              {`${photoCard.categoryEmoji} ${photoCard.categoryName}`}
            </PhotoCardCategoryBadge>
            <EventName className="text-main-light-2 dark:text-main-dark-2">{`${photoCard.eventName}`}</EventName>
            <ArtistMemberName className="text-main-light-3 dark:text-main-dark-3">{`${photoCard.artistName} ${photoCard.memberName}`}</ArtistMemberName>
          </PhotoCardTextColumn>
        </PhotoCardBox>
      )}
      {isUndefined(photoCard) ? (
        <TagInfoBox
          className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
          style={{ height: "150px" }}
        />
      ) : isEmpty(photoCard) || isEmpty(photoCard.tags) ? (
        <TagInfoBox
          className="bg-pure-white dark:bg-pure-black 
        shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
        >
          {/* <PointText>
                {"+1"}
              </PointText> */}
          <TagInfoTitle className="text-onLabel-light dark:text-onLabel-dark">
            {t("ReportPhotoCardConfirmPage.tagTitle")}
          </TagInfoTitle>
          <TagInfoDescription className="text-main-light-3 dark:text-main-dark-3">
            {t("ReportPhotoCardConfirmPage.tagDescription")}
          </TagInfoDescription>
          <TagInputWrapper>
            <PrimaryInput
              placeholder={t("ReportPhotoCardConfirmPage.tagInputPlaceholder")}
              isReadOnly={true}
              onClick={navigateAddTags}
            />
          </TagInputWrapper>
        </TagInfoBox>
      ) : (
        <TagInfoBox
          className="bg-pure-white dark:bg-pure-black 
        shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
        >
          <TagInfoTitle className="text-onLabel-light dark:text-onLabel-dark">
            {t("ReportPhotoCardConfirmPage.tagTitle")}
          </TagInfoTitle>
          <TagInfoDescription className="text-main-light-3 dark:text-main-dark-3">
            {t("ReportPhotoCardConfirmPage.tagDescription")}
          </TagInfoDescription>
          <TagListWrapper>
            {mergedTags().map((tag) => {
              return (
                <Badge
                  key={tag.tagId}
                  paddingY={"5px"}
                  paddingX={"8px"}
                  borderRadius={"4px"}
                  fontWeight={600}
                >
                  <span style={{ color: LightColors.onLabel }}>
                    {tag.category === "distributors"
                      ? `${tag.name} 🛍 `
                      : `${tag.name}`}
                  </span>
                </Badge>
              );
            })}
            {isConfirmed === "true" && (
              <Badge
                className="border-[1px] border-disabled-light dark:border-disabled-dark bg-pure-white dark:bg-pure-black text-textPrimary-light dark:text-textPrimary-dark"
                paddingY={"5px"}
                paddingX={"8px"}
                borderRadius={"4px"}
                fontWeight={600}
                // FIXME : border={"1px dashed #D5D5D5"}
                onClick={navigateAddTags}
              >
                <span className="text-onLabel-light dark:text-onLabel-dark">
                  {t("ReportPhotoCardConfirmPage.addTag")}
                </span>
              </Badge>
            )}
          </TagListWrapper>
        </TagInfoBox>
      )}
      <Center>
        <RegularSolidButton
          className="absolute bottom-0"
          text={t("confirm")}
          style={{
            marginBottom: "calc(24px + env(safe-area-inset-bottom))",
            padding: "16px",
            width: "calc(100% - 48px)",
          }}
          onClicked={() => onConfirmedReport()}
        />
      </Center>
    </>
  );
}
