import React, { useEffect, useState } from "react";

import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Page from "../../components/Page";
import useReviewStatsQuery from "../../components/api/queries/useReviewStatsQuery";
import useReviewsQuery from "../../components/api/queries/useReviewsQuery";
import PostImagesBox from "../../components/community/PostImagesBox";
import EmptyCase from "../../components/errors/EmptyCase";
import FeedbackProgress from "../../components/tradings/FeedbackProgress";
import TradingPhotoCardBox from "../../components/tradings/TradingPhotoCardBox";
import LightColors from "../../constants/LightColors";
import { capitalizeFirstLetter } from "../../utils/TextUtil";
import GridSkeletons from "../../components/skeletons/GridSkeletons";

const PageBackgroundWrapper = styled.div`
  background: ${LightColors.secondary};
  height: 100%;
`;

const TitleWrapper = styled.div`
  padding: 24px 24px 10px 24px;
`;
const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
`;

const FeedbackProgressWrapper = styled.div`
  padding: 12px 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
`;

const ReviewCard = styled.div`
  margin: 12px 18px;
  padding: 24px;
  border-radius: 18px;
  box-shadow: 0px 0px 14px 0px #00000014;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
`;

const TradingPhotoCardBoxWrapper = styled.div`
  padding: 12px 0px;
  border-radius: 18px;
`;

const ReviewerNickname = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-top: 12px;
`;

const ReviewContent = styled.div`
  margin-top: 11px;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
`;

const FeedbackOption = styled.div`
  margin-top: 7px;
  padding: 6px 10px;
  border-radius: 30px;
`;

export default function TradingPhotoCardReviewsPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const currentQueryParams = new URLSearchParams(location.search);
  const userProfileId = currentQueryParams.get("userProfileId");

  const [paginatedReviews, setPaginatedReviews] = useState();

  const reviewStats = useReviewStatsQuery({ userProfileId: userProfileId });
  const { data, isFetching, fetchNextPage, hasNextPage } = useReviewsQuery({
    userProfileId: userProfileId,
  });

  const handlePaginatedChatRooms = (paginatedData) => {
    const reviews = paginatedData.pages?.flatMap((page) =>
      page.reviews.map((review) => review)
    );
    setPaginatedReviews(reviews);
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedChatRooms(data);
    }
  }, [data]);

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <PageBackgroundWrapper>
      <Page
        title={t("TradingPhotoCardReviewsPage.pageTitle")}
        showTitle={true}
        noPadding={true}
        showBackButton={true}
        titleTransparent={true}
      >
        <div>
          {isUndefined(reviewStats?.positiveFeedbackOptions) ? (
            <GridSkeletons
              gridSize={1}
              skeletonSize={1}
              skeletonHeight={"190px"}
              paddingX={"22px"}
            />
          ) : (
            !isEmpty(reviewStats?.positiveFeedbackOptions) && (
              <>
                <TitleWrapper>
                  <Title className="text-pure-black dark:text-pure-white">
                    {t("TradingPhotoCardReviewsPage.feedbackOptionsTitle")}
                  </Title>
                </TitleWrapper>
                <FeedbackProgressWrapper>
                  {FeedbackProgress({
                    reviewsCount: reviewStats.reviewsCount,
                    positiveFeedbackOptions:
                      reviewStats.positiveFeedbackOptions,
                  })}
                </FeedbackProgressWrapper>
              </>
            )
          )}
          {isUndefined(paginatedReviews) ? (
            <>
              <GridSkeletons
                gridSize={1}
                skeletonSize={2}
                skeletonHeight={"200px"}
                paddingX={"12px"}
                paddingY={"24px"}
              />
            </>
          ) : isEmpty(paginatedReviews) ? (
            <>
              <EmptyCase
                emoji={"🙂"}
                description={t("TradingPhotoCardReviewsPage.emptyReviews")}
              />
            </>
          ) : (
            <>
              <TitleWrapper>
                <Title className="text-pure-black dark:text-pure-white">
                  {t("TradingPhotoCardReviewsPage.reviewsTitle")}
                </Title>
              </TitleWrapper>
              {paginatedReviews.map((review) => {
                return (
                  <ReviewWrapper
                    className="border-b-[1px] border-separator-light-2 dark:border-separator-dark-2"
                    key={review.reviewId}
                    onClick={() => navigate(`/reviews/${review.sentReviewId}`)}
                  >
                    <ReviewCard className="bg-pure-white dark:bg-pure-black">
                      <Row>
                        <TradingPhotoCardBoxWrapper className="bg-pure-white dark:bg-pure-black">
                          <TradingPhotoCardBox
                            tradingPhotoCard={review.reviewable}
                          />
                        </TradingPhotoCardBoxWrapper>
                      </Row>
                      <Row>
                        <ReviewerNickname className="text-main-light-2 dark:text-main-dark-2">
                          {`To. ${review.userProfile.nickname}`}
                        </ReviewerNickname>
                      </Row>
                      <Row>
                        <ReviewContent className="text-main-light-1 dark:text-main-dark-1">
                          {review.content}
                        </ReviewContent>
                      </Row>
                      {review.pictureUrl && (
                        <Row style={{ padding: "12px 0px" }}>
                          <PostImagesBox
                            images={[review.pictureUrl]}
                            enableDelete={false}
                            enableFullSizeViewer={true}
                          />
                        </Row>
                      )}
                      {review.feedbackOptions?.map((feedbackOption) => {
                        return (
                          <Row key={feedbackOption}>
                            <FeedbackOption className="bg-surface-light dark:bg-surface-dark text-main-light-4 dark:text-main-dark-4">
                              {t(
                                `ReviewFeedbackOptions.${capitalizeFirstLetter(
                                  feedbackOption
                                )}`
                              )}
                            </FeedbackOption>
                          </Row>
                        );
                      })}
                    </ReviewCard>
                  </ReviewWrapper>
                );
              })}
            </>
          )}
        </div>
      </Page>
    </PageBackgroundWrapper>
  );
}
