import React, { useEffect, useState } from "react";

import {
  Center,
  Flex,
  HStack,
  SimpleGrid,
  Spacer,
  Spinner,
  Switch,
  useColorMode,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { t } from "i18next";
import { chunk, isEmpty, isNull } from "lodash";
import EasyVirtualized from "react-easy-virtualized";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

import LightColors from "../../constants/LightColors";
import { ReactComponent as RightArrowIcon } from "../../icons/arrow.right.svg";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import { queryGet } from "../../utils/queryClient";
import TradingPhotoCardResponse from "../api/model/TradingPhotoCardResponse";
import useExploreTradingPhotoCardsQueryV2 from "../api/queries/useExploreTradingPhotoCardsQueryV2";
import {
  useMatchedPhotoCards,
  useTradingPhotoCardsAll,
} from "../api/queries/useTradingPhotoCards";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import TradingPhotoCardFilterBottomDrawer from "../drawer/TradingPhotoCardFilterBottomDrawer";
import HorizontalScrollMembers from "../lists/HorizontalScrollMembers";
import { ListRenderer } from "../lists/ListRenderer";
import ArtistIconSelector from "../selectors/ArtistIconSelector";
import GridSkeletons from "../skeletons/GridSkeletons";
import EmptyExploreTradingPhotoCard from "./EmptyExploreTradingPhotoCard";
import EmptyMatchedTradingPhotoCard from "./EmptyMatchedTradingPhotoCard";
import ExploreTradingPhotoCard from "./ExploreTradingPhotoCard";
import MatchedTargetTradingPhotoCard from "./MatchedTargetTradingPhotoCard";
import OnGoingMatchingTradingPhotoCard from "./OngoingMatchedTradingPhotoCard";
import TradingManagerBarV2 from "./TradingManagerBarV2";

async function requestReMatching(memberId) {
  return fetch(baseUrl() + `/trading_photo_cards/rematching`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      member_id: memberId,
    }),
  }).then((data) => data.json());
}

const ArtistIconSelectorWrapper = styled.div`
  padding-top: 20px;
`;

const ManagerBarWrapper = styled.div`
  padding-bottom: 24px;
`;

const ListWrapper = styled.div`
  padding: 0px 24px;
`;

const TitleWrapper = styled.div`
  padding: 20px 24px 4px 24px;
`;
const MembersWrapper = styled.div`
  padding: 0px 0px 14px 0px;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
`;

const FilterButtonText = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
`;

const ChatRoomsBox = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

const ChatRoomsText = styled.div`
  padding: 11px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
  background: -webkit-linear-gradient(180deg, #1e4bc7, #8400f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

export default function ExchangesTabPanel({
  fabWrapperRef,
  onShowBottomSheet,
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  // const [boxTabIndex, setBoxTabIndex] = useState(0);
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [onlyIdentityVerified, setOnlyIdentityVerified] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] =
    useState([]);
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();
  const [paginatedTradingPhotoCards, setPaginatedTradingPhotoCards] =
    useState();
  const [isFilteringDrawerOpen, setIsFilteringDrawerOpen] = useState(false);

  const myTradingPhotoCards = useTradingPhotoCardsAll({ category: "exchange" });
  const handlePaginatedTradingPhotoCards = (paginatedData) => {
    const tradingPhotoCards = paginatedData.pages?.flatMap((page) =>
      page.tradingPhotoCards.map(
        (tradingPhotoCard) => new TradingPhotoCardResponse(tradingPhotoCard)
      )
    );
    setPaginatedTradingPhotoCards(tradingPhotoCards);
  };

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useExploreTradingPhotoCardsQueryV2({
      artistId: selectedArtist?.artistId,
      onlyIdentityVerified: onlyIdentityVerified,
      memberIds: selectedMember?.memberId ?? [],
      photoCardCategories: selectedPhotoCardCategories,
      eventIds: selectedEventIds,
      category: "exchange",
    });

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedTradingPhotoCards(data);
    }
  }, [data]);

  const matchedTradingPhotoCards = useMatchedPhotoCards({
    artistId: selectedTradingPhotoCard?.artistId,
    tradingPhotoCardId: selectedTradingPhotoCard?.tradingPhotoCardId,
    memberId: selectedMember?.memberId,
    onlyIdentityVerified: onlyIdentityVerified,
    photoCardCategories: selectedPhotoCardCategories,
    eventIds: selectedEventIds,
  });

  const isOngoingMatching = () => {
    if (isEmpty(selectedTradingPhotoCard)) return false;
    return (
      isNull(selectedTradingPhotoCard.matchedCount) ||
      selectedTradingPhotoCard.matchedCount <= 0
    );
  };

  const onClickReMatch = async () => {
    if (isEmpty(selectedTradingPhotoCard)) return;
    if (!selectedTradingPhotoCard.memberId) return;

    const response = await requestReMatching(selectedTradingPhotoCard.memberId);
    if (response.success) {
      queryClient.invalidateQueries(["tradingPhotoCards"]);
      setTimeout(() => {
        queryClient.invalidateQueries(["matchedTradingPhotoCards"]);
      }, 1500);
    }
  };

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
    initFilters();
  };

  const onSelectedMember = (member) => {
    if (isEmpty(member)) {
      setSelectedMember(null);
    } else {
      setSelectedMember(member);
    }
  };

  const onSelectedTradingPhotoCard = async (tradingPhotoCard) => {
    if (isEmpty(tradingPhotoCard)) {
      setSelectedTradingPhotoCard(null);
      initFilters();
    } else {
      const url = `/api/v1/artists/${tradingPhotoCard.artistId}`;
      const response = await queryGet(url);
      if (response?.artist) {
        onSelectedArtist(response?.artist);
        setSelectedTradingPhotoCard(tradingPhotoCard);
      }
    }
  };

  const initFilters = () => {
    setOnlyIdentityVerified(false);
    setSelectedEventIds([]);
    setSelectedPhotoCardCategories([]);
  };

  const handleFilterSelected = (filteredObj) => {
    setIsFilteringDrawerOpen(false);
    if (!isEmpty(filteredObj)) {
      setOnlyIdentityVerified(filteredObj.identityVerified);
      setSelectedEventIds(filteredObj.eventIds);
      setSelectedPhotoCardCategories(filteredObj.photoCardCategories);

      queryClient.invalidateQueries(["matchedTradingPhotoCards"]);
    }
  };

  const isCheckedPhotoCardFilter = () => {
    return (
      onlyIdentityVerified ||
      !isEmpty(selectedPhotoCardCategories) ||
      !isEmpty(selectedEventIds)
    );
  };

  const onOpenFilterDrawer = (isOpen) => {
    if (fabWrapperRef.current) {
      fabWrapperRef.current.style.visibility = isOpen ? "hidden" : "visible";
    }
    setIsFilteringDrawerOpen(isOpen);
  };

  const onOpenedCardBottomSheet = (isOpen) => {
    if (onShowBottomSheet) {
      onShowBottomSheet(isOpen);
    }
  };

  useEffect(() => {
    if (onShowBottomSheet) {
      onShowBottomSheet(isFilteringDrawerOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilteringDrawerOpen]);

  return (
    <>
      <ManagerBarWrapper className="border-b-[1px] border-surface-light dark:border-surface-dark">
        <TradingManagerBarV2
          tradingPhotoCards={myTradingPhotoCards}
          isShowExploreCard={true}
          category={"exchange"}
          isShowChatRoomsCount={true}
          onClickTradingPhotoCard={(tradingPhotoCard) =>
            onSelectedTradingPhotoCard(tradingPhotoCard)
          }
        />
      </ManagerBarWrapper>
      {isEmpty(selectedTradingPhotoCard) ? (
        <ArtistIconSelectorWrapper>
          <ArtistIconSelector
            onSelected={(artist) => onSelectedArtist(artist)}
          />
        </ArtistIconSelectorWrapper>
      ) : (
        selectedTradingPhotoCard.chatRoomsCount > 0 && (
          <ChatRoomsBox
            className="bg-surface-light dark:bg-surface-dark" // FIXME : background: #0000000d;
            onClick={() =>
              navigate(
                `/trading_photo_cards/${selectedTradingPhotoCard.tradingPhotoCardId}/my_chat_rooms`
              )
            }
          >
            <HStack placeContent={"center"}>
              <ChatRoomsText>
                {t("ExchangesTabPanel.chatRoomsBoxText", {
                  count: selectedTradingPhotoCard.chatRoomsCount,
                })}
              </ChatRoomsText>
              <RightArrowIcon width={"15px"} height={"15px"} fill="#1E4AC7" />
            </HStack>
          </ChatRoomsBox>
        )
      )}

      <TitleWrapper>
        <RenderTitleAndFilterSection
          selectedTradingPhotoCard={selectedTradingPhotoCard}
          isCheckedPhotoCardFilter={isCheckedPhotoCardFilter()}
          setIsFilteringDrawerOpen={onOpenFilterDrawer}
        />
      </TitleWrapper>
      <MembersWrapper>
        <HorizontalScrollMembers
          members={selectedArtist?.members}
          selectedMember={selectedMember}
          onSelectedMember={(member) => onSelectedMember(member)}
        />
      </MembersWrapper>
      {isEmpty(selectedTradingPhotoCard) ? (
        <ListWrapper>
          <ListRenderer
            data={paginatedTradingPhotoCards}
            skeletonComponent={
              <GridSkeletons
                gridSize={3}
                skeletonSize={3}
                skeletonHeight={"96px"}
                showText={true}
              />
            }
            emptyComponent={<EmptyExploreTradingPhotoCard />}
            renderList={(data) => (
              <PaginatedTradingPhotoCardsList
                paginatedTradingPhotoCards={data}
                loadMore={loadMore}
                hasNextPage={hasNextPage}
                onShowBottomSheet={onOpenedCardBottomSheet}
              />
            )}
          />
        </ListWrapper>
      ) : isEmpty(matchedTradingPhotoCards) ? (
        <RenderOngoingOrEmptyMatch
          isOngoingMatching={isOngoingMatching()}
          onClickReMatch={onClickReMatch}
        />
      ) : (
        <ListWrapper>
          <RenderMatchedTradingPhotoCardsList
            matchedTradingPhotoCards={matchedTradingPhotoCards}
          />
        </ListWrapper>
      )}
      {isFilteringDrawerOpen && (
        <TradingPhotoCardFilterBottomDrawer
          isOpen={isFilteringDrawerOpen}
          onClose={() => onOpenFilterDrawer(false)}
          onConfirm={(filteredObj) => handleFilterSelected(filteredObj)}
          identityVerified={onlyIdentityVerified}
          artistId={selectedArtist?.artistId}
          eventIds={selectedEventIds}
          photoCardCategories={selectedPhotoCardCategories}
        />
      )}
    </>
  );
}

const EmptyFilterButton = styled.div`
  height: 35px;
`;

const RenderTitleAndFilterSection = ({
  selectedTradingPhotoCard,
  isCheckedPhotoCardFilter,
  setIsFilteringDrawerOpen,
}) => {
  return (
    <Flex align={"center"} marginY={"2px"}>
      <Title className="text-main-light-2 dark:text-main-dark-2">
        {t("ExchangesTabPanel.availablePoca")}
      </Title>
      <Spacer />
      {isEmpty(selectedTradingPhotoCard) ? (
        <SecondaryLineButton
          className={`border ${
            isCheckedPhotoCardFilter
              ? "dark:border-primary-dark border-primary-light"
              : "border-[rgba(0, 0, 0, 0.05)]"
          }`}
          text={
            <FilterButtonText
              className={
                isCheckedPhotoCardFilter
                  ? "text-primary-light dark:text-primary-dark"
                  : "text-main-light-1 dark:text-main-dark-1"
              }
              isChecked={isCheckedPhotoCardFilter}
            >
              {t("photoCardPage.filter")}
            </FilterButtonText>
          }
          rightElement={
            <Switch
              size={"sm"}
              alignContent={"center"}
              colorScheme={"primary"}
              isChecked={isCheckedPhotoCardFilter}
              onChange={() => setIsFilteringDrawerOpen(true)}
              style={{ "--switch-track-height": "0.6rem" }}
              // TODO Switch BGColor
            />
          }
          style={{
            margin: "1.5px 0px",
            boxShadow: isCheckedPhotoCardFilter
              ? "0px 0px 5px 0px rgba(255, 118, 250, 0.2), 0px 0px 8px 0px rgba(161, 142, 255, 0.3)"
              : "",
            padding: "8px 14px",
            gap: "4px",
          }}
          onClickButton={() => setIsFilteringDrawerOpen(true)}
        />
      ) : (
        <EmptyFilterButton />
      )}
    </Flex>
  );
};

const RenderOngoingOrEmptyMatch = ({ isOngoingMatching, onClickReMatch }) => (
  <ListWrapper>
    {isOngoingMatching ? (
      <OnGoingMatchingTradingPhotoCard />
    ) : (
      <EmptyMatchedTradingPhotoCard onClickReMatch={onClickReMatch} />
    )}
  </ListWrapper>
);

const ContainerWithPadding = styled.div`
  padding-bottom: calc(56px + env(safe-area-inset-bottom));
`;

const RenderMatchedTradingPhotoCardsList = ({ matchedTradingPhotoCards }) => (
  <ContainerWithPadding>
    <Virtuoso
      useWindowScroll
      overscan={480}
      data={chunk(matchedTradingPhotoCards, 3)}
      style={{ height: "100px" }}
      itemContent={(index, chunkCards) => (
        <SimpleGrid
          key={index}
          columns={3}
          spacing={"7px"}
          marginBottom={"24px"}
        >
          {chunkCards.map((card) => {
            return (
              <MatchedTargetTradingPhotoCard
                key={card.matchedTradingPhotoCardId}
                matchedTradingPhotoCard={card}
              />
            );
          })}
        </SimpleGrid>
      )}
    />
  </ContainerWithPadding>
);

const PaginatedTradingPhotoCardsList = ({
  paginatedTradingPhotoCards,
  hasNextPage,
  loadMore,
  onShowBottomSheet,
}) => (
  <ContainerWithPadding>
    <EasyVirtualized
      useParentScrollElement={true}
      overscanRowCount={3}
      onLoadMore={loadMore}
      hasMore={hasNextPage}
      loader={
        <Center marginTop={"8px"}>
          <Spinner
            size="sm"
            className="text-primary-light dark:text-primary-dark"
            marginRight={"20px"}
          />
        </Center>
      }
    >
      {chunk(paginatedTradingPhotoCards, 3).map((chunkCards, chunkIndex) => (
        <SimpleGrid
          key={chunkIndex}
          columns={3}
          spacing={"7px"}
          marginBottom={"24px"}
        >
          {chunkCards.map((card) => {
            return (
              <ExploreTradingPhotoCard
                key={card.tradingPhotoCardId}
                tradingPhotoCard={card}
                onShowBottomSheet={onShowBottomSheet}
              />
            );
          })}
        </SimpleGrid>
      ))}
    </EasyVirtualized>
  </ContainerWithPadding>
);
