import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

import { ReactComponent as CheckedIcon } from "../../icons/check.svg";
import useArtistEventsQuery from "../api/queries/useArtistEventsQuery";
import PrimaryButton from "../buttons/PrimaryButton";
import { EventNameBox } from "../event/EventNameBox";
import { ListRenderer } from "../lists/ListRenderer";
import PriamryRadio from "../radio/PriamryRadio";
import GridSkeletons from "../skeletons/GridSkeletons";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import EventsGroupList from "../lists/EventsGroupList";

// Styled Components
const DrawerTitleRow = styled.div`
  padding: 38px 24px 12px 24px;
  white-space: pre-wrap;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
`;

const DrawerContentWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

const SectionTitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const PhotoCardCategoryBox = styled.div`
  border-radius: 8px;
  display: flex;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  width: 100%;
`;

const PhotoCardCategoryText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
`;

const EventNameWrapper = styled.div`
  flex-grow: 1;
`;

const EmptyText = styled.div`
  margin-top: 32px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
`;

const EventCategoryListWrapper = styled.div`
  padding: 8px 0px;
`;

const PHOTOCARD_CATEGORIES = ["default", "special", "md", "trading"];

const PhotoCardFilterBottomDrawer = ({
  artistId,
  photoCardCategories = [],
  eventIds = [],
  isOpen,
  onClose,
  focusRef,
  onConfirm,
}) => {
  const [selectedEventIds, setSelectedEventIds] = useState(eventIds);
  const [selectedEventCategoryName, setSelectedEventCategoryName] =
    useState("all");
  const [selectedPhotoCardCategories, setSelectedPhotoCardCategories] =
    useState(photoCardCategories);

  const events = useArtistEventsQuery({
    artistId: artistId,
    photoCardCategories: selectedPhotoCardCategories,
  });

  const onSelectEvent = (eventId) => {
    setSelectedEventIds((prev) =>
      prev.includes(eventId)
        ? prev.filter((id) => id !== eventId)
        : [...prev, eventId]
    );
  };

  const onSelectCategory = (category) => {
    setSelectedPhotoCardCategories((prev) =>
      prev.includes(category)
        ? prev.filter((prevCategory) => prevCategory !== category)
        : [...prev, category]
    );
  };

  const onSelectedEventCategoryName = (eventCategoryName) => {
    setSelectedEventCategoryName(eventCategoryName);
  };

  const initFilters = () => {
    setSelectedPhotoCardCategories([]);
    setSelectedEventIds([]);
  };

  const handleConfirm = () => {
    onConfirm({
      eventIds: selectedEventIds,
      photoCardCategories: selectedPhotoCardCategories,
    });
    onClose();
  };

  const filteredEvents = useMemo(() => {
    if (selectedEventCategoryName === "all") {
      return events; // Return all events when "all" is selected
    }
    return events?.filter(
      (event) => event.categoryName === selectedEventCategoryName
    );
  }, [events, selectedEventCategoryName]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      finalFocusRef={focusRef}
    >
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          },
        }}
        style={{
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: "",
        }}
      >
        <DrawerBody
          padding={0}
          minHeight={"60vh"}
          height={"60vh"}
          maxHeight={"60vh"}
        >
          <DrawerTitleRow className="text-main-light-1 dark:text-main-dark-1">
            {t("PhotoCardFilterBottomDrawer.title")}
          </DrawerTitleRow>
          <DrawerContentWrapper>
            <SectionTitle className="text-main-light-2 dark:text-main-dark-2">
              {t("PhotoCardFilterBottomDrawer.categoryTitle")}
            </SectionTitle>
            <SimpleGrid
              columns={2}
              justifyItems={"center"}
              gap={"8px"}
              paddingTop={"10px"}
              paddingBottom={"18px"}
            >
              {PHOTOCARD_CATEGORIES.map((category) => {
                const isChecked =
                  selectedPhotoCardCategories.includes(category);
                return (
                  <PhotoCardCategoryBox
                    className={`bg-pure-white border-[1px] dark:bg-pure-black ${
                      isChecked
                        ? "border-primary-light dark:border-primary-dark"
                        : "border-separator-light-1 dark:border-separator-dark-1"
                    }
                    shadow-[0px_1px_3px_0px_rgba(0,0,0,0.04)] dark:shadow-none`}
                    key={category}
                    onClick={() => onSelectCategory(category)}
                  >
                    {isChecked && (
                      <CheckedIcon
                        className="fill-primary-light dark:fill-primary-dark"
                        width={"16px"}
                        height={"16px"}
                      />
                    )}
                    <PhotoCardCategoryText
                      className={
                        isChecked
                          ? "text-primary-light dark:text-primary-dark"
                          : "text-pure-black dark:text-pure-white"
                      }
                    >
                      {t(`PhotoCardFilterBottomDrawer.${category}Category`)}
                    </PhotoCardCategoryText>
                  </PhotoCardCategoryBox>
                );
              })}
            </SimpleGrid>
          </DrawerContentWrapper>
          <DrawerContentWrapper>
            <SectionTitle className="text-main-light-2 dark:text-main-dark-2">
              {t("PhotoCardFilterBottomDrawer.eventTitle")}
            </SectionTitle>
          </DrawerContentWrapper>
          <EventCategoryListWrapper>
            <EventsGroupList
              events={events}
              selectedEventCategoryName={selectedEventCategoryName}
              onSelectedEventCategory={(eventCategoryName) =>
                onSelectedEventCategoryName(eventCategoryName)
              }
            />
          </EventCategoryListWrapper>
          <DrawerContentWrapper>
            {
              <ListRenderer
                data={filteredEvents}
                emptyComponent={
                  <EmptyText className="text-onDisabled-light dark:text-onDisabled-dark">
                    {t("PhotoCardFilterBottomDrawer.emptyCategory")}
                  </EmptyText>
                }
                skeletonComponent={
                  <GridSkeletons
                    gridSize={1}
                    skeletonSize={10}
                    skeletonHeight={"48px"}
                    marginBottom={"8px"}
                  />
                }
                renderList={(data) =>
                  data.map((event) => (
                    <Row
                      className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1"
                      key={event.eventId}
                    >
                      <EventNameWrapper>
                        <EventNameBox
                          event={event}
                          isDisabled={!selectedEventIds.includes(event.eventId)}
                        />
                      </EventNameWrapper>
                      <PriamryRadio
                        isChecked={selectedEventIds.includes(event.eventId)}
                        onChanged={() => onSelectEvent(event.eventId)}
                      />
                    </Row>
                  ))
                }
              />
            }
          </DrawerContentWrapper>
        </DrawerBody>
        <DrawerFooter
          paddingTop="10px"
          paddingBottom="calc(env(safe-area-inset-bottom) + 20px)"
          paddingX="20px"
          gap={"6px"}
        >
          <SecondaryLineButton
            text={t("PhotoCardFilterBottomDrawer.initButton")}
            style={{ padding: "16px", border: "0px", fontSize: "17px" }}
            onClickButton={() => initFilters()}
          />
          <PrimaryButton
            fontWeight={600}
            fontSize={"17px"}
            lineHeight={"20px"}
            padding={"16px 34px"}
            width={"100%"}
            onClick={handleConfirm}
            text={t("confirm")}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
export default PhotoCardFilterBottomDrawer;
