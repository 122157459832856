import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";

export default async function UpdateTradingPhotoCardsGroup({groupId, deletedImageKeys, dataUrls, tradingPhotoCards, description, tradeMethod}) {
  const body = {
    group_id: groupId,
    description: description,
    trade_method: tradeMethod,
    data_urls: dataUrls,
    deleted_image_keys: deletedImageKeys,
    trading_photo_cards: tradingPhotoCards.map((t) => {
      return {
        trading_photo_card_id: t.tradingPhotoCardId,
        price: t.price,
      }
    }),
  }
  return fetch(baseUrl() + `/api/v2/trading_photo_cards/groups/${groupId}/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify(body),
  });
}
