import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  AbsoluteCenter,
  Center,
  Input,
  InputGroup,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import ToastMessageBox from "../../../components/alerts/ToastMessageBox";
import AddEvent from "../../../components/api/mutations/AddEvent";
import useSearchableEventsQuery from "../../../components/api/queries/useSearchableEventsQuery";
import EventActivityBox from "../../../components/event/EventActivityBox";
import StickyHeader from "../../../components/StickyHeader";
import LightColors from "../../../constants/LightColors";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow.left.svg";
import { useQueryParams } from "../../../utils/useQueryParams";
import { LineButton } from "../../../components/buttons/LineButton";

const PageWrapper = styled.div`
  margin-top: calc(64px + env(safe-area-inset-top));
  padding: 24px;
`;
const InputWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  align-items: center;
  padding: 0px 11px;
  width: 100%;
  margin: 0px 8px 0px 12px;
`;

const UnknwonEventText = styled.div`
  width: max-content;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  white-space: pre-wrap;
`;

const SearchEventPage = () => {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();
  const toast = useToast();

  const [searchInput, setSearchInput] = useState("");

  const queryParams = new URLSearchParams(window.location.search);
  const events = useSearchableEventsQuery({
    artistId: queryParams.get("artistId"),
    category: queryParams.get("activity"),
  });

  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 300);
  });

  const onConfirmAddEvent = async (event) => {
    const response = await AddEvent({
      artistId: queryParams.get("artistId"),
      name: event.name,
      category: event.category,
    });
    if (response && response.ok) {
      const responseBody = await response.json();
      const eventId = responseBody.event.eventId;
      if (eventId) {
        navigateSelectEvent(responseBody.event);
      }
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("profiles.failedToCreateAlert")} />
        ),
      });
    }
  };

  const navigateSelectEvent = (event) => {
    const eventId = event.eventId;
    const activity = event.category;
    updateQueryParams(
      { eventId, activity },
      "/report-photo-card/card-categories"
    );
  };

  const filteredEvents = useMemo(() => {
    if (isEmpty(events)) return [];
    if (isEmpty(searchInput)) return [];
    const lowerCaseSearchInput = searchInput.toLowerCase();
    return events.filter((event) => {
      const eventName = event.name ? event.name.toLowerCase() : "";
      const eventCategoryName = event.categoryName
        ? event.categoryName.toLowerCase()
        : "";
      const localizedNames = event.translations?.localizedName || {};

      return (
        eventName.includes(lowerCaseSearchInput) ||
        eventCategoryName.includes(lowerCaseSearchInput) ||
        Object.values(localizedNames).some(
          (name) => name && name.toLowerCase().includes(lowerCaseSearchInput)
        )
      );
    });
  }, [events, searchInput]);

  const eventActivityCategory = (event) => {
    const { category } = event;
    return ["lightstick", "seasons", "membership"].includes(category)
      ? "misc"
      : category;
  };

  const NewEvent = useMemo(() => {
    return {
      eventId: "NewEventId",
      name: searchInput,
      category: "misc",
    };
  }, [searchInput]);

  return (
    <>
      <StickyHeader
        title={t("UserProfileSearchPage.pageName")}
        transparent={true}
        headerContainer={
          <>
            <ArrowLeftIcon
              className="fill-pure-black dark:fill-pure-white"
              onClick={() => navigate(-1)}
            />
            <InputWrapper className="bg-secondary-light dark:bg-secondary-dark">
              <InputGroup border={"transparent"}>
                <Input
                  className="text-main-light-2 dark:text-main-dark-2"
                  ref={inputRef}
                  _focus={{ border: "transparent", boxShadow: "none" }}
                  _active={{ border: "transparent" }}
                  _hover={{ border: "transparent" }}
                  placeholder={t("ReportSearchEventPage.placeHolder")}
                  outline={"none"}
                  fontSize={"15px"}
                  paddingLeft={"3px"}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </InputGroup>
            </InputWrapper>
          </>
        }
      />
      <PageWrapper>
        <div className="flex flex-col gap-[12px]">
          {isEmpty(filteredEvents) ? (
            <AbsoluteCenter>
              <UnknwonEventText className="text-main-light-4 dark:text-main-dark-4">
                {t("ReportSearchEventPage.unknownEventText")}
              </UnknwonEventText>
            </AbsoluteCenter>
          ) : (
            filteredEvents.map((event) => {
              return (
                <EventActivityBox
                  key={event.eventId}
                  event={event}
                  activityCategory={eventActivityCategory(event)}
                  handleSelectEvent={() => navigateSelectEvent(event)}
                />
              );
            })
          )}
        </div>
        {searchInput && (
          <div style={{ marginTop: "12px" }}>
            <EventActivityBox
              key={NewEvent.eventId}
              event={{ ...NewEvent, name: searchInput }}
              activityCategory={eventActivityCategory(NewEvent)}
              handleSelectEvent={() => onConfirmAddEvent(NewEvent)}
            />
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default SearchEventPage;
