import * as React from "react";
import {
  Center,
  ChakraProvider,
  Container,
  useColorMode,
} from "@chakra-ui/react";

import "./App.css";
import Router from "./router/Router";
import { createQueryClient } from "./utils/queryClient";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryHome from "./components/errors/ErrorBoundaryHome";
import i18n from "./i18n/i18n";
import { getLanguage } from "./utils/etcUtils";
import customTheme from "./theme";

const queryClient = createQueryClient();

function ThemeInitializer() {
  const { setColorMode } = useColorMode();

  React.useEffect(() => {
    const savedTheme = localStorage.getItem("chakra-ui-color-mode");
    const prefersDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const systemTheme = prefersDarkMode ? "dark" : "light";

    if (!savedTheme) {
      // 로컬스토리지 값이 없으면 시스템 테마를 따라감
      setColorMode(systemTheme);
      localStorage.setItem("chakra-ui-color-mode", systemTheme);
    } else if (savedTheme !== systemTheme) {
      // 로컬스토리지 값과 시스템 테마가 다르면 시스템 테마로 맞춤
      setColorMode(systemTheme);
      localStorage.setItem("chakra-ui-color-mode", systemTheme);
    } else {
      // 로컬스토리지 값과 시스템 테마가 같다면, 그대로 유지
      setColorMode(savedTheme);
    }

    document.documentElement.classList.toggle("dark", systemTheme === "dark");
  }, [setColorMode]);

  return null;
}

function App() {
  const { colorMode } = useColorMode();

  React.useEffect(() => {
    const savedLanguage = getLanguage();
    if (savedLanguage) {
      if (savedLanguage !== localStorage.getItem("i18nextLng")) {
        console.log(`Set locale with savedLanguage : ${savedLanguage}`);
        i18n.changeLanguage(savedLanguage);
      }
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary fallback={<ErrorBoundaryHome />}>
        <ChakraProvider theme={customTheme}>
          <ThemeInitializer />
          <Center
            padding={0}
            backgroundSize={{ xl: "cover" }}
            bg={colorMode === "dark" ? "background.dark" : "background.light"}
          >
            <Container
              width={"100%"}
              padding={0}
              bg={colorMode === "dark" ? "background.dark" : "background.light"}
              boxShadow={{
                xl: `0px 0px 24px 0px ${
                  colorMode === "dark"
                    ? "rgba(0, 0, 0, 0.3)"
                    : "rgba(0, 0, 0, 0.1)"
                }`,
              }}
            >
              <Router />
              <ReactQueryDevtools initialOpen={false} />
            </Container>
          </Center>
        </ChakraProvider>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
