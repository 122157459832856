import React, { useEffect, useState } from "react";

import { Divider, HStack, Text, VStack } from "@chakra-ui/react";
import Sheet from "react-modal-sheet";

import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import PrimaryButton from "../buttons/PrimaryButton";
import PriamryCheckBox from "../checkbox/PriamryCheckBox";

export default function TempPaymentAgreementBottomSheet({
  isOpen,
  setIsOpen,
  onAgreementClick,
  title,
  description,
  buttonText = "결제하기",
  transparentBackground = false,
}) {
  const [isAgreeAll, setIsAgreeAll] = useState(false);
  const [isNotChild, setIsNotChild] = useState(false);
  const [termAndPrivacy, setTermAndPrivacy] = useState(false);

  useEffect(() => {
    // Check if all child checkboxes are checked
    const allChecked = isNotChild && termAndPrivacy;

    // Set the state of the parent checkbox accordingly
    setIsAgreeAll(allChecked);
  }, [isNotChild, termAndPrivacy]);

  const isRequiredAllChecked = () => {
    return termAndPrivacy && isNotChild;
  };

  const onClickButton = () => {
    if (!isRequiredAllChecked()) {
      return;
    }
    onAgreementClick();
  };

  const handleAgreeAllToggle = (checked) => {
    setIsAgreeAll(checked);
    setIsNotChild(checked);
    setTermAndPrivacy(checked);
  };

  return (
    <div style={{ height: "120px" }}>
      <Sheet
        isOpen={isOpen}
        disableDrag={false}
        springConfig={SPRING_CONFIG}
        onClose={() => setIsOpen(false)}
        detent={"content-height"}
      >
        <Sheet.Container
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          }}
        >
          <Sheet.Header className="py-4">
            {title && (
              <Text
                fontWeight={600}
                fontSize={"20px"}
                marginTop={"10px"}
                paddingRight={"36px"}
                wordBreak={"auto-phrase"}
              >
                {title}
              </Text>
            )}
            {description && (
              <Text
                fontWeight={400}
                fontSize={"14px"}
                marginTop={"7px"}
                marginBottom={"20px"}
                paddingRight={"36px"}
                wordBreak={"auto-phrase"}
              >
                {description}
              </Text>
            )}
            <HStack>
              <PriamryCheckBox
                size={"lg"}
                isChecked={isAgreeAll}
                onChanged={(checked) => handleAgreeAllToggle(checked)}
              />
              <Text
                className="text-main-light-1 dark:text-main-dark-1"
                fontSize={"17px"}
                fontWeight={600}
              >
                {"모두 동의"}
              </Text>
            </HStack>
            <Divider className="py-1" />
          </Sheet.Header>
          <Sheet.Content className="py-1" disableDrag={true}>
            <VStack align={"flex-start"}>
              <HStack>
                <PriamryCheckBox
                  isChecked={isNotChild}
                  onChanged={(checked) => setIsNotChild(checked)}
                />
                <Text
                  fontSize={"15px"}
                  fontWeight={400}
                  className="text-main-light-1 dark:text-main-dark-1"
                >
                  {"만 14세 이상 입니다."}
                </Text>
              </HStack>
              <HStack>
                <PriamryCheckBox
                  isChecked={termAndPrivacy}
                  onChanged={(checked) => setTermAndPrivacy(checked)}
                />
                <HStack spacing={0.5}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={400}
                    className="text-main-light-1 dark:text-main-dark-1"
                    minWidth={"50px"}
                  >
                    {
                      "(필수) 구매 및 결제대행서비스 이용약관, 개인정보 제3자 제공에 모두 동의합니다."
                    }
                  </Text>
                </HStack>
              </HStack>
              <div style={{ marginTop: "25px", width: "100%" }}>
                <PrimaryButton
                  text={buttonText}
                  onClick={() => onClickButton()}
                  width={"100%"}
                  minHeight={"52px"}
                  paddingX={"24px"}
                  paddingY={"16px"}
                  fontSize={"17px"}
                  disabled={!isRequiredAllChecked()}
                />
              </div>
            </VStack>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          style={
            transparentBackground ? { backgroundColor: "transparent" } : {}
          }
          onClose={() => setIsOpen(false)}
        />
      </Sheet>
    </div>
  );
}
