import { Box, Center, Stack, Text, Wrap } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { findIndex, first, isEmpty } from "lodash";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FilledButton from "../components/FilledButton";
import Page from "../components/Page";
import PhotoCardsByEvent from "../components/PhotoCardsByEvent";
import MyPhotoCardBottomSheet from "../components/bottomSheet/MyPhotoCardBottomSheet";
import { ReactComponent as ArrowUpShapeIcon } from "../icons/arrow.up.shape.svg";
import SelectPhotoCardBottomNav from "../layout/SelectPhotoCardBottomNav";

import { t } from "i18next";
import EmptyPhotoCardEventSkeleton from "../components/skeletons/EmptyPhotoCardEventSkeleton";
import baseUrl from "../utils/baseUrl";
import { setEvent } from "../utils/ga";
import getToken from "../utils/getToken";
import { queryGet } from "../utils/queryClient";
import { getArtistId, getMemberIdAnyway } from "../utils/etcUtils";

async function collectPhotoCards(artistId, memberId, selectedPhotoCards) {
  return fetch(baseUrl() + `/photo_cards/collected`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      artistId: artistId,
      memberId: memberId,
      photoCardIds: selectedPhotoCards.map((p) => {
        return p.photoCardId;
      }),
    }),
  }).then((data) => data.json());
}

export default function ArtistPhotoCardsPage({
  componentData,
  onConfirmedCollectPhotoCard,
  showBackButton = false,
}) {
  const artistId = getArtistId();
  const { state } = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const data = state || componentData;
  const [selectedMember, setSelectedMember] = useState(null);
  const [likedMembers, setLikedMembers] = useState([]);
  const [selectedPhotoCards, setSelectedPhotoCards] = useState();
  const [editable, setEditable] = useState(data?.editable);

  const [photoCardByEvent, setPhotoCardByEvent] = useState();
  const [isMyPhotoCardsBottomSheetOpen, setIsMyPhotoCardsBottomSheetOpen] =
    useState(false);

  const { data: artist } = useQuery(
    ["artist", artistId],
    () => queryGet(`/artists/${artistId}`),
    {
      select: (data) => data?.artist,
    }
  );

  const { data: userProfile } = useQuery(
    ["userProfile", artistId],
    () => queryGet(`/artists/${artistId}/user_profile`),
    {
      enabled: !!artistId,
      staleTime: 0,
    }
  );

  useEffect(() => {
    return () => {
      setLikedMembers([]);
      setSelectedMember(null);
    };
  }, []);

  useEffect(() => {
    setLikedMembers(userProfile?.likedMembers);
    if (
      findIndex(
        userProfile?.likedMembers,
        (m) => getMemberIdAnyway(m) === getMemberIdAnyway(selectedMember)
      ) === -1
    ) {
      setSelectedMember(first(userProfile?.likedMembers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const collectedPhotoCardsQueryParams = new URLSearchParams();
  if (!!getMemberIdAnyway(selectedMember)) {
    collectedPhotoCardsQueryParams.append(
      "memberId",
      getMemberIdAnyway(selectedMember)
    );
  }
  const { data: collectedPhotoCards } = useQuery(
    ["collectedPhotoCards", collectedPhotoCardsQueryParams.toString()],
    () =>
      collectedPhotoCardsQueryParams.has("memberId") &&
      queryGet(
        `/users/collected_photo_cards?${collectedPhotoCardsQueryParams}`
      ),
    {
      enabled: collectedPhotoCardsQueryParams.has("memberId"),
      select: (data) => data?.photoCards,
    }
  );

  const { data: originPhotoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards?page=${1}&per_page=9999`
      ),
    {
      enabled: !!getMemberIdAnyway(selectedMember),
    }
  );

  const refreshPage = () => {
    setSelectedPhotoCards(null);
    queryClient.invalidateQueries(["artistPhotoCards"]);
    queryClient.refetchQueries(["collectedPhotoCards"]);
  };

  const initializePagination = () => {
    setPhotoCardByEvent(null);
  };

  useEffect(() => {
    if (collectedPhotoCards) {
      setSelectedPhotoCards(collectedPhotoCards);
    }
    setPhotoCardByEvent(originPhotoCardByEvent);
  }, [originPhotoCardByEvent, collectedPhotoCards]);

  const onChangedMember = (member) => {
    initializePagination();
    setSelectedMember(member);
  };

  const selectPhotoCard = (photoCard, event) => {
    if (editable) {
      const tempList = [...selectedPhotoCards];
      if (
        findIndex(tempList, (p) => p.photoCardId === photoCard.photoCardId) >= 0
      ) {
        setSelectedPhotoCards(
          tempList.filter((t) => t?.photoCardId !== photoCard.photoCardId)
        );
      } else {
        tempList.push(photoCard);
        setSelectedPhotoCards(tempList);
      }
    } else {
      navigate(`/photo_cards/${photoCard.photoCardId}`);
    }
  };

  const invalidateQueries = () => {
    initializePagination();
    queryClient.invalidateQueries(["collectedPhotoCards"]);
    queryClient.invalidateQueries(["artistPhotoCards"]);
  };

  const confirmedSelectPhotoCard = async () => {
    if (editable) {
      if (isEmpty(selectedPhotoCards)) {
        return alert(t("photoCardPage.confirmErrorAlert"));
      } else {
        const response = await collectPhotoCards(
          artistId,
          getMemberIdAnyway(selectedMember),
          selectedPhotoCards
        );
        if (onConfirmedCollectPhotoCard) {
          onConfirmedCollectPhotoCard();
        } else if (response && response.success) {
          if (data?.firstTime) {
            invalidateQueries();
            navigate(`/home`);
          } else {
            setEditable(false);
            refreshPage();
          }
        }
      }
    } else {
      setEditable(!editable);
    }
  };

  const onHistoryBackClick = () => {
    if (editable && !data?.firstTime) {
      setEditable(false);
    } else {
      invalidateQueries();
      window.history.back();
    }
  };

  const onNavigationClick = () => {
    if (data?.firstTime) {
      setEvent({
        category: "PhotoCard",
        action: "Skip Collected PhotoCards",
        label: "PhotoCardsPage",
      });
      navigate(`/home`);
    } else if (editable) {
      navigate("/photo_cards/report", {
        state: {
          artist: artist,
          members: likedMembers,
          showBackButton: true,
          navBarVisible: false,
        },
      });
    } else {
      setEvent({
        category: "PhotoCard",
        action: "Edit Collected PhotoCards",
        label: "PhotoCardsPage",
      });
      setEditable(true);
    }
  };

  return (
    <div>
      <Page
        title={t("photoCardPage.pageName")}
        showBackButton={data?.showBackButton || showBackButton}
        userProfile={userProfile}
        navigationElement={
          data?.firstTime ? (
            <Text>{t("photoCardPage.navigationSkip")}</Text>
          ) : editable ? (
            <Text>{t("photoCardPage.navigationReport")}</Text>
          ) : (
            <Text>{t("photoCardPage.navigationEdit")}</Text>
          )
        }
        onNavigationClick={onNavigationClick}
        onHistoryBackClick={onHistoryBackClick}
      >
        <Stack spacing={0} className={data?.showBackButton ? "" : "mt-5"}>
          {data?.firstTime ? (
            <>
              <Text
                fontSize="26px"
                color={"#444444"}
                fontWeight={700}
                lineHeight={"120%"}
              >
                {t("photoCardPage.title")}
              </Text>
              <Text fontSize="15px" color={"#888"} mt={"8px!important"}>
                {t("photoCardPage.description")}
              </Text>
            </>
          ) : (
            <>
              <Text fontSize="17px" color={"#444444"} fontWeight={500}>
                {t("photoCardPage.listTitle")}
              </Text>
              <Text fontSize="26px" fontWeight={700}>
                {artist?.name}
              </Text>
            </>
          )}

          {data?.firstTime ? (
            <></>
          ) : !editable ? (
            <Center>
              <Box
                marginTop={"18px"}
                marginBottom={"16px"}
                paddingX={"24px"}
                paddingY={"14px"}
                width={"100%"}
                borderRadius={"12px"}
                backgroundImage={"/assets/backgrounds/banner_bg_holography.png"}
              >
                <Wrap
                  justify={"center"}
                  onClick={() => setIsMyPhotoCardsBottomSheetOpen(true)}
                >
                  <Text color={"#222222"} fontSize={"17px"} fontWeight={600}>
                    {t("photoCardPage.bannerTitle")}
                  </Text>
                  <ArrowUpShapeIcon fill="black" />
                </Wrap>
              </Box>
            </Center>
          ) : (
            <></>
          )}
        </Stack>
        {likedMembers?.length > 1 ? (
          <Wrap spacing={"8px"} paddingTop="18px" paddingBottom="16px">
            {likedMembers?.map((member) => {
              return (
                <FilledButton
                  key={getMemberIdAnyway(member)}
                  paddingX={"12px"}
                  paddingY={"8px"}
                  borderRadius={"32px"}
                  text={member.name}
                  fontSize={"14px"}
                  fontWeight={500}
                  onClicked={() => onChangedMember(member)}
                  filled={
                    getMemberIdAnyway(selectedMember) ===
                    getMemberIdAnyway(member)
                  }
                />
              );
            })}
          </Wrap>
        ) : (
          <></>
        )}
        <div>
          {photoCardByEvent && selectedPhotoCards ? (
            <PhotoCardsByEvent
              photoCardsByEvent={photoCardByEvent}
              selectedPhotoCards={selectedPhotoCards}
              onClickedPhotoCard={selectPhotoCard}
              enableAll={false}
              collectingMode={editable}
              editable={editable}
            />
          ) : (
            <EmptyPhotoCardEventSkeleton />
          )}
        </div>
        <div className="endOfScroll" style={{ paddingBottom: "120px" }}>
          {/* {!isEndOfScroll && <EmptyPhotoCardEventSkeleton/>} */}
        </div>
      </Page>

      <SelectPhotoCardBottomNav
        selectedMember={selectedMember}
        selectedCardCount={selectedPhotoCards?.length}
        totalCardCount={photoCardByEvent?.totalCount}
        onConfirmedButton={confirmedSelectPhotoCard}
        editable={editable}
      />

      <MyPhotoCardBottomSheet
        members={likedMembers}
        isMyPhotoCardsBottomSheetOpen={isMyPhotoCardsBottomSheetOpen}
        setIsMyPhotoCardsBottomSheetOpen={setIsMyPhotoCardsBottomSheetOpen}
      />
    </div>
  );
}
