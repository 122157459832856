import React, { useState } from "react";
import Sheet from "react-modal-sheet";

import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import useUserQuery from "../api/queries/useUserQuery";
import styled from "styled-components";
import { Radio } from "@chakra-ui/react";
import { t } from "i18next";

const ArtistFandomWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  flex-direction: row;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
`;
const SelectedArtistText = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: left;
`;

const UnSelectedArtistText = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: left;
`;

const DisabledArtistText = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20.4px;
  text-align: left;
`;

const RadioWrapper = styled.div`
  flex-shrink: 1;
`;

export default function SelectArtistFandomBottomSheet({
  isOpen,
  setIsOpen,
  onSelectArtistFandom,
}) {
  const user = useUserQuery();
  const [selectedArtist, setSelectedArtist] = useState();

  const onSelectArtist = (artist) => {
    setSelectedArtist(artist);
    onSelectArtistFandom(artist);
  };

  return (
    <Sheet
      isOpen={isOpen}
      disableDrag={true}
      springConfig={SPRING_CONFIG}
      onClose={() => setIsOpen(false)}
      detent={"content-height"}
    >
      <Sheet.Container
        className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
        style={{
          padding: "18px 20px",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <Sheet.Content className="py-1" disableDrag={true}>
          <ArtistFandomWrapper>
            {user &&
              user.likedArtists.map((artist) => {
                return (
                  <Row
                    key={artist.artistId}
                    onClick={() => onSelectArtist(artist)}
                  >
                    <TextWrapper>
                      {artist === selectedArtist ? (
                        <SelectedArtistText className="text-primary-light dark:text-primary-dark">
                          {artist.fandomName}
                        </SelectedArtistText>
                      ) : (
                        <UnSelectedArtistText className="text-main-light-2 dark:text-main-dark-2">
                          {artist.fandomName}
                        </UnSelectedArtistText>
                      )}
                    </TextWrapper>
                    <RadioWrapper>
                      <Radio
                        colorScheme="primary"
                        isChecked={artist === selectedArtist}
                      />
                    </RadioWrapper>
                  </Row>
                );
              })}
            <Row key={"friendsOnly"}>
              <TextWrapper>
                <DisabledArtistText className="text-onDisabled-light dark:text-onDisabled-dark">
                  {t("addPostPage.friendsOnly")}
                </DisabledArtistText>
              </TextWrapper>
              <RadioWrapper>
                <Radio
                  colorScheme="primary"
                  disabled={true}
                  isChecked={false}
                />
              </RadioWrapper>
            </Row>
          </ArtistFandomWrapper>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => setIsOpen(false)} />
    </Sheet>
  );
}
