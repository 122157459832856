import React from "react";

import {
  Badge,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import styled from "styled-components";

import { t } from "i18next";

const headerStyle = {
  paddingTop: "24px",
  paddingBottom: "0px",
};

const HeaderTitle = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
`;

const MemberColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const MemberText = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

export default function MutualMembersBottomDrawer({
  isOpen,
  onClose,
  members,
}) {
  const MemberRow = (member) => {
    return (
      <MemberColumn key={member.memberId}>
        <MemberText className="text-main-light-1 dark:text-main-dark-1">
          {member.name}
        </MemberText>
        <div className="flex flex-row gap-1">
          {member.artistNames.map((artistName) => {
            return (
              <Badge
                className="text-onLabel-light dark:text-onLabel-dark bg-surface-light dark:bg-surface-dark"
                style={{
                  width: "fit-content",
                  fontSize: "11px",
                  lineHeight: "16px",
                  fontWeight: 600,
                  padding: "4px 8px",
                  borderRadius: "4px",
                }}
              >
                {artistName}
              </Badge>
            );
          })}
        </div>
      </MemberColumn>
    );
  };

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
        <DrawerOverlay />
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            },
          }}
          style={{
            maxWidth: "calc(var(--chakra-sizes-xl))",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            left: "",
            right: "",
          }}
        >
          <DrawerHeader style={headerStyle}>
            <HeaderTitle className="text-main-light-2 dark:text-main-dark-2">
              {t("MutualMembersBottomDrawer.title")}
            </HeaderTitle>
          </DrawerHeader>
          <DrawerBody paddingTop={"20px"} paddingX={"20px"} maxHeight={"360px"}>
            {members.map((member) => {
              return (
                <Column className="border-b-[1px] border-separator-light-1 dark:border-separator-dark-1">
                  {MemberRow(member)}
                </Column>
              );
            })}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
