import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";
import { isEmpty } from "lodash";

export default function useExchangeablePhotoCardsQuery({
  userId,
  artistId,
  memberId,
}) {
  const isEnabled = !isEmpty(artistId) && !isEmpty(memberId) && !isEmpty(userId)
  const { data: photoCards } = useQuery(
    ["ExchangeablePhotoCards", artistId, memberId, userId],
    () => queryGet(`/api/v1/photo_cards/exchangeable?artist_id=${artistId}&member_id=${memberId}&user_id=${userId}`),{
      enabled: isEnabled,
      select: (data) => {
        if (data.photoCards) {
          return data.photoCards;
        } else if (!data.success && data.error){
          return { error: data.error }
        }
      },
    },
  );

  return photoCards;
}
