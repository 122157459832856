import { Image } from "@chakra-ui/react";
import { t } from "i18next";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import styled from "styled-components";

const GradientBorderBox = styled.div`
  width: 100%;
  background: linear-gradient(99.67deg, #5c3dfa 0%, #00c8ff 50%, #5500ff 100%)
      border-box,
    linear-gradient(white, white) padding-box;
  border: 1.5px solid transparent;
  border-radius: 12px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08),
    0px 0px 0px 1px rgba(0, 0, 0, 0.01);
  padding: 1px;
  overflow: auto;
`;

const ContentContainer = styled.div`
  border-radius: 12px;
  padding: 20px 0px;
`;
const HeaderWrapper = styled.div`
  padding: 0px 20px 12px 20px;
  border-radius: 12px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
`;

const Description = styled.span`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
`;

const ViewAllButton = styled.button`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

const ScrollContainer = styled.div`
  width: 100%;
  scroll-snap-align: start;

  .react-horizontal-scrolling-menu--scroll-container {
    padding-left: 20px;
  }
`;

export default function PendingPhotoCardsSection({
  onViewAllClick,
  onPhotoCardClick,
  reportedPhotoCards,
}) {
  return (
    <GradientBorderBox>
      <ContentContainer className="bg-background-light dark:bg-background-dark">
        <HeaderWrapper className="bg-background-light dark:bg-background-dark">
          <Header>
            <div className="flex flex-col gap-1">
              <Title className="text-main-light-2 dark:text-main-dark-2">
                {t("PendingPhotoCardsSection.title")}
              </Title>
              <Description className="text-main-light-2 dark:text-main-dark-2">
                {t("PendingPhotoCardsSection.description")}
              </Description>
            </div>
            {onViewAllClick && (
              <ViewAllButton
                className="text-primary-light dark:text-primary-dark"
                onClick={onViewAllClick}
              >
                {t("PendingPhotoCardsSection.viewAll")}
              </ViewAllButton>
            )}
          </Header>
        </HeaderWrapper>

        <ScrollContainer>
          <ScrollMenu separatorClassName="pl-2">
            {reportedPhotoCards?.map((photoCard) => (
              <Image
                key={photoCard.photoCardId}
                src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                height={"90px"}
                maxWidth={"57px"}
                objectFit={"cover"}
                aspectRatio={"55/87"}
                loading={"lazy"}
                borderRadius="8px"
                boxShadow={
                  "0px 2px 2px 0px rgba(0, 0, 0, 0.08), 0px 4px 9px 0px rgba(0, 0, 0, 0.08)"
                }
                onClick={() => onPhotoCardClick(photoCard)}
              />
            ))}
          </ScrollMenu>
        </ScrollContainer>
      </ContentContainer>
    </GradientBorderBox>
  );
}
