import styled from "styled-components";
import { openNewTab } from "../../utils/UIUtils";
import { t } from "i18next";

const GradientBox = styled.div`
  border: 1.5px solid transparent;
  border-radius: 12px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(99.67deg, #5c3dfa 0%, #00c8ff 50%, #5500ff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
`;

const InsideBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  align-items: center;
`;

const Title = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
`;

const Description = styled.div`
  font-size: 13px;
  font-weight: 300;
  line-height: 15px;
  text-align: center;
  margin-top: 6px;
`;

const ReportButton = styled.div`
  margin-top: 20px;
  width: fit-content;
  padding: 8px 20px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
`;

export const ReportPhotoCardBox = ({ artist, member }) => {
  return (
    <GradientBox
      onClick={() =>
        openNewTab(
          `/report-photo-card/event-activities?artistId=${artist?.artistId}&memberId=${member?.memberId}`
        )
      }
    >
      <InsideBoxWrapper className="bg-background-light dark:bg-background-dark rounded-[12px]">
        <Title className="text-main-light-2 dark:text-main-dark-2">
          {t("ReportPhotoCardBox.title", { memberName: member.name })}
        </Title>
        <Description className="text-main-light-3 dark:text-main-dark-3">
          {t("ReportPhotoCardBox.description")}
        </Description>
        <ReportButton className="bg-main-light-2 dark:bg-main-dark-2 text-pure-white dark:text-pure-black">
          {t("ReportPhotoCardBox.reportButton")}
        </ReportButton>
      </InsideBoxWrapper>
    </GradientBox>
  );
};
