import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { t } from "i18next";
import { findIndex, sortBy } from "lodash";
import uuid from "react-uuid";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import {
  Divider,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  useBreakpointValue,
  useToast,
  Wrap,
} from "@chakra-ui/react";

import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as CloseIcon } from "../icons/xmark.svg";
import FilledButton from "../components/FilledButton";
import EmptyCase from "../components/errors/EmptyCase";
import TradingPhotoCardWindowList from "../components/lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../components/photocard/AddTradingPhotoCard";
import EmptyPhotoCardEventSkeleton from "../components/skeletons/EmptyPhotoCardEventSkeleton";
import ToastMessageBox from "../components/alerts/ToastMessageBox";
import { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import EditNewTradingPhotoCardsBottomSheet from "../components/bottomSheet/EditNewTradingPhotoCardsBottomSheet";
import { EventNameBox } from "../components/event/EventNameBox";
import LikedMembersList from "../components/lists/LikedMembersList";
import ArtistTextSelector from "../components/selectors/ArtistTextSelector";
import filterPhotoCards from "../utils/SearchPhotoCard";
import { getMemberIdAnyway } from "../utils/etcUtils";
import { queryGet } from "../utils/queryClient";
import StickyHeader from "../components/StickyHeader";
import LightColors from "../constants/LightColors";

const Title = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  white-space: pre-wrap;
  line-height: 31px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const PageWrapper = styled.div`
  margin-top: calc(58px + env(safe-area-inset-top));
  padding-left: 24px;
  padding-right: 24px;
`;

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 8px;
`;

export default function AddTradingPhotoCardPage() {
  const [searchText, setSearchText] = useState();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState();
  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  const [isShowEditBottomSheet, setIsShowEditBottomSheet] = useState(false);

  const artistId = selectedArtist?.artistId;
  const navigate = useNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const user = useUserQuery();
  const artists = useArtistQueryLikedOnly();

  const convertPhotoCardWithEvent = (photoCard, e) => {
    return { ...photoCard, ...{ event: e.event } };
  };

  const convertTradingPhotoCard = (photoCard, e) => {
    return {
      tradingPhotoCardId: uuid(),
      artistId: photoCard.artistId,
      status: "wating",
      price: null,
      photoCard: photoCard,
      event: e.event,
    };
  };

  const { data: allPhotoCardByEvent } = useQuery(
    ["allArtistPhotoCards", artistId],
    () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
    {
      enabled: !!artistId,
      select: (r) =>
        r.events.flatMap((e) =>
          e.photoCards.map((p) => convertPhotoCardWithEvent(p, e))
        ),
    }
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards`
      ),
    {
      enabled: !!artistId && !!selectedMember,
    }
  );

  const selectPhotoCard = (photoCard, e) => {
    const tempList = [...newTradingPhotoCards];
    tempList.unshift(convertTradingPhotoCard(photoCard, e));
    setNewTradingPhotoCards(tempList);
  };

  const deletePhotoCard = (photoCard) => {
    const index = findIndex(
      newTradingPhotoCards,
      (t) => t.photoCard.photoCardId === photoCard.photoCardId
    );
    if (index > -1) newTradingPhotoCards.splice(index, 1);
    setNewTradingPhotoCards([...newTradingPhotoCards]);
  };

  const onHandleSearchText = (text) => {
    setSearchText(text);
  };

  const showEditCardBottomSheet = () => {
    if (user && user.tradingUserProfile) {
      setIsShowEditBottomSheet(true);
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("tradingPhotoCardPage.noProfileToast")} />
        ),
      });
    }
  };

  const onClosedBottomSheet = () => {
    queryClient.invalidateQueries(["user"]);
    queryClient.refetchQueries(["tradingPhotoCards", artistId]);
    queryClient.invalidateQueries(["matchedTradingPhotoCards", artistId]);

    window.history.back();
  };

  const columns = useBreakpointValue({
    base: 4, // 모바일
    md: 6, // iPad
    lg: 6, // 큰 화면
  });

  return (
    <>
      <StickyHeader
        title={t("addTradingPhotoCardPage.pageName")}
        showTitle={true}
        showBackButton={true}
        rightContent={
          <FilledButton
            paddingX={"18px"}
            paddingY={"11px"}
            borderRadius={"32px"}
            fontSize={"14px"}
            filled={newTradingPhotoCards?.length > 0}
            className={
              newTradingPhotoCards?.length > 0
                ? "bg-primary-light dark:bg-primary-dark text-white"
                : "bg-disabled-light dark:bg-disabled-dark text-onDisabled-light dark:text-onDisabled-dark"
            }
            text={
              newTradingPhotoCards?.length > 0
                ? t("addTradingPhotoCardPage.countButton", {
                    count: newTradingPhotoCards?.length,
                  })
                : t("addTradingPhotoCard.addButton")
            }
            fontWeight={500}
            onClicked={showEditCardBottomSheet}
          />
        }
      />
      <PageWrapper>
        {newTradingPhotoCards?.length > 0 ? (
          <div
            className="bg-background-light dark:bg-background-dark"
            style={{
              position: "sticky",
              top: "calc(64px + env(safe-area-inset-top))",
              zIndex: 99999,
            }}
          >
            <div
              className="horizontalScroll hiddenScrollBar"
              style={{ paddingTop: "8px" }}
            >
              {newTradingPhotoCards?.map((t) => {
                return (
                  <div
                    key={t.tradingPhotoCardId}
                    className="relative"
                    style={{ marginRight: "4px" }}
                  >
                    <Image
                      src={t.photoCard.pictureUrl}
                      width={57}
                      height={90}
                      borderRadius={"8px"}
                      loading={"lazy"}
                    />
                    <div
                      className="absolute top-0 right-0 bg-primary-light dark:bg-primary-dark"
                      style={{
                        borderRadius: "32px",
                        padding: "5px",
                        marginRight: 3,
                        marginTop: -8,
                      }}
                      onClick={() => deletePhotoCard(t.photoCard)}
                    >
                      <CloseIcon
                        className="fill-white"
                        width={"16px"}
                        height={"16px"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Wrap marginX={"-24px"} paddingTop={"24px"}>
              <Divider />
            </Wrap>
          </div>
        ) : (
          <></>
        )}
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("addTradingPhotoCardPage.title")}
        </Title>
        <InputGroup marginTop={"10px"}>
          <Input
            className="text-main-light-2 dark:text-main-dark-2 bg-secondary-light dark:bg-secondary-dark
            placeholder:text-[17px] placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark"
            height={"54px"}
            border={"none"}
            borderRadius={"12px"}
            fontSize={"17px"}
            fontWeight={400}
            placeholder={t("addTradingPhotoCardPage.searchInputPlaceholder")}
            onChange={(e) => onHandleSearchText(e.target.value)}
          />
          <InputRightElement
            children={<SearchIcon fill={LightColors.primary} />}
            marginRight={"12px"}
            marginTop={"6px"}
          />
        </InputGroup>
        {!searchText && (
          <>
            <ArtistTextSelector
              artists={artists}
              initialArtistId={artistId}
              onSelected={setSelectedArtist}
            />
            <LikedMembersList
              artistMembers={selectedArtist?.members}
              onChangeLikedMember={setSelectedMember}
              showAllMember={true}
            />
          </>
        )}
        {!photoCardByEvent && (
          <Wrap paddingY="15px">
            <EmptyPhotoCardEventSkeleton />
          </Wrap>
        )}
        {!!searchText ? (
          <TradingPhotoCardWindowList
            tradingPhotoCards={filterPhotoCards(
              allPhotoCardByEvent,
              searchText
            )}
            addTradingPhotoCard={selectPhotoCard}
          />
        ) : photoCardByEvent && photoCardByEvent?.totalCount > 0 ? (
          <Virtuoso
            useWindowScroll
            style={{ height: "100%" }}
            overscan={480}
            data={photoCardByEvent?.events}
            itemContent={(index, e) => {
              const photoCards = e.photoCards.map((p) =>
                convertPhotoCardWithEvent(p, e)
              );
              return (
                photoCards.length > 0 && (
                  <Stack key={e.event?.name}>
                    <EventNameBoxWrapper>
                      <EventNameBox event={e.event} />
                    </EventNameBoxWrapper>
                    <SimpleGrid
                      columns={columns}
                      gap={"8px"}
                      margin={"0px!important"}
                    >
                      {sortBy(photoCards, (p) => {
                        return -p.collectedCount;
                      }).map((photoCard) => {
                        return (
                          <AddTradingPhotoCard
                            key={photoCard.photoCardId}
                            photoCard={photoCard}
                            onClickPhotoCard={(p, e) => selectPhotoCard(p, e)}
                          />
                        );
                      })}
                    </SimpleGrid>
                  </Stack>
                )
              );
            }}
          />
        ) : (
          photoCardByEvent && (
            <EmptyCase
              emoji={"🥲"}
              title={t("addTradingPhotoCard.emptyCollectedCaseTitle")}
              description={t(
                "addTradingPhotoCard.emptyCollectedCaseDescription"
              )}
              buttonText={t("addTradingPhotoCard.emptyCollectedCaseButton")}
              onClickButton={() =>
                navigate(`/artists/photo_cards_v2?editable=true`)
              }
            />
          )
        )}
      </PageWrapper>
      {user && isShowEditBottomSheet && (
        <EditNewTradingPhotoCardsBottomSheet
          isShowBottomSheet={isShowEditBottomSheet}
          setIsShowBottomSheet={setIsShowEditBottomSheet}
          tradingUserProfile={user.tradingUserProfile}
          tradingPhotoCards={newTradingPhotoCards}
          onClosed={onClosedBottomSheet}
        />
      )}
    </>
  );
}
