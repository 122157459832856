import React, { useRef, useState } from "react";

import { isIOS, isMobile } from "react-device-detect";
import Sheet from "react-modal-sheet";
import { useFilePicker } from "use-file-picker";

import {
  Center,
  Flex,
  Image,
  Input,
  Spacer,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";

import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as EditIcon } from "../../icons/edit.svg";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import PrimaryButton from "../buttons/PrimaryButton";

import { first } from "lodash";
import { useQueryClient } from "react-query";
import { isNativeIOS } from "../../utils/NativeUtil";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import addAndMergeUserProfileV1 from "../api/mutations/addAndMergeUserProfileV1";
import { useNavigate } from "react-router-dom";
import ToastMessageBox from "../alerts/ToastMessageBox";
import addUserProfileV1 from "../api/mutations/addUserProfileV1";
import { t } from "i18next";
import { hasBatchim } from "../../utils/TextUtil";

const addProfileButtonStyle = {
  padding: "14px",
  fontWeight: 600,
  fontSize: "17px",
  lineHeight: "20px",
};

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
  lineHeight: "20px",
  wordBreak: "keep-all",
  padding: "0px 30px",
};

export default function AddUserProfileV1BottomSheet({
  title,
  userProfile,
  isShowProfileBottomSheet,
  setIsShowProfileBottomSheet,
  isMergeProfile = false,
  category = "trading",
  afterGoBack = false,
  disableDrag = true,
  disableBackdrop = false,
  onCompleted,
}) {
  const containerRef = useRef();
  const inputRef = useRef();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const [nickname, setNickname] = useState(userProfile?.nickname);
  const [errorMessage, setErrorMessage] = useState("");

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  const buttonText = () => {
    if (!nickname) return t("profiles.defaultButton");
    return hasBatchim(nickname)
      ? t("profiles.hasBatchimNickNameButton", { nickname: nickname })
      : t("profiles.nickNameButton", { nickname: nickname });
  };

  const onFocusedInput = () => {
    if (isNativeIOS()) return;
    if (containerRef.current && isMobile && isIOS) {
      containerRef.current.style.paddingBottom = "360px";
    }
  };

  const onUnFocusedInput = () => {
    if (isNativeIOS()) return;
    if (containerRef.current && isMobile && isIOS) {
      containerRef.current.style.paddingBottom = "0px";
    }
  };

  const listener = () => {
    const MIN_KEYBOARD_HEIGHT = 300; // N.B.! this might not always be correct
    const isMobile = window.innerWidth < 768;
    const isKeyboardOpen =
      isMobile &&
      isIOS &&
      window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height;

    if (!isKeyboardOpen && inputRef.current) {
      onUnFocusedInput();
    }
  };
  window.visualViewport.addEventListener("resize", listener);

  const onUpdateNickname = (nickname) => {
    setErrorMessage("");
    setNickname(nickname);
  };

  const createUserProfile = async (nickname) => {
    if (!nickname) {
      return setErrorMessage(t("profiles.emptyNicknameErrorMessage"));
    }
    if (userProfile && !isMergeProfile) {
      category = userProfile.category;
    }
    if (isMergeProfile) {
      const response = await addAndMergeUserProfileV1(
        category,
        nickname,
        filesContent[0]?.content
      );
      if (response.ok) {
        toast({
          duration: 1500,
          render: () => (
            <ToastMessageBox
              message={t("profiles.mergedNicknameToastMessage")}
            />
          ),
        });
        completed();
      }
    } else {
      const response = await addUserProfileV1(
        category,
        nickname,
        filesContent[0]?.content
      );
      if (response.ok) {
        completed();
      }
    }
  };

  const completed = () => {
    queryClient.refetchQueries("users");
    queryClient.refetchQueries("userProfile");
    queryClient.refetchQueries("V1:UserProfilesList");
    setIsShowProfileBottomSheet(false);
    if (onCompleted) {
      onCompleted();
    }
    if (afterGoBack) {
      navigate(-1);
    }
  };

  return (
    <div>
      <Sheet
        isOpen={isShowProfileBottomSheet}
        disableDrag={disableDrag}
        onClose={() => setIsShowProfileBottomSheet(false)}
        springConfig={SPRING_CONFIG}
        detent="content-height"
      >
        <Sheet.Container
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            paddingBottom: "calc(2rem + env(safe-area-inset-bottom))",
          }}
        >
          <Sheet.Header
            className="bottomSheetHeader"
            style={{ paddingTop: "30px" }}
          >
            <VStack className="text-center" gap={0}>
              <Text style={titleStyle}>{title || t("profiles.title")}</Text>
            </VStack>
          </Sheet.Header>
          <Sheet.Content
            ref={containerRef}
            disableDrag={true}
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
              position: "sticky",
            }}
          >
            <Center>
              <Wrap onClick={openFilePicker}>
                <WrapItem className="relative">
                  <Center
                    className="bg-theirty-light dark:bg-theirty-dark"
                    width={"64px"}
                    height={"64px"}
                    borderRadius={"32px"}
                  >
                    {filesContent.length > 0 || userProfile?.thumbnailUrl ? (
                      <>
                        <Image
                          width={"64px"}
                          height={"64px"}
                          objectFit={"cover"}
                          borderRadius={"full"}
                          src={
                            first(filesContent)?.content ||
                            userProfile?.thumbnailUrl
                          }
                        />
                        <div
                          className="absolute bottom-0 right-0 bg-primary-light dark:bg-primary-dark text-onPrimary"
                          style={{
                            borderRadius: "32px",
                            padding: "3px",
                          }}
                        >
                          <EditIcon
                            className="fill-white"
                            width={"10px"}
                            height={"10px"}
                          />
                        </div>
                      </>
                    ) : (
                      <div>
                        <ProfileFillIcon
                          className="fill-white"
                          width={"45px"}
                          height={"45px"}
                        />
                        <div
                          className="bg-primary-light dark:bg-primary-dark text-onPrimary absolute bottom-0 right-0"
                          style={{
                            borderRadius: "32px",
                            padding: "4px",
                            borderWidth: "2px",
                            borderColor: "white",
                          }}
                        >
                          <CameraIcon
                            className="fill-white"
                            width={"16px"}
                            height={"16px"}
                          />
                        </div>
                      </div>
                    )}
                  </Center>
                </WrapItem>
              </Wrap>
            </Center>
            <div style={{ marginTop: "24px" }}>
              <Input
                className="bg-secondary-light dark:bg-secondary-dark hover:bg-secondary-light dark:hover:bg-secondary-dark"
                ref={inputRef}
                variant="filled"
                size={"lg"}
                _focusVisible={true}
                placeholder={t("profiles.inputNickNamePlaceholder")}
                maxLength={20}
                value={nickname}
                onFocus={() => onFocusedInput()}
                onBlur={() => onUnFocusedInput()}
                onTouchStart={(e) =>
                  setTimeout(() => {
                    onFocusedInput(e);
                  }, 150)
                }
                onChange={(e) => onUpdateNickname(e.target.value)}
              />
              <Flex>
                <div>
                  {errorMessage && (
                    <Text className="text-warning" fontSize={"13px"}>
                      {errorMessage}
                    </Text>
                  )}
                </div>
                <Spacer />
                <div>
                  {nickname && (
                    <Text value={nickname} fontSize={"13px"}>
                      {nickname.length}/20
                    </Text>
                  )}
                </div>
              </Flex>
            </div>
            <PrimaryButton
              disabled={!nickname && !errorMessage}
              text={buttonText()}
              marginTop={"12px"}
              style={addProfileButtonStyle}
              onClick={() => createUserProfile(nickname)}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
          onClick={() => !disableBackdrop && setIsShowProfileBottomSheet(false)}
        />
      </Sheet>
    </div>
  );
}
