import { Image, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isNull } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const containerStyle = {
  width: "120px",
  height: "120px",
  borderRadius: "12px",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "10px",
};

const thumbnailImageStyle = {
  aspectRatio: "55/87",
  height: "63px",
  borderRadius: "5px",
  zIndex: 2,
  position: "relative",
};

const PhotoCardName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 0px !important;
`;

const EventName = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 3px !important;
`;

const PriceName = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-top: 6px !important;
`;

export default function CompactTradingPhotoCard({ tradingPhotoCard }) {
  const navigate = useNavigate();

  if (isEmpty(tradingPhotoCard)) return null;

  const navigateTradingPhotoCardDeatail = () => {
    navigate(
      `/trading_photo_cards/${tradingPhotoCard.tradingPhotoCardId}?photoCardId=${tradingPhotoCard.photoCardId}`
    );
  };

  return (
    <VStack
      align="center"
      width="120px"
      alignItems={"left"}
      onClick={navigateTradingPhotoCardDeatail}
    >
      {
        // isEmpty(tradingPhotoCard.pictureUrls) ?
        // <div style={containerStyle}>
        //    <Image
        //     style={thumbnailImageStyle}
        //     src={tradingPhotoCard.photoCard.mediumThumbnailUrl || tradingPhotoCard.photoCard.pictureUrl}
        //     alt={tradingPhotoCard.photoCard.memberName}
        //   />
        // </div>
        // :
        // <div style={containerStyle}>
        //   <Image
        //     style={fullImageStyle}
        //     src={first(tradingPhotoCard.mediumThumbnailUrls) || first(tradingPhotoCard.pictureUrls)}
        //     alt={tradingPhotoCard.photoCard.memberName}
        //   />
        // </div>
        <div
          className="bg-surface-light dark:bg-surface-dark"
          style={containerStyle}
        >
          <Image
            style={thumbnailImageStyle}
            src={
              tradingPhotoCard.photoCard.mediumThumbnailUrl ||
              tradingPhotoCard.photoCard.pictureUrl
            }
            alt={tradingPhotoCard.photoCard.memberName}
          />
        </div>
      }
      <PhotoCardName className="text-main-light-1 dark:text-main-dark-1">
        {t("photoCardDetailPage.title", {
          memberName: tradingPhotoCard.photoCard.memberName,
          categoryName: tradingPhotoCard.photoCard.categoryName,
          categoryEmoji: tradingPhotoCard.photoCard.categoryEmoji,
        })}
      </PhotoCardName>

      <EventName className="text-main-light-3 dark:text-main-dark-3">
        {tradingPhotoCard.photoCard.eventName}
      </EventName>

      {tradingPhotoCard.category === "sale" && (
        <PriceName className="text-textPrimary-light dark:text-textPrimary-dark">
          {isNull(tradingPhotoCard.price)
            ? t("tradingPhotoCardPage.offerPriceWithEmoji")
            : tradingPhotoCard.price}
        </PriceName>
      )}
    </VStack>
  );
}
