import React, { useEffect, useRef, useState } from "react";

import {
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  ScaleFade,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import ko from "date-fns/locale/ko";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { ReactComponent as LikeIcon } from "../../icons/like.line.svg";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { ReactComponent as MegaphoneFillIcon } from "../../icons/megaphone.fill.svg";
import { ReactComponent as MegaphoneIcon } from "../../icons/megaphone.svg";
import RepostBottomDrawer from "../drawer/RepostBottomDrawer";
import toggleLikedPost from "../api/mutations/LikePost";
import ProfileAvatar from "../thumbnails/ProfileAvatar";
import PostImagesBox from "./PostImagesBox";
import QuotedPostPreview from "./QuotedPostPreview";
import { t } from "i18next";
import { ellipsis } from "../../utils/TextUtil";
import { openNewTab } from "../../utils/UIUtils";

const authorNameStyle = {
  fontWeight: 600,
  lineHeight: "17px",
  fontSize: "14px",
};

const authoredAtStyle = {
  paddingTop: "3px",
  fontWeight: 400,
  lineHeight: "14px",
  fontSize: "12px",
};

const titleStyle = {
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  paddingTop: "3px",
  paddingBottom: "3px",
  userSelect: "auto",
};

const contentStyle = {
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  paddingTop: "3px",
  paddingBottom: "3px",
  userSelect: "auto",
};

const eventNameStyle = {
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "18px",
  noOfLines: 1,
};

const pocaNameStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  noOfLines: 1,
};

const memberNameBadgeStyle = {
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "16px",
  borderRadius: "4px",
  width: "fit-content",
};
const ImageBoxWrpper = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
`;

const PhotoCardWrpper = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
`;

const HolographyBox = styled.div`
  margin-left: 12px;
  border: 1px solid transparent;
  border-radius: 32px;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(102deg, #860eff 0%, #2de7d1 44%, #c855ff 100%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  padding: 7px 12px 5px 12px;
`;

const FandomText = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  background: linear-gradient(102deg, #860eff 0%, #2de7d1 44%, #c855ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const RepostBox = styled.div`
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  border-radius: 12px;
`;

const DividerWrapper = styled.div`
  padding-bottom: 16px;
  margin-left: -24px !important;
  margin-right: -24px !important;
`;

const PostStatText = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

const PostStatCount = styled.p`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
`;

const DotWrapper = styled.p``;

export default function Post({ post, reposted = false }) {
  const focusRef = useRef();
  const navigate = useNavigate();

  const [postState, setPostState] = useState();
  const [isOpenRepostBottomSheet, setIsOpenRepostBottomSheet] = useState();
  const [linkPreviews, setLinkPreviews] = useState([]);

  useEffect(() => {
    setPostState(post);
  }, [post]);

  const navigatePostDetails = () => {
    const pathName = `/posts/${postState.postId}`;
    if (pathName !== window.location.pathname) {
      navigate(pathName);
    }
  };

  const onClickLiked = async (postId) => {
    const response = await toggleLikedPost(postId);

    if (response && response.ok) {
      setPostState((prevPost) => ({
        ...prevPost,
        isLiked: !prevPost.isLiked,
        likesCount: prevPost.isLiked
          ? (prevPost.likesCount || 0) - 1
          : (prevPost.likesCount || 0) + 1,
      }));
    }
  };

  const onClickReposted = async (isMegaphoned) => {
    setPostState((prevPost) => ({
      ...prevPost,
      isMegaphoned: isMegaphoned,
      repostsCount: isMegaphoned
        ? (prevPost.repostsCount || 0) - 1
        : (prevPost.repostsCount || 0) + 1,
    }));
  };

  const fetchLinkPreview = async (url) => {
    try {
      const response = await fetch(
        `https://api.linkpreview.net/?key=5906ad40d7ed9e471334f224b61f4861&q=${url}`
      );
      const data = await response.json();
      setLinkPreviews((prev) => [...prev, data]);
    } catch (error) {
      console.error("Error fetching link preview:", error);
    }
  };

  useEffect(() => {
    if (!postState) return;

    const urls = postState?.content?.match(/https?:\/\/[^\s]+/g);
    if (urls) {
      urls.forEach((url) => fetchLinkPreview(url));
    }
  }, [postState, postState?.content]);

  const onClickLinkPreview = (preview) => {
    if (isEmpty(preview?.url)) return;
    openNewTab(
      `${preview.url}?refer=pocapan.com&utm_source=pocapan&utm_medium=referral`
    );
  };

  return (
    postState && (
      <Flex key={postState.postId} ref={focusRef} flexWrap={"wrap"}>
        <VStack
          flexGrow={1}
          spacing={0}
          align={"left"}
          paddingLeft={"0px"}
          width={"100%"}
        >
          <Flex paddingBottom={"9px"} direction={"row"} alignItems={"center"}>
            <Flex flexGrow={1}>
              <ProfileAvatar
                profileImageUrl={
                  postState.anonymous
                    ? "/assets/icons/ghost_profile_icon.png"
                    : postState.authorThumbnailUrl
                }
                widthAndHeight={"34px"}
                onClick={() =>
                  postState?.userProfileId &&
                  navigate(`/feed/user_profile/${postState?.userProfileId}`)
                }
              />
              <VStack
                align={"start"}
                paddingLeft={"10px"}
                spacing={0}
                onClick={() => navigatePostDetails()}
              >
                <Text
                  className="text-main-light-2 dark:text-main-dark-2"
                  style={authorNameStyle}
                >
                  {postState.authorName}
                </Text>
                <Text
                  className="text-main-light-4 dark:text-main-dark-4"
                  style={authoredAtStyle}
                >
                  {format(
                    new Date(postState.authoredAt),
                    "aaa HH:mm · yyyy.MM.dd",
                    { locale: ko }
                  )}
                </Text>
              </VStack>
            </Flex>
            {postState.fandomName && (
              <Flex flexShrink={1}>
                <HolographyBox>
                  <FandomText>{`Only ${postState.fandomName}`}</FandomText>
                </HolographyBox>
              </Flex>
            )}
          </Flex>

          <div
            onClick={() => navigatePostDetails()}
            style={{ maxWidth: "100%" }}
          >
            {postState.title && (
              <Text
                className="text-main-light-2 dark:text-main-dark-2"
                key={"title"}
                style={titleStyle}
              >
                {postState.title}
              </Text>
            )}
            {postState.content && (
              <Text
                className="text-main-light-2 dark:text-main-dark-2"
                key={"content"}
                whiteSpace={"pre-wrap"}
                style={contentStyle}
              >
                {postState.content
                  .split(/(https?:\/\/[^\s]+)/g)
                  .map((part, index) =>
                    part.match(/https?:\/\/[^\s]+/) ? (
                      <div
                        style={{ textDecoration: "underline" }}
                        onClick={() =>
                          onClickLinkPreview(
                            linkPreviews.find((preview) => preview.url === part)
                          )
                        }
                      >
                        {part}
                      </div>
                    ) : (
                      part
                    )
                  )}
              </Text>
            )}
            {linkPreviews.map((preview, index) => (
              <div
                className="link-preview"
                key={index}
                onClick={() => onClickLinkPreview(preview)}
              >
                <Box
                  marginTop={"12px"}
                  borderWidth="1px"
                  borderRadius="8px"
                  padding="4"
                  boxShadow="md"
                >
                  <Text
                    className="text-main-light-2 dark:text-main-dark-2"
                    style={titleStyle}
                    noOfLines={1}
                  >
                    {preview.title}
                  </Text>
                  <Text
                    className="text-main-light-2 dark:text-main-dark-2"
                    style={contentStyle}
                    noOfLines={2}
                  >
                    {preview.description}
                  </Text>
                  <img src={preview.image} alt={preview.title} />
                </Box>
              </div>
            ))}
          </div>

          <div>
            {!isEmpty(postState.pictureUrls) && (
              <ImageBoxWrpper>
                <PostImagesBox
                  images={postState.pictureUrls}
                  focusRef={focusRef}
                />
              </ImageBoxWrpper>
            )}

            {postState.photoCard && (
              <PhotoCardWrpper>
                <HStack
                  className="border-[1px] border-separator-light-2 dark:border-separator-dark-2"
                  padding={"16px"}
                  borderRadius={"12px"}
                  onClick={() =>
                    navigate(`/photo_cards/${postState.photoCard.photoCardId}`)
                  }
                >
                  <Image
                    width={"40px"}
                    height={"68px"}
                    src={postState.photoCard.pictureUrl}
                  />
                  <VStack align={"left"} spacing={0}>
                    <Text
                      className="text-main-light-1 dark:text-main-dark-1"
                      style={eventNameStyle}
                      noOfLines={1}
                    >
                      {postState.photoCard.eventName}
                    </Text>
                    <Text
                      className="text-main-light-3 dark:text-main-dark-3"
                      style={pocaNameStyle}
                    >
                      {postState.photoCard.pocaname || postState.photoCard.name}
                    </Text>
                    <Badge
                      className="bg-label-light dark:bg-label-dark"
                      marginTop={"8px!important"}
                      padding={"4px 8px"}
                      style={memberNameBadgeStyle}
                    >
                      {postState.photoCard.memberName}
                    </Badge>
                  </VStack>
                </HStack>
              </PhotoCardWrpper>
            )}
          </div>

          <div>
            {postState.repostSource && postState.postType === "quotation" && (
              <Box
                className="border-[1px] border-separator-light-2 dark:border-separator-dark-2"
                marginY={"3px"}
              >
                <RepostBox className="border-[1px] border-separator-light-2 dark:border-separator-dark-2">
                  <QuotedPostPreview
                    post={postState.repostSource}
                    reposted={true}
                    disableActions={true}
                  />
                </RepostBox>
              </Box>
            )}
          </div>

          {/* <div>
          {
            postState.commentSource &&
            <Box borderWidth={"1px"} borderColor={LightColors.separatorlv2} padding={"12px"} borderRadius={"12px"}>
              <Comment comment={postState.commentSource} isPosted={true}/>
            </Box>
          }
        </div> */}

          <HStack
            paddingTop={"10px"}
            paddingBottom={"16px"}
            spacing={"4px"}
            marginLeft={"-3px!important"}
          >
            <div onClick={() => onClickLiked(postState.postId)}>
              {
                <>
                  {postState.isLiked && (
                    <ScaleFade initialScale={0.5} in={postState.isLiked}>
                      <LikeShapeIcon
                        className="fill-warning"
                        width={"30px"}
                        height={"30px"}
                      />
                    </ScaleFade>
                  )}
                  {!postState.isLiked && (
                    <ScaleFade initialScale={0.9} in={!postState.isLiked}>
                      <LikeIcon
                        className="fill-main-light-3 dark:fill-main-dark-3"
                        width={"30px"}
                        height={"30px"}
                      />
                    </ScaleFade>
                  )}
                </>
              }
            </div>
            <div onClick={() => setIsOpenRepostBottomSheet(true)}>
              {postState.isMegaphoned || postState.isQuoted ? (
                <MegaphoneFillIcon
                  className="fill-primary-light dark:fill-primary-dark"
                  width={"30px"}
                  height={"30px"}
                />
              ) : (
                <MegaphoneIcon
                  className="fill-main-light-3 dark:fill-main-dark-3"
                  width={"30px"}
                  height={"30px"}
                />
              )}
            </div>
          </HStack>

          <DividerWrapper>
            <Divider className="text-separator-light-1 dark:text-separator-dark-1" />
          </DividerWrapper>
          {
            <HStack spacing={"6px"}>
              <HStack spacing={"3px"}>
                <PostStatText className="text-main-light-4 dark:text-main-dark-4">
                  {t("comment")}
                </PostStatText>
                <PostStatCount className="text-main-light-3 dark:text-main-dark-3">
                  {postState.commentsCount}
                </PostStatCount>
              </HStack>
              <DotWrapper className="text-main-light-3 dark:text-main-dark-3">
                {"·"}
              </DotWrapper>
              <HStack
                spacing={"3px"}
                onClick={() => navigate(`/posts/${postState.postId}/reposts`)}
              >
                <PostStatText className="text-main-light-4 dark:text-main-dark-4">
                  {t("megaphone")}
                </PostStatText>
                <PostStatCount className="text-main-light-3 dark:text-main-dark-3">
                  {postState.repostsCount}
                </PostStatCount>
              </HStack>
              <DotWrapper className="text-main-light-3 dark:text-main-dark-3">
                {"·"}
              </DotWrapper>
              <HStack
                spacing={"3px"}
                onClick={() =>
                  navigate(`/posts/${postState.postId}/liked_profiles`)
                }
              >
                <PostStatText className="text-main-light-4 dark:text-main-dark-4">
                  {t("like")}
                </PostStatText>
                <PostStatCount className="text-main-light-3 dark:text-main-dark-3">
                  {postState.likesCount}
                </PostStatCount>
              </HStack>
            </HStack>
          }
        </VStack>
        {isOpenRepostBottomSheet && (
          <RepostBottomDrawer
            focusRef={focusRef}
            post={postState}
            isOpen={isOpenRepostBottomSheet}
            onClose={() => setIsOpenRepostBottomSheet(false)}
            onConfirmedRepost={() => onClickReposted(true)}
            onCanceledRepost={() => onClickReposted(false)}
          />
        )}
      </Flex>
    )
  );
}
