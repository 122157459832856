import React from "react";
import { Box, Center } from "@chakra-ui/react";

export default function WarningButton({ text, disabled, ...props }) {
  return (
    <Box
      className={`text-center ${
        !disabled
          ? "bg-warningContainer text-warning"
          : "bg-disabled-light dark:bg-disabled-dark text-sub-light-2 dark:text-sub-dark-2"
      }`}
      borderRadius="52px"
      alignContent="center"
      lineHeight="17px"
      paddingX="12px"
      paddingY="8px"
      fontWeight={600}
      fontSize="14px"
      {...props}
    >
      <Center>{text}</Center>
    </Box>
  );
}
