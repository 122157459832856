import { Card, Image, Text } from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import ShowUserProfilesBottomSheet from "../bottomSheet/ShowUserProfilesBottomSheet";
import { t } from "i18next";

const cardStyle = {
  borderRadius: "20px",
  background: "white",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.08)",
  padding: "18px",
  marginRight: "5px",
};

const descriptionStyle = {
  fontSize: "14px",
  wordBreak: "keep-all",
  fontWeight: 500,
  lineHeight: "17px",
};

const tradingCountStyle = {
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
};

export function AggregatedPhotoCardCard({
  artistId,
  tradingPhotoCardId,
  aggregatedPhotoCard,
}) {
  const navigate = useNavigate();
  const [isShowBottomSheet, setIsShowBottomSheet] = useState(false);

  const saleTradingPhotoCards = useMemo(() => {
    return aggregatedPhotoCard?.tradingPhotoCards?.filter(
      (t) => t.category === "sale"
    );
  }, [aggregatedPhotoCard?.tradingPhotoCards]);

  const exchangeTradingPhotoCards = useMemo(() => {
    return aggregatedPhotoCard?.tradingPhotoCards?.filter(
      (t) => t.category === "exchange"
    );
  }, [aggregatedPhotoCard?.tradingPhotoCards]);

  const onClickPhotoCard = () => {
    if (
      !isEmpty(saleTradingPhotoCards) &&
      !isEmpty(exchangeTradingPhotoCards)
    ) {
      navigate(
        `/artists/${artistId}/trading_photo_card_users?tradingPhotoCardId=${tradingPhotoCardId}&photoCardId=${aggregatedPhotoCard.photoCardId}`
      );
    } else if (
      saleTradingPhotoCards.length + exchangeTradingPhotoCards.length >
      2
    ) {
      navigate(
        `/artists/${artistId}/trading_photo_card_users?tradingPhotoCardId=${tradingPhotoCardId}&photoCardId=${aggregatedPhotoCard.photoCardId}`
      );
    } else {
      setIsShowBottomSheet(true);
    }
  };

  return (
    <>
      <Card
        maxWidth={"200px"}
        minWidth={"187px"}
        style={cardStyle}
        onClick={() => onClickPhotoCard()}
      >
        <div className="flex">
          <div className="grow">
            <div className="flex flex-col h-full">
              <Text
                className="grow text-main-light-2 dark:text-main-dark-2"
                style={descriptionStyle}
              >
                {t("tradingPhotoCardPage.aggregatedPhotoCardCardTitle")}
              </Text>
              <Text
                className="flex-none text-primary-light dark:text-primary-dark"
                style={tradingCountStyle}
              >
                {t("cardsCount", { count: aggregatedPhotoCard.tradingCount })}
              </Text>
            </div>
          </div>
          <div className="flex-none" style={{ paddingLeft: "12px" }}>
            <Image
              src={aggregatedPhotoCard.pictureUrl}
              width={"48px"}
              objectFit={"cover"}
              borderRadius={"12px"}
              loading={"lazy"}
            />
          </div>
        </div>
      </Card>
      {isShowBottomSheet && (
        <ShowUserProfilesBottomSheet
          artistId={artistId}
          isShowBottomSheet={isShowBottomSheet}
          setIsShowBottomSheet={setIsShowBottomSheet}
          saleTradingPhotoCards={saleTradingPhotoCards}
          exchangeTradingPhotoCards={exchangeTradingPhotoCards}
        />
      )}
    </>
  );
}
