import {
  Box,
  Center,
  Image,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";
import { useState } from "react";

export const ArtistCircleBox = ({ artist, onArtistSelected }) => {
  const [selectedArtist, setSelectedArtist] = useState(null);

  return (
    <Stack>
      <Center>
        <Box>
          <Wrap>
            <WrapItem position={"relative"}>
              {
                // #region 아티스트 아이콘
              }
              <Image
                className="bg-background-light dark:bg-background-dark"
                src={artist.pictureUrl}
                borderRadius="full"
                width={"154px"}
                height={"154px"}
                pl="4px"
                alt={artist.name}
                onClick={() => {
                  setSelectedArtist(artist);
                  onArtistSelected(artist);
                }}
                loading={"lazy"}
              />
              {
                // #endregion
              }
              {
                // #region 선택시 오버레이
              }
              <WrapItem
                className="bg-secondary-light dark:bg-secondary-dark"
                borderRadius="full"
                width={"154px"}
                height={"154px"}
                pl="4px"
                zIndex={
                  selectedArtist?.artistId === artist.artistId ? 999 : -11
                }
                style={{ position: "absolute", top: 0, left: 0 }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <LikeShapeIcon
                  className="fill-background-light dark:fill-background-dark"
                  width={60}
                  height={60}
                />
              </WrapItem>
              {
                // #endregion
              }
            </WrapItem>
          </Wrap>
        </Box>
      </Center>

      <Text
        className="text-center text-main-light-2 dark:text-main-dark-2"
        paddingTop="16px"
        mt="0px!important"
        fontSize="17px"
        fontWeight={400}
        lineHeight="20.4px"
      >
        {artist.name}
      </Text>
    </Stack>
  );
};
