import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { ReactComponent as ArrowLeftIcon } from "../icons/arrow.left.svg";
import { useHandleHistoryBack } from "../utils/useHandleHistoryBack";
import LightColors from "../constants/LightColors";
import { useColorMode } from "@chakra-ui/react";

const Header = styled.header`
  position: fixed;
  max-width: var(--chakra-sizes-prose);
  top: 0;
  width: 100%;
  background: ${({ isVisible, $colorMode }) =>
    isVisible
      ? $colorMode === "dark"
        ? "#0D0E12"
        : LightColors.purewhite
      : "transparent"};
  color: ${({ $colorMode }) => ($colorMode === "dark" ? "#FAFAFA" : "#222222")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-bottom: 15px;
  padding-left: calc(16px + env(safe-area-inset-left));
  padding-right: calc(16px + env(safe-area-inset-right));
  z-index: 1000;
`;

const Title = styled.div`
  color: ${({ isVisible, $colorMode }) =>
    isVisible
      ? $colorMode === "dark"
        ? "#FAFAFA"
        : LightColors.pureblack
      : "transparent"};
  font-weight: 700;
  font-size: 17px;
  line-height: 20px;
  text-align: center;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const CenterContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;

const RightContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const StickyHeader = ({
  title,
  headerContainer,
  leftContent,
  centerContent,
  rightContent,
  alwaysShowTitle = false,
  showTitle,
  showBackButton,
  transparent = false,
  onClickBackButton,
}) => {
  const [showHeader, setShowHeader] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const handleHistoryBack = useHandleHistoryBack();
  const { colorMode } = useColorMode();

  const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 20) {
      setShowHeader(true); // 스크롤 20px 이상이면 타이틀 표시
    } else {
      setShowHeader(false); // 스크롤 20px 이하이면 타이틀 숨김
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  return (
    <div style={{ maxWidth: "var(--chakra-sizes-prose)" }}>
      <Helmet>
        <title>{title}</title> {/* Helmet을 사용하여 title 설정 */}
      </Helmet>

      <Header
        isVisible={showHeader}
        transparent={transparent}
        $colorMode={colorMode}
      >
        {headerContainer ? (
          headerContainer
        ) : (
          <>
            <LeftContainer>
              {showBackButton ? (
                <ArrowLeftIcon
                  fill={
                    colorMode === "dark" ? "#FAFAFA" : LightColors.pureblack
                  }
                  onClick={
                    onClickBackButton ? onClickBackButton : handleHistoryBack
                  }
                />
              ) : (
                leftContent
              )}
            </LeftContainer>
            <CenterContainer>
              {alwaysShowTitle ? (
                <Title isVisible={true} $colorMode={colorMode}>
                  {title}
                </Title>
              ) : showTitle ? (
                <Title isVisible={showHeader} $colorMode={colorMode}>
                  {title}
                </Title>
              ) : (
                centerContent
              )}
            </CenterContainer>
            <RightContainer>{rightContent}</RightContainer>
          </>
        )}
      </Header>
    </div>
  );
};

export default StickyHeader;
