import React from "react";

import { HStack, Skeleton, useColorModeValue } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import styled from "styled-components";

import RegularSolidButton from "../buttons/RegularSolidButton";

const Row = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
`;

const EventsGroupList = ({
  events,
  selectedEventCategoryName = "all",
  onSelectedEventCategory,
  addEntireOption = true,
}) => {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");
  const primaryStartColor = useColorModeValue(
    "secondary.light",
    "secondary.dark"
  );
  const primaryEndColor = useColorModeValue("theirty.light", "theirty.dark");

  if (isEmpty(events)) {
    return (
      <HStack paddingY={"8px"} paddingLeft={"24px"}>
        <Skeleton
          height="33px"
          width="47px"
          borderRadius={"32px"}
          startColor={primaryStartColor}
          endColor={primaryEndColor}
        />
        <Skeleton
          height="33px"
          width="47px"
          borderRadius={"32px"}
          startColor={startColor}
          endColor={endColor}
        />
        <Skeleton
          height="33px"
          width="47px"
          borderRadius={"32px"}
          startColor={startColor}
          endColor={endColor}
        />
      </HStack>
    );
  }

  const groupedEvents = events?.reduce((acc, event) => {
    const categoryName = event.categoryName;
    if (!acc[categoryName]) {
      acc[categoryName] = { categoryName, count: 0 };
    }
    acc[categoryName].count += 1;
    return acc;
  }, {});

  const groupedEventArray = Object.values(groupedEvents);

  return (
    <div className="w-full" style={{}}>
      <ScrollMenu wrapperClassName="padding-left-scroll-container">
        <Row>
          {addEntireOption && (
            <RegularSolidButton
              key={"all"}
              text={t("entire")}
              onClicked={() => onSelectedEventCategory("all")}
              filled={selectedEventCategoryName === "all"}
            />
          )}
        </Row>

        {groupedEventArray.map(({ categoryName, count }) => (
          <Row key={categoryName}>
            <RegularSolidButton
              text={`${categoryName} (${count})`} // Display category name and count
              onClicked={() => onSelectedEventCategory(categoryName)}
              filled={selectedEventCategoryName === categoryName}
              styled={{}}
            />
          </Row>
        ))}
      </ScrollMenu>
    </div>
  );
};

export default EventsGroupList;
