import React from "react";

import { Box } from "@chakra-ui/react";
import styled from "styled-components";

const ToastMessage = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  text-align: center;
`;

const defaultToastStyle = {
  // FIXME : backgroundColor: "rgba(0, 0, 0, 0.64)",
  borderRadius: "32px",
  marginBottom: "75vh",
};

const bottomToastStyle = {
  // FIXME : backgroundColor: "rgba(0, 0, 0, 0.64)",
  borderRadius: "32px",
  marginBottom: "10vh",
};

export default function ToastMessageBox({
  leftIcon,
  message,
  showOnBottom = false,
}) {
  return (
    <Box
      className="text-center text-white bg-surface-light dark:text-black dark:bg-surface-dark"
      p={2}
      style={showOnBottom ? bottomToastStyle : defaultToastStyle}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection={"row"}
      gap={"4px"}
    >
      {leftIcon && leftIcon}
      <ToastMessage className="text-pure-white dark:text-pure-black">
        {message}
      </ToastMessage>
    </Box>
  );
}
