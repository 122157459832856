import React, { useState } from "react";
import LightColors from "../../constants/LightColors";
import styled from "styled-components";

const StyledSelect = styled.select`
  padding: 16px 0;
  width: 100%;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  border-radius: 8px;
  appearance: none; /* Hides the default arrow */
  -webkit-appearance: none; /* Safari/Chrome */
  -moz-appearance: none; /* Firefox */
  outline: none; /* Removes focus outline */
`;

const YearPicker = ({
  initYear,
  minYear,
  maxYear,
  postfix = "",
  prefix = "",
  onSelectedYear,
}) => {
  const startYear = minYear || new Date().getFullYear() - 100;
  const endYear = maxYear || new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState();

  const handleChange = (event) => {
    setSelectedYear(event.target.value);
    onSelectedYear(event.target.value);
  };

  return (
    <div className="w-full">
      <StyledSelect
        value={selectedYear || initYear}
        onChange={handleChange}
        className={`bg-pure-white dark:bg-pure-black border-[1px] border-separator-light-2 dark:border-separator-dark-2 
          focus:outline-none focus:border-[1px] focus:border-primary-light dark:focus:border-primary-dark
          focus:bg-secondary-light dark:focus:bg-secondary-dark focus:text-primary-light dark:focus:text-primary-dark
          aria-selected:border-[1px] aria-selected:border-primary-light dark:aria-selected:border-primary-dark
          aria-selected:bg-secondary-light dark:aria-selected:bg-secondary-dark aria-selected:text-onSecondary-light dark:aria-selected:text-onSecondary-dark
          ${selectedYear ? "selected" : ""}`}
        style={{
          textAlign: "-webkit-center",
          textAlignLast: "center",
          direction: "ltr",
        }}
      >
        {Array.from({ length: endYear - startYear + 1 }, (_, index) => {
          const year = startYear + index;
          return (
            <option
              key={index}
              value={year}
              selected={year === selectedYear}
              style={{ textAlign: "center", direction: "ltr" }}
            >
              {prefix}
              {year}
              {postfix}
            </option>
          );
        })}
      </StyledSelect>
    </div>
  );
};

export default YearPicker;
