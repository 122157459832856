import { Input } from "@chakra-ui/react";
import React from "react";

export default function PrimaryInput({
  defaultValue,
  placeholder,
  onChangeText,
  ...props
}) {
  return (
    <Input
      className="bg-secondary-light dark:bg-secondary-dark focus:border-secondary-light dark:focus:border-secondary-dark
      hover:bg-secondary-light dark:hover:bg-secondary-dark placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark
      "
      borderRadius={"12px"}
      defaultValue={defaultValue}
      onChange={onChangeText}
      variant="filled"
      paddingY={6}
      placeholder={placeholder}
      {...props}
    />
  );
}
