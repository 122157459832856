import React from "react";

import {
  AbsoluteCenter,
  AlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
  Spinner,
} from "@chakra-ui/react";

export default function ProgressSpinnerDialog({ isOpen, onClose }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay style={{ zIndex: 2147483647 }}>
        <AlertDialogContent className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark">
          <AbsoluteCenter>
            <Spinner
              size="md"
              className="text-primary-light dark:text-primary-dark"
            />
          </AbsoluteCenter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
