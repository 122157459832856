import React, { useEffect, useRef, useState } from "react";

import { Skeleton, useColorModeValue, Wrap } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";
import styled from "styled-components";
import { ReactComponent as LikeShapeIcon } from "../../icons/like.shape.svg";

const StyledPhotoCard = styled.div`
  width: 100%;
  aspect-ratio: 52/86;
  box-sizing: border-box;
  border-radius: 8px;
  background-size: cover, cover;
  background-position: center, center;
  background-image: ${({ backgroundImage }) =>
    `url('/assets/backgrounds/photocard-bg.png'), url(${backgroundImage})`};
  background-blend-mode: ${({ isCollected }) =>
    isCollected ? "multiply" : "normal"};

  // Apply grayscale and opacity conditionally
  filter: ${({ isCollected }) =>
    isCollected ? "grayscale(0)" : "grayscale(1) opacity(0.5)"};
`;

const PhotoCardCollectedText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 5px;
`;

const PhotoCardName = styled.div`
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
`;

const likeShapeStyle = {
  width: "24px",
  height: "24px",
};

export default function PhotoCard({ photoCard, isCollected, ...props }) {
  const targetRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <div ref={targetRef} style={{ aspectRatio: "55/87" }}>
      {isVisible ? (
        <motion.div
          initial={{ opacity: 0, y: -1 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -1 }}
          transition={{ duration: 0.5 }}
        >
          <Wrap
            className="relative"
            key={photoCard.photoCardId}
            aspectRatio={"55/87"}
            borderRadius={"8px"}
            {...props}
          >
            <StyledPhotoCard
              isCollected={isCollected || photoCard.collectedCount > 0}
              backgroundImage={
                photoCard.mediumThumbnailUrl || photoCard.pictureUrl
              }
            />
            {((isCollected && photoCard.collectedCount > 0) ||
              photoCard.isWished) && (
              <div className="absolute top-0 right-0">
                <LikeShapeIcon className="fill-white" style={likeShapeStyle} />
              </div>
            )}
            <div
              className="absolute inset-x-0 bottom-0"
              style={{ margin: "7px" }}
            >
              {photoCard.collectedCount > 0 && (
                <PhotoCardCollectedText className="text-pure-white">
                  {t("photoCardDetailPage.collected")}
                </PhotoCardCollectedText>
              )}
              <PhotoCardName className="text-pure-white">
                {photoCard.getCategoryDisplay()}
              </PhotoCardName>
            </div>
          </Wrap>
        </motion.div>
      ) : (
        <Skeleton
          width="100%"
          aspectRatio={"55/87"}
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
      )}
    </div>
  );
}
