import React, { useEffect, useState } from "react";

import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import Page from "../components/Page";

import { Box, Text, useColorMode } from "@chakra-ui/react";
import { t } from "i18next";
import { findIndex, isEmpty, uniqBy } from "lodash";
import BlacklistPhotoCardsByEvent from "../components/BlacklistPhotoCardsByEvent";
import { useArtistQueryLikedOnly } from "../components/api/queries/useArtistQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import useTradingPhotoCardPreferencesQuery from "../components/api/queries/useTradingPhotoCardPreferencesQuery";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import LikedMembersList from "../components/lists/LikedMembersList";
import ArtistIconSelector from "../components/selectors/ArtistIconSelector";
import pushPopPhotoCard from "../utils/SelectPhotoCard";
import baseUrl from "../utils/baseUrl";
import { getArtistId, getMemberIdAnyway } from "../utils/etcUtils";
import getToken from "../utils/getToken";
import { queryGet } from "../utils/queryClient";

const titleStyle = {
  fontSize: "26px",
  fontWeight: 700,
  lineHeight: "120%",
};

async function saveBlacklistTradingCardPhotoCards(
  artistId,
  userProfileId,
  blacklistPhotoCardIds,
  whitelistPhotoCardIds
) {
  return fetch(
    baseUrl() + `/api/v1/artists/${artistId}/trading_photo_card_preferences`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify({
        user_profile_id: userProfileId,
        blacklist_trading_photo_cards: blacklistPhotoCardIds.map((id) => {
          return {
            artist_id: artistId,
            photo_card_id: id,
          };
        }),
        whitelist_trading_photo_cards: whitelistPhotoCardIds.map((id) => {
          return {
            artist_id: artistId,
            photo_card_id: id,
          };
        }),
      }),
    }
  );
}

export default function BlacklistTradingPhotoCardsPage() {
  const { colorMode } = useColorMode();
  const artistId = getArtistId();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [likedMember, setLikedMember] = useState();

  const [whitelistPhotoCards, setWhitelistPhotoCards] = useState([]);
  const [blacklistPhotoCards, setBlacklistPhotoCards] = useState([]);
  const [collectedPhotoCards, setCollectedPhotoCards] = useState([]);
  const [selectedPhotoCards, setSelectedPhotoCards] = useState([]);

  const likedArtists = useArtistQueryLikedOnly();
  const user = useUserQuery();

  const { data: tradingPhotoCardPreferences } = useQuery(
    ["TradingPhotoCardPreferences::V1", artistId],
    () =>
      queryGet(`/api/v1/artists/${artistId}/trading_photo_card_preferences`),
    {
      enabled: !!artistId,
      select: (r) => r.tradingPhotoCardPreferences,
      cachedTime: 0,
      stableTime: 0,
    }
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(likedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          likedMember
        )}/photo_cards`
      ),
    {
      enabled: !!likedMember,
    }
  );

  useEffect(() => {
    const whitelist = tradingPhotoCardPreferences
      ?.filter((p) => p.preferenceType === "whitelist")
      ?.map((b) => b.photoCard);
    setWhitelistPhotoCards(whitelist);

    const blacklist = tradingPhotoCardPreferences
      ?.filter((p) => p.preferenceType === "blacklist")
      ?.map((b) => b.photoCard);
    setBlacklistPhotoCards(blacklist);

    const collected = photoCardByEvent?.events?.flatMap((e) =>
      e.photoCards.filter((photoCard) => photoCard.collectedCount > 0)
    );
    setCollectedPhotoCards(collected);

    onChangedSelectedPhotoCard(collected, whitelist, blacklist);
  }, [tradingPhotoCardPreferences, photoCardByEvent]);

  const isCollectedCard = (photoCard) => {
    var collected = photoCardByEvent?.events?.flatMap((e) =>
      e.photoCards.filter((photoCard) => photoCard.collectedCount > 0)
    );
    return (
      findIndex(collected, (p) => p.photoCardId === photoCard.photoCardId) >= 0
    );
  };

  const isBlacklistedCard = (photoCard) => {
    return (
      findIndex(
        blacklistPhotoCards,
        (p) => p.photoCardId === photoCard.photoCardId
      ) >= 0
    );
  };

  const isWhitelistedCard = (photoCard) => {
    return (
      findIndex(
        whitelistPhotoCards,
        (p) => p.photoCardId === photoCard.photoCardId
      ) >= 0
    );
  };

  const onSelectedBlacklistPhotoCard = (photoCard) => {
    var tempWhitelist = [...whitelistPhotoCards];
    var tempBlacklist = [...blacklistPhotoCards];

    var isBlacklistedOrWhitelisted = false;

    if (isBlacklistedCard(photoCard)) {
      isBlacklistedOrWhitelisted = true;
      tempBlacklist = pushPopPhotoCard(tempBlacklist, photoCard);
      setBlacklistPhotoCards(tempBlacklist);
    }

    if (isWhitelistedCard(photoCard)) {
      isBlacklistedOrWhitelisted = true;
      tempWhitelist = pushPopPhotoCard(tempWhitelist, photoCard);
      setWhitelistPhotoCards(tempWhitelist);
    }

    if (isBlacklistedOrWhitelisted) {
      return onChangedSelectedPhotoCard(
        collectedPhotoCards,
        tempWhitelist,
        tempBlacklist
      );
    }

    if (isCollectedCard(photoCard)) {
      tempWhitelist = pushPopPhotoCard(tempWhitelist, photoCard);
      setWhitelistPhotoCards(tempWhitelist);
    } else {
      tempBlacklist = pushPopPhotoCard(tempBlacklist, photoCard);
      setBlacklistPhotoCards(tempBlacklist);
    }
    return onChangedSelectedPhotoCard(
      collectedPhotoCards,
      tempWhitelist,
      tempBlacklist
    );
  };

  const onChangedSelectedPhotoCard = (collected, whitelist, blacklist) => {
    var photoCards = [];
    if (!isEmpty(blacklist)) {
      photoCards = [...photoCards, ...blacklist];
    }
    if (!isEmpty(whitelist) && !isEmpty(collected)) {
      collected = collected?.filter(
        (c) =>
          findIndex(whitelist, (w) => w.photoCardId === c.photoCardId) === -1
      );
    }
    if (!isEmpty(collected)) {
      photoCards = uniqBy([...photoCards, ...collected], "photoCardId");
    }
    setSelectedPhotoCards(photoCards);
  };

  const onChangeLikedMember = (member) => {
    setLikedMember(member);
  };

  // TODO: Not enough to handle event
  // const onBlacklistedMember = (member) => {
  //   photoCardByEvent?.events?.forEach((e) => e.photoCards.forEach((photoCard) => {
  //     if(photoCard.memberId == member.member_id){
  //       const blacklist = pushPopPhotoCard(blacklistPhotoCards, photoCard)
  //       setBlacklistPhotoCards(blacklist)
  //       onChangedSelectedPhotoCard(collectedPhotoCards, whitelistPhotoCards, blacklist)
  //     }
  //   }))
  // }

  const onSaveBlacklistTradingCard = async () => {
    const response = await saveBlacklistTradingCardPhotoCards(
      artistId,
      user.tradingUserProfile.userProfileId,
      blacklistPhotoCards.map((p) => p.photoCardId),
      whitelistPhotoCards.map((p) => p.photoCardId)
    );

    if (response.ok) {
      queryClient.invalidateQueries("TradingPhotoCardPreferences::V1");
      window.history.back();
    }
  };

  return (
    <div
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      } safeAreaPaddingExceptBottomBackground`}
    >
      <Page
        title={t("blacklistCards.pageName")}
        showBackButton={true}
        themeColor={"white"}
        showTitle={false}
        navigationElement={
          selectedPhotoCards.length > 0 ? (
            <PrimaryButton text={t("save")} />
          ) : (
            <SecondaryButton text={t("save")} />
          )
        }
        onNavigationClick={() => onSaveBlacklistTradingCard()}
      >
        <Text
          className="text-main-light-2 dark:text-main-dark-2"
          style={titleStyle}
        >
          {t("blacklistCards.title")}
        </Text>
        <Text
          fontSize="15px"
          className="text-main-light-4 dark:text-main-dark-4"
          mt={2}
        >
          {t("blacklistCards.description")}
        </Text>
        {(likedArtists?.length ?? 0) > 1 ? (
          <Box
            overflowX="scroll"
            overflowY="hidden"
            pt="12px"
            css={{
              "::-webkit-scrollbar": {
                display: "none", // 스크롤바 숨김
              },
            }}
            style={{ margin: "0px -24px" }}
            width={"100%"}
            maxWidth="calc(var(--chakra-sizes-xl))"
          >
            <ArtistIconSelector
              onSelected={(artist) =>
                navigate(
                  `/artists/${artist.artistId}/blacklist_trading_photo_cards`,
                  { replace: true }
                )
              }
              onAddCallbackUrl="/artists/:artist_id/blacklist_trading_photo_cards"
            />
          </Box>
        ) : (
          <Box h={"10px"} />
        )}
        <div style={{ paddingTop: "10px", paddingBottom: "12px" }}>
          <LikedMembersList onChangeLikedMember={onChangeLikedMember} />
        </div>
        {/* TODO : Add features to handle event
          <Box style={boxButtonStyle} onClick={() => onBlacklistedMember(likedMember)}>
            {"🚫" + likedMember?.name + "의 모든 포카 매칭 거부 하기"}
          </Box>
        */}
        {tradingPhotoCardPreferences && (
          <BlacklistPhotoCardsByEvent
            photoCardsByEvent={photoCardByEvent}
            selectedPhotoCards={selectedPhotoCards}
            onClickedPhotoCard={onSelectedBlacklistPhotoCard}
            enableAll={false}
            collectingMode={true}
            editable={false}
          />
        )}
      </Page>
    </div>
  );
}
