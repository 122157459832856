import React, { useCallback, useRef, useState } from "react";

import { t } from "i18next";
import Cropper from "react-perspective-cropper";
import styled from "styled-components";
import { useFilePicker } from "use-file-picker";
import { AbsoluteCenter, Center, Spinner, useToast } from "@chakra-ui/react";

import ToastMessageBox from "../../components/alerts/ToastMessageBox";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { ReactComponent as RefreshFillIcon } from "../../icons/refresh.fill.svg";
import { useQueryParams } from "../../utils/useQueryParams";
import AddPhotoCard from "../../components/api/mutations/AddPhotoCard";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import { useNavigate } from "react-router-dom";

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 64px 24px 10px 24px;
  white-space: pre-wrap;
  z-index: 1000;
`;

const AddPhotoCardBoxContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
`;

const AddPhotoCardBox = styled.div`
  display: flex;
  width: 171px;
  height: 270px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px dashed var(--tint-primary, #5c3dfa);
  background: ${LightColors.purewhite};
`;

const CropperContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  border-radius: 7px;
`;

const ConfirmButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.29);
  padding-bottom: env(safe-area-inset-bottom);
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
`;

const DEFAULT_CV_PARAMS = {
  grayScale: false,
  th: false,
};

export default function ReportPhotoCardUploadPhotoPage() {
  const toast = useToast();
  const { queryParams, updateQueryParams } = useQueryParams();

  const cropperRef = useRef();
  const [cropState, setCropState] = useState();

  const [imageToCrop, setImageToCrop] = useState(null);
  const [dataURL, setDataURL] = useState(null);
  const [isOpenCVLoading, setIsOpenCVLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const convertBlobToDataURL = (blob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setDataURL(reader.result);
    };
    reader.readAsDataURL(blob);
  };

  const onDragStop = useCallback((s) => setCropState(s), []);
  const onChange = useCallback((s) => setCropState(s), []);

  const { openFilePicker, plainFiles, filesContent, clear } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  const handleAddPhotoClick = () => {
    openFilePicker();
  };

  // When a file is selected
  React.useEffect(() => {
    if (filesContent.length > 0) {
      setIsOpenCVLoading(true);
      const interval = setInterval(() => {
        if (window.cv) {
          setIsOpenCVLoading(false);
          clearInterval(interval);
        }
      }, 100);
      // Load the selected image into the cropper
      setImageToCrop(filesContent[0].content);
    }
  }, [filesContent]);

  const doCrop = async () => {
    try {
      const res = await cropperRef.current.done({
        preview: true,
        filterCvParams: DEFAULT_CV_PARAMS,
      });
      convertBlobToDataURL(res);
    } catch (e) {
      resetImage();
      toast({
        duration: 1500,
        render: () => <ToastMessageBox message={t("errorBoundary.title")} />,
      });
    }
  };

  const resetImage = () => {
    clear();
    setImageToCrop(null);
    setDataURL(null);
  };

  const sendPhotoCard = async () => {
    setIsUploading(true);
    if (isUploading) return;
    const response = await AddPhotoCard({
      artistId: queryParams.get("artistId"),
      memberId: queryParams.get("memberId"),
      eventId: queryParams.get("eventId"),
      category: queryParams.get("category"),
      filename: filesContent[0].name,
      dataUrl: dataURL,
    });
    setIsUploading(false);
    if (response && response.ok) {
      const responseBody = await response.json();
      const photoCardId = responseBody.photoCard.photoCardId;
      if (photoCardId) {
        updateQueryParams({ photoCardId }, "/report-photo-card/confirm");
      }
    } else {
      toast({
        duration: 1500,
        render: () => <ToastMessageBox message={t("errorBoundary.title")} />,
      });
    }
  };

  const isSelectedImageData = () => {
    return imageToCrop || dataURL;
  };

  return (
    <>
      {isSelectedImageData() ? (
        <RenderCropper
          cropperRef={cropperRef}
          imageToCrop={imageToCrop}
          dataURL={dataURL}
          resetImage={resetImage}
          onChange={onChange}
          onDragStop={onDragStop}
          sendPhotoCard={sendPhotoCard}
          doCrop={doCrop}
          isOpenCVLoading={isOpenCVLoading}
        />
      ) : (
        <RenderBeforeUpload handleAddPhotoClick={handleAddPhotoClick} />
      )}
      {(isOpenCVLoading || isUploading) && (
        <AbsoluteCenter zIndex={9999}>
          <Spinner
            size="lg"
            className="text-primary-light dark:text-primary-dark"
          />
        </AbsoluteCenter>
      )}
    </>
  );
}

const BeforeUploadTitle = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  white-space: pre-wrap;
`;

const BeforeUploadDescpription = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-top: 14px;
  white-space: pre-wrap;
`;

const DisclaimerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  /* FIXME : background: #ffffff0d; */
  border-radius: 23px;
`;

const DisclaimerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const DisclaimerEmoji = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
`;

const DisclaimerText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
`;

const CameraButtonWrapper = styled.div`
  padding-top: 32px;
  width: 100%;
`;

const RenderBeforeUpload = ({ handleAddPhotoClick }) => {
  const navigate = useNavigate();
  return (
    <div style={{ background: "black", width: "100vw", height: "100vh" }}>
      <StickyHeader
        title={t("reportPhotoCardPage.pageName")}
        showBackButton={false}
        leftContent={
          <ArrowLeftIcon
            className="fill-pure-white"
            onClick={() => navigate(-1)}
          />
        }
        showTitle={true}
        rightContent={null}
        transparent={true}
      />
      <AbsoluteCenter width={"calc(100% - 48px)"}>
        <Center flexDirection={"column"}>
          <BeforeUploadTitle className="text-pure-white">
            {t("ReportPhotoCardUploadPhotoPage.title")}
          </BeforeUploadTitle>
          <BeforeUploadDescpription className="text-main-light-4">
            {t("ReportPhotoCardUploadPhotoPage.description")}
          </BeforeUploadDescpription>
          <img
            width={"158px"}
            src={"/assets/icons/UploadPhotoCardCamera.png"}
            alt="UploadPhotoCardCamera"
          />
          <DisclaimerBox className="bg-[rgba(255, 255, 255, 0.05)]">
            <DisclaimerRow>
              <DisclaimerEmoji>{"🌸"}</DisclaimerEmoji>
              <DisclaimerText className="text-pure-white">
                {t("ReportPhotoCardUploadPhotoPage.disclaimerRowLine1")}
              </DisclaimerText>
            </DisclaimerRow>
            <DisclaimerRow>
              <DisclaimerEmoji>{"✨"}</DisclaimerEmoji>
              <DisclaimerText>
                {t("ReportPhotoCardUploadPhotoPage.disclaimerRowLine2")}
              </DisclaimerText>
            </DisclaimerRow>
            <DisclaimerRow>
              <DisclaimerEmoji>{"📊"}</DisclaimerEmoji>
              <DisclaimerText>
                {t("ReportPhotoCardUploadPhotoPage.disclaimerRowLine3")}
              </DisclaimerText>
            </DisclaimerRow>
          </DisclaimerBox>
          <CameraButtonWrapper>
            <RegularSolidButton
              text={t("ReportPhotoCardUploadPhotoPage.addPhotoCardButton")}
              paddingY={"16px"}
              onClick={handleAddPhotoClick}
              style={{ width: "100%" }}
            />
          </CameraButtonWrapper>
        </Center>
      </AbsoluteCenter>
    </div>
  );
};

const RenderCropper = ({
  cropperRef,
  imageToCrop,
  dataURL,
  resetImage,
  onChange,
  onDragStop,
  sendPhotoCard,
  doCrop,
}) => {
  return (
    <>
      <StickyHeader
        transparent={true}
        rightContent={
          (imageToCrop || dataURL) && (
            <RefreshFillIcon
              className="fill-pure-white"
              width={"24px"}
              height={"24px"}
              onClick={resetImage}
            />
          )
        }
      />
      <Overlay>
        <CropperContainer className="bg-background-light">
          <Cropper
            ref={cropperRef}
            image={imageToCrop}
            onChange={onChange}
            onDragStop={onDragStop}
            pointBgColor={"#FFFFFF"}
            pointBorder={""}
            lineColor={"#FFFFFF"}
            openCvPath={"/assets/opencv/opencv-3-4-13.min.js"}
            maxWidth={360}
            maxHeight={568}
          />
        </CropperContainer>
        <ConfirmButtonWrapper>
          <PrimaryButton
            marginY={"16px"}
            marginX={"24px"}
            paddingY={"16px"}
            text={dataURL ? t("send") : t("confirm")}
            fontSize={"17px"}
            onClick={dataURL ? sendPhotoCard : doCrop}
          />
        </ConfirmButtonWrapper>
      </Overlay>
    </>
  );
};
