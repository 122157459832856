import React from "react";

import {
  SimpleGrid,
  Skeleton,
  SkeletonText,
  useColorModeValue,
} from "@chakra-ui/react";

export default function GridSkeletons({
  gridSize,
  skeletonSize,
  skeletonHeight,
  showText = false,
  ...props
}) {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");

  return (
    <SimpleGrid columns={gridSize} gap={"15px"} {...props}>
      {[...Array(skeletonSize).keys()].map((key) => {
        return (
          <div key={key}>
            <Skeleton
              height={skeletonHeight}
              startColor={startColor}
              endColor={endColor}
              borderRadius={"12px"}
            />
            {showText && (
              <SkeletonText
                mt={"12px"}
                noOfLines={2}
                width={"75px"}
                startColor={startColor}
                endColor={endColor}
                borderRadius={"12px"}
              />
            )}
          </div>
        );
      })}
    </SimpleGrid>
  );
}
