import React, { useEffect, useState } from "react";

import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { find, sortBy } from "lodash";

import { ReactComponent as ChevronDownIcon } from "../../icons/chevron.down.small.svg";
import { getArtistId } from "../../utils/etcUtils";
import saveData from "../../utils/saveData";
import "./ArtistTextSelector.css";
import { t } from "i18next";

const ArtistTextSelector = ({
  artists,
  useInitArtistId = true,
  includeEntire = false,
  saveArtistId = true,
  onSelected,
  alignDropdown = "right",
  // 좋아요한 아티스트가 1개 이상일 경우
  customTextAreaElementMultiple = null,
  // 좋아요한 아티스트가 1개만 있을 경우
  customTextAreaElementSingle = null,
  // dropdown 하단에 추가할 추가 메뉴들
  customExtraDropdownMenus = null,
}) => {
  const DEFAULT_ARTIST = {
    name: t("entire"),
    slug: "entire",
    visibility: true,
    sortIndex: 0,
    fandomName: t("entire"),
    shortName: t("entire"),
    artistId: "entire",
  };

  const [selectedArtist, setSelectedArtist] = useState(null);

  useEffect(() => {
    if (!!selectedArtist) return;
    if ((artists?.length ?? 0) === 0) return;

    const initialArtistId = getArtistId();
    if (useInitArtistId && initialArtistId) {
      // 초기 값이 있을 경우
      const currentArtist = find(
        artists,
        (item) => item.artistId === initialArtistId
      );

      if (currentArtist) {
        setSelectedArtist(currentArtist);
        onSelected?.(currentArtist);
        return;
      }
    }

    const selected = includeEntire ? DEFAULT_ARTIST : artists[0];
    if (saveArtistId) {
      saveData("artist", selected.artistId);
    }
    setSelectedArtist(selected);
    onSelected?.(selected);
  }, [artists, includeEntire, useInitArtistId, saveArtistId]);

  const handleArtistSelect = (artist) => {
    if (saveArtistId) {
      saveData("artist", artist.artistId);
    }

    setSelectedArtist(artist);
    onSelected?.(artist);
  };

  return (
    <>
      {(artists?.length ?? 0) > 1 ? (
        <Menu autoSelect={false} placement="bottom-end">
          {customTextAreaElementMultiple ? (
            customTextAreaElementMultiple(selectedArtist)
          ) : (
            <MenuButton
              className="!text-pure-black dark:!text-pure-white"
              as={Button}
              colorScheme=""
              width={"fit-content"}
              padding={0}
              mt={"10px"}
              rightIcon={
                <ChevronDownIcon
                  className="fill-main-light-2 dark:fill-main-dark-2"
                  width={"20px"}
                  height={"20px"}
                />
              }
            >
              <Text fontSize={"17px"} fontWeight={700} lineHeight={"20px"}>
                {t("photoCardPage.likedArtistPhotoCardList", {
                  artist: selectedArtist?.shortName || selectedArtist?.name,
                })}
              </Text>
            </MenuButton>
          )}
          <MenuList
            textAlign={alignDropdown}
            borderRadius={"12px"}
            minW="107px"
            maxW="200px"
            mr="10px"
            zIndex={9999999999}
            spacing={"16px"}
            py={"12px"}
          >
            {includeEntire && (
              <ArtistMenuItem
                artist={DEFAULT_ARTIST}
                alignDropdown={alignDropdown}
                selectedArtist={selectedArtist}
                handleArtistSelect={handleArtistSelect}
              />
            )}
            {sortBy(artists, (artist) => artist.shortName || artist.name).map(
              (artist) => (
                <ArtistMenuItem
                  key={artist.artistId}
                  artist={artist}
                  alignDropdown={alignDropdown}
                  selectedArtist={selectedArtist}
                  handleArtistSelect={handleArtistSelect}
                />
              )
            )}
            {customExtraDropdownMenus?.map((extraMenu, index) => (
              <MenuItem
                className="artistTextSelectorMenuItem !text-primary-light dark:!text-primary-dark"
                key={`extra_menu_${index}`}
                alignItems={alignDropdown}
                command={alignDropdown === "right" ? extraMenu?.name : null}
                fontSize="14px"
                lineHeight="17px"
                fontWeight={500}
                px={"24px"}
                py={"8px"}
                onClick={() => extraMenu.onClick?.()}
              >
                {alignDropdown === "right" ? null : extraMenu?.name}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : (
        selectedArtist && (
          <Menu autoSelect={false} placement="bottom-end">
            {customTextAreaElementSingle ? (
              customTextAreaElementSingle(selectedArtist)
            ) : (
              <MenuButton
                as={Button}
                colorScheme=""
                width={"fit-content"}
                padding={0}
                mt={"20px"}
              >
                <Text fontSize={"17px"} fontWeight={700} lineHeight={"20px"}>
                  {t("photoCardPage.likedArtistPhotoCardList", {
                    artist: selectedArtist?.shortName || selectedArtist?.name,
                  })}
                </Text>
              </MenuButton>
            )}
          </Menu>
        )
      )}
    </>
  );
};

const ArtistMenuItem = ({
  artist,
  alignDropdown,
  selectedArtist,
  handleArtistSelect,
}) => {
  return (
    <MenuItem
      className={`artistTextSelectorMenuItem ${
        selectedArtist?.artistId === artist.artistId
          ? "!text-main-light-1 dark:!text-main-dark-1"
          : "!text-main-light-2 dark:!text-main-dark-2"
      }`}
      key={artist.artistId}
      alignItems={alignDropdown}
      command={
        alignDropdown === "right" ? artist.shortName || artist.name : null
      }
      fontSize="14px"
      lineHeight="17px"
      px={"24px"}
      py={"8px"}
      fontWeight={selectedArtist?.artistId === artist.artistId ? 600 : 500}
      onClick={() => handleArtistSelect(artist)}
    >
      {alignDropdown === "right" ? null : artist.shortName || artist.name}
    </MenuItem>
  );
};

export default ArtistTextSelector;
