import { Box, Drawer, DrawerBody, DrawerContent, DrawerFooter, DrawerHeader, DrawerOverlay, Image, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import React, { useState } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LightColors from "../../constants/LightColors";
import ToastMessageBox from "../alerts/ToastMessageBox";
import createChatRoom from "../api/mutations/legacy/createChatRoom";
import useExchangeablePhotoCardsQuery from "../api/queries/useExchagablePhotoCardsQuery";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryLineButton from "../buttons/SecondaryLineButton";
import CheckIcon from '../icons/CheckIcon';
import GridSkeletons from "../skeletons/GridSkeletons";
import TradingPhotoCardInfo from "../tradings/TradingPhotoCardInfo";

const Title = styled.div`
  font-weight: 700;
  font-size: 17px;
  line-height: 100%;
`

const Description = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
`

const IconWrapper = styled.div`
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 3px;
`

export default function ExchangeablePhotoCardBottomDrawer({isOpen, onClose, focusRef, tradingPhotoCard, onConfirmedPhotoCard}) {
  const navigate = useNavigate();
  const toast = useToast();

  const exchangeablePhotoCards = useExchangeablePhotoCardsQuery({
    userId: tradingPhotoCard.userId,
    artistId: tradingPhotoCard.artistId,
    memberId: tradingPhotoCard.memberId,
  })

  const [selectedPhotoCard, setSelectedPhotoCard] = useState(null);

  const hasErrorCode = () => {
    return !isEmpty(exchangeablePhotoCards.error)
  }

  const navigateChatRoom = async () => {
    const response = await createChatRoom(tradingPhotoCard.artistId, tradingPhotoCard.tradingPhotoCardId);
    if (response.success) {
      navigate(`/artists/${tradingPhotoCard.artistId}/chats/${response.chatRoom.chatRoomId}`)
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("exploreTradingPhotoCardBottomSheet.errorNoProfileToast")}/>
        ),
      })
    }
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement='bottom'
        finalFocusRef={focusRef}
      >
        <DrawerOverlay/>
        <DrawerContent
          motionProps={{
            variants: {
              enter: {
                y: "0%",
                transition: { duration: 0.15 },
              },
              exit: {
                y: "100%",
                transition: { duration: 0.1 },
              },
            }
          }}
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px"
          }}
        >
          <DrawerHeader>
            <Title className="mb-[20px] text-main-light-1 dark:text-main-dark-1">
              {t("tradingPhotoCardPage.matchedTradingPhotoCardTarget")}
            </Title>
            <TradingPhotoCardInfo tradingPhotoCard={tradingPhotoCard} showProfile={true} showPrice={false}/>
          </DrawerHeader>
          <DrawerBody p={0}>
            <div className="px-[24px]">
              <Title className="mb-[5px] text-main-light-1 dark:text-main-dark-1">
                {t("ExchangeablePhotoCardBottomDrawer.subtitle")}
              </Title>
              <Description className="text-main-light-3 dark:text-main-dark-3">
              {t("ExchangeablePhotoCardBottomDrawer.description")}
              </Description>
            </div>
            {
              isUndefined(exchangeablePhotoCards) ?
              <GridSkeletons className={"mx-[24px] mt-[12px] mb-[114px]"} gridSize={4} skeletonSize={3} skeletonHeight={'90px'}/> :
              isEmpty(exchangeablePhotoCards) ?
              <RenderError
                errorCode={"NONE"}
                navigateChatRoom={navigateChatRoom}
              /> : (
                hasErrorCode() ?
                <RenderError
                  errorCode={exchangeablePhotoCards.error.code}
                  navigateChatRoom={navigateChatRoom}
                /> :
                <>
                  <Box className="mt-[14px] mb-[20px] w-full">
                    <ScrollMenu wrapperClassName='padding-left-scroll-container'>
                      {exchangeablePhotoCards.map((photoCard) => (
                        <Box
                          key={photoCard.photoCardId}
                          cursor="pointer"
                          onClick={() => setSelectedPhotoCard(photoCard)}
                          position="relative"
                          className="mr-[4px]"// Ensure a minimum width for each item
                        >
                          <Box
                            border={selectedPhotoCard?.photoCardId === photoCard.photoCardId
                              ? `1px solid ${LightColors.primary}`
                              : "1px solid transparent"}
                            borderRadius="12px"
                            boxShadow={selectedPhotoCard?.photoCardId === photoCard.photoCardId
                              ? `0 0 0 1px ${LightColors.primary}`
                              : "transparent"}
                            transition="all 0.2s"
                          >
                            <Image
                              src={photoCard.mediumThumbnailUrl || photoCard.pictureUrl}
                              minWidth={"57px"}
                              width={"57px"}
                              height="90px"
                              aspectRatio={"55/87"}
                              borderRadius="8px"
                              loading={"lazy"}
                              objectFit="cover"
                            />
                          </Box>
                          {selectedPhotoCard?.photoCardId === photoCard.photoCardId && (
                            <IconWrapper className="absolute right-0 bottom-0 bg-primary-light dark:bg-primary-dark">
                              <CheckIcon width={"16px"} height={"16px"} fill="white"/>
                            </IconWrapper>
                          )}
                        </Box>
                      ))}
                    </ScrollMenu>
                  </Box>

                  <DrawerFooter className="flex flex-col safeAreaMarginBottom px-[24px!important]" width={"100%"} style={{padding: 0}}>
                    <PrimaryButton
                      text={t("confirm")}
                      disabled={isEmpty(selectedPhotoCard)}
                      onClick={() => onConfirmedPhotoCard(selectedPhotoCard)}
                      width="100%"
                      style={{padding: "16px 0px", fontSize: "17px"}}
                    />
                    <Description className="mt-[14px] mb-4 text-main-light-3 dark:text-main-dark-3">
                      {t("ExchangeablePhotoCardBottomDrawer.disclaimer")}
                    </Description>
                  </DrawerFooter>
                </>
              )
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}


const ErrorBox = styled.div`
  background-color: ${LightColors.surface};
`

const ErrorTitle = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${LightColors.textmainlv3};
`

const RenderError = ({errorCode, navigateChatRoom}) => {
  const navigate = useNavigate();

  const errorTitle = () => {
    switch (errorCode) {
      case "INVALID_USER_ID":
      case "EMPTY_COLLECTED_PHOTO_CARDS":
        return "교환 가능한 포카가 없어요"
      default:
        return "보유중인 포카가 없어요"
    }
  }

  const navigateCollectPhotoCards = () => {
    navigate("/home");
  }

  return(
    <ErrorBox className="flex flex-col mx-[24px] p-[32px] text-center rounded-[8px] my-[16px]">
      <ErrorTitle className="mb-[20px]">
        {errorTitle()}
      </ErrorTitle>
      <PrimaryButton
        className="py-[11px!important]"
        text={"보유 포카 체크하러가기"}
        onClick={() => navigateCollectPhotoCards()}
        width="100%"
      />
      {
        errorCode !== "INVALID_USER_ID" &&
        <SecondaryLineButton
          className={"py-[11px] text-[14px] mt-[6px]"}
          text={"그냥 상대방에게 찔러보기"}
          onClick={navigateChatRoom}
        />
      }
    </ErrorBox>
  )
}
