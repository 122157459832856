import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { t } from "i18next";

import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
};

const descriptionStyle = {
  fontWeight: 400,
  fontSize: "15px",
  whiteSpace: "pre-line",
};

export default function LeaveChatRoomDialog({
  isOpen,
  onClose,
  onClickDialogButton,
}) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} isCentered>
      <AlertDialogOverlay style={{ zIndex: 2147483647 }}>
        <AlertDialogContent
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          borderRadius={"40px"}
          paddingTop={"32px"}
          paddingBottom={"16px"}
          marginX={"6%"}
          style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)" }}
        >
          <AlertDialogBody paddingX={"32px"}>
            <VStack className="text-center">
              <Text
                className="text-main-light-1 dark:text-main-dark-1"
                style={titleStyle}
              >
                {t("tradingPhotoCardChatRoom.leaveChatRoomDialogTitle")}
              </Text>
              <Text
                className="text-main-light-4 dark:text-main-dark-4"
                style={descriptionStyle}
              >
                {t("tradingPhotoCardChatRoom.leaveChatRoomDialogDescription")}
              </Text>
            </VStack>
            <VStack className="w-full" marginTop={"20px"}>
              <PrimaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={() => onClickDialogButton()}
                text={t("tradingPhotoCardChatRoom.leaveChatRoomDialogConfirm")}
              />
              <SecondaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={() => onClose()}
                text={t("cancel")}
              />
            </VStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
