import styled from "styled-components";

const EventBoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const EventCategoryBox = styled.div`
  width: 53px;
  min-width: 53px;
  max-width: 53px;
  display: inline-flex;
  padding: 6px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px;
  border-radius: 10px;
  background-image: url(/assets/backgrounds/categorybox.png);
  background-size: cover;
`;

const EventCategoryEmoji = styled.img`
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
`;

const EventTextColumn = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 8px;
  gap: 0px;
`;

const EventCategoryText = styled.div`
  color: rgba(48, 25, 165, 0.93);
  text-align: center;
  font-size: 10px;
  line-height: 13px;
  font-weight: 700;
  letter-spacing: -0.1px;
`;

const EventTitleText = styled.div`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 18px;
  margin-bottom: 3px;
`;

const EventDescriptionRow = styled.div`
  display: flex;
  align-items: start;
  flex-direction: row;
`;

const EventDescriptionText = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-left: ${(props) => props.paddingX}px;
  padding-right: ${(props) => props.paddingX}px;
`;

export const EventNameBox = ({ event, isDisabled = false }) => {
  return (
    <EventBoxContainer alignItems={"center"}>
      <EventCategoryBox isDisabled={isDisabled}>
        <EventCategoryEmoji
          src={`/assets/icons/eventCategory/ic_event_${event.category}.png`}
        >
          {event.categoryEmoji}
        </EventCategoryEmoji>
        <EventCategoryText
          className="mix-blend-color-burn dark:mix-blend-plus-lighter"
          isDisabled={isDisabled}
        >
          {event.categoryName}
        </EventCategoryText>
      </EventCategoryBox>
      <EventTextColumn>
        <EventTitleText
          className="text-main-light-1 dark:text-main-dark-1"
          isDisabled={isDisabled}
        >
          {event.name}
        </EventTitleText>
        <EventDescriptionRow>
          {event.localizedName && (
            <EventDescriptionText
              className="text-main-light-4 dark:text-main-dark-4"
              isDisabled={isDisabled}
            >
              {event.localizedName}
            </EventDescriptionText>
          )}
          {event.localizedName && event.countryEmoji && event.countryName && (
            <EventDescriptionText
              className="text-sub-light-2 dark:text-sub-dark-2"
              paddingX={4}
              isDisabled={isDisabled}
            >{`•`}</EventDescriptionText>
          )}
          {event.countryEmoji && event.countryName && (
            <EventDescriptionText
              className="text-main-light-4 dark:text-main-dark-4"
              isDisabled={isDisabled}
            >{`${event.countryEmoji} ${event.countryName}`}</EventDescriptionText>
          )}
        </EventDescriptionRow>
      </EventTextColumn>
    </EventBoxContainer>
  );
};
