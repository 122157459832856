import React from "react";

import Sheet from "react-modal-sheet";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import { Center, VStack } from "@chakra-ui/react";
import styled from "styled-components";
import PrimaryButton from "../buttons/PrimaryButton";
import { t } from "i18next";

const Title = styled.p`
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const Description = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
`;

const Box = styled.div`
  display: flex;
  padding: 8px 18px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 18px;
  margin-top: 23px;
`;

const BoxItemWrapper = styled.div`
  display: flex;
  padding: 14px 0px;
  align-items: center;
  align-self: stretch;
`;

const NoneBorderBoxItemWrapper = styled.div`
  display: flex;
  padding: 14px 0px;
  align-items: center;
  align-self: stretch;
`;

const BoxEmojiWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 46px;
  width: 44px;
  height: 44px;
  font-size: 24px;
`;
const BoxTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 11px;
`;

const BoxTitle = styled.p`
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const BoxDescription = styled.p`
  text-align: left;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  white-space: pre-line;
`;

export default function MyHeartBottomSheet({ isShowBottomSheet, onClose }) {
  const closeBottomSheet = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div>
      <Sheet
        springConfig={SPRING_CONFIG}
        isOpen={isShowBottomSheet}
        disableDrag={true}
        onClose={onClose}
        detent="content-height"
        style={{ position: "sticky" }}
      >
        <Sheet.Container
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            position: "sticky",
          }}
        >
          <Sheet.Header
            className="bottomSheetHeader"
            style={{ paddingTop: "45px", paddingBottom: "0px" }}
          >
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("MyHeartBoxBottomSheet.title")}
            </Title>
            <Center>
              <img
                className="w-[320px]"
                src="/assets/logos/FanHearts.png"
                alt="fanheartsLogo.graphic"
              />
            </Center>
          </Sheet.Header>
          <Sheet.Content
            disableDrag={true}
            style={{
              paddingLeft: "24px",
              paddingRight: "24px",
              position: "sticky",
            }}
          >
            <VStack>
              <Description className="text-main-light-4 dark:text-main-dark-4">
                {t("MyHeartBoxBottomSheet.description")}
              </Description>
            </VStack>
            <Box className="bg-surface-light dark:bg-surface-dark">
              <BoxItemWrapper className="border-b-[1px] border-separator-light-2 dark:border-separator-dark-2">
                <BoxEmojiWrapper className="bg-surface-light dark:bg-surface-dark">
                  ️💕
                </BoxEmojiWrapper>
                <BoxTextWrapper>
                  <BoxTitle className="text-pure-black dark:text-pure-white">
                    {t("MyHeartBoxBottomSheet.earningTitle")}
                  </BoxTitle>
                  <BoxDescription className="text-main-light-4 dark:text-main-dark-4">
                    {t("MyHeartBoxBottomSheet.earningDescription")}
                  </BoxDescription>
                </BoxTextWrapper>
              </BoxItemWrapper>
              <NoneBorderBoxItemWrapper>
                <BoxEmojiWrapper className="bg-surface-light dark:bg-surface-dark">
                  ️🎁
                </BoxEmojiWrapper>
                <BoxTextWrapper>
                  <BoxTitle className="text-pure-black dark:text-pure-white">
                    {t("MyHeartBoxBottomSheet.usageTitle")}
                  </BoxTitle>
                  <BoxDescription className="text-main-light-4 dark:text-main-dark-4">
                    {t("MyHeartBoxBottomSheet.usageDescription")}
                  </BoxDescription>
                </BoxTextWrapper>
              </NoneBorderBoxItemWrapper>
            </Box>
            <PrimaryButton
              padding={"16px 34px"}
              marginTop={"28px"}
              marginBottom={"42px"}
              text={t("MyHeartBoxBottomSheet.closeButton")}
              fontSize={"17px"}
              fontWeight={600}
              onClick={(e) => closeBottomSheet(e)}
            />
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onClick={(e) => closeBottomSheet(e)} />
      </Sheet>
    </div>
  );
}
