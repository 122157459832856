import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import { Virtuoso } from "react-virtuoso";
import EmptyCase from "../errors/EmptyCase";
import GridSkeletons from "../skeletons/GridSkeletons";
import NotificationItem from "./NotificationItem";

export default function NewNotificationTabPanel({ notifications, loadMore }) {
  if (isUndefined(notifications)) {
    return (
      <GridSkeletons gridSize={1} skeletonSize={3} skeletonHeight={"60px"} />
    );
  } else if (isEmpty(notifications)) {
    return <EmptyCase emoji={"🙂"} title={t("emptyMessages.title")} />;
  }
  return (
    <div>
      <Virtuoso
        useWindowScroll
        overscan={480}
        data={notifications}
        endReached={loadMore}
        style={{ height: "100px" }}
        itemContent={(_, notification) => (
          <div
            key={notification.notificationId}
            style={{ paddingBottom: "12px" }}
          >
            <NotificationItem
              key={notification.notificationId}
              notification={notification}
            />
          </div>
        )}
      />
    </div>
  );
}
