import React from "react";

import { t } from "i18next";
import { Spacer } from "@chakra-ui/react";
import styled from "styled-components";

import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { useQueryParams } from "../../utils/useQueryParams";
import { ReportPhotoCardBreadcrumb } from "../../components/report/ReportPhotoCardBreadcrumb";
import { useNavigate } from "react-router-dom";

const CATEGORIES = {
  album: [
    {
      key: "default",
      name: t("PhotoCardCategory.album.default.name"),
      description: t("PhotoCardCategory.album.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.album.special.name"),
      description: t("PhotoCardCategory.album.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.album.md.name"),
      description: t("PhotoCardCategory.album.md.description"),
    },
  ],
  offline: [
    {
      key: "default",
      name: t("PhotoCardCategory.offline.default.name"),
      description: t("PhotoCardCategory.offline.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.offline.special.name"),
      description: t("PhotoCardCategory.offline.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.offline.md.name"),
      description: t("PhotoCardCategory.offline.md.description"),
    },
    {
      key: "trading",
      name: t("PhotoCardCategory.offline.trading.name"),
      description: t("PhotoCardCategory.offline.trading.description"),
    },
  ],
  ADs: [
    {
      key: "default",
      name: t("PhotoCardCategory.ADs.default.name"),
      description: t("PhotoCardCategory.ADs.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.ADs.special.name"),
      description: t("PhotoCardCategory.ADs.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.ADs.md.name"),
      description: t("PhotoCardCategory.ADs.md.description"),
    },
  ],
  content: [
    {
      key: "default",
      name: t("PhotoCardCategory.content.default.name"),
      description: t("PhotoCardCategory.content.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.content.special.name"),
      description: t("PhotoCardCategory.content.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.content.md.name"),
      description: t("PhotoCardCategory.content.md.description"),
    },
  ],
  misc: [
    {
      key: "default",
      name: t("PhotoCardCategory.misc.default.name"),
      description: t("PhotoCardCategory.misc.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.misc.special.name"),
      description: t("PhotoCardCategory.misc.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.misc.md.name"),
      description: t("PhotoCardCategory.misc.md.description"),
    },
  ],
  etc: [
    {
      key: "default",
      name: t("PhotoCardCategory.etc.default.name"),
      description: t("PhotoCardCategory.etc.default.description"),
    },
    {
      key: "special",
      name: t("PhotoCardCategory.etc.special.name"),
      description: t("PhotoCardCategory.etc.special.description"),
    },
    {
      key: "md",
      name: t("PhotoCardCategory.etc.md.name"),
      description: t("PhotoCardCategory.etc.md.description"),
    },
    {
      key: "trading",
      name: t("PhotoCardCategory.etc.trading.name"),
      description: t("PhotoCardCategory.etc.trading.description"),
    },
  ],
};

const ReportPhotoCardBreadcrumbWrapper = styled.div`
  padding: calc(54px + env(safe-area-inset-top)) 24px 10px 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 0px 24px 10px 24px;
`;

const CategoryBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`;

const CategoryBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
`;

const CategoryTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const CategoryTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`;

const CategoryDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

const DEFAULT_ACTIVITIES = ["album", "offline", "ADs", "content", "etc"];

export default function ReportPhotoCardCategoriesPage() {
  const navigate = useNavigate();
  const { queryParams, updateQueryParams } = useQueryParams();

  const convertActivity = (activity) => {
    if (DEFAULT_ACTIVITIES.includes(activity)) {
      return activity;
    } else {
      return "misc";
    }
  };
  const currentCategory =
    CATEGORIES[convertActivity(queryParams.get("activity"))];

  const handleSelectCategory = (category) => {
    updateQueryParams({ category }, "/report-photo-card/card-candidates");
  };

  return (
    <>
      <StickyHeader
        showTitle={true}
        showBackButton={true}
        title={t("ReportPhotoCardCategoriesPage.pageName")}
        onClickBackButton={() => navigate(-1)}
      />
      <ReportPhotoCardBreadcrumbWrapper>
        <ReportPhotoCardBreadcrumb />
      </ReportPhotoCardBreadcrumbWrapper>
      <Title>{t("ReportPhotoCardCategoriesPage.title")}</Title>
      <CategoryBoxWrapper>
        {currentCategory?.map((category) => {
          return (
            <CategoryBox
              className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
              key={category.key}
              onClick={() => handleSelectCategory(category.key)}
            >
              <CategoryTextColumn>
                <CategoryTitle className="text-pure-black dark:text-pure-white">
                  {category.name}
                </CategoryTitle>
                <CategoryDescription className="text-main-light-4 dark:text-main-dark-4">
                  {category.description}
                </CategoryDescription>
              </CategoryTextColumn>
              <Spacer />
              <RegularSolidButton text={t("select")} />
            </CategoryBox>
          );
        })}
      </CategoryBoxWrapper>
    </>
  );
}
