import {
  Divider,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  useColorMode,
  Wrap,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { t } from "i18next";
import { findIndex, isEmpty, sortBy, throttle } from "lodash";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import uuid from "react-uuid";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import StickyHeader from "../../components/StickyHeader";
import addTradingPhotoCards from "../../components/api/mutations/addTradingPhotoCards";
import { useArtistQueryLikedOnly } from "../../components/api/queries/useArtistQuery";
import usePhotoCardEventsQuery from "../../components/api/queries/usePhotoCardEventsQuery";
import useReportedPhotoCardsQuery from "../../components/api/queries/useReportedPhotoCardsQuery";
import useSearchablePhotoCardsQuery from "../../components/api/queries/useSearchablePhotoCardsQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import { EventNameBox } from "../../components/event/EventNameBox";
import LikedMembersList from "../../components/lists/LikedMembersList";
import TradingPhotoCardWindowList from "../../components/lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../../components/photocard/AddTradingPhotoCard";
import PendingPhotoCardsSection from "../../components/photocard/PendingPhotoCardsSection";
import { ReportPhotoCardBox } from "../../components/report/ReportPhotoCardBox";
import ArtistTextSelector from "../../components/selectors/ArtistTextSelector";
import EmptyPhotoCardEventSkeleton from "../../components/skeletons/EmptyPhotoCardEventSkeleton";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { filterSearchablePhotoCards } from "../../utils/SearchPhotoCard";

const AddButtonText = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;

const PageWrapper = styled.div`
  padding-top: calc(54px + env(safe-area-inset-top));
  padding-left: 24px;
  padding-right: 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  white-space: pre-wrap;
  line-height: 31px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 8px;
`;

const ReportPhotoCardBoxWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: calc(18px + env(safe-area-inset-bottom));
`;

const PendingPhotoCardsSectionWrapper = styled.div`
  padding: 12px 0px 0px 0px;
`;

export default function AddTradingPhotoCardPageV2() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const currentQueryParams = new URLSearchParams(location.search);
  const isExchange = currentQueryParams.get("isExchange");

  const [searchText, setSearchText] = useState();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState();
  const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  // const [searchablePhotoCards, setSearchablePhotoCards] = useState();

  // const [allPhotoCardByEvent, setAllPhotoCardByEvent] = useState([]);
  const [photoCardByEvent, setPhotoCardByEvent] = useState([]);

  const artistId = selectedArtist?.artistId;
  const user = useUserQuery();
  const artists = useArtistQueryLikedOnly();
  const reportedPhotoCards = useReportedPhotoCardsQuery({
    user: user,
    artistId: artistId || selectedArtist?.artistId,
  });

  const convertPhotoCardWithEvent = (photoCard, e) => {
    return {
      category: isExchange === "true" ? "exchange" : "sale",
      ...photoCard,
      ...{ event: e.event },
    };
  };

  const convertTradingPhotoCard = (photoCard, e) => {
    return {
      tradingPhotoCardId: uuid(),
      category: isExchange === "true" ? "exchange" : "sale",
      artistId: artistId,
      status: "wating",
      price: null,
      photoCard: photoCard,
      event: e,
    };
  };

  // const { data: allPhotoCardByEvent } = useQuery(
  //   ["allArtistPhotoCards", artistId],
  //   () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
  //   {
  //     enabled: !!artistId,
  //     select: (r) => r.events.flatMap((e) => e.photoCards.map((p) => convertPhotoCardWithEvent(p, e)))
  //   },
  // );

  const handlePaginatedEvents = (paginatedData, setEvent) => {
    const events = paginatedData.pages?.flatMap((page) =>
      page.events.map((event) => event)
    );
    setEvent(events);
  };

  const {
    data: memberPhotoCards,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = usePhotoCardEventsQuery({
    artistId: artistId,
    memberId: selectedMember?.memberId,
  });

  useEffect(() => {
    if (!isEmpty(memberPhotoCards)) {
      handlePaginatedEvents(memberPhotoCards, setPhotoCardByEvent);
      // setPhotoCardsInfo(first(data.pages)?.meta?.photoCardsInfo);
    }
  }, [memberPhotoCards]);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const searchablePhotoCards = useSearchablePhotoCardsQuery({
    artistId: artistId,
    memberId: selectedMember?.memberId,
  });

  const handlePhotoCardClick = (photoCard) => {
    selectPhotoCard(photoCard, photoCard.event);
  };

  // useEffect(() => {
  //   if (!isEmpty(artistPhotoCards)) {
  //     handlePaginatedEvents(artistPhotoCards, setAllPhotoCardByEvent);
  //     // setPhotoCardsInfo(first(data.pages)?.meta?.photoCardsInfo);
  //   }
  // }, [artistPhotoCards]);

  // const { data: photoCardByEvent } = useQuery(
  //   ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
  //   () => queryGet(`/artists/${artistId}/members/${getMemberIdAnyway(selectedMember)}/photo_cards`),
  //   {
  //     enabled: !!artistId && !!selectedMember,
  //   },
  // );

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if (event.origin !== window.location.origin) return;
      if (isEmpty(event.data?.type)) return;

      if (event.data?.type === "reported-new-photo-cards") {
        queryClient.invalidateQueries(["photoCardEvents"]);
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);

  const selectPhotoCard = (photoCard, e) => {
    const tempList = [...newTradingPhotoCards];
    tempList.unshift(convertTradingPhotoCard(photoCard, e));
    setNewTradingPhotoCards(tempList);
  };

  const deletePhotoCard = (photoCard) => {
    const index = findIndex(
      newTradingPhotoCards,
      (t) => t.photoCard.photoCardId === photoCard.photoCardId
    );
    if (index > -1) newTradingPhotoCards.splice(index, 1);
    setNewTradingPhotoCards([...newTradingPhotoCards]);
  };

  const onHandleSearchText = (text) => {
    setSearchText(text);
  };

  const confirmAddTradingPhotoCard = async () => {
    if (isEmpty(newTradingPhotoCards)) {
      return;
    }
    if (isExchange === "true") {
      if (user?.tradingUserProfile) {
        const response = await addTradingPhotoCards(
          user?.tradingUserProfile?.userProfileId,
          newTradingPhotoCards
        );
        if (response.ok) {
          queryClient.invalidateQueries(["tradingPhotoCards"]);
          queryClient.invalidateQueries(["TradingPhotoCards"]);
          queryClient.invalidateQueries(["tradingPhotoCardsAll"]);
          navigate("/tradings/completed_add?isExchange=true", {
            replace: true,
          });
        }
      }
    } else {
      navigate(
        `/tradings/add_sales_price?artistId=${artistId}&category=${
          isExchange === "true" ? "exchange" : "sale"
        }`,
        {
          state: {
            photoCardIds: newTradingPhotoCards.map(
              (t) => t.photoCard.photoCardId
            ),
          },
        }
      );
    }
  };

  return (
    <>
      <StickyHeader
        title={
          isExchange === "true"
            ? t("addTradingPhotoCardPage.exchangePageName")
            : t("addTradingPhotoCardPage.salesPageName")
        }
        showTitle={true}
        showBackButton={true}
        rightContent={
          <AddButtonText
            className={
              newTradingPhotoCards?.length > 0
                ? "text-primary-light dark:text-primary-dark"
                : "text-main-light-3 dark:text-main-dark-3"
            }
            onClick={confirmAddTradingPhotoCard}
          >
            {t("addTradingPhotoCard.addButton")}
          </AddButtonText>
        }
      />
      <PageWrapper>
        {newTradingPhotoCards?.length > 0 ? (
          <div
            className="bg-background-light dark:bg-background-dark"
            style={{
              position: "sticky",
              top: "calc(54px + env(safe-area-inset-top))",
              zIndex: 99999,
            }}
          >
            <div
              className="horizontalScroll hiddenScrollBar"
              style={{ paddingTop: "8px" }}
            >
              {newTradingPhotoCards?.map((t) => {
                return (
                  <div
                    key={t.tradingPhotoCardId}
                    className="relative"
                    style={{ marginRight: "4px" }}
                  >
                    <Image
                      src={
                        t.photoCard.mediumThumbnailUrl || t.photoCard.pictureUrl
                      }
                      width={57}
                      height={90}
                      objectFit={"cover"}
                      borderRadius={"8px"}
                      loading={"lazy"}
                    />
                    <div
                      className="absolute top-0 right-0 bg-primary-light dark:bg-primary-dark"
                      style={{
                        borderRadius: "32px",
                        padding: "5px",
                        marginRight: 3,
                        marginTop: -8,
                      }}
                      onClick={() => deletePhotoCard(t.photoCard)}
                    >
                      <CloseIcon
                        className="fill-pure-white"
                        width={"16px"}
                        height={"16px"}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <Wrap marginX={"-24px"} paddingTop={"24px"}>
              <Divider />
            </Wrap>
          </div>
        ) : (
          <></>
        )}
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {isExchange === "true"
            ? t("addTradingPhotoCardPage.exchangeTitle")
            : t("addTradingPhotoCardPage.salesTitle")}
        </Title>
        <InputGroup marginTop={"12px"}>
          <Input
            className="!border-none placeholder:text-[17px] placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark text-main-light-2 dark:!text-main-dark-2 !bg-secondary-light dark:!bg-secondary-dark"
            height={"54px"}
            borderRadius={"12px"}
            fontSize={"17px"}
            fontWeight={600}
            placeholder={t("addTradingPhotoCardPage.searchInputPlaceholder")}
            onChange={(e) => onHandleSearchText(e.target.value)}
          />
          <InputRightElement
            children={
              <SearchIcon className="fill-primary-light dark:fill-primary-dark" />
            }
            marginRight={"12px"}
            marginTop={"6px"}
          />
        </InputGroup>
        <ArtistTextSelector
          artists={artists}
          initialArtistId={artistId}
          onSelected={setSelectedArtist}
        />
        <LikedMembersList
          artistMembers={selectedArtist?.members}
          onChangeLikedMember={setSelectedMember}
          showAllMember={true}
        />
        {!isEmpty(reportedPhotoCards) && (
          <PendingPhotoCardsSectionWrapper>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              <PendingPhotoCardsSection
                reportedPhotoCards={reportedPhotoCards}
                onPhotoCardClick={handlePhotoCardClick}
              />
            </motion.div>
          </PendingPhotoCardsSectionWrapper>
        )}
        {isEmpty(photoCardByEvent) && (
          <Wrap paddingY="15px">
            <EmptyPhotoCardEventSkeleton />
          </Wrap>
        )}
        {!!searchText && !isEmpty(searchablePhotoCards) ? (
          <TradingPhotoCardWindowList
            tradingPhotoCards={filterSearchablePhotoCards(
              searchablePhotoCards,
              searchText
            )}
            addTradingPhotoCard={selectPhotoCard}
          />
        ) : !isEmpty(photoCardByEvent) ? (
          <Virtuoso
            useWindowScroll
            style={{ height: "100%" }}
            endReached={loadMore}
            overscan={480}
            data={photoCardByEvent}
            itemContent={(_index, e) => {
              const photoCards = e.photoCards?.map((p) =>
                convertPhotoCardWithEvent(p, e)
              );
              return (
                !isEmpty(photoCards) && (
                  <Stack key={e.event?.name} paddingBottom={"18px"}>
                    <EventNameBoxWrapper>
                      <EventNameBox event={e} />
                    </EventNameBoxWrapper>
                    <SimpleGrid columns={4} gap={"8px"}>
                      {sortBy(photoCards, (p) => {
                        return -p.collectedCount;
                      }).map((photoCard) => {
                        return (
                          <AddTradingPhotoCard
                            key={photoCard.photoCardId}
                            photoCard={photoCard}
                            onClickPhotoCard={(p, e) => selectPhotoCard(p, e)}
                          />
                        );
                      })}
                    </SimpleGrid>
                  </Stack>
                )
              );
            }}
          />
        ) : (
          <></>
        )}
        {selectedArtist && selectedMember && (
          <ReportPhotoCardBoxWrapper>
            <ReportPhotoCardBox
              artist={selectedArtist}
              member={selectedMember}
            />
          </ReportPhotoCardBoxWrapper>
        )}
      </PageWrapper>
    </>
  );
}
