import { AbsoluteCenter, Center } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const TextWrapper = styled.div`
  padding-top: 22px;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  white-space: pre-wrap;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-top: 14px;
  white-space: pre-wrap;
`;

const FanBuddyChatBotSplashPage = () => {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/fan-buddy/mbti");
  }, 1500);

  return (
    <AbsoluteCenter style={{ padding: "0 24px", width: "100%" }}>
      <Center flexDirection={"column"}>
        <img
          width={"172px"}
          height={"172px"}
          src={"/assets/backgrounds/fan-buddy/Mage Light Skin Tone.png"}
          alt={"wizard"}
        />
        <TextWrapper>
          <motion.div
            initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Title className="text-main-light-1 dark:text-main-dark-1">
              {"지금부터 덕메카드를 만들기 위해\n"}
              <span className="text-primary-light dark:text-primary-dark">
                {"30초 컷"}
              </span>
              {" 테스트를 시작할게요!"}
            </Title>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -10 }} // 위에서 아래로 이동
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }} // 0.5초 딜레이 추가
          >
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {
                "상대방의 메세지를 보고\n내 대답과 가장 가까운 대답을 눌러주세요."
              }
            </Description>
          </motion.div>
        </TextWrapper>
      </Center>
    </AbsoluteCenter>
  );
};

export default FanBuddyChatBotSplashPage;
