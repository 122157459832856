import React, { useRef, useState } from "react";

import {
  Input,
  InputGroup,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { isEmpty, isUndefined, sortBy } from "lodash";
import Sheet from "react-modal-sheet";
import { useQuery } from "react-query";

import { ReactComponent as CloseIcon } from "../../../icons/xmark.svg";
import { queryGet } from "../../../utils/queryClient";

import { t } from "i18next";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";
import filterPhotoCards from "../../../utils/SearchPhotoCard";
import { SPRING_CONFIG } from "../../../utils/bottomSheetSpringOption";
import { EventNameBox } from "../../event/EventNameBox";
import LikedMembersList from "../../lists/LikedMembersList";
import TradingPhotoCardWindowList from "../../lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../../photocard/AddTradingPhotoCard";
import ArtistIconSelector from "../../selectors/ArtistIconSelector";
import EmptyPhotoCardEventSkeletonV2 from "../../skeletons/EmptyPhotoCardEventSkeletonV2";

const EventNameBoxWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 8px;
`;

export default function AddPhotoCardBottomSheet({
  title,
  isCheckPhotoCardBottomSheetOpen,
  setIsCheckPhotoCardBottomSheetOpen,
  onConfirmedPhotoCards,
}) {
  const containerRef = useRef();
  const headerRef = useRef();

  const [selectedArtist, setSelectedArtist] = useState();
  const [selectedMember, setSelectedMember] = useState();
  const [searchText, setSearchText] = useState();

  const artistId = selectedArtist?.artistId;

  const convertPhotoCardWithEvent = (photoCard, e) => {
    return { ...photoCard, ...{ event: e.event } };
  };

  const { data: allPhotoCardByEvent } = useQuery(
    [`allArtistPhotoCards`, artistId],
    () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
    {
      enabled: !!artistId,
      select: (r) =>
        r.events.flatMap((e) =>
          e.photoCards.map((p) => convertPhotoCardWithEvent(p, e))
        ),
    }
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, selectedMember?.memberId],
    () =>
      queryGet(
        `/artists/${artistId}/members/${selectedMember?.memberId}/photo_cards?page=1&per_page=99999`
      ),
    {
      enabled: !!artistId && !!selectedMember?.memberId,
    }
  );

  const selectPhotoCard = (photoCard, e) => {
    onConfirmedPhotoCards(photoCard);
  };

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
  };

  const onSelectedMember = (member) => {
    setSelectedMember(member);
  };

  const onHandleSearchText = (text) => {
    setSearchText(text);
  };

  const scrollHeight = () => {
    if (
      containerRef?.current?.offsetHeight &&
      headerRef?.current?.offsetHeight
    ) {
      return (
        containerRef?.current?.offsetHeight -
        headerRef?.current?.offsetHeight -
        24
      );
    } else {
      return "60vh";
    }
  };

  const columns = useBreakpointValue({
    base: 4, // 모바일
    md: 6, // iPad
    lg: 6, // 큰 화면
  });

  return (
    <Sheet
      isOpen={isCheckPhotoCardBottomSheetOpen}
      onClose={() => setIsCheckPhotoCardBottomSheetOpen(false)}
      springConfig={SPRING_CONFIG}
      style={{ zIndex: 2147483647 }}
    >
      <Sheet.Container
        ref={containerRef}
        className="pb-8 !bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
      >
        <Sheet.Header ref={headerRef}>
          <SimpleGrid columns={3} alignItems="center" margin={"16px"}>
            <CloseIcon
              onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)}
              fill={"black"}
            />
            <Text
              fontWeight={700}
              fontSize={"17px"}
              lineHeight={"20px"}
              className="text-center"
            >
              {title}
            </Text>
            <div></div>
          </SimpleGrid>

          <ArtistIconSelector
            onSelected={onSelectedArtist}
            enableAddCard={false}
          />
          <div
            style={{
              paddingTop: "4px",
              paddingLeft: "24px",
              paddingRight: "24px",
            }}
          >
            <LikedMembersList
              artistMembers={selectedArtist?.members}
              onChangeLikedMember={onSelectedMember}
              showAllMember={true}
            />
          </div>

          <InputGroup marginTop="12px" paddingX={"24px"}>
            <Input
              className="placeholder:text-onDisabled-light dark:placeholder:text-onDisabled-dark bg-surface-light dark:bg-surface-dark text-main-light-2 dark:text-main-dark-2"
              height={"45px"}
              border={"none"}
              borderRadius={"12px"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={400}
              placeholder={t("addPhotoCard.searchPlaceholder")}
              onChange={(e) => onHandleSearchText(e.target.value)}
              width={"calc(100% + 12px)"}
            />
          </InputGroup>
          <Text
            className="text-sub-light-1 dark:text-sub-dark-1"
            fontSize={"13px"}
            paddingX={"24px"}
            paddingTop={"8px"}
            paddingBottom={"24px"}
            textAlign={"left"}
          >
            {t("addPhotoCard.description")}
          </Text>
        </Sheet.Header>

        <Sheet.Content
          disableDrag={true}
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            overflowY: "auto",
          }}
        >
          {!isEmpty(searchText) && !isEmpty(allPhotoCardByEvent) ? (
            <TradingPhotoCardWindowList
              tradingPhotoCards={filterPhotoCards(
                allPhotoCardByEvent,
                searchText
              )}
              addTradingPhotoCard={selectPhotoCard}
              windowHeight={scrollHeight()}
            />
          ) : isUndefined(photoCardByEvent) ? (
            <EmptyPhotoCardEventSkeletonV2 />
          ) : (
            !isEmpty(photoCardByEvent) && (
              <Virtuoso
                style={{ height: scrollHeight() }}
                overscan={480}
                data={photoCardByEvent?.events}
                itemContent={(_, e) => {
                  const photoCards = e.photoCards.map((p) =>
                    convertPhotoCardWithEvent(p, e)
                  );
                  return (
                    photoCards.length > 0 && (
                      <Stack key={e.event?.name} paddingBottom={"18px"}>
                        <EventNameBoxWrapper>
                          <EventNameBox event={e.event} />
                        </EventNameBoxWrapper>
                        <SimpleGrid
                          columns={columns}
                          gap={"8px"}
                          marginTop={"0px!important"}
                        >
                          {sortBy(photoCards, (p) => {
                            return -p.collectedCount;
                          }).map((photoCard) => {
                            return (
                              <AddTradingPhotoCard
                                key={photoCard.photoCardId}
                                photoCard={photoCard}
                                onClickPhotoCard={(p, e) =>
                                  selectPhotoCard(p, e)
                                }
                              />
                            );
                          })}
                        </SimpleGrid>
                      </Stack>
                    )
                  );
                }}
              />
            )
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => setIsCheckPhotoCardBottomSheetOpen(false)}
      />
    </Sheet>
  );
}
