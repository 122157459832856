import React, { useEffect, useState } from "react";

import {
  Tab,
  TabList,
  TabPanels,
  Tabs,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import "react-horizontal-scrolling-menu/dist/styles.css";

import Page from "../components/Page";
import ExchangingPhotoCardsTab from "./tabs/ExchangingPhotoCardsTab";
import PurchasingPhotoCardsTab from "./tabs/PurchasingPhotoCardsTab";
import SellingPhotoCardsTab from "./tabs/SellingPhotoCardsTab";
import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import { t } from "i18next";
import ScrollRestoration from "../utils/ScrollRestoration";

const activeTabStyle = (colorMode, theme) => ({
  fontWeight: 700,
  borderBottom: "3px solid",
  borderColor:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  color:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  borderBox: "",
});

export default function MyPhotoCardsMarketPage() {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(
    currentQueryParams.get("tabIndex") || 0
  );

  ScrollRestoration(window.location.pathname);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  };

  const tradingUserProfiles = useUserProfilesListQuery("trading");
  useEffect(() => {
    if (tradingUserProfiles && tradingUserProfiles.length > 1) {
      navigate("/users/merge_profiles?backUrl=/my");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles]);

  return (
    <div>
      <Page
        title={t("myPhotoCards.pageName")}
        showTitle={true}
        showBackButton={true}
        noGutter={true}
      >
        <Tabs
          isFitted
          index={parseInt(tabIndex)}
          paddingTop={"2px"}
          borderColor={"transparent"}
          colorScheme="primary"
          onChange={handleTabsChange}
        >
          <TabList
            className="border-b-[1px] !border-surface-light dark:!border-surface-dark"
            marginX={"-24px"}
            paddingX={"16px"}
            borderBottom={"1px"}
          >
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("myPhotoCards.exchangingPhotoCardsTabName")}
            </Tab>
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("myPhotoCards.sellingPhotoCardsTabName")}
            </Tab>
            <Tab
              _active={""}
              _selected={activeTabStyle(colorMode, theme)}
              lineHeight={"19px"}
              paddingY={"13px"}
            >
              {t("myPhotoCards.purchasingPhotoCardsTabName")}
            </Tab>
          </TabList>
          <TabPanels style={{ paddingBottom: "120px", paddingTop: "16px" }}>
            <ExchangingPhotoCardsTab />
            <SellingPhotoCardsTab />
            <PurchasingPhotoCardsTab />
          </TabPanels>
        </Tabs>
      </Page>
    </div>
  );
}
