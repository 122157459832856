import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import Sheet from "react-modal-sheet";

import {
  Box,
  HStack,
  Image,
  Stack,
  Switch,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  Wrap,
  Flex,
  Progress,
} from "@chakra-ui/react";

import download from "downloadjs";
import html2canvas from "html2canvas";

import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as SaveIcon } from "../../icons/save.svg";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { ReactComponent as ChevronDownIcon } from "../../icons/chevron.down.small.svg";

import FilledButton from "../FilledButton";
import PhotoCardsByEvent from "../PhotoCardsByEvent";

import { queryGet } from "../../utils/queryClient";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import { setEvent } from "../../utils/ga";
import { t } from "i18next";
import LikedMembersList from "../lists/LikedMembersList";
import { first } from "lodash";
import { getArtistId, getMemberIdAnyway } from "../../utils/etcUtils";

export default function MyPhotoCardBottomSheet({
  members,
  isMyPhotoCardsBottomSheetOpen,
  setIsMyPhotoCardsBottomSheetOpen,
}) {
  const ref = useRef();
  const artistId = getArtistId();

  const [selectedMember, setSelectedMember] = useState(null);
  const [photoCardByEvent, setPhotoCardByEvent] = useState();
  const [selectedPhotoCards, setSelectedPhotoCards] = useState(first(members));
  const [isSaveImage, setIsSaveImage] = useState(false);
  const [showEventName, setShowEventName] = useState(false);
  const [gridType, setGridType] = useState("md");
  const [tabIndex, setTabIndex] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [sourceDataUrl, setSourceDataUrl] = useState();

  useEffect(() => {
    if (isMyPhotoCardsBottomSheetOpen) {
      setEvent({
        category: "PhotoCard",
        action: "Show MyPhotoCard BottomSheet",
        label: "BottomSheet",
      });
    }
  }, [isMyPhotoCardsBottomSheetOpen]);

  const { data: artistPhotoCards } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards`
      ),
    {
      enabled: !!selectedMember && isMyPhotoCardsBottomSheetOpen,
    }
  );

  useEffect(() => {
    setSelectedMember(first(members));
  }, [members]);

  useEffect(() => {
    if (!isMyPhotoCardsBottomSheetOpen) return;
    switch (tabIndex) {
      case 1:
        setEvent({
          category: "PhotoCard",
          action: "Show UnCollected PhotoCards",
          label: "MyPhotoCardBottomSheet",
        });
        const unCollected = {
          events: artistPhotoCards?.events?.map((e) => {
            return {
              event: e.event,
              photoCards: unCollectedPhotoCards(e.photoCards),
            };
          }),
        };
        setPhotoCardByEvent(unCollected);
        return setSelectedPhotoCards(
          unCollected?.events?.flatMap((e) =>
            unCollectedPhotoCards(e.photoCards)
          )
        );
      case 2:
        setEvent({
          category: "PhotoCard",
          action: "Show Wished PhotoCards",
          label: "MyPhotoCardBottomSheet",
        });
        const wished = {
          events: artistPhotoCards?.events?.map((e) => {
            return {
              event: e.event,
              photoCards: wishedPhotoCards(e.photoCards),
            };
          }),
        };
        setPhotoCardByEvent(wished);
        return setSelectedPhotoCards(
          wished?.events?.flatMap((e) => wishedPhotoCards(e.photoCards))
        );
      default:
        setEvent({
          category: "PhotoCard",
          action: "Show Collected PhotoCards",
          label: "MyPhotoCardBottomSheet",
        });
        const collected = {
          events: artistPhotoCards?.events?.map((e) => {
            return {
              event: e.event,
              photoCards: collectedPhotoCards(e.photoCards),
            };
          }),
        };
        setPhotoCardByEvent(collected);
        return setSelectedPhotoCards(
          collected?.events?.flatMap((e) => collectedPhotoCards(e.photoCards))
        );
    }
  }, [tabIndex, artistPhotoCards]);

  const onChangedMember = (member) => {
    setSelectedMember(member);
  };

  const collectedPhotoCards = (photoCards) => {
    return photoCards.filter((p) => p.collectedCount > 0);
  };

  const unCollectedPhotoCards = (photoCards) => {
    return photoCards?.filter((p) => p.collectedCount === 0);
  };

  const wishedPhotoCards = (photoCards) => {
    return photoCards?.filter((p) => p.isWished);
  };

  const getPhotoSizeText = () => {
    switch (gridType) {
      case "sm":
        return t("myPhotoCardBottomSheet.smallSize");
      case "md":
        return t("myPhotoCardBottomSheet.middleSize");
      case "lg":
        return t("myPhotoCardBottomSheet.largeSize");
      default:
        return t("myPhotoCardBottomSheet.largeSize");
    }
  };

  const toggleSwitchChanged = () => {
    setShowEventName(showEventName ? false : true);
  };

  const convertSourceToCanvas = () => {
    setEvent({
      category: "PhotoCard",
      action: "Create PhotoCard Board",
      label: "PhotoCard Board",
    });
    setIsExporting(true);
    const sourceElement = document.getElementById("source");
    html2canvas(sourceElement, {
      backgroundColor: null,
      allowTaint: false,
      useCORS: true,
      imageTimeout: 0,
      onclone: function (cloned) {
        cloned.getElementById("source").style.display = "block";
      },
    }).then((canvas) => {
      setIsExporting(false);
      setIsSaveImage(true);
      setSourceDataUrl(canvas.toDataURL("image/png"));
    });
  };

  const capturePhotoCardBoard = () => {
    setEvent({
      category: "PhotoCard",
      action: "Save PhotoCard Board",
      label: "PhotoCard Board",
    });
    download(sourceDataUrl, `${`pocapan_${Date.now()}`}.png`);
  };

  const sharePhotoCardBoard = () => {
    setEvent({
      category: "PhotoCard",
      action: "Share PhotoCard Board",
      label: "PhotoCard Board",
    });
    window.location.href = t("myPhotoCardBottomSheet.shareTwiiterText");
  };

  const isScrollableState = () => {
    const bottomSheet = document.querySelector(".bottomSheetContent");
    const saveButton = document.querySelector(".saveButton");

    return (
      bottomSheet?.scrollHeight > window.innerHeight ||
      selectedPhotoCards?.length > (getPhotoSizeText() === "md" ? 8 : 20) ||
      saveButton?.getBoundingClientRect()?.y > window.innerHeight
    );
  };

  const exitBottomSheet = () => {
    exitSaveImage();
    setIsMyPhotoCardsBottomSheetOpen(false);
  };

  const exitSaveImage = () => {
    setIsSaveImage(false);
    setIsExporting(false);
    setSourceDataUrl(null);
  };

  return (
    <Sheet
      ref={ref}
      isOpen={isMyPhotoCardsBottomSheetOpen}
      disableDrag={true}
      onClose={() => setIsMyPhotoCardsBottomSheetOpen(false)}
      springConfig={SPRING_CONFIG}
    >
      <Sheet.Container
        className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
        style={{
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          paddingBottom: "calc(2rem + env(safe-area-inset-bottom))",
        }}
      >
        <Sheet.Header className="bottomSheetHeader">
          <div id="bottomSheetHeader">
            <Stack>
              {isSaveImage ? (
                <ArrowLeftIcon
                  className="fill-black dark:fill-white"
                  onClick={() => exitSaveImage()}
                />
              ) : (
                <CloseIcon
                  className="fill-black dark:fill-white"
                  onClick={() => exitBottomSheet()}
                />
              )}
            </Stack>
          </div>
        </Sheet.Header>
        <Sheet.Content disableDrag={true} className="bottomSheetContent">
          {isSaveImage ? (
            <>
              <Image src={sourceDataUrl} />
              <HStack mt={10}>
                {
                  <Box
                    className="bg-primary-light dark:bg-primary-dark"
                    padding={"10px"}
                    borderRadius={"full"}
                    onClick={() => capturePhotoCardBoard()}
                  >
                    <Wrap padding={"6px"}>
                      <SaveIcon
                        className="fill-white"
                        width={"24px"}
                        height={"24px"}
                      />
                    </Wrap>
                  </Box>
                }
                <FilledButton
                  width={"full"}
                  borderRadius={"32px"}
                  paddingX={"16px"}
                  paddingY={"16px"}
                  fontSize={"17px"}
                  fontWeight={600}
                  lineHeight={"20px"}
                  text={t("myPhotoCardBottomSheet.shareImageTwitterButton")}
                  filled={true}
                  onClicked={() => sharePhotoCardBoard()}
                />
              </HStack>
            </>
          ) : (
            <>
              <HStack paddingY={"10px"}>
                <Text
                  className={
                    tabIndex === 0
                      ? "text-pure-black dark:text-pure-white"
                      : "text-onDisabled-light dark:text-onDisabled-dark"
                  }
                  fontWeight={600}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  as={tabIndex === 0 ? "b" : ""}
                  onClick={() => setTabIndex(0)}
                >
                  {t("myPhotoCardBottomSheet.collectedPhotoCards")}
                </Text>
                <Text
                  className={
                    tabIndex === 1
                      ? "text-pure-black dark:text-pure-white"
                      : "text-onDisabled-light dark:text-onDisabled-dark"
                  }
                  fontWeight={600}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  as={tabIndex === 1 ? "b" : ""}
                  onClick={() => setTabIndex(1)}
                >
                  {t("myPhotoCardBottomSheet.unCollectedPhotoCards")}
                </Text>
                <Text
                  className={
                    tabIndex === 2
                      ? "text-pure-black dark:text-pure-white"
                      : "text-onDisabled-light dark:text-onDisabled-dark"
                  }
                  fontWeight={600}
                  fontSize={"20px"}
                  lineHeight={"24px"}
                  as={tabIndex === 2 ? "b" : ""}
                  onClick={() => setTabIndex(2)}
                >
                  {t("myPhotoCardBottomSheet.wishedPhotoCards")}
                </Text>
              </HStack>
              {<LikedMembersList onChangeLikedMember={onChangedMember} />}
              <SimpleGrid columns={2} alignItems="center" paddingY={"14px"}>
                <Menu>
                  <MenuButton
                    size="xs"
                    className="bg-background-light dark:bg-backgroundElevated-dark"
                  >
                    <HStack spacing={0}>
                      <Text
                        className="text-main-light-3 dark:text-main-dark-3"
                        fontSize={"13px"}
                        fontWeight={400}
                        lineHeight={"16px"}
                        marginRight={"4px"}
                      >
                        {getPhotoSizeText()}
                      </Text>
                      <ChevronDownIcon
                        className="fill-main-light-3 dark:fill-main-dark-3"
                        width={"20px"}
                        height={"20px"}
                      />
                    </HStack>
                  </MenuButton>
                  <MenuList>
                    <MenuItem key={"sm"} onClick={() => setGridType("sm")}>
                      {t("myPhotoCardBottomSheet.smallSize")}
                    </MenuItem>
                    <MenuItem key={"md"} onClick={() => setGridType("md")}>
                      {t("myPhotoCardBottomSheet.middleSize")}
                    </MenuItem>
                    <MenuItem key={"lg"} onClick={() => setGridType("lg")}>
                      {t("myPhotoCardBottomSheet.largeSize")}
                    </MenuItem>
                  </MenuList>
                </Menu>
                <div
                  className="flex justify-end"
                  style={{ alignItems: "center" }}
                >
                  <Switch
                    size="sm"
                    colorScheme="primary"
                    isChecked={showEventName}
                    onChange={() => toggleSwitchChanged()}
                  />
                  <Text
                    className="text-main-light-3 dark:text-main-dark-3"
                    fontSize={"13px"}
                    fontWeight={400}
                    lineHeight={"16px"}
                    paddingLeft={2}
                  >
                    {t("myPhotoCardBottomSheet.showPhotoCardName")}
                  </Text>
                </div>
              </SimpleGrid>
              <Flex
                height={isScrollableState() ? "" : "calc(100% - 150px)"}
                direction={"column"}
                justifyContent={"space-between"}
                marginBottom={"80px"}
              >
                <div
                  id="source"
                  style={{
                    display: "none",
                    borderRadius: "18px",
                    padding: "12px",
                    background: "url('/assets/backgrounds/holography.png')",
                    backgroundSize: "cover",
                  }}
                >
                  <div
                    style={{
                      paddingTop: "4px",
                      paddingBottom: "12px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      borderRadius: "8px",
                      background:
                        "linear-gradient(90deg, rgba(255, 255, 255, 0.3) 100%, rgba(255, 255, 255, 0.1) 100%), linear-gradient(134.41deg, rgba(255, 255, 255, 0.4) 10%, rgba(255, 255, 255, 0) 100%)",
                    }}
                  >
                    <PhotoCardsByEvent
                      photoCardsByEvent={photoCardByEvent}
                      selectedPhotoCards={selectedPhotoCards}
                      collectingMode={false}
                      showEventName={showEventName}
                      gridType={gridType}
                      onClickedPhotoCard={() => {}}
                    />
                    <div className="w-full flex justify-center">
                      <div className="w-3/4 mt-10 mb-5">
                        <img
                          alt="pocapan logo"
                          src="/assets/logos/pocapan.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <PhotoCardsByEvent
                    photoCardsByEvent={photoCardByEvent}
                    selectedPhotoCards={selectedPhotoCards}
                    collectingMode={false}
                    showEventName={showEventName}
                    gridType={gridType}
                    onClickedPhotoCard={() => {}}
                  />
                </>
                <div
                  className="bottomButton"
                  style={{
                    width: "calc(100% - 45px)",
                    marginBottom: "calc(40px + env(safe-area-inset-bottom))",
                  }}
                >
                  {isExporting ? (
                    <Progress
                      size="sm"
                      colorScheme="primary"
                      isIndeterminate
                      borderRadius={"12px"}
                    />
                  ) : (
                    selectedPhotoCards?.length > 0 && (
                      <FilledButton
                        paddingX={"16px"}
                        paddingY={"16px"}
                        fontSize={"17px"}
                        fontWeight={600}
                        lineHeight={"20px"}
                        borderRadius={"32px"}
                        marginTop={isScrollableState() ? "20px" : ""}
                        text={t("myPhotoCardBottomSheet.createPhotoCardBoard")}
                        filled={true}
                        onClicked={() => convertSourceToCanvas()}
                      />
                    )
                  )}
                </div>
              </Flex>
            </>
          )}
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onClick={() => exitBottomSheet()} />
    </Sheet>
  );
}
