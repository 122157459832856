import React, { useEffect, useMemo, useState } from "react";

import { t } from "i18next";
import { filter, isEmpty } from "lodash";
import { Helmet } from "react-helmet-async";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";

import useGroupTradingPhotoCardsQuery from "../../components/api/queries/useGroupTradingPhotoCardsQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import NewSaleTradingPhotoCard from "../../components/tradings/NewSaleTradingPhotoCard";
import { ReactComponent as ArrowLeftIcon } from "../../icons/arrow.left.svg";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import { useColorMode } from "@chakra-ui/react";

const Header = styled.header`
  width: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: calc(16px + env(safe-area-inset-top));
  padding-bottom: 15px;
  padding-left: calc(16px + env(safe-area-inset-left));
  padding-right: calc(16px + env(safe-area-inset-right));
  z-index: 1000;
`;

const LeftContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  padding: 0 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  white-space: pre-wrap;
`;

const ListWrapper = styled.div`
  padding: 12px 24px 64px 24px;
`;

export default function EditPricesInTradingPhotoCardsPage() {
  const { colorMode } = useColorMode();
  const params = useParams();
  const location = useLocation();

  // useMemo로 검색 파라미터 값 메모이제이션
  const tradingPhotoCardIds = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get("tradingPhotoCardIds")?.split(",") || [];
  }, [location.search]); // location.search가 변경될 때만 재계산

  const handleHistoryBack = useHandleHistoryBack();
  const groupTradingPhotoCards = useGroupTradingPhotoCardsQuery(
    params.group_id
  );
  const [tradingPhotoCards, setTradingPhotoCards] = useState([]);

  useEffect(() => {
    if (isEmpty(groupTradingPhotoCards)) return;

    const filtered = groupTradingPhotoCards.filter((card) =>
      tradingPhotoCardIds.includes(card.tradingPhotoCardId)
    );
    setTradingPhotoCards(filtered);
  }, [tradingPhotoCardIds, groupTradingPhotoCards]); // 안정적인 종속성

  const user = useUserQuery();

  const onChangedItem = (item) => {
    const items = tradingPhotoCards.map((tradingPhotoCard) => {
      if (tradingPhotoCard.tradingPhotoCardId === item.tradingPhotoCardId) {
        return { ...item, price: item.price, priceNumber: item.priceNumber };
      } else {
        return tradingPhotoCard;
      }
    });
    setTradingPhotoCards(items);
  };

  const onDeletedItem = (item) => {
    const items = filter(tradingPhotoCards, (tradingPhotoCard) => {
      return tradingPhotoCard.tradingPhotoCardId !== item.tradingPhotoCardId;
    });
    setTradingPhotoCards(items);

    if (isEmpty(items)) {
      alert(t("ConfirmNewSalesTradingPhotoCardsPage.removeAllDialog"));
      handleHistoryBack();
    }
  };

  const handleDone = () => {
    window.opener?.postMessage(
      {
        type: "update-trading-photo-cards",
        params: {
          tradingPhotoCards: tradingPhotoCards.map((tradingPhotoCard) => {
            return {
              tradingPhotoCardId: tradingPhotoCard.tradingPhotoCardId,
              price: tradingPhotoCard.priceNumber || tradingPhotoCard.price,
              priceNumber: tradingPhotoCard.priceNumber,
            };
          }),
        },
      },
      window.location.origin
    );
    window.close();
  };

  return (
    <>
      <Helmet>
        <title>{t("ConfirmNewSalesTradingPhotoCardsPage.title")}</title>{" "}
        {/* Helmet을 사용하여 title 설정 */}
      </Helmet>
      <Header className="bg-white dark:bg-background-dark">
        <LeftContainer>
          {
            <ArrowLeftIcon
              className="fill-black dark:fill-white"
              onClick={handleHistoryBack}
            />
          }
        </LeftContainer>
      </Header>
      <TitleWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("ConfirmNewSalesTradingPhotoCardsPage.title")}
        </Title>
        <Description className="text-sub-light-1 dark:text-sub-dark-1">
          {t("ConfirmNewSalesTradingPhotoCardsPage.description")}
        </Description>
      </TitleWrapper>
      <ListWrapper>
        <div>
          {tradingPhotoCards?.map((tradingPhotoCard) => {
            return (
              <div
                key={tradingPhotoCard.tradingPhotoCardId}
                style={{ marginBottom: "16px" }}
              >
                <NewSaleTradingPhotoCard
                  user={user}
                  editMode={true}
                  tradingPhotoCard={tradingPhotoCard}
                  onChangedItem={(item) => onChangedItem(item)}
                  onDeletedItem={(item) => onDeletedItem(item)}
                />
              </div>
            );
          })}
        </div>
      </ListWrapper>
      <div
        className={`${
          colorMode === "dark" ? "darkBottomNavbar" : "bottomNavbar"
        } bg-background-light dark:bg-background-dark text-center`}
        style={{
          padding: "0px 24px calc(16px + env(safe-area-inset-bottom)) 24px",
          zIndex: 9999,
        }}
      >
        <PrimaryButton
          paddingY={"16px"}
          text={t("next")}
          zIndex={9999}
          onClick={() => handleDone()}
        />
      </div>
    </>
  );
}
