import React from "react";
import styled from "styled-components";
import { setEvent } from "../../utils/ga";

// 스타일 정의
const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 52px;
  line-height: 17px;
  cursor: pointer;

  min-width: ${({ minWidth }) => minWidth || "37px"};
  min-height: ${({ minHeight }) => minHeight || "33px"};
  padding: ${({ paddingX, paddingY }) =>
    `${paddingY || "8px"} ${paddingX || "12px"}`};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-weight: ${({ fontWeight }) => fontWeight || 500};

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const Avatar = styled.img`
  border-radius: 50%;
  width: 24px; /* 원하는 크기로 조정 */
  height: 24px; /* 원하는 크기로 조정 */
  margin-right: 8px;
`;

const IconContainer = styled.div`
  margin-left: 4px; /* 아이콘과 텍스트 간의 간격 */
`;

export default function RegularSolidButton({
  text,
  filled = true,
  onClicked,
  isLoading = false,
  icon,
  image,
  ...props // 나머지 속성은 Styled Component로 전달
}) {
  const handleClick = () => {
    setEvent({
      category: "Click Button",
      action: "Click RegularButton",
      label: text,
    });

    if (onClicked) {
      onClicked();
    }
  };

  return (
    <StyledButton
      className={
        filled
          ? "bg-primary-light dark:bg-primary-dark text-onPrimary"
          : "bg-secondary-light text-onSecondary-light dark:text-onSecondary-dark dark:bg-secondary-dark"
      }
      filled={filled}
      onClick={handleClick}
      disabled={isLoading}
      {...props} // Styled Component에 모든 나머지 속성 전달
    >
      {image && <Avatar src={image} alt="avatar" />}
      {isLoading ? (
        <Spinner
          className="border-[4px] border-label-light dark:border-label-dark border-t-primary-light dark:border-t-primary-dark"
          size="sm"
        />
      ) : (
        <span>{text}</span>
      )}
      {icon && <IconContainer>{icon}</IconContainer>}
    </StyledButton>
  );
}

// 스피너 컴포넌트 정의
const Spinner = styled.div`
  margin-right: 20px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
