import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Avatar,
  Flex,
  HStack,
  Text,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { t } from "i18next";
import Page from "../components/Page";
import useUserQuery from "../components/api/queries/useUserQuery";
import UserProfileList from "../components/lists/UserProfileList";
import HomeBottomNav from "../layout/HomeBottomNav";
import { ReactComponent as CardFillIcon } from "../icons/card.fill.svg";
import { ReactComponent as FirstShapeIcon } from "../icons/first.shape.svg";
import { ReactComponent as LikeShapeIcon } from "../icons/like.shape.svg";
import { ReactComponent as StarShapeIcon } from "../icons/star.shape.svg";
import { ReactComponent as SettingIcon } from "../icons/setting.svg";
import { MyHeartBox } from "../components/userProfile/MyHeartBox";

const avatarStyle = {
  width: "36px",
  height: "36px",
};

const avatarIconStyle = {
  width: "20px",
  height: "20px",
};

const menuRowTextStyle =
  "text-[15px] font-medium leading-[18px] tracking-[0em] text-left text-main-light-2 dark:text-main-dark-2";

export default function MyPage() {
  const { colorMode } = useColorMode();
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const artistId = currentQueryParams.get("artistId");

  const navigate = useNavigate();
  const user = useUserQuery();

  const navigateIfUserExists = (path) => {
    if (!user) return;
    navigate(path);
  };
  const isKorean = () => {
    return user && user.country === "KR";
  };

  return (
    <div
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      } safeAreaPaddingExceptBottomBackground`}
    >
      <Page
        showBackButton={false}
        title={t("myPage.pageName")}
        userProfile={user}
        style={{ paddingTop: "13px", marginBottom: "96px" }}
        themeColor={colorMode === "dark" ? "#0D0E12" : "#FFFFFF"}
      >
        <Flex mb="10px" className="items-center">
          <Text
            className="text-main-light-1 dark:text-main-dark-1"
            fontSize={"26px"}
            fontWeight={700}
          >
            {t("myPage.title")}
          </Text>

          <Avatar
            className="!bg-secondary-light dark:!bg-secondary-dark ml-auto"
            style={avatarStyle}
            icon={
              <SettingIcon
                className="fill-onSecondary-light dark:fill-onSecondary-dark"
                onClick={() => navigate("/account/manage")}
                style={avatarIconStyle}
                width={"24px"}
                height={"24px"}
              />
            }
          />
        </Flex>

        <UserProfileList user={user} />

        {/* <MyHeartBox /> */}

        <VStack alignItems={"left"} spacing={"20px"} marginTop={"26px"}>
          <Text
            className="text-main-light-3 dark:text-main-dark-3"
            fontSize={"14px"}
            fontWeight={600}
          >
            {t("myPage.manageMembersRow")}
          </Text>
          <HStack spacing={"13px"} onClick={() => navigate("/artists/manage")}>
            <Avatar
              className="!bg-secondary-light dark:!bg-secondary-dark"
              style={avatarStyle}
              icon={
                <StarShapeIcon
                  className="fill-onSecondary-light dark:fill-onSecondary-dark"
                  style={avatarIconStyle}
                />
              }
            />
            <div className={menuRowTextStyle}>
              {t("myPage.manageArtistsRow")}
              <span className="text-primary-light dark:text-primary-dark">{` ${
                user?.likedArtistsCount || ""
              }`}</span>
            </div>
          </HStack>
          <HStack spacing={"13px"} onClick={() => navigate("/members/manage")}>
            <Avatar
              className="!bg-secondary-light dark:!bg-secondary-dark"
              style={avatarStyle}
              icon={
                <FirstShapeIcon
                  className="fill-onSecondary-light dark:fill-onSecondary-dark"
                  style={avatarIconStyle}
                />
              }
            />
            <div className={menuRowTextStyle}>
              {t("myPage.manageMembersRow")}
              <span className="text-primary-light dark:text-primary-dark">{` ${
                user?.likedMembersCount || ""
              }`}</span>
            </div>
          </HStack>

          <Text
            className="text-main-light-3 dark:text-main-dark-3"
            fontSize={"14px"}
            fontWeight={600}
          >
            {t("myPage.managePocasRow")}
          </Text>
          <HStack
            spacing={"13px"}
            onClick={() =>
              navigateIfUserExists(
                `/users/${user?.userId}/my_photo_cards_market`
              )
            }
          >
            <Avatar
              className="!bg-secondary-light dark:!bg-secondary-dark"
              style={avatarStyle}
              icon={
                <CardFillIcon
                  className="fill-onSecondary-light dark:fill-onSecondary-dark"
                  style={avatarIconStyle}
                />
              }
            />
            <div className={menuRowTextStyle}>
              {t("myPage.tradingsRow")}
              <span className="text-primary-light dark:text-primary-dark">{` ${
                user?.tradingPhotoCardsCount || ""
              }`}</span>
            </div>
          </HStack>
          <HStack
            spacing={"13px"}
            onClick={() =>
              navigateIfUserExists(`/users/${user?.userId}/my_photo_cards_wish`)
            }
          >
            <Avatar
              className="!bg-secondary-light dark:!bg-secondary-dark"
              style={avatarStyle}
              icon={
                <LikeShapeIcon
                  className="fill-onSecondary-light dark:fill-onSecondary-dark"
                  style={avatarIconStyle}
                />
              }
            />
            <div className={menuRowTextStyle}>
              {t("myPage.wishedRow")}
              <span className="text-primary-light dark:text-primary-dark">{` ${
                user?.wishedPhotoCardsCount || ""
              }`}</span>
            </div>
          </HStack>
        </VStack>

        {
          // #endregion
        }

        {
          // #region 약관 & 로그 아웃 등 그 밖 기능등
        }
        <VStack
          className="text-sub-light-1 dark:text-sub-dark-1"
          alignItems={"baseline"}
          fontSize="xs"
          mt="24px"
        >
          <Text onClick={() => (window.location.href = "/pocapan_terms.html")}>
            {t("userProfilePage.terms")}
          </Text>
          <Text
            onClick={() => (window.location.href = "/pocapan_privacy.html")}
          >
            {t("userProfilePage.privacy")}
          </Text>
          <Text className="pt-5" wordBreak={"keep-all"}>
            <a
              href="https://www.pocapan.com"
              style={{ textDecoration: "underline" }}
            >
              @Peacecover
            </a>{" "}
            inc. All rights reserved.
          </Text>
        </VStack>
        {
          // #endregion
        }
        <div
          className="safeAreaMarginBottom"
          style={{
            paddingBottom: "130px",
          }}
        />
      </Page>

      <HomeBottomNav tabIndex={isKorean() ? 4 : 3} artistId={artistId} />
    </div>
  );
}
