import { Box, GridItem, SimpleGrid } from "@chakra-ui/react";
import styled from "styled-components";

import { useArtistQueryAll } from "../../components/api/queries/useArtistQuery";
import { ArtistCircleBox } from "../../components/artist/ArtistCircleBox";
import StickyHeader from "../../components/StickyHeader";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const TitleWrapper = styled.div`
  padding-top: calc(54px + env(safe-area-inset-top));
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  white-space: pre-wrap;
`;

const ArtistsWrapper = styled.div`
  padding: 26px 24px;
`;

export const ReportPhotoCardSelectArtistPage = () => {
  const navigate = useNavigate();
  const artists = useArtistQueryAll();

  const onArtistSelected = (artist) => {
    navigate(`/report-photo-card/select-member?artistId=${artist.artistId}`);
  };

  return (
    <>
      <StickyHeader
        showBackButton={true}
        showTitle={true}
        title={t("reportPhotoCardPage.pageName")}
      />
      <TitleWrapper>
        <Title className="text-main-light-1 dark:text-main-dark-1">
          {t("ReportPhotoCardSelectArtistPage.title")}
        </Title>
      </TitleWrapper>
      <ArtistsWrapper>
        <SimpleGrid columns={2} spacingX={"18px"} spacingY={"26px"}>
          {artists &&
            artists
              .filter((a) => !!a.likedDate)
              .map((artist) => {
                return (
                  <GridItem rowSpan={1} key={artist.name}>
                    <Box>
                      <ArtistCircleBox
                        artist={artist}
                        onArtistSelected={(artist) => onArtistSelected(artist)}
                      />
                    </Box>
                  </GridItem>
                );
              })}
        </SimpleGrid>
      </ArtistsWrapper>
    </>
  );
};
