import React from "react";

import { Text, VStack } from "@chakra-ui/react";
import { ReactComponent as MatchingFillIcon } from "../../icons/matching.fill.svg";
import TradingPhotoCardInfo from "./TradingPhotoCardInfo";
import { t } from "i18next";

const matchedTitleStyle = {
  fontSize: "13px",
  fontWeight: 600,
};

export default function MatchedTradingPhotoCardInfo({ source, target }) {
  return (
    <div className="flex">
      <div className="flex-1">
        <VStack align={"flex-start"}>
          <Text
            className="text-main-light-4 dark:text-main-dark-4"
            style={matchedTitleStyle}
          >
            {t("tradingPhotoCardPage.matchedTradingPhotoCardMine")}
          </Text>
          <TradingPhotoCardInfo tradingPhotoCard={source} halfType={true} />
        </VStack>
      </div>
      <div
        className="flex items-center shrink pt-4"
        style={{ paddingLeft: "6px", paddingRight: "6px" }}
      >
        <MatchingFillIcon
          width={"16px"}
          className="fill-sub-light-1 dark:fill-sub-dark-1"
        />
      </div>
      <div className="flex-1">
        <VStack align={"flex-start"}>
          <Text style={matchedTitleStyle}>
            {t("tradingPhotoCardPage.matchedTradingPhotoCardTarget")}
          </Text>
          <TradingPhotoCardInfo tradingPhotoCard={target} halfType={true} />
        </VStack>
      </div>
    </div>
  );
}
