import React, { useEffect, useState } from "react";

import { Input, InputGroup, Spacer } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import styled from "styled-components";

import useUserQuery from "../../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../../components/bottomSheet/AddUserProfileV1BottomSheet";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import { ReportPhotoCardBreadcrumb } from "../../components/report/ReportPhotoCardBreadcrumb";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as ArrowRightSmall } from "../../icons/arrow.right.small.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import { useQueryParams } from "../../utils/useQueryParams";
import { useNavigate } from "react-router-dom";
import { openNewTab } from "../../utils/UIUtils";

const ACTIVITIES = [
  { key: "album" },
  { key: "offline" },
  { key: "ADs" },
  { key: "misc" },
  { key: "content" },
];

const ReportPhotoCardBreadcrumbWrapper = styled.div`
  padding: calc(54px + env(safe-area-inset-top)) 24px 10px 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 0px 24px 10px 24px;
`;

const ActivityBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 22px;
  padding-bottom: 32px;
`;

const ActivityBox = styled.div`
  display: flex;
  padding: 24px 20px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  /* FIXME : box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.01),
    0px 0px 14px 0px rgba(0, 0, 0, 0.08); */
`;

const ActivityTextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const ActivityTitle = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`;

const ActivityDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
`;

const SelectButtonWrapper = styled.div`
  width: max-width;
`;

const UnknownActivity = styled.div`
  color: ${LightColors.textmainlv2};
  font-size: 15px;
  font-weight: 600;
`;

const UnknownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  place-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
`;

const InputWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  margin: 20px 24px 0px 24px;
  align-items: center;
  padding: 2px 11px;
`;

export default function ReportPhotoCardActivitiesPage() {
  const navigate = useNavigate();
  const { updateQueryParams } = useQueryParams();

  const user = useUserQuery();
  const isEmptyBaseUserProfile = user && isEmpty(user.baseUserProfile);

  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] =
    useState(false);

  useEffect(() => {
    if (isEmptyBaseUserProfile) {
      setIsShowBaseProfileBottomSheet(true);
    }
  }, [user]);

  const handleSelectActivity = (activity) => {
    if (activity === "etc") {
      updateQueryParams({ activity }, "/report-photo-card/card-categories");
    } else if (activity === "misc") {
      updateQueryParams({ activity }, "/report-photo-card/misc-activities");
    } else {
      updateQueryParams({ activity }, "/report-photo-card/events");
    }
  };

  const handleSearchEvent = () => {
    updateQueryParams({}, "/report-photo-card/search-events");
  };

  return (
    <>
      <StickyHeader
        title={t("ReportPhotoCardActivitiesPage.pageName")}
        showBackButton={true}
        showTitle={true}
      />
      <ReportPhotoCardBreadcrumbWrapper>
        <ReportPhotoCardBreadcrumb artistId={"newjeans"} />
      </ReportPhotoCardBreadcrumbWrapper>
      <Title>{t("ReportPhotoCardActivitiesPage.title")}</Title>
      <InputWrapper
        className="bg-secondary-light dark:bg-secondary-dark"
        onClick={() => handleSearchEvent()}
      >
        <SearchIcon
          className="fill-placeholder-light dark:fill-placeholder-dark"
          width={"24px"}
          height={"24px"}
        />
        <InputGroup border={"transparent"}>
          <Input
            paddingLeft={"3px"}
            readOnly={true}
            _focus={{ border: "transparent", boxShadow: "none" }}
            _active={{ border: "transparent" }}
            _hover={{ border: "transparent" }}
            outline={"none"}
            placeholder={t("ReportSearchEventPage.placeHolder")}
            fontSize={"15px"}
          />
        </InputGroup>
      </InputWrapper>
      <ActivityBoxWrapper>
        {ACTIVITIES.map((activity) => {
          return (
            <ActivityBox
              className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
              key={activity.key}
              onClick={() => handleSelectActivity(activity.key)}
            >
              <ActivityTextColumn>
                <ActivityTitle className="text-pure-black dark:text-pure-white">
                  {t(`EventCategory.${activity.key}.title`)}
                </ActivityTitle>
                <ActivityDescription className="text-main-light-4 dark:text-main-dark-4">
                  {t(`EventCategory.${activity.key}.description`)}
                </ActivityDescription>
              </ActivityTextColumn>
              <Spacer />
              <SelectButtonWrapper>
                <RegularSolidButton text={t("select")} />
              </SelectButtonWrapper>
            </ActivityBox>
          );
        })}
      </ActivityBoxWrapper>
      {/* <UnknownWrapper onClick={() => handleSelectActivity("etc")}>
        <UnknownActivity>
          {t("ReportPhotoCardActivitiesPage.unknownActivity")}
        </UnknownActivity>
        <ArrowRightSmall width={"20px"} height={"20px"} fill={LightColors.textmainlv2}/>
      </UnknownWrapper> */}
      {isEmptyBaseUserProfile && isShowBaseProfileBottomSheet && (
        <AddUserProfileV1BottomSheet
          title={t("createFeedUserProfilePage.title")}
          userProfile={user.baseUserProfile}
          isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
          category={"base"}
          disableBackdrop={true}
        />
      )}
    </>
  );
}
