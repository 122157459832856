import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as CheckedIcon } from "../../icons/check.circle.fill.svg";
import { ReactComponent as UnCheckedIcon } from "../../icons/check.radio.line.svg";

const CheckboxContainer = styled.div`
  display: inline-flex;
  vertical-align: middle;
  cursor: pointer;
  flex-direction: row;
`;

const Text = styled.div`
  margin-left: 10px;
  font-size: 15px;
  font-weight: 350;
`;

const PriamryRadio = ({ size = "md", isChecked, onChanged, text }) => {
  const getSize = () => {
    return size === "md" ? "24px" : "32px";
  };
  return (
    <CheckboxContainer onClick={() => onChanged(!isChecked)}>
      {isChecked ? (
        <CheckedIcon
          className="fill-primary-light dark:fill-primary-dark"
          width={getSize()}
          height={getSize()}
        />
      ) : (
        <UnCheckedIcon
          className="stroke-sub-light-2 dark:stroke-sub-dark-2"
          width={getSize()}
          height={getSize()}
        />
      )}
      {text && (
        <Text className="text-main-light-1 dark:text-main-dark-1">{text}</Text>
      )}
    </CheckboxContainer>
  );
};

export default PriamryRadio;
