import { ReactComponent as LikeLineIconSvg } from "../../icons/like.line.svg";

export default function LikeLineIcon({ color, ...props }) {
  return (
    <LikeLineIconSvg
      className="fill-black dark:fill-white"
      width={"28px"}
      height={"28px"}
      {...props}
    />
  );
}
