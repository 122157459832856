// src/pages/KeywordGridPage.js
import { AbsoluteCenter, Center, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import StickyHeader from "../../components/StickyHeader";
import { ReactComponent as CloseIcon } from "../../icons/xmark.svg";
import { ReactComponent as CheckedIcon } from "../../icons/check.circle.fill.svg";
import ToastMessageBox from "../../components/alerts/ToastMessageBox";

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: center;
  white-space: pre-wrap;
  text-align: left;
`;

const Description = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  padding-top: 14px;
`;

const Card = styled.div`
  margin: 32px 0 50px 0;
  max-width: 182px;
  border-radius: 22px;
  border: 1px outset rgba(0, 0, 0, 0.1);
  background: ${(props) =>
    `linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, #FFF 5.61%, rgba(255, 255, 255, 0.30) 19.79%, #FFF 36%, rgba(255, 255, 255, 0.48) 70%, #FFF 100%), linear-gradient(134deg, rgba(${props.backgroundPrimaryColor},1) 9.43%, rgba(255, 255, 255, 0.90) 45.41%, rgba(${props.backgroundSecondaryColor},1) 81.4%)`};
  box-shadow: 0px -6px 40px 0px rgba(61, 250, 165, 0.2),
    0px 6px 40px 0px rgba(42, 102, 246, 0.25);
  animation: shadowPulse 3s infinite, float 2.5s ease-in-out infinite;

  @keyframes shadowPulse {
    0% {
      ${(props) =>
        `box-shadow: 0px -6px 40px 0px rgba(${props.backgroundPrimaryColor}, 0.20), 0px 6px 40px 0px rgba(${props.backgroundSecondaryColor}, 0.25)`};
    }
    50% {
      ${(props) =>
        `box-shadow: 0px -12px 80px 0px rgba(${props.backgroundPrimaryColor}, 0.40), 0px 12px 80px 0px rgba(${props.backgroundSecondaryColor}, 0.35)`};
    }
    100% {
      ${(props) =>
        `box-shadow: 0px -6px 40px 0px rgba(${props.backgroundPrimaryColor}, 0.20), 0px 6px 40px 0px rgba(${props.backgroundSecondaryColor}, 0.25)`};
    }
  }

  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }
`;

const CardTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  text-align: center;
  margin-top: 34px;
  margin-bottom: 13px;
`;

const MBTIImage = styled.div`
  width: 93px;
  height: 93px;
  -webkit-mask-image: ${(props) => `url(/assets/icons/mbti/${props.mbti}.png)`};
  -webkit-mask-composite: source-in;
  mask-composite: intersect;
  -webkit-mask-size: cover;
  mask-size: cover;
  background: ${(props) =>
    `linear-gradient(-135deg, rgba(${props.textPrimaryColor}, 0.7), rgba(${props.textSecondaryColor}, 0.7))`};
`;

const MBTITitle = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  background: ${(props) =>
    `linear-gradient(90deg, rgba(${props.textPrimaryColor},1) 0%, rgba(${props.textSecondaryColor},1) 100%)`};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  word-break: keep-all;
`;

const MBTIDescription = styled.div`
  padding-top: 10px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  word-break: keep-all;
`;

const CompatibleMBTITitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  padding-bottom: 10px;
`;

const CompatibleMBTIDescription = styled.div`
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  background: ${(props) =>
    `linear-gradient(90deg, rgba(${props.textPrimaryColor},1) 0%, rgba(${props.textSecondaryColor},1) 100%)`};
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const RestartText = styled.div`
  margin-top: 36px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  text-decoration: underline;
`;

const FAN_BUDDY_MBTI = {
  INTJ: {
    name: "돌판의 오은영쌤",
    description: "덕메들이 그렇게 고민상담을 많이하신다고!",
    compatible: ["ENTJ", "INTP"],
    incompatible: ["ESFP", "ISFJ"],
    textColor: {
      primary: "79, 70, 229",
      secondary: "251, 191, 36",
    },
    backgroundColor: {
      primary: "165, 163, 244",
      secondary: "253, 230, 138",
    },
  },
  INTP: {
    name: "떡밥 찾기 장인",
    description: "뮤비에서 숨겨진 의미를 찾아내시나요?",
    compatible: ["INTJ", "ENTP"],
    incompatible: ["ESFJ", "ISFP"],
    textColor: {
      primary: "147, 51, 234",
      secondary: "52, 211, 153",
    },
    backgroundColor: {
      primary: "216, 180, 243",
      secondary: "167, 243, 200",
    },
  },
  ENTJ: {
    name: "예비 총대 전문가",
    description: "당신이 총대를 안하면 누가해요ㅠ",
    compatible: ["INTJ", "ENFJ"],
    incompatible: ["ISFP", "INFP"],
    textColor: {
      primary: "244, 63, 94",
      secondary: "255, 214, 10",
    },
    backgroundColor: {
      primary: "253, 176, 176",
      secondary: "255, 229, 153",
    },
  },
  ENTP: {
    name: "키보드 최애 지킴이",
    description: "최애는 내가 지킨다.. 악플러 다 데려와",
    compatible: ["INTP", "ENTJ"],
    incompatible: ["ISFJ", "ESFJ"],
    textColor: {
      primary: "16, 185, 129",
      secondary: "245, 158, 11",
    },
    backgroundColor: {
      primary: "167, 243, 208",
      secondary: "253, 217, 155",
    },
  },
  INFJ: {
    name: "돌판의 인간 위키",
    description: "혹시 모든 스케줄을 다 외우고 계시나요?",
    compatible: ["ENFJ", "INFP"],
    incompatible: ["ESTP", "ENTP"],
    textColor: {
      primary: "74, 222, 128",
      secondary: "59, 130, 246",
    },
    backgroundColor: {
      primary: "188, 244, 194",
      secondary: "147, 197, 253",
    },
  },
  INFP: {
    name: "돌판의 평화주의자",
    description: "내 탐라는 언제나 평화 그 자체..♥",
    compatible: ["INFJ", "ENFP"],
    incompatible: ["ESTJ", "ENTJ"],
    textColor: {
      primary: "167, 139, 250",
      secondary: "251, 191, 36",
    },
    backgroundColor: {
      primary: "221, 214, 254",
      secondary: "253, 230, 138",
    },
  },
  ENFJ: {
    name: "서포트 조공 총대",
    description: "총대는 내가 할게, 참여는 누가 할래?",
    compatible: ["INFJ", "ESFJ"],
    incompatible: ["ISTP", "INTP"],
    textColor: {
      primary: "38, 171, 127",
      secondary: "255, 109, 0",
    },
    backgroundColor: {
      primary: "157, 236, 203",
      secondary: "255, 194, 153",
    },
  },
  ENFP: {
    name: "오프 가면 꼭 있는 사람",
    description: "방구석1열도 최소 덕메랑 같이 있는편..",
    compatible: ["INFP", "ESFJ"],
    incompatible: ["ISTJ", "INTJ"],
    textColor: {
      primary: "255, 113, 113",
      secondary: "59, 130, 246",
    },
    backgroundColor: {
      primary: "255, 195, 195",
      secondary: "147, 197, 253",
    },
  },
  ISTJ: {
    name: "덕질 매너 지킴이",
    description: "오프에서 줄 제일 잘서고, 매너 최고인 선배님.",
    compatible: ["ESTJ", "ISFJ"],
    incompatible: ["ENFP", "ESFP"],
    textColor: {
      primary: "37, 99, 235",
      secondary: "250, 204, 21",
    },
    backgroundColor: {
      primary: "163, 200, 253",
      secondary: "255, 224, 138",
    },
  },
  ISFJ: {
    name: "돌판의 좋아요 요정",
    description: "최애 관련 글만 보이면 바로 좋아요 눌러주는 당신",
    compatible: ["ESFJ", "ISTJ"],
    incompatible: ["ENTP", "INTP"],
    textColor: {
      primary: "34, 211, 238",
      secondary: "248, 113, 113",
    },
    backgroundColor: {
      primary: "165, 239, 250",
      secondary: "253, 195, 195",
    },
  },
  ESTJ: {
    name: "명예 최애 매니저",
    description: "최애 관련 글만 보이면 바로 좋아요 눌러주는 당신",
    compatible: ["ISTJ", "ESFJ"],
    incompatible: ["INFP", "ENFP"],
    textColor: {
      primary: "59, 130, 246",
      secondary: "249, 115, 22",
    },
    backgroundColor: {
      primary: "147, 197, 253",
      secondary: "252, 200, 154",
    },
  },
  ESFJ: {
    name: "입덕 전문 가이드",
    description: "당신이랑 대화하면 바로 입덕하게 되",
    compatible: ["ENFJ", "ISFJ"],
    incompatible: ["INTP", "ISTP"],
    textColor: {
      primary: "251, 191, 36",
      secondary: "168, 85, 247",
    },
    backgroundColor: {
      primary: "253, 230, 138",
      secondary: "216, 180, 243",
    },
  },
  ISTP: {
    name: "예비 홈마 또는 네임드",
    description: "창작물 뭐 하나는 만들어봤다에 한표!",
    compatible: ["ESTP", "ISFP"],
    incompatible: ["ENFJ", "ESFJ"],
    textColor: {
      primary: "16, 185, 129",
      secondary: "244, 63, 94",
    },
    backgroundColor: {
      primary: "167, 243, 208",
      secondary: "253, 176, 176",
    },
  },
  ISFP: {
    name: "포카 꾸미기 장인",
    description: "포카 꾸미기 스티커만 최소 백개!",
    compatible: ["ESFP", "INFP"],
    incompatible: ["ESTJ", "INTJ"],
    textColor: {
      primary: "192, 132, 252",
      secondary: "255, 214, 10",
    },
    backgroundColor: {
      primary: "235, 217, 254",
      secondary: "255, 229, 153",
    },
  },
  ESTP: {
    name: "돌판의 헤르미온느",
    description: "혹시 해외 투어 일정에 맞춰 여행 계획까지 짜시는 분인가요?",
    compatible: ["ISTP", "ESFP"],
    incompatible: ["INFJ", "ISFJ"],
    textColor: {
      primary: "244, 63, 94",
      secondary: "52, 211, 153",
    },
    backgroundColor: {
      primary: "253, 176, 176",
      secondary: "167, 243, 200",
    },
  },
  ESFP: {
    name: "차애 전문 영업러",
    description: "당신을 만나면 없던 차애도 생긴다고..!",
    compatible: ["ENFP", "ISFP"],
    incompatible: ["INTJ", "ISTJ"],
    textColor: {
      primary: "250, 204, 21",
      secondary: "96, 165, 250",
    },
    backgroundColor: {
      primary: "255, 224, 138",
      secondary: "189, 230, 254",
    },
  },
};

const ShowFanBuddyCardPage = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const currentQueryParams = new URLSearchParams(window.location.search);
  const mbti = currentQueryParams.get("mbti");
  const showToast = currentQueryParams.get("showToast");

  useEffect(() => {
    if (showToast === "true") {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox
            leftIcon={
              <CheckedIcon
                className="fill-verified"
                width={"20px"}
                height={"20px"}
              />
            }
            message={"사전 신청 완료! 오픈되면 알림을 보내드릴게요."}
          />
        ),
      });
    }
  }, [toast, showToast]);

  const onClickReStart = () => {
    navigate("/fan-buddy/intro");
  };

  const exit = () => {
    navigate("/feed");
  };

  return (
    <div>
      <StickyHeader
        title={"덕메찾기"}
        alwaysShowTitle={true}
        showBackButton={false}
        leftContent={
          <CloseIcon
            className="fill-pure-black dark:fill-pure-white"
            width={"20px"}
            height={"20px"}
            onClick={exit}
          />
        }
        style={{ maxWidth: "calc(var(--chakra-sizes-xl))" }}
      />
      <AbsoluteCenter
        style={{
          padding: "0 24px",
          width: "100%",
          maxWidth: "calc(var(--chakra-sizes-xl))",
        }}
      >
        <Center flexDirection={"column"}>
          <Title className="text-main-light-1 dark:text-main-dark-1">
            {"나의 덕메카드 완성!"}
          </Title>
          <Description className="text-main-light-4 dark:text-main-dark-4">
            {"덕메카드는 언제든지 다시 만들 수 있어요"}
          </Description>
          <Card
            backgroundPrimaryColor={
              FAN_BUDDY_MBTI[mbti]["backgroundColor"]["primary"]
            }
            backgroundSecondaryColor={
              FAN_BUDDY_MBTI[mbti]["backgroundColor"]["secondary"]
            }
            textPrimaryColor={FAN_BUDDY_MBTI[mbti]["textColor"]["primary"]}
            textSecondaryColor={FAN_BUDDY_MBTI[mbti]["textColor"]["secondary"]}
          >
            <div
              className="flex flex-col items-center"
              style={{ padding: "0px 20px" }}
            >
              <CardTitle className="text-main-light-4 dark:text-main-dark-4">
                {"나의 덕메 카드"}
              </CardTitle>
              <MBTIImage
                mbti={mbti}
                textPrimaryColor={FAN_BUDDY_MBTI[mbti]["textColor"]["primary"]}
                textSecondaryColor={
                  FAN_BUDDY_MBTI[mbti]["textColor"]["secondary"]
                }
              />
              {/* <MBTIImage src={`/assets/icons/mbti/${mbti}.png`} alt={mbti}/> */}
              {/* </ImageContainer> */}
              <div
                style={{
                  height: "86px",
                  marginBottom: "16px",
                  alignContent: "center",
                }}
              >
                <MBTITitle
                  textPrimaryColor={
                    FAN_BUDDY_MBTI[mbti]["textColor"]["primary"]
                  }
                  textSecondaryColor={
                    FAN_BUDDY_MBTI[mbti]["textColor"]["secondary"]
                  }
                >
                  {FAN_BUDDY_MBTI[mbti]["name"]}
                </MBTITitle>
                <MBTIDescription className="text-main-light-2 dark:text-main-dark-2">
                  {FAN_BUDDY_MBTI[mbti]["description"]}
                </MBTIDescription>
              </div>
            </div>
          </Card>
          <div className="flex justify-between w-full">
            <div className="flex-1 text-center">
              <CompatibleMBTITitle className="text-main-light-3 dark:text-main-dark-3">
                {"나랑 잘맞아요"}
              </CompatibleMBTITitle>
              <div className="flex flex-col gap-[3px]">
                {FAN_BUDDY_MBTI[mbti]["compatible"].map((compatibleMBTI) => (
                  <CompatibleMBTIDescription
                    key={compatibleMBTI}
                    textPrimaryColor={
                      FAN_BUDDY_MBTI[compatibleMBTI]["textColor"]["primary"]
                    }
                    textSecondaryColor={
                      FAN_BUDDY_MBTI[compatibleMBTI]["textColor"]["secondary"]
                    }
                  >
                    {FAN_BUDDY_MBTI[compatibleMBTI]["name"]}
                  </CompatibleMBTIDescription>
                ))}
              </div>
            </div>
            <div
              className="flex-1 text-center"
              style={{
                borderLeft: "1px solid rgba(217, 217, 217, 1)",
                height: "100%",
              }}
            >
              <CompatibleMBTITitle className="text-main-light-3 dark:text-main-dark-3">
                {"나와는 쫌..ㅎ"}
              </CompatibleMBTITitle>
              <div className="flex flex-col gap-[3px]">
                {FAN_BUDDY_MBTI[mbti]["incompatible"].map((compatibleMBTI) => (
                  <CompatibleMBTIDescription
                    key={compatibleMBTI}
                    textPrimaryColor={
                      FAN_BUDDY_MBTI[compatibleMBTI]["textColor"]["primary"]
                    }
                    textSecondaryColor={
                      FAN_BUDDY_MBTI[compatibleMBTI]["textColor"]["secondary"]
                    }
                  >
                    {FAN_BUDDY_MBTI[compatibleMBTI]["name"]}
                  </CompatibleMBTIDescription>
                ))}
              </div>
            </div>
          </div>
          {/* <RegularSolidButton text={"아직 사람들을 모집중이에요"} style={{ padding: "16px 20px", width: "100%", borderRadius: "8px" }}/> */}
          <RestartText
            className="text-main-light-3 dark:text-main-dark-3"
            onClick={() => onClickReStart()}
          >
            {"덕메카드 다시 만들기"}
          </RestartText>
        </Center>
      </AbsoluteCenter>
    </div>
  );
};

export default ShowFanBuddyCardPage;
