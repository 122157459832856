import React, { useEffect, useState } from "react";

import { Badge } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import styled from "styled-components";

import ConfirmPhotoCard from "../../components/api/mutations/ConfirmPhotoCard";
import usePhotoCardsQuery from "../../components/api/queries/usePhotoCardsQuery";
import RegularSolidButton from "../../components/buttons/RegularSolidButton";
import { ReportPhotoCardBreadcrumb } from "../../components/report/ReportPhotoCardBreadcrumb";
import StickyHeader from "../../components/StickyHeader";
import LightColors from "../../constants/LightColors";
import { ReactComponent as CameraIcon } from "../../icons/camera.svg";
import { fetchPaginatedData } from "../../utils/PaginationUtil";
import { useQueryParams } from "../../utils/useQueryParams";
import { useNavigate } from "react-router-dom";

const ReportPhotoCardBreadcrumbWrapper = styled.div`
  padding: calc(54px + env(safe-area-inset-top)) 24px 10px 24px;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  text-align: left;
  padding: 0px 24px 10px 24px;
  white-space: pre-wrap;
`;

const PhotoCardBoxWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 36px;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PhotoCardBox = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
`;

const PhotoCardImage = styled.img`
  width: 57px;
  height: 90px;
  border-radius: 8px;
  object-fit: cover;
`;

const AddPhotoCardBox = styled.div`
  display: flex;
  width: 57px;
  height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px dashed var(--tint-primary, #5c3dfa);
`;

const PhotoCardTitle = styled.div`
  font-size: 17px;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  line-height: 20px;
  flex-grow: 1;
`;

const TagWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  gap: 4px;
`;

const ButtonWrapper = styled.div`
  min-width: fit-content;
`;

export default function ReportPhotoCardCandidatesPage() {
  const navigate = useNavigate();
  const { queryParams, updateQueryParams } = useQueryParams();

  const [photoCards, setPhotoCards] = useState();
  const { data: photoCardsData } = usePhotoCardsQuery({
    artistId: queryParams.get("artistId"),
    memberId: queryParams.get("memberId"),
    eventId: queryParams.get("eventId"),
    eventCategory: queryParams.get("activity"),
    photoCardCategory: queryParams.get("category"),
    unconfirmed: true,
  });

  useEffect(() => {
    if (!isEmpty(photoCardsData)) {
      setPhotoCards(fetchPaginatedData(photoCardsData, "photoCards"));
    }
  }, [photoCardsData]);

  const handleSelectPhotoCard = async (photoCardId) => {
    const response = await ConfirmPhotoCard(photoCardId);
    if (response.ok) {
      const isConfirmed = true;
      updateQueryParams(
        { photoCardId, isConfirmed },
        "/report-photo-card/confirm"
      );
    }
  };

  const handleUploadPhoto = () => {
    updateQueryParams({}, "/report-photo-card/upload-photo");
  };

  const mergedTags = (photoCard) => {
    if (isEmpty(photoCard)) return [];

    const tags = photoCard.tags || [];
    const distributors = photoCard.distributors || [];
    return distributors.concat(tags).slice(0, 3);
  };

  return (
    <>
      <StickyHeader
        title={t("ReportPhotoCardCandidatesPage.pageName")}
        showBackButton={true}
        showTitle={true}
        onClickBackButton={() => navigate(-1)}
      />
      <ReportPhotoCardBreadcrumbWrapper>
        <ReportPhotoCardBreadcrumb />
      </ReportPhotoCardBreadcrumbWrapper>
      <Title>{t("ReportPhotoCardCandidatesPage.title")}</Title>
      <PhotoCardBoxWrapper>
        {photoCards?.map((photoCard) => {
          return (
            <PhotoCardBox
              className="bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
              key={photoCard.photoCardId}
              onClick={() => handleSelectPhotoCard(photoCard.photoCardId)}
            >
              <PhotoCardImage src={photoCard.pictureUrl} />
              {isEmpty(mergedTags(photoCard)) ? (
                <TagWrapper>
                  <Badge
                    className="border-[1px] border-separator-light-1 dark:border-separator-dark-1 bg-pure-white dark:bg-pure-black text-onLabel-light dark:text-onLabel-dark"
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      lineHeight: "16px",
                      padding: "5px 8px",
                      // FIXME : border: "1px solid #E4E4E4",
                      borderRadius: "4px",
                    }}
                    // onClick={() => navigate(`/tags/add?taggableType=PhotoCard&taggableId=${photoCard.photoCardId}`)}
                  >
                    {t("ReportPhotoCardCandidatesPage.emptyTag")}
                  </Badge>
                </TagWrapper>
              ) : (
                <TagWrapper>
                  {mergedTags(photoCard).map((tag) => {
                    return (
                      <Badge
                        className="border-[1px] border-separator-light-1 dark:border-separator-dark-1 bg-pure-white dark:bg-pure-black text-onLabel-light dark:text-onLabel-dark"
                        style={{
                          fontSize: "13px",
                          fontWeight: 600,
                          lineHeight: "16px",
                          padding: "5px 8px",
                          // FIXME : border: "1px solid #E4E4E4",
                          borderRadius: "4px",
                        }}
                        // onClick={() => navigate(`/tags/add?taggableType=PhotoCard&taggableId=${photoCard.photoCardId}`)}
                      >
                        {tag.name}
                      </Badge>
                    );
                  })}
                </TagWrapper>
              )}
              <ButtonWrapper>
                <RegularSolidButton text={t("select")} width={"max-width"} />
              </ButtonWrapper>
            </PhotoCardBox>
          );
        })}
        <PhotoCardBox key={"Add"} onClick={handleUploadPhoto}>
          <AddPhotoCardBox className="bg-pure-white dark:bg-pure-black">
            <CameraIcon
              className="fill-primary-light dark:fill-primary-dark"
              width={"24px"}
              height={"24px"}
            />
          </AddPhotoCardBox>
          <PhotoCardTitle
            className="text-primary-light dark:text-primary-dark"
            fontWeight={500}
          >
            {t("ReportPhotoCardCandidatesPage.addManullay")}
          </PhotoCardTitle>
          <RegularSolidButton text={t("select")} />
        </PhotoCardBox>
      </PhotoCardBoxWrapper>
    </>
  );
}
