import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import React from "react";
import styled from "styled-components";
import PrimaryButton from "../buttons/PrimaryButton";

const MenuItem = styled.div`
  padding: 16px 0px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
`;

const tradeMethods = ["direct", "standardDelivery"];

export default function TradeMethodBottomDrawer({
  user,
  isOpen,
  onClose,
  onSelectTradeMethod,
}) {
  const [selectedMethod, setSelectedMethod] = React.useState(null);

  const TradeMethodsMemoized = React.useMemo(() => {
    const methods = [...tradeMethods];
    if (user?.country === "KR") {
      methods.push("slowDelivery");
    }
    return methods;
  }, [user?.country]);

  const handleSelect = (method) => {
    setSelectedMethod(method);
  };

  const handleComplete = () => {
    onSelectTradeMethod(selectedMethod);
    onClose();
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="bottom">
      <DrawerOverlay />
      <DrawerContent
        motionProps={{
          variants: {
            enter: {
              y: "0%",
              transition: { duration: 0.15 },
            },
            exit: {
              y: "100%",
              transition: { duration: 0.1 },
            },
          },
        }}
        style={{
          paddingTop: "24px",
          maxWidth: "calc(var(--chakra-sizes-xl))",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          left: "",
          right: "",
        }}
      >
        <div
          className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 24px 16px 24px",
          }}
        >
          <span style={{ fontSize: "18px", fontWeight: "600" }}>
            {"거래방식"}
          </span>
          <span
            className="text-main-light-2 dark:text-main-dark-2"
            style={{
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => {
              setSelectedMethod(null);
              onSelectTradeMethod(null);
              onClose();
            }}
          >
            {t("TradeMethod.any")}
          </span>
        </div>
        <DrawerBody>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${TradeMethodsMemoized.length}, 1fr)`,
              gap: "16px",
            }}
          >
            {TradeMethodsMemoized.map((method) => (
              <MenuItem
                className={`border border-separator-light-1 dark:border-separator-dark-1 text-main-light-2 dark:text-main-dark-2
                  ${
                    selectedMethod === method
                      ? "border-primary-light dark:border-primary-dark text-primary-light dark:text-primary-dark"
                      : "border-separator-light-1 dark:border-separator-dark-1 text-main-light-2 dark:text-main-dark-2"
                  }
                  `}
                key={method}
                onClick={() => handleSelect(method)}
                style={{
                  backgroundColor: "transparent",
                }}
              >
                {t(`TradeMethod.${method}`)}
              </MenuItem>
            ))}
          </div>
        </DrawerBody>
        <DrawerFooter>
          <PrimaryButton
            disabled={isEmpty(selectedMethod)}
            text={t("confirm")}
            onClick={!isEmpty(selectedMethod) && handleComplete}
            style={{
              width: "100%",
              padding: "16px 24px",
            }}
          />
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
