import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";

import { t } from "i18next";
import { first, isEmpty } from "lodash";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useArtistQueryAll } from "../components/api/queries/useArtistQuery";
import { ArtistCircleBox } from "../components/artist/ArtistCircleBox";
import Page from "../components/Page";
import { getArtistIdAnyway, sleep } from "../utils/etcUtils";
import { saveData } from "../utils/saveData";

export default function SelectArtistsPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // 구 ios앱에서 최초 로그인 후 /artists로 보내는데, isFirst가 true여야하지만, 아무런 값도 넣지 않아서 최초 로그인시 재로그인해야하는 버그 수정용
  const hasNoQueryParams = Array.from(queryParams.keys()).length === 0;

  console.log("hasNoQueryParams", hasNoQueryParams);

  const artistIdQueryParam = queryParams.get("artistId");
  const isFirstParam =
    queryParams.get("isFirst") ??
    location.pathname == "/select_initial_artists";
  const callbackParam = queryParams.get("callback")
    ? decodeURIComponent(queryParams.get("callback"))
    : undefined;

  const artists = useArtistQueryAll();

  useEffect(() => {
    if (artistIdQueryParam) return;
    if (isEmpty(artists)) return;

    const likedArtists = artists?.filter((a) => !!a.likedDate);
    if (likedArtists && !isEmpty(likedArtists)) {
      const artistId = getArtistIdAnyway(first(likedArtists));
      if (artistId) {
        saveData("artist", artistId);
        navigate(`/home`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artists, artistIdQueryParam]);

  const onArtistSelected = async (artist) => {
    if (artist.artistId == null) {
      return alert(t("selectArtistPage.errorAlert"));
    }
    // 서버로 요청이 사라지면서 선택 하트가 보이게 하기 위해 약간의 딜레이를 준다.
    await sleep(300);

    navigate(`/artists/${artist.artistId}/members`, {
      state: {
        artistId: artist.artistId,
        firstTime: isFirstParam,
        callbackUrl: callbackParam,
        registerArtist: true,
      },
    });
  };

  return (
    <Page title={t("selectArtistPage.pageName")}>
      <Text
        className="text-main-light-2 dark:text-main-dark-2"
        fontSize="26px"
        fontWeight={700}
        lineHeight={"120%"}
      >
        {t("selectArtistPage.title")}
      </Text>
      {/* <Text fontSize='15px' color={"#888"} mt={2}>
        {t("selectArtistPage.description")}
      </Text> */}
      <Grid
        templateColumns="repeat(2, 1fr)"
        paddingTop={"37px"}
        paddingBottom={"96px"}
        gap={3}
      >
        {artists &&
          artists
            .filter((a) => !a.likedDate)
            .map((artist) => {
              return (
                <GridItem rowSpan={1} key={artist.name}>
                  <Box>
                    <ArtistCircleBox
                      artist={artist}
                      onArtistSelected={(artist) => onArtistSelected(artist)}
                    />
                  </Box>
                </GridItem>
              );
            })}
      </Grid>
    </Page>
  );
}
