import React from "react";
import { HStack, Stack, Skeleton, useColorModeValue } from "@chakra-ui/react";

export default function EmptyPhotoCardEventSkeleton() {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");

  return (
    <Stack>
      <Skeleton
        width={"150px"}
        height="20px"
        startColor={startColor}
        endColor={endColor}
        borderRadius="9px"
      />
      <HStack marginTop={"14px!important"}>
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
      </HStack>
      <Skeleton
        width={"120px"}
        height="20px"
        startColor={startColor}
        endColor={endColor}
        borderRadius="9px"
        marginTop={"24px!important"}
      />
      <HStack marginTop={"14px!important"}>
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
        <Skeleton
          height="90px"
          width="60px"
          startColor={startColor}
          endColor={endColor}
          borderRadius="8px"
        />
      </HStack>
    </Stack>
  );
}
