import React, { useState } from "react";

import { t } from "i18next";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import CommonDialog from "../../components/alerts/CommonDialog";
import confirmTradingPhotoCardReview from "../../components/api/mutations/confirmTradingPhotoCardReview";
import rejectTradingPhotoCardReview from "../../components/api/mutations/rejectTradingPhotoCardReview";
import useReviewQuery from "../../components/api/queries/useReviewQuery";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import PostImagesBox from "../../components/community/PostImagesBox";
import Page from "../../components/Page";
import TradingPhotoCardBox from "../../components/tradings/TradingPhotoCardBox";
import { useHandleHistoryBack } from "../../utils/useHandleHistoryBack";
import { capitalizeFirstLetter } from "../../utils/TextUtil";
import { useColorMode } from "@chakra-ui/react";

const TradingPhotoCardBoxWrapper = styled.div`
  padding: 10px 24px 20px 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
    0px 6px 15px 0px rgba(0, 0, 0, 0.06);
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  white-space: pre-wrap;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ReviewWrapper = styled.div`
  padding: 30px 24px 10px 24px;
  text-align: center;
  height: calc(100vh - 320px);
  align-content: center;
`;

const ReviewCard = styled.div`
  margin: 28px 7px 0px 7px;
  padding: 26px 24px 19px 24px;
  border-radius: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  box-shadow: 0px 0px 0px 1px rgba(142, 127, 221, 0.05),
    0px 0px 18px 0px rgba(179, 171, 222, 0.32);
`;

const ReviewerNickname = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const ReviewContent = styled.div`
  margin-top: 12px;
  font-size: 17px;
  font-weight: 400;
  line-height: 26px;
`;

const FeedbackOptionWrapper = styled.div`
  margin-top: 15px;
`;

const FeedbackOption = styled.div`
  margin-bottom: 7px;
  padding: 6px 10px;
  border-radius: 30px;
`;

const SkipText = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  white-space: pre-wrap;
`;

const ConfirmButtonWrapper = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 99999;
  padding-bottom: env(safe-area-inset-bottom);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.03),
    0px -6px 15px 0px rgba(0, 0, 0, 0.06);
`;

export default function TradingPhotoCardReviewDetailPage() {
  const { colorMode } = useColorMode();
  const params = useParams();
  const navigate = useNavigate();
  const handleHistoryBack = useHandleHistoryBack();
  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const review = useReviewQuery(params.review_id);

  const onConfirmed = async (navigateMode) => {
    const reviewId = review.reviewId || params.review_id;
    if (isEmpty(reviewId)) return;

    const response = await confirmTradingPhotoCardReview({
      reviewId: reviewId,
    });
    if (response.ok) {
      if (navigateMode === "Exit") {
        handleHistoryBack();
      } else if (navigateMode === "AddReview") {
        navigate(
          `/trading_photo_cards/${review.reviewableId}/add_review?reviewerId=${reviewId}`
        );
      } else if (navigateMode === "SentReview") {
        navigate(`/reviews/${review.sentReviewId}`, { replace: true });
      } else {
        navigate("/home");
      }
    }
  };

  const onRejectReview = async () => {
    const response = await rejectTradingPhotoCardReview({
      reviewId: review.reviewId,
      blacklisted: true,
    });
    if (response.ok) {
      handleHistoryBack();
    }
  };

  return (
    <div
      style={{
        background: `url(/assets/backgrounds/${
          colorMode === "dark" ? "bg_review_dark.png" : "bg_review_light.png"
        })`,
        backgroundRepeat: "round",
      }}
    >
      <Page
        title={t("tradingPhotoCardPage.pageTitle")}
        showBackButton={true}
        onHistoryBackClick={() => {
          review?.isMine ? handleHistoryBack() : onConfirmed("Exit");
        }}
        noPadding={true}
      >
        {isEmpty(review) ? (
          <></>
        ) : (
          <>
            <TradingPhotoCardBoxWrapper className="bg-pure-white dark:bg-pure-black">
              <TradingPhotoCardBox tradingPhotoCard={review.reviewable} />
            </TradingPhotoCardBoxWrapper>
            <ReviewWrapper>
              <Title className="text-main-light-1 dark:text-main-dark-1">
                {review.isMine
                  ? t("TradingPhotoCardReviewDetailPage.sentTitle")
                  : t("TradingPhotoCardReviewDetailPage.receivedTitle")}
              </Title>
              <ReviewCard className="bg-pure-white dark:bg-pure-black">
                {review.isMine && (
                  <Row>
                    <ReviewerNickname className="text-main-light-3 dark:text-main-dark-3">
                      {`To. ${review.userProfile.nickname}`}
                    </ReviewerNickname>
                  </Row>
                )}
                <Row>
                  <ReviewContent
                    className="text-main-light-1 dark:text-main-dark-1"
                    style={{ marginTop: review.isMine ? "12px" : "0px" }}
                  >
                    {review.content}
                  </ReviewContent>
                </Row>
                {review.pictureUrl && (
                  <Row style={{ marginTop: "15px" }}>
                    <PostImagesBox
                      images={[review.pictureUrl]}
                      enableDelete={false}
                      enableFullSizeViewer={true}
                    />
                  </Row>
                )}
                <FeedbackOptionWrapper style={{ marginTop: "15px" }}>
                  {review.feedbackOptions?.map((feedbackOption) => {
                    return (
                      <Row key={feedbackOption}>
                        <FeedbackOption className="text-main-light-4 dark:text-main-dark-4 bg-surface-light dark:bg-surface-dark">
                          {t(
                            `ReviewFeedbackOptions.${capitalizeFirstLetter(
                              feedbackOption
                            )}`
                          )}
                        </FeedbackOption>
                      </Row>
                    );
                  })}
                </FeedbackOptionWrapper>

                {!review.isMine && (
                  <Row
                    style={{
                      placeContent: "end",
                      paddingTop: "6px",
                      paddingBottom: "4px",
                    }}
                  >
                    <ReviewerNickname className="text-main-light-3 dark:text-main-dark-3">
                      {`From. ${review.reviewer.nickname}`}
                    </ReviewerNickname>
                  </Row>
                )}
              </ReviewCard>
            </ReviewWrapper>
            {review.isMine ? ( // 내 리뷰일 경우
              review.receivedReviewId ? (
                <ConfirmButtonWrapper className="bg-pure-white dark:bg-pure-black">
                  <PrimaryButton
                    marginY={"16px"}
                    marginX={"24px"}
                    paddingY={"16px"}
                    text={t("myPhotoCards.receivedReviewButton")}
                    fontSize={"17px"}
                    onClick={() =>
                      navigate(`/reviews/${review.receivedReviewId}`, {
                        replace: true,
                      })
                    }
                  />
                </ConfirmButtonWrapper>
              ) : (
                // TODO : 상대방에게 후기 답장 요청하기
                <></>
              )
            ) : (
              // 내가 받은 리뷰일 경우
              // 상대방에게 후기 남기기 노출
              <ConfirmButtonWrapper className="bg-pure-white dark:bg-pure-black">
                {review.sentReviewId ? (
                  <PrimaryButton
                    marginY={"16px"}
                    marginX={"24px"}
                    paddingY={"16px"}
                    text={t("myPhotoCards.sentReviewButton")}
                    fontSize={"17px"}
                    onClick={() => onConfirmed("SentReview")}
                  />
                ) : (
                  // 리뷰 답장을 하지 않은 경우
                  <PrimaryButton
                    marginY={"16px"}
                    marginX={"24px"}
                    paddingY={"16px"}
                    text={t("TradingPhotoCardReviewDetailPage.confirm")}
                    fontSize={"17px"}
                    onClick={() => onConfirmed("AddReview")}
                  />
                )}
                {!review.confirmedAt && ( // 리뷰 확인을 하지 않은 경우
                  <Row
                    style={{ margin: "2px 20px 10px 20px" }}
                    onClick={() => setIsOpenRejectDialog(true)}
                  >
                    <SkipText className="text-main-light-3 dark:text-main-dark-3">
                      {t(
                        `TradingPhotoCardReviewDetailPage.notConfirmedTradingButton`
                      )}
                    </SkipText>
                  </Row>
                )}
              </ConfirmButtonWrapper>
            )}
          </>
        )}
        {review && !review.isMine && isOpenRejectDialog && (
          <CommonDialog
            isOpen={isOpenRejectDialog}
            onClose={() => setIsOpenRejectDialog(false)}
            title={t("TradingPhotoCardReviewDetailPage.rejctDialogTitle", {
              nickname: review.userProfile.nickname,
            })}
            onClickDialogButton={onRejectReview}
          />
        )}
      </Page>
    </div>
  );
}
