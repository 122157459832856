import React from "react";
import styled from "styled-components";

import { ReactComponent as ProfileFillIconSvg } from "../../icons/profile.fill.svg";
import { Image } from "@chakra-ui/react";

const AvatarWrapper = styled.div`
  padding: 10px;
  border-radius: 50%;
  padding: 5px;
`;

const ImageWrapper = styled.div`
  border-radius: 32px;
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)";
`;

export default function ProfileAvatar({
  profileImageUrl,
  widthAndHeight = "24px",
  svgWidthAndHeight = "24px",
  onClick,
  ...props
}) {
  return profileImageUrl ? (
    <ImageWrapper
      onClick={onClick}
      style={{ width: `${widthAndHeight}`, height: `${widthAndHeight}` }}
    >
      <Image
        borderRadius={"full"}
        objectFit={"cover"}
        src={profileImageUrl}
        style={{ width: `${widthAndHeight}`, height: `${widthAndHeight}` }}
      />
    </ImageWrapper>
  ) : (
    <AvatarWrapper
      className="bg-theirty-light dark:bg-theirty-dark"
      onClick={onClick}
      style={{
        width: `${widthAndHeight}`,
        height: `${widthAndHeight}`,
        ...props,
      }}
    >
      <ProfileFillIconSvg
        className="fill-white dark:fill-black"
        width={svgWidthAndHeight}
        height={svgWidthAndHeight}
      />
    </AvatarWrapper>
  );
}
