import { Box, HStack, Image, Text } from "@chakra-ui/react";
import { filter, find, first, isEmpty, isUndefined } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getArtistId, sleep } from "../../utils/etcUtils";
import saveData from "../../utils/saveData";
import { useArtistQueryAll } from "../api/queries/useArtistQuery";
// import { onElementVisible } from '../../utils/UIUtils';
import { t } from "i18next";

import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import GridSkeletons from "../skeletons/GridSkeletons";

const ArtistIconSelector = ({
  onSelected,
  onAddCallbackUrl,
  enableAddCard = true,
}) => {
  const ref = useRef();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const artists = useArtistQueryAll();

  // #region 컴포넌트가 화면에 보여졌을때를 감지해서 선택된 엘리먼트로 스크롤링한다.
  const selectedArtistToCenter = (artistId) => {
    const selectedElement = document.getElementById(`artistIcon_${artistId}`);
    if (selectedElement) {
      selectedElement.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  // useEffect(() => {
  //   return onElementVisible(ref, () => {
  //     async function scroll() {
  //       await sleep(100);
  //       selectedArtistToCenter(getArtistId());
  //     }
  //     scroll();

  //     if (selectedArtist) {
  //       onSelected?.(selectedArtist);
  //     } else {
  //       selectInitialArtist();
  //     }
  //   });
  // }, [selectedArtist]);
  // #endregion

  useEffect(() => {
    if (isEmpty(artists)) return; // 아티스트 리스트가 없으면 Early return
    if (!isEmpty(selectedArtist)) return; // 선택된 아티스트가 있으면 Early return

    let initArtist = first(artists);
    const savedArtistId = getArtistId();
    if (savedArtistId) {
      // 저장된 아티스트가 있는 경우
      initArtist = find(
        artists,
        (item) => !!item.likedDate && item.artistId === savedArtistId
      );
    }

    setSelectedArtist(initArtist);
    onSelected?.(initArtist);
    async function scroll() {
      await sleep(500);
      selectedArtistToCenter(getArtistId());
    }
    scroll();
  }, [onSelected, artists, selectedArtist]);

  const handleArtistSelect = (artist) => {
    saveData("artist", artist.artistId);
    setSelectedArtist(artist);
    onSelected?.(artist);
    selectedArtistToCenter(artist.artistId);
  };

  return (
    <Box ref={ref}>
      <HStack
        spacing={"9px"}
        px={"20px"}
        overflowY={"hidden"}
        css={{ "::-webkit-scrollbar": { display: "none" } }}
      >
        {
          // TODO : Refactor with ListRenderer
          isUndefined(artists) ? (
            <GridSkeletons
              gridSize={1}
              skeletonSize={1}
              skeletonHeight={"96px"}
              marginBottom={"16px"}
              width={"100%"}
            />
          ) : isEmpty(artists) ? (
            <></>
          ) : (
            artists
              .filter((artist) => !!artist.likedDate)
              .map((artist) => (
                <ArtistCard
                  key={artist.artistId}
                  artist={artist}
                  selected={artist.artistId === selectedArtist?.artistId}
                  onSelect={(_) => handleArtistSelect(artist)}
                />
              ))
          )
        }
        {!isUndefined(artists) &&
          artists &&
          filter(artists, (a) => !!a.likedDate).length < artists.length &&
          enableAddCard && <AddCard onAddCallbackUrl={onAddCallbackUrl} />}
        <div
          style={{
            width: "20px" /* 또는 원하는 공간의 크기 */,
            flexShrink:
              "0" /* Flex 컨테이너 내에서 이 요소가 축소되지 않도록 함 */,
          }}
        />
      </HStack>
    </Box>
  );
};

const ArtistCard = ({ artist, selected, onSelect }) => {
  const [applyAnimation, setApplyAnimation] = useState(false);
  const getClassName = () => {
    if (!applyAnimation) return selected ? "no-animation" : "fade-out";
    return selected ? "fade-in" : "fade-out";
  };
  const onClick = (e) => {
    if (!applyAnimation) setApplyAnimation(true);
    onSelect?.(e);
  };
  return (
    <Box
      as="button"
      onClick={onClick}
      style={{ position: "relative" }}
      width="64px"
      height="108px"
      id={`artistIcon_${artist.artistId}`}
    >
      <div
        className={getClassName()}
        style={{ position: "absolute", top: 0, left: 0, zIndex: 1 }}
      >
        <svg width="66" height="66">
          {
            <circle
              className="stroke-onSecondary-light dark:stroke-onSecondary-dark"
              cx="33"
              cy="33"
              r="28"
              fill="none"
              strokeWidth="1.5"
            />
          }
        </svg>
      </div>

      <Box width="64px" height="108px" mx={"7px"} mt={"7px"}>
        <Image
          className="border-[1px] border-placeholder-light dark:border-placeholder-dark"
          style={{
            width: "52px",
            height: "52px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
          src={artist.thumbnailUrl}

          //thumbnailUrl
          //pictureUrl
        />
        <Text
          fontSize="14px"
          py={"14px"}
          className={`text-center ${
            selected
              ? "text-onSecondary-light dark:text-onSecondary-dark"
              : "text-main-light-2 dark:text-main-dark-2"
          }`} // FIXME : textColor={selected ? "#5C3DFA" : "#4E4E4E"}
          fontWeight={600}
          lineHeight={"17px"}
          noOfLines={2}
          whiteSpace="pre-line"
          marginLeft={"-12px"}
        >
          {artist.shortName || artist.name}
        </Text>
      </Box>
    </Box>
  );
};

const AddCard = ({ onAddCallbackUrl }) => {
  const navigate = useNavigate();
  return (
    <Box
      as="button"
      onClick={(_) => {
        if (onAddCallbackUrl) {
          navigate(
            `/artists?artistId=register_artist&callback=${encodeURIComponent(
              onAddCallbackUrl
            )}`
          );
        } else {
          navigate(`/artists?artistId=register_artist`);
        }
      }}
      width="64px"
      height="115px"
      style={{
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", top: "3px", left: 0, zIndex: 1 }}>
        <svg width="66" height="66">
          <circle
            className="stroke-onTheirty-light dark:stroke-onTheirty-dark"
            cx="33"
            cy="33"
            r="24"
            fill="none"
            strokeWidth="2"
            strokeDasharray="5,5"
          />
        </svg>
      </div>

      <Box px={"6px"}>
        <Box
          width={"64px"}
          height={"65px"}
          paddingTop={"14px"}
          paddingLeft={"15px"}
        >
          <PlusIcon className="fill-onTheirty-light dark:fill-onTheirty-dark" />
        </Box>
        <Text
          className="text-main-light-2 dark:text-main-light-2" // FIXME textColor={"#4E4E4E"}
          fontSize="14px"
          pb={"10px"}
          fontWeight={600}
          lineHeight={"17px"}
          marginLeft={"2px"}
        >
          {t("manageArtistPage.addLikedArtist")}
        </Text>
      </Box>
    </Box>
  );
};

export default ArtistIconSelector;
