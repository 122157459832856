import React from "react";
import styled from "styled-components";

const StyledButton = styled.div`
  padding: 24px 8px;
  text-align: center;
  align-content: center;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

export const LineButton = ({ text, isSelected, ...props }) => {
  return (
    <StyledButton
      className={`text-center border-[1px] ${
        isSelected
          ? "border-primary-light dark:border-primary-dark bg-secondary-light dark:bg-secondary-dark text-onSecondary-light dark:text-onSecondary-dark"
          : "border-separator-light-1 dark:border-separator-dark-1 text-main-light-3 dark:text-main-dark-3"
      }`}
      {...props}
    >
      {text}
    </StyledButton>
  );
};
