import { useQuery } from "react-query";
import { queryGet } from "../../../utils/queryClient";

export default function useNotificationsCountQuery() {
  const { data: unreadMessages } = useQuery(
    ["unreadMessages"],
    () => queryGet(`/notifications/unread_messages`),
    {
      cachedTime: 2500,
      staleTime: 10000,
    },
  );
  return unreadMessages?.unreadMessagesCount ?? 0;
}
