import LightColors from "../../constants/LightColors";
import { isEmpty } from "lodash";
import styled from "styled-components";

const SmallBadge = styled.div`
  background-color: ${LightColors.surface};
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  color: ${(props) =>
    props.textColor === "primary"
      ? LightColors.textprimary
      : LightColors.onLabel};
  padding: 4px 8px;
  border-radius: 4px;
`;

const TagBadge = styled.div`
  background-color: ${LightColors.surface};
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  color: ${(props) =>
    props.textColor === "primary"
      ? LightColors.textprimary
      : LightColors.onLabel};
  padding: 5px 8px;
  border-radius: 4px;
`;

const MediumBadge = styled.div`
  background-color: ${LightColors.surface};
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: ${(props) =>
    props.textColor === "primary"
      ? LightColors.textprimary
      : LightColors.onLabel};
  padding: 6px 10px;
  border-radius: 6px;
`;

export const TagsList = ({ photoCard, size = "sm", textColor = "default" }) => {
  const BadgeComponent =
    size === "xs" ? SmallBadge : size === "md" ? MediumBadge : TagBadge;
  return (
    <>
      <TagBadges
        tags={photoCard.distributors}
        size={size}
        textColor={textColor}
      />
      <TagBadges tags={photoCard.tags} size={size} textColor={textColor} />
      {photoCard.meta?.polaroid && (
        <BadgeComponent textColor={textColor}>{"POLAROID"}</BadgeComponent>
      )}
      {photoCard.meta?.unit && (
        <BadgeComponent textColor={textColor}>{"UNIT"}</BadgeComponent>
      )}
    </>
  );
};

const TagBadges = ({ tags, size = "sm", textColor = "default" }) => {
  const BadgeComponent =
    size === "xs" ? SmallBadge : size === "md" ? MediumBadge : TagBadge;
  if (isEmpty(tags)) {
    return <></>;
  } else {
    return tags.map((tag) => {
      return (
        <BadgeComponent textColor={textColor}>
          {tag?.name || tag}
        </BadgeComponent>
      );
    });
  }
};
