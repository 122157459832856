import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  Input,
  InputGroup,
  Spacer,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import {
  first,
  isEmpty,
  isNull,
  isUndefined,
  last,
  shuffle,
  throttle,
} from "lodash";
import { Helmet } from "react-helmet-async";
import { Trans } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import styled from "styled-components";

import updateUserLocaleOrCountry from "../../components/api/mutations/updateUserLocaleOrCountry";
import updateUserPreferences from "../../components/api/mutations/updateUserPreferences";
import usePostsQuery from "../../components/api/queries/usePostsQuery";
import useRefreshJwtQuery from "../../components/api/queries/useRefreshJwtQuery";
import useUserQuery from "../../components/api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../../components/bottomSheet/AddUserProfileV1BottomSheet";
import SecondaryButton from "../../components/buttons/SecondaryButton";
import PostsList from "../../components/community/PostsList";
import SelectFeedCountryBottomDrawer from "../../components/drawer/SelectFeedCountryBottomDrawer";
import ShowTradingProfileBottomDrawer from "../../components/drawer/ShowTradingProfileBottomDrawer";
import { PopupFabButton } from "../../components/fab/PopupFabButton";
import FanBuddyBar from "../../components/fan-buddy/FanBuddyBar";
import HomeHeader from "../../components/home/HomeHeader";
import CheckIcon from "../../components/icons/CheckIcon";
import WhiteToastMessageBox from "../../components/toast/WhiteToastMessageBox";
import i18n from "../../i18n/i18n";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow.right.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";
import HomeBottomNav from "../../layout/HomeBottomNav";
import {
  getLocalStorageWithExpiry,
  setLocalStorageWithExpiry,
} from "../../utils/ExpiredLocalStorage";
import { openNewTab } from "../../utils/UIUtils";
import {
  getArtistId,
  getArtistIdAnyway,
  getLanguage,
} from "../../utils/etcUtils";
import saveData from "../../utils/saveData";

const PageWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top));
  background-attachment: scroll;
  height: 100vh;
`;

const HeaderWrapper = styled.div`
  padding: 12px 24px;
`;

const activeTabStyle = {
  borderBottom: "3px solid",
  color: "rgba(92, 61, 250, 1)!important",
  fontSize: "16px",
  lineHeight: "19px",
  fontWeight: 600,
  borderBox: "",
};

const defaultTabStyle = {
  fontSize: "16px",
  lineHeight: "19px",
  padding: "13px 12px",
  borderColor: "transparent",
  whiteSpace: "nowrap",
};

const InputWrapper = styled.div`
  display: flex;
  border-radius: 12px;
  margin: 20px 24px 0px 24px;
  align-items: center;
  padding: 2px 11px;
`;

const TAB_KEYS = [
  "recentTab",
  "followingTab",
  "friendshipTab",
  "marketplaceTab",
];

export default function FeedHomePage() {
  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const tabRefs = useRef([]); // 각 Tab 별 Ref

  const [selectedArtist, setSelectedArtist] = useState();
  const [paginatedPosts, setPaginatedPosts] = useState();
  const [countryCode, setCountryCode] = useState(
    localStorage.getItem("feedCountryCode")
  );
  const [currentPagination, setCurrentPagination] = useState();
  // const [isShowNewPostsNotification, setIsShowNewPostsNotification] = useState(false);
  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] =
    useState(false);
  const [isShowTradingProfileBottomSheet, setIsShowTradingProfileBottomSheet] =
    useState(false);
  const [isShowCountryDrawer, setIsShowCountryDrawer] = useState(false);
  // const [hiddenCollectedPhotoCardStat, setHiddenCollectedPhotoCardStat] = useState(localStorage.getItem("hiddenCollectedPhotoCardStat") === "true");
  const [tabIndex, setTabIndex] = useState(0);

  const authToken = useRefreshJwtQuery();
  if (authToken?.jwt) {
    saveData("token", JSON.stringify(authToken?.jwt));
  }

  const isExpiredToShowTradingProfileBottomSheet = () => {
    const confirmedTradingProfileBottomSheet = getLocalStorageWithExpiry(
      "tradingProfileBottomSheetOnFeed"
    );
    return (
      confirmedTradingProfileBottomSheet === false ||
      isNull(confirmedTradingProfileBottomSheet)
    );
  };

  const user = useUserQuery();

  useEffect(() => {
    if (isEmpty(user)) return;

    if (user && isEmpty(user?.baseUserProfile)) {
      return setIsShowBaseProfileBottomSheet(true);
    }

    if (
      !user?.showTradingProfile &&
      isExpiredToShowTradingProfileBottomSheet()
    ) {
      setIsShowTradingProfileBottomSheet(true);
    }

    if (isEmpty(user.locale) || getLanguage() === user.locale) return;
    saveData("i18nextLng", user.locale);
    i18n.changeLanguage(user.locale);
  }, [user]);

  if (user && isEmpty(user?.country) && !isEmpty(countryCode)) {
    updateUserLocaleOrCountry({ userId: user?.userId, country: countryCode });
    queryClient.invalidateQueries("users");
  }

  const shuffledFirstArtist = useMemo(() => {
    return first(shuffle(user?.likedArtists));
  }, [user]);

  const categoryGroup = () => {
    if (tabIndex === 2) {
      return "community";
    } else if (tabIndex === 3) {
      return "market_place";
    } else {
      return null;
    }
  };

  const { data, isFetching, fetchNextPage, hasNextPage, refetch } =
    usePostsQuery({
      country: countryCode || user?.country,
      artistId: selectedArtist?.artistId,
      onlyFollowers: tabIndex === 1,
      categoryGroup: categoryGroup(),
    });

  const artistId = getArtistIdAnyway(selectedArtist) || getArtistId();
  // const artist = useArtistQuery(artistId);

  useEffect(() => {
    if (!isEmpty(selectedArtist)) {
      setPaginatedPosts(undefined);
      setCurrentPagination(undefined);
      queryClient.resetQueries({
        queryKey: ["Posts", selectedArtist.artistId],
        exact: true,
      });
      // Refetch only first pagination
      refetch({
        refetchPage: (page, index) => index === 0,
        resetPageOnRefetch: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtist]);

  const handlePaginatedPosts = (paginatedData) => {
    const posts = paginatedData.pages?.flatMap((page) =>
      page.posts.map((tradingPhotoCard) => tradingPhotoCard)
    );
    setPaginatedPosts(posts);
  };

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };
  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPosts(data);
      setCurrentPagination(last(data.pages)?.meta?.pagination);
    }
  }, [data]);

  const navigateAddUserProfile = () => {
    navigate("/feed/create_feed_user_profile");
  };

  const categoryByFabIndex = (fabIndex) => {
    switch (fabIndex) {
      case 0:
        return "FreeTalk";
      case 1:
        return "Question";
      case 2:
        return "LookingForFriends";
      case 3:
        return "Selling";
      default:
        break;
    }
  };

  const navigateAddPost = (buttonIndex) => {
    if (isEmpty(user?.baseUserProfile)) {
      navigateAddUserProfile();
    } else {
      let postAddUrl = "/posts/add";
      const queryParams = new URLSearchParams({
        category: categoryByFabIndex(buttonIndex),
      });

      if (!isEmpty(selectedArtist)) {
        queryParams.append("artistId", selectedArtist.artistId);
        queryParams.append("fandomName", selectedArtist.fandomName);
      }
      postAddUrl = `${postAddUrl}/?${queryParams.toString()}`;
      openNewTab(postAddUrl);
    }
  };

  const showToastMessage = (postId) => {
    toast({
      position: "top",
      duration: 2000,
      render: () => (
        <WhiteToastMessageBox
          startElement={<CheckIcon className="fill-verified" />}
          message={t("feedPage.uploadToastMessage")}
          endElement={
            <SecondaryButton
              className="text-pure-white dark:text-pure-black bg-main-light-4 dark:bg-main-dark-4"
              lineHeight={"16px"}
              paddingX={"12px"}
              paddingY={"6px"}
              fontSize={"13px"}
              text={t("feedPage.uploadToastShowPost")}
              onClick={() => openNewTab(`/posts/${postId}`)}
            />
          }
        />
      ),
    });
  };

  useEffect(() => {
    const receiveMessage = throttle((event) => {
      if (event.origin !== window.location.origin) return;
      if (isEmpty(event.data?.type)) return;

      if (event.data?.type === "refresh-post-feeds-by-add-post-page") {
        queryClient.invalidateQueries("Posts");
      } else if (event.data?.type === "show-toast-uploaded-post-on-feed") {
        const post = event.data?.params?.post;
        if (!isEmpty(post)) {
          showToastMessage(post.postId);
          queryClient.invalidateQueries("Posts");
        }
      } else if (event.data?.type === "invalidated-user-profile") {
        queryClient.invalidateQueries("users");
        queryClient.invalidateQueries(["userProfile"]);
      }
    }, 1000);

    window.addEventListener("message", receiveMessage, false);

    return () => {
      window.removeEventListener("message", receiveMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient]);

  // #region pull to refresh
  const [refreshing, setRefreshing] = useState(false);
  const [lastY, setLastY] = useState(null);
  const spinnerAnimatedProps = useSpring({
    to: {
      transform: refreshing ? "translateY(88px)" : "translateY(0px)",
      opacity: refreshing ? 1 : 0,
    },
    from: {
      transform: "translateY(0px)",
      opacity: 0,
    },
  });

  const bodyAnimatedProps = useSpring({
    to: {
      transform: refreshing ? "translateY(88px)" : "translateY(0px)",
    },
    from: {
      transform: "translateY(0px)",
    },
  });

  const handleTouchStart = (e) => {
    setLastY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const newY = e.touches[0].clientY;
    const deltaY = newY - lastY;
    if (refreshing) {
      return;
    }
    if (deltaY > 150 && window.scrollY <= 0) {
      setRefreshing(true);
      const startTime = Date.now(); // 현재 시간 기록
      refetch({
        refetchPage: (page, index) => index === 0,
        resetPageOnRefetch: true,
      }).then(() => {
        const elapsedTime = Date.now() - startTime; // 경과 시간 계산
        const remainingTime = Math.max(1500 - elapsedTime, 0); // 남은 시간 계산
        setTimeout(() => {
          setRefreshing(false);
        }, remainingTime);
      });
    }
  };
  // #endregion

  // const onHiddenCollectedPhotoCardStat = () => {
  //   saveData("hiddenCollectedPhotoCardStat", true);
  //   setHiddenCollectedPhotoCardStat(true);
  // }

  const onChangedTab = (tabIndex) => {
    setTabIndex(tabIndex);
    if (tabRefs.current[tabIndex]) {
      tabRefs.current[tabIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  };

  const onConfirmedCountryCode = (countryCode) => {
    saveData("feedCountryCode", countryCode);
    queryClient.invalidateQueries("Posts");
    setCountryCode(countryCode);
    setIsShowCountryDrawer(false);
  };

  const onCloseShowTradingProfileBottomSheet = () => {
    const ONE_WEEK = 1000 * 60 * 60 * 24 * 7;
    setLocalStorageWithExpiry(
      "tradingProfileBottomSheetOnFeed",
      true,
      ONE_WEEK
    );
    setIsShowTradingProfileBottomSheet(false);
  };

  const onUpdateUserPreferences = async () => {
    const response = await updateUserPreferences({ showTradingProfile: true });
    if (response && response.ok) {
      setIsShowTradingProfileBottomSheet(false);
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(["userProfile"]);
    }
  };

  const onClickProfile = () => {
    if (isEmpty(user?.baseUserProfile)) {
      setIsShowBaseProfileBottomSheet(true);
    } else {
      openNewTab(`/feed/user_profile/${user?.baseUserProfile?.userProfileId}`);
    }
  };

  const isShowingBottomSheet = () => {
    return (
      isShowBaseProfileBottomSheet ||
      isShowCountryDrawer ||
      isShowTradingProfileBottomSheet ||
      isShowBaseProfileBottomSheet
    );
  };

  return (
    <PageWrapper
      className={`${
        colorMode === "dark" ? "darkHomeBackground" : "homeBackground"
      }`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
      <Helmet>
        <title>{t("tabs.feed")}</title>
        <meta
          name="theme-color"
          content={colorMode === "dark" ? "#0D0E12" : "#FFFFFF"}
        />
      </Helmet>
      <animated.div
        style={{
          ...spinnerAnimatedProps,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: refreshing ? "auto" : "0px",
        }}
      >
        <Spinner color="#C2C1C7" />
      </animated.div>

      <animated.div style={bodyAnimatedProps}>
        <HeaderWrapper>
          <HomeHeader
            title={t("feedPage.title")}
            user={user}
            onClickTitle={() => setIsShowCountryDrawer(true)}
            onClickProfileButton={() => navigateAddUserProfile()}
          />
        </HeaderWrapper>
        <SocialProfileBar
          user={user}
          onClickEmptyProfile={onClickProfile}
          onClickSocialProfileBar={onClickProfile}
        />
        <Tabs
          isFitted
          index={parseInt(tabIndex)}
          onChange={(index) => onChangedTab(index)}
          borderColor={"transparent"}
          colorScheme="primary"
        >
          <div className="border-b-[1px] border-surface-light dark:border-surface-dark">
            <TabList
              borderBottom={"none"}
              paddingX={"16px"}
              className="hiddenScrollBar"
              overflowX={"scroll"}
              overflowY={"hidden"}
            >
              {TAB_KEYS.map((key, index) => (
                <Tab
                  className="!text-main-light-4 dark:!text-main-light-4"
                  key={key}
                  ref={(el) => (tabRefs.current[index] = el)} // 각 Tab에 Ref 할당
                  _active={""}
                  _selected={activeTabStyle}
                  style={defaultTabStyle}
                >
                  {t(`feedPage.${key}`)}
                </Tab>
              ))}
            </TabList>
          </div>
          {user?.country === "KR" && <FanBuddyBar />}
          <TabPanels>
            <TabPanel padding={0}>
              {/* {
                (!selectedArtist && !hiddenCollectedPhotoCardStat) &&
                <div>
                  { <MyCollectedPhotoCardsStat user={user} artist={artist} onHiddenCollectedPhotoCardStat={onHiddenCollectedPhotoCardStat}/> }
                </div>
              } */}
              <PostsList
                user={user}
                posts={paginatedPosts}
                artistId={
                  selectedArtist?.artistId || shuffledFirstArtist?.artistId
                }
                loadMore={loadMore}
                hasMore={hasNextPage}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <InputWrapper
                className="bg-secondary-light dark:bg-secondary-dark"
                onClick={() => openNewTab("/feed/user_profile/search")}
              >
                <SearchIcon
                  className="fill-placeholder-light dark:fill-placeholder-dark"
                  width={"24px"}
                  height={"24px"}
                />
                <InputGroup border={"transparent"}>
                  <Input
                    paddingLeft={"3px"}
                    readOnly={true}
                    _focus={{ border: "transparent", boxShadow: "none" }}
                    _active={{ border: "transparent" }}
                    _hover={{ border: "transparent" }}
                    outline={"none"}
                    placeholder={t("feedPage.searchPlaceholder")}
                    fontSize={"15px"}
                  />
                </InputGroup>
              </InputWrapper>
              <PostsList
                user={user}
                posts={paginatedPosts}
                artistId={
                  selectedArtist?.artistId || shuffledFirstArtist?.artistId
                }
                loadMore={loadMore}
                hasMore={hasNextPage}
                followOnly={true}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <PostsList
                user={user}
                posts={paginatedPosts}
                artistId={
                  selectedArtist?.artistId || shuffledFirstArtist?.artistId
                }
                loadMore={loadMore}
                hasMore={hasNextPage}
              />
            </TabPanel>
            <TabPanel padding={0}>
              <PostsList
                user={user}
                posts={paginatedPosts}
                artistId={
                  selectedArtist?.artistId || shuffledFirstArtist?.artistId
                }
                loadMore={loadMore}
                hasMore={hasNextPage}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </animated.div>
      <HomeBottomNav tabIndex={2} />
      {!isShowingBottomSheet() && (
        <PopupFabButton
          buttonTexts={[
            t("feedPage.freeTalkFabButton"),
            t("feedPage.questionFabButton"),
            t("feedPage.fanBuddyFabButton"),
            t("feedPage.forSaleFabButton"),
          ]}
          onClickFabChildrenButton={navigateAddPost}
        />
      )}

      {isShowCountryDrawer && (
        <SelectFeedCountryBottomDrawer
          isOpen={isShowCountryDrawer}
          onClose={() => setIsShowCountryDrawer(false)}
          defaultCountryCode={user?.country}
          onConfirmedCountryCode={onConfirmedCountryCode}
        />
      )}
      {isShowTradingProfileBottomSheet && (
        <ShowTradingProfileBottomDrawer
          isOpen={isShowTradingProfileBottomSheet}
          onClose={() => onCloseShowTradingProfileBottomSheet()}
          onUpdateUserPreferences={onUpdateUserPreferences}
        />
      )}
      {isShowBaseProfileBottomSheet && (
        <AddUserProfileV1BottomSheet
          title={t("createFeedUserProfilePage.title")}
          isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
          category={"base"}
        />
      )}
    </PageWrapper>
  );
}

const ActiveProfileBar = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin: 0px 24px;
  padding: 14px 12px;
  align-items: center;
`;
const PrimaryColorText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 8px;
`;

const ActiveProfileText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  padding-left: 8px;
`;

const ProfileImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 4px;
`;

const SocialProfileBar = ({ user, onClickSocialProfileBar }) => {
  if (isUndefined(user)) {
    return <></>;
  } else if (isEmpty(user?.baseUserProfile)) {
    return (
      <ActiveProfileBar
        onClick={onClickSocialProfileBar}
        className="bg-surface-light dark:bg-[rgba(255,255,255,0.05)]"
      >
        <div style={{ position: "relative", zIndex: 1 }}>
          <svg width="24" height="24">
            <circle
              className="stroke-onTheirty-light dark:stroke-onTheirty-dark"
              cx="12"
              cy="12"
              r="11"
              fill="none"
              strokeWidth="1"
              strokeDasharray="1,2"
            />
            <line
              className="stroke-onTheirty-light dark:stroke-onTheirty-dark"
              x1="12"
              y1="8"
              x2="12"
              y2="16"
              strokeWidth="1"
            />
            <line
              className="stroke-onTheirty-light dark:stroke-onTheirty-dark"
              x1="8"
              y1="12"
              x2="16"
              y2="12"
              strokeWidth="1"
            />
          </svg>
        </div>
        <PrimaryColorText className="text-primary-light dark:text-primary-dark">
          {t("feedPage.emptyProfile")}
        </PrimaryColorText>
        <Spacer />
        <ArrowRightIcon
          className="fill-main-light-4 dark:fill-main-dark-4"
          width={16}
          height={16}
        />
      </ActiveProfileBar>
    );
  } else {
    return (
      <ActiveProfileBar
        className="bg-surface-light dark:bg-[rgba(255,255,255,0.05)]"
        onClick={onClickSocialProfileBar}
      >
        <ProfileImage
          src={
            user?.baseUserProfile?.thumbnailUrl ||
            user?.baseUserProfile?.pictureUrl
          }
        />
        <ActiveProfileText className="text-main-light-2 dark:text-main-dark-2">
          <Trans
            i18nKey="feedPage.activeProfile"
            components={{ bold: <strong /> }}
            values={{ nickname: user?.baseUserProfile.nickname || "-" }}
          />
        </ActiveProfileText>
        <Spacer />
        <ArrowRightIcon
          className="fill-main-light-4 dark:fill-main-dark-4"
          width={16}
          height={16}
        />
      </ActiveProfileBar>
    );
  }
};
