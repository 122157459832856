import React from "react";

import { Flex, Image, VStack } from "@chakra-ui/react";
import { t } from "i18next";
import uuid from "react-uuid";
import styled from "styled-components";

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
`;

const Badge = styled.div`
  min-width: 73px;
  min-height: 21px;
  padding: 4px 7px;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 600;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  align-self: center;
`;

const CardsWrapper = styled.div`
  margin-top: 12px;
  /* FIXME : border: 1px solid rgba(231, 231, 231, 0.5); */
  border-radius: 12px;
  padding: 16px;
`;

export default function PhotoCardFirstCaptorCard({
  name,
  imagePath,
  description,
}) {
  return (
    <CardsWrapper className="border-[1px] border-[rgba(231, 231, 231, 0.50)]">
      <Flex key={uuid()}>
        <Image
          width={"34px"}
          height={"34px"}
          borderRadius={"full"}
          src={imagePath}
        />
        <VStack
          alignItems={"flex-start"}
          alignSelf={"center"}
          flexGrow={1}
          paddingLeft={"12px"}
          spacing={"4px"}
        >
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {name}
          </Title>
          <Description className="text-main-light-3 dark:text-main-dark-3">
            {description}
          </Description>
        </VStack>
        <Badge className="bg-surface-light dark:bg-surface-dark">
          {t("photoCardDetailPage.firstCaptorBadge")}
        </Badge>
      </Flex>
    </CardsWrapper>
  );
}
