import { Box, Center, HStack, Text } from "@chakra-ui/react";
import React from "react";

export default function PrimaryButton({ text, disabled, icon, ...props }) {
  return (
    <Box
      className={`text-center ${
        disabled
          ? "text-onDisabled-light dark:text-onDisabled-dark bg-disabled-light dark:bg-disabled-dark"
          : "text-onPrimary  bg-primary-light"
      }`}
      borderRadius={"52px"}
      lineHeight={"17px"}
      paddingX={"12px"}
      paddingY={"8px"}
      fontWeight={500}
      fontSize={"14px"}
      {...props}
    >
      {icon ? (
        <HStack spacing={"2px"}>
          <Text
            className="text-pure-white dark:text-pure-black"
            fontWeight={600}
            lineHeight={"17px"}
          >
            {text}
          </Text>
          {icon}
        </HStack>
      ) : (
        <Center>
          <p>{text}</p>
        </Center>
      )}
    </Box>
  );
}
