import React, { useRef, useState } from "react";

import {
  Badge,
  Flex,
  HStack,
  Image,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { first, isEmpty } from "lodash";
import { openNewTab } from "../../utils/UIUtils";
import RepostBottomDrawer from "../drawer/RepostBottomDrawer";
import ProfileAvatar from "../thumbnails/ProfileAvatar";

const authorNameStyle = {
  fontWeight: 600,
  lineHeight: "16px",
  fontSize: "13px",
};

const authoredAtStyle = {
  paddingTop: "1px",
  fontWeight: 500,
  lineHeight: "13px",
  fontSize: "11px",
  paddingLeft: "4px",
};

const titleStyle = {
  fontWeight: 600,
  lineHeight: "18px",
  fontSize: "15px",
};

const contentStyle = {
  paddingTop: "3px",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  wordBreak: "break-word",
};

const noPaddingContentStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "18px",
  wordBreak: "break-word",
};

const eventNameStyle = {
  fontWeight: 600,
  fontSize: "15px",
  lineHeight: "18px",
  noOfLines: 1,
};

const pocaNameStyle = {
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "17px",
  noOfLines: 1,
};

const memberNameBadgeStyle = {
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "16px",
  borderRadius: "4px",
  width: "fit-content",
};

export default function QuotedPostPreview({ post, disableActions = false }) {
  const focusRef = useRef();
  const [isOpenRepostBottomSheet, setIsOpenRepostBottomSheet] = useState();
  return (
    post && (
      <Flex
        key={post.postId}
        ref={focusRef}
        onClick={() =>
          post.postId &&
          !post.isDeleted &&
          !disableActions &&
          openNewTab(`/posts/${post.postId}`)
        }
      >
        <VStack flexGrow={1} spacing={0} align={"left"}>
          {!post.isDeleted && (
            <Flex paddingBottom={"7px"}>
              <Flex direction={"row"} alignItems={"center"}>
                <div style={{ paddingRight: "4px" }}>
                  <ProfileAvatar
                    profileImageUrl={post.authorThumbnailUrl}
                    widthAndHeight={"15px"}
                    svgWidthAndHeight={"15px"}
                    padding={0}
                  />
                </div>
                <Text
                  className="text-onLabel-light dark:text-onLabel-dark"
                  style={authorNameStyle}
                >
                  {post.authorName}
                </Text>
                <Text
                  className="text-sub-light-1 dark:text-sub-dark-1"
                  style={authoredAtStyle}
                >
                  {post.authoredAt}
                </Text>
              </Flex>
              <Spacer />
            </Flex>
          )}
          <div>
            {post.title && (
              <Text
                className="text-onLabel-light dark:text-onLabel-dark"
                key={"title"}
                style={titleStyle}
                noOfLines={1}
              >
                {post.title}
              </Text>
            )}
            {post.content &&
              (post.isDeleted ? (
                <Text
                  className="text-main-light-2 dark:text-main-dark-2"
                  key={"content"}
                  textAlign={"center"}
                  whiteSpace={"pre-wrap"}
                  style={contentStyle}
                  noOfLines={2}
                >
                  {post.content}
                </Text>
              ) : (
                <Text
                  className="!text-main-light-2 dark:!text-main-dark-2"
                  key={"content"}
                  whiteSpace={"pre-wrap"}
                  style={
                    isEmpty(post.title) ? noPaddingContentStyle : contentStyle
                  }
                  noOfLines={2}
                >
                  {post.content}
                </Text>
              ))}
          </div>
          {isEmpty(post.pictureUrls) && post.photoCard && (
            <HStack
              padding={"16px"}
              borderRadius={"12px"}
              bgColor={"rgba(0, 0, 0, 0.04)"}
            >
              <Image
                width={"40px"}
                height={"68px"}
                src={
                  post.photoCard.mediumThumbnailUrl || post.photoCard.pictureUrl
                }
              />
              <VStack align={"left"} spacing={0}>
                <Text
                  className="text-main-light-1 dark:text-main-dark-1"
                  style={eventNameStyle}
                  noOfLines={1}
                >
                  {post.photoCard.eventName}
                </Text>
                <Text
                  className="text-main-light-3 dark:text-main-dark-3"
                  style={pocaNameStyle}
                >
                  {post.photoCard.pocaname || post.photoCard.name}
                </Text>
                <Badge
                  className="bg-label-light dark:bg-label-dark"
                  marginTop={"8px!important"}
                  padding={"4px 8px"}
                  style={memberNameBadgeStyle}
                >
                  {post.photoCard.memberName}
                </Badge>
              </VStack>
            </HStack>
          )}
        </VStack>

        {!isEmpty(post.pictureUrls) && (
          <Flex flexShrink={1} paddingLeft={"12px"}>
            <div style={{ width: "78px", height: "78px" }}>
              <Image
                width={"78px"}
                height={"78px"}
                objectFit={"cover"}
                borderRadius={"16px"}
                src={first(post.pictureUrls)}
              />
            </div>
          </Flex>
        )}

        {isOpenRepostBottomSheet && (
          <RepostBottomDrawer
            focusRef={focusRef}
            post={post}
            isOpen={isOpenRepostBottomSheet}
            onClose={() => setIsOpenRepostBottomSheet(false)}
          />
        )}
      </Flex>
    )
  );
}
