import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { useRef } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import { t } from "i18next";

const titleStyle = {
  fontWeight: 700,
  fontSize: "17px",
};

const descriptionStyle = {
  fontWeight: 400,
  fontSize: "15px",
  whiteSpace: "pre-line",
};

export default function CommonDialog({
  isOpen,
  onClose,
  onClickDialogButton,
  title,
  description,
  children,
  yesButtonText = t("confirm"),
  noButtonText = t("cancel"),
  isOneButton = false,
}) {
  const cancelRef = useRef();
  return (
    <AlertDialog
      isOpen={isOpen}
      autoFocus={false}
      closeOnOverlayClick={false}
      onClose={onClose}
      leastDestructiveRef={cancelRef.current}
      isCentered
    >
      <AlertDialogOverlay style={{ zIndex: 2147483647 }}>
        <AlertDialogContent
          className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
          borderRadius={"40px"}
          paddingTop={"32px"}
          paddingBottom={"16px"}
          marginX={"6%"}
          style={{ boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.25)" }}
        >
          <AlertDialogBody paddingX={"32px"}>
            <VStack className="text-center">
              {title && (
                <Text
                  className="text-pure-black dark:text-pure-white"
                  style={titleStyle}
                >
                  {title}
                </Text>
              )}
              {description && (
                <Text
                  className="text-main-light-4 dark:text-main-dark-4"
                  style={descriptionStyle}
                >
                  {description}
                </Text>
              )}
            </VStack>
            {children && <div>{children}</div>}
            <HStack className="w-full" marginTop={"20px"}>
              {!isOneButton && (
                <SecondaryButton
                  ref={cancelRef.current}
                  fontWeight={600}
                  fontSize={"17px"}
                  lineHeight={"20px"}
                  padding={"16px 34px"}
                  width={"100%"}
                  onClick={onClose}
                  text={noButtonText}
                />
              )}
              <PrimaryButton
                fontWeight={600}
                fontSize={"17px"}
                lineHeight={"20px"}
                padding={"16px 34px"}
                width={"100%"}
                onClick={() => onClickDialogButton()}
                text={yesButtonText}
              />
            </HStack>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
