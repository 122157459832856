import React from "react";

import { t } from "i18next";
import { isEmpty, isUndefined } from "lodash";
import styled from "styled-components";

import RowSkeletons from "../skeletons/RowSkeletons";
import SalesTradingPhotoCardProfileRow from "./SalesTradingPhotoCardProfileRow";

// const MoveToExploreButton = styled.div`
//   padding-top: 16px;
//   padding-bottom: 8px;
//   padding-left: 12px;
//   padding-right: 12px;
//   font-size: 14px;
//   font-weight: 500;
//   line-height: 17px;
//   letter-spacing: 0em;
//   text-align: center;
//   color: ${colors.textPurple};
// `

const Description = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  white-space: pre;
  padding: 28px 0px;
`;

const SalesTradingPhotoCardsWrapper = styled.div`
  margin-top: 12px;
  border: 1px solid;
  border-radius: 12px;
  border-image-source: linear-gradient(
    134.41deg,
    rgba(255, 255, 255, 0.6) 5.69%,
    rgba(255, 255, 255, 0) 95.22%
  );
  background: linear-gradient(
      91.59deg,
      rgba(255, 255, 255, 0.5) 31.85%,
      rgba(255, 255, 255, 0.1) 102.92%
    ),
    linear-gradient(
      134.41deg,
      rgba(255, 255, 255, 0.6) 5.69%,
      rgba(255, 255, 255, 0) 95.22%
    );
  padding: 8px 16px;
`;

export default function SalesTradingPhotoCardProfileRowListCard({
  tradingPhotoCards,
}) {
  return (
    <SalesTradingPhotoCardsWrapper className="shadow-[0px_2px_10px_0px_rgba(0,0,0,0.08)] dark:shadow-none">
      {isUndefined(tradingPhotoCards) ? (
        <RowSkeletons
          skeletonsCount={3}
          skeletonHeight={"42px"}
          gapHeight={"2px"}
        />
      ) : (
        <>
          {isEmpty(tradingPhotoCards) ? (
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {t("photoCardDetailPage.emptysaleTradingPhotoCards")}
            </Description>
          ) : (
            tradingPhotoCards.map((salesTradingPhotoCard, index) => (
              <React.Fragment key={salesTradingPhotoCard.tradingPhotoCardId}>
                <SalesTradingPhotoCardProfileRow
                  tradingPhotoCard={salesTradingPhotoCard}
                  showbottomBorder={index !== tradingPhotoCards.length - 1}
                />
              </React.Fragment>
            ))
          )}
          {/* <MoveToExploreButton onClick={() => moveToExplore()}>{"양도판에서 판매 포카 모두보기"}</MoveToExploreButton> */}
        </>
      )}
    </SalesTradingPhotoCardsWrapper>
  );
}
