import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { t } from "i18next";
import uuid from "react-uuid";
import styled from "styled-components";

import Page from "../components/Page";
import CommonVerticalButtonDialog from "../components/alerts/CommonVerticalButtonDialog";
import useUserQuery from "../components/api/queries/useUserQuery";
import UserVerificationBottomSheet from "../components/bottomSheet/UserVerificationBottomSheet";
import ArrowRightIcon from "../components/icons/ArrowRightIcon";
import colors from "../constants/colors";
import { sendToNative } from "../utils/NativeUtil";
import initializePortOne from "../utils/PortOne";
import baseUrl from "../utils/baseUrl";
import { setEvent } from "../utils/ga";
import getToken from "../utils/getToken";
import SelectLocaleBottomDrawer from "../components/drawer/SelectLocaleBottomDrawer";
import updateUserLocaleOrCountry from "../components/api/mutations/updateUserLocaleOrCountry";
import SupportedLocales from "../constants/SupportedLocales";
import { first, isEmpty } from "lodash";
import i18n from "../i18n/i18n";
import SelectCountryBottomDrawer from "../components/drawer/SelectCountryBottomDrawer";
import Countries from "../constants/Countries";
import saveData from "../utils/saveData";

async function initializedPhotoCards(artistId) {
  return fetch(baseUrl() + `/photo_cards/initialized`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      artistId: artistId,
    }),
  }).then((data) => data.json());
}

async function deleteUser() {
  return fetch(baseUrl() + `/users/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
  }).then((data) => data.json());
}

const Row = styled.div`
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  min-height: 66px;
`;

const TextColumn = styled.div`
  flex-grow: 1;
  align-content: center;
`;

const Title = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const Description = styled.p`
  padding-top: 6px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-shrink: 1;
  align-items: center;
`;

const ErrorTextButton = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const PrimaryTextButton = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${colors.primary};
`;

const ORDERS_BY_EMAIL = {
  "tigerjj84@gmail.com":
    "https://shopify.com/73272099056/account/orders/a1dda597a8e7432de5178a95a7d37aa5?locale=ko-KR",
  "horyunlee@gmail.com":
    "https://shopify.com/73272099056/account/orders/561dbab367187e870f74ea76d120c1a7",
  "codms6116@naver.com":
    "https://shopify.com/73272099056/account/orders/de254cb1bc493154168843fb641cea81?locale=ko-KR",
  "a31718660@gmail.com":
    "https://shopify.com/73272099056/account/orders/f9e106e10d9e81bfcdd2faa4c4a4cdd6?locale=ko-KR",
  "tnqlsdl2906@naver.com":
    "https://shopify.com/73272099056/account/orders/a1b9470dc9ef82e089c206d5ba6c1763?locale=ko-KR",
  "jiuni7872@naver.com":
    "https://shopify.com/73272099056/account/orders/c5f0933ccc0ef8b8a65c3c5f5b587472?locale=ko-KR",
  "beyondstory100@naver.com":
    "https://shopify.com/73272099056/account/orders/8f153e77b393de4207768080efc565ad?locale=ko-KR",
  "feeling8793@naver.com":
    "https://shopify.com/73272099056/account/orders/4cf6f4f4238c7d2d1691a51b09249e53?locale=ko-KR",
  "jihaeng0725@naver.com":
    "https://shopify.com/73272099056/account/orders/6d055e8943e637e0e4d794206318aca8?locale=ko-KR",
  "rhtkagk@naver.com":
    "https://shopify.com/73272099056/account/orders/51199385ab095e6ce3a9ae243ec1f66a?locale=ko-KR",
  "yo_20110@icloud.com":
    "https://shopify.com/73272099056/account/orders/39d83da54857b6702b7144e1f8ace6a6?locale=ko-KR",
  "dustn667@naver.com":
    "https://shopify.com/73272099056/account/orders/e0e5cbc2162a62723200133f8bacb986?locale=ko-KR",
  "bgqhse@naver.com":
    "https://shopify.com/73272099056/account/orders/9209151fdc019f07e888d4c05caa2d13?locale=ko-KR",
  "kjyoup@gmail.com":
    "https://shopify.com/73272099056/account/orders/ebde36fb8a8dedf223101a33ba2d9bcc?locale=ko-KR",
};

export default function ManageAccountPage() {
  const location = useLocation();
  const currentQueryParams = new URLSearchParams(location.search);
  const artistId = currentQueryParams.get("artistId");
  const user = useUserQuery();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isShowVerificationBottomSheet, setIsShowVerificationBottomSheet] =
    useState(false);
  const [isShowLocaleBottomSheet, setIsShowLocaleBottomSheet] = useState(false);
  const [isShowCountryBottomSheet, setIsShowCountryBottomSheet] =
    useState(false);

  const { origin, pathname, search } = window.location;

  function requestIdentityVerification() {
    const IMP = initializePortOne();

    IMP.certification({
      merchant_uid: `imp-${uuid()}`,
      min_age: 14,
      company: "포카판",
      popup: false,
      m_redirect_url: `${origin}/identity_verification_callback?redirect_path=${encodeURIComponent(
        pathname + search
      )}`,
    });
  }

  const invalidateQueries = () => {
    queryClient.invalidateQueries("users");
  };

  const onFinishedInitialized = (isSuccess) => {
    if (isSuccess) {
      invalidateQueries();
    } else {
      alert(t("userProfilePage.errorIntializePhotoCardsAlert"));
    }
  };

  const navigateUserIdentity = (showDialog) => {
    if (showDialog) {
      setIsOpenAlert(true);
    } else {
      if (user?.identified) {
        requestIdentityVerification();
      } else {
        setIsShowVerificationBottomSheet(true);
      }
      setIsOpenAlert(false);
    }
  };

  const confirmInitializePhotoCards = () => {
    if (!user?.likedArtists) return;
    if (user?.likedArtists.length === 0) return;

    if (user?.likedArtists.length > 1) {
      navigate("/artists/reset_all_cards");
    } else {
      // 아티스트 가 한명만 있을 경우
      setEvent({
        category: "PhotoCard",
        action: "Select Initialize PhotoCards",
        label: "User Profile Page",
      });
      if (window.confirm(t("userProfilePage.initializePhotoCardsAlert"))) {
        setEvent({
          category: "PhotoCard",
          action: "Initialize PhotoCards",
          label: "User Profile Page",
        });
        initializedPhotoCards(artistId).then((r) =>
          onFinishedInitialized(r.success)
        );
      }
    }
  };

  const initializeLocalStorage = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("artist");
    localStorage.clear();
    sendToNative('{"type": "logout"}');
  };

  const logout = () => {
    if (window.confirm(t("userProfilePage.logoutConfirmAlert"))) {
      initializeLocalStorage();
      queryClient.clear();
      return navigate("/");
    }
  };

  const deleteAccount = () => {
    if (window.confirm(t("userProfilePage.deleteAccountConfirmAlert"))) {
      deleteUser().then((r) => {
        // TODO handle error
        initializeLocalStorage();
        queryClient.clear();
        return navigate("/");
      });
    }
  };

  const onConfirmedLocale = async (locale) => {
    const response = await updateUserLocaleOrCountry({
      userId: user.userId,
      locale: locale,
    });
    if (response) {
      saveData("i18nextLng", locale);
      i18n.changeLanguage(locale);
      invalidateQueries();
      queryClient.clear();
    }
  };

  const localeObject = useMemo(() => {
    if (isEmpty(user)) return;

    if (user.locale === "zh-HK") {
      return {
        code: "zh-TW",
        name: "繁體中文",
      };
    }

    const filteredLocale = SupportedLocales.filter(
      (locale) => locale.code === user.locale
    );
    if (!isEmpty(filteredLocale)) {
      return first(filteredLocale);
    }

    return {
      code: "en",
      name: "English",
    };
  }, [user]);

  const onConfirmedCountyCode = async (countryCode) => {
    const response = await updateUserLocaleOrCountry({
      userId: user.userId,
      country: countryCode,
    });
    if (response) {
      invalidateQueries();
      queryClient.clear();
    }
  };

  const countryObject = useMemo(() => {
    if (isEmpty(user)) return;
    const filteredCountry = Countries.filter(
      (country) => country.code === user.country
    );
    return first(filteredCountry);
  }, [user]);

  const navigateOrder = () => {
    const orderUrl = ORDERS_BY_EMAIL[user.email];
    window.location.href = orderUrl;
  };

  return (
    <Page
      title={t("manageAccountPage.pageTitle")}
      showBackButton={true}
      showTitle={true}
      userProfile={user}
      style={{ paddingTop: "13px", marginBottom: "96px" }}
    >
      <Row className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1">
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.accountEmailTitle")}
          </Title>
          <Description className="text-main-light-4 dark:text-main-dark-4">
            {user?.email}
          </Description>
        </TextColumn>
      </Row>
      {user && user.country === "KR" && !isEmpty(ORDERS_BY_EMAIL[user?.email]) && (
        <Row
          className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
          onClick={() => navigateOrder()}
        >
          <TextColumn>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {"포카판스토어 구매내역"}
            </Title>
          </TextColumn>
          <ButtonColumn>
            <ArrowRightIcon
              className="fill-sub-light-1 dark:fill-sub-dark-1"
              width={"20px"}
              style={{ marginLeft: "4px", padding: "2px 4px" }}
            />
          </ButtonColumn>
        </Row>
      )}

      {user && user.country === "KR" && (
        <Row
          className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
          onClick={() => navigateUserIdentity(!!user?.identified)}
        >
          <TextColumn>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("manageAccountPage.identityVerificationTitle")}
            </Title>
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {t("manageAccountPage.identityVerificationDescription")}
            </Description>
          </TextColumn>
          <ButtonColumn>
            {user?.identified ? (
              <PrimaryTextButton>
                {t("manageAccountPage.identityVerificationDone")}
              </PrimaryTextButton>
            ) : (
              <ErrorTextButton className="text-warning">
                {t("manageAccountPage.identityVerificationIncomplete")}
              </ErrorTextButton>
            )}
            <ArrowRightIcon
              className="fill-sub-light-1 dark:fill-sub-dark-1"
              width={"20px"}
              style={{ marginLeft: "4px", padding: "2px 4px" }}
            />
          </ButtonColumn>
        </Row>
      )}

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        onClick={() => setIsShowCountryBottomSheet(true)}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.countryTitle")}
          </Title>
          <Description className="text-main-light-4 dark:text-main-dark-4">
            {t("manageAccountPage.countryDescription")}
          </Description>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{countryObject?.name}</PrimaryTextButton>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        onClick={() => setIsShowLocaleBottomSheet(true)}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.localeTitle")}
          </Title>
          <Description className="text-main-light-4 dark:text-main-dark-4">
            {t("manageAccountPage.localeDescription")}
          </Description>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{localeObject?.name}</PrimaryTextButton>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      {/* <Row>
        <TextColumn>
          <Title>{t("manageAccountPage.promotionTitle")}</Title>
        </TextColumn>
        <ButtonColumn>
          {
            user?.isPromotionAgreed ? <PrimaryTextButton>{t("manageAccountPage.promotionAgreed")}</PrimaryTextButton> : <ErrorTextButton>{t("manageAccountPage.promotionDisagreed")}</ErrorTextButton>
          }
          <ArrowRightIcon width={"20px"} style={{marginLeft: "4px", padding: "2px 4px"}} fill="#AAAAAA"/>
        </ButtonColumn>
      </Row> */}

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        onClick={() => confirmInitializePhotoCards()}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.initializePhotoCards")}
          </Title>
        </TextColumn>
        <ButtonColumn>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        onClick={() => deleteAccount()}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.deleteAccount")}
          </Title>
        </TextColumn>
        <ButtonColumn>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        onClick={() => logout()}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.logout")}
          </Title>
        </TextColumn>
        <ButtonColumn>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      <Row
        className="border-b border-b-separator-light-1 dark:border-b-separator-dark-1"
        style={{ borderBottom: "0px" }}
      >
        <TextColumn>
          <Title className="text-main-light-2 dark:text-main-dark-2">
            {t("manageAccountPage.appVersion")}
          </Title>
        </TextColumn>
        <ButtonColumn>
          <PrimaryTextButton>{"1.0.3"}</PrimaryTextButton>
          <ArrowRightIcon
            className="fill-sub-light-1 dark:fill-sub-dark-1"
            width={"20px"}
            style={{ marginLeft: "4px", padding: "2px 4px" }}
          />
        </ButtonColumn>
      </Row>

      {user && (
        <CommonVerticalButtonDialog
          isOpen={isOpenAlert}
          onClose={() => setIsOpenAlert(false)}
          title={t("manageAccountPage.identityVerificationDialogTitle")}
          description={t(
            "manageAccountPage.identityVerificationDialogDescription",
            {
              phoneNumber: user.phoneNumber,
              certifiedAt: format(new Date(user.certifiedAt), "yyyy.MM.dd"),
            }
          )}
          upButtonText={t("manageAccountPage.identityVerificationDialogNo")}
          downButtonText={t("manageAccountPage.identityVerificationDialogYes")}
          onClickDialogUpButton={() => setIsOpenAlert(false)}
          onClickDialogDownButton={() => navigateUserIdentity(false)}
        />
      )}

      {user && !user.identified && (
        <UserVerificationBottomSheet
          isShowBottomSheet={isShowVerificationBottomSheet}
          setIsShowBottomSheet={setIsShowVerificationBottomSheet}
        />
      )}

      {user && isShowCountryBottomSheet && (
        <SelectCountryBottomDrawer
          isOpen={isShowCountryBottomSheet}
          onClose={setIsShowCountryBottomSheet}
          onConfirmedCountryCode={onConfirmedCountyCode}
          defaultCountryCode={user.country}
        />
      )}

      {user && isShowLocaleBottomSheet && (
        <SelectLocaleBottomDrawer
          isOpen={isShowLocaleBottomSheet}
          onClose={setIsShowLocaleBottomSheet}
          onConfirmedLocale={onConfirmedLocale}
          defaultLocale={localeObject?.code}
        />
      )}
    </Page>
  );
}
