import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { t } from "i18next";
import { isEmpty, sortBy } from "lodash";
import { Virtuoso } from "react-virtuoso";

import {
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";

import { ReactComponent as SearchIcon } from "../../icons/search.svg";

import TradingPhotoCardWindowList from "../../components/lists/TradingPhotoCardWindowList";
import AddTradingPhotoCard from "../../components/photocard/AddTradingPhotoCard";

import Page from "../../components/Page";
import ArtistIconSelector from "../../components/selectors/ArtistIconSelector";
import filterPhotoCards from "../../utils/SearchPhotoCard";
import removedEmojiEventName from "../../utils/TextUtil";
import { getMemberIdAnyway } from "../../utils/etcUtils";
import { queryGet } from "../../utils/queryClient";

export default function AddPhotoCardPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchText, setSearchText] = useState();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [selectedMember, setSelectedMember] = useState();
  // const [newTradingPhotoCards, setNewTradingPhotoCards] = useState([]);
  // const [isShowEditBottomSheet, setIsShowEditBottomSheet] = useState(false);

  const artistId = selectedArtist?.artistId;
  const convertPhotoCardWithEvent = (photoCard, e) => {
    return { ...photoCard, ...{ event: e.event } };
  };

  const { data: searchTargetPhotoCards } = useQuery(
    ["allArtistPhotoCards", artistId],
    () => queryGet(`/artists/${artistId}/photo_cards?page=1&per_page=999999`),
    {
      enabled: !!artistId,
      select: (r) =>
        r.events.flatMap((e) =>
          e.photoCards.map((p) => convertPhotoCardWithEvent(p, e))
        ),
    }
  );

  const { data: photoCardByEvent } = useQuery(
    ["artistPhotoCards", artistId, getMemberIdAnyway(selectedMember)],
    () =>
      queryGet(
        `/artists/${artistId}/members/${getMemberIdAnyway(
          selectedMember
        )}/photo_cards`
      ),
    {
      enabled: !!artistId && !!selectedMember,
    }
  );

  const onSelectedArtist = (artist) => {
    setSelectedArtist(artist);
  };

  const selectPhotoCard = (photoCard, e) => {
    console.log(photoCard);
  };

  return (
    <>
      <Page
        title={t("addPhotoCardPage.pageTitle")}
        showTitle={true}
        showBackButton={true}
      >
        <ArtistIconSelector
          onSelected={onSelectedArtist}
          enableAddCard={false}
        />

        <InputGroup marginTop={"12px"}>
          <Input
            className="placeholder:text-[17px] placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark text-main-light-2 dark:text-main-dark-2 bg-secondary-light dark:bg-secondary-dark"
            height={"54px"}
            border={"none"}
            borderRadius={"12px"}
            fontSize={"17px"}
            fontWeight={600}
            placeholder={t("addTradingPhotoCardPage.searchInputPlaceholder")}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <InputRightElement
            children={
              <SearchIcon className="fill-primary-light dark:fill-primary-dark" />
            }
            marginRight={"12px"}
            marginTop={"6px"}
          />
        </InputGroup>

        {!isEmpty(searchText) ? (
          <TradingPhotoCardWindowList
            tradingPhotoCards={filterPhotoCards(
              searchTargetPhotoCards,
              searchText
            )}
            addTradingPhotoCard={selectPhotoCard}
          />
        ) : (
          photoCardByEvent && (
            <Virtuoso
              useWindowScroll
              style={{ height: "100%" }}
              overscan={480}
              data={photoCardByEvent?.events}
              itemContent={(_, e) => {
                const photoCards = e.photoCards.map((p) =>
                  convertPhotoCardWithEvent(p, e)
                );
                return (
                  photoCards.length > 0 && (
                    <Stack key={e.event?.name} paddingBottom={"18px"}>
                      <Wrap
                        paddingTop={"10px"}
                        paddingBottom={"4px"}
                        align={"center"}
                        marginLeft={"-1px"}
                        spacing={0}
                      >
                        <WrapItem alignItems={"center"}>
                          <Text fontSize={"20px"} marginRight={"4px"}>
                            {e.event?.name?.slice(0, 2)}
                          </Text>
                          <Text fontSize={"13px"}>
                            {removedEmojiEventName(e.event?.name)}
                          </Text>
                        </WrapItem>
                      </Wrap>
                      <SimpleGrid columns={4} gap={"8px"}>
                        {sortBy(photoCards, (p) => {
                          return -p.collectedCount;
                        }).map((photoCard) => {
                          return (
                            <AddTradingPhotoCard
                              key={photoCard.photoCardId}
                              photoCard={photoCard}
                              onClickPhotoCard={(p, e) => selectPhotoCard(p, e)}
                            />
                          );
                        })}
                      </SimpleGrid>
                    </Stack>
                  )
                );
              }}
            />
          )
        )}
      </Page>
    </>
  );
}
