import { TabPanel } from "@chakra-ui/react";
import { t } from "i18next";
import { first, isEmpty, isUndefined } from "lodash";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import CommonDialog from "../../components/alerts/CommonDialog";
import ChatRoomPhotoCardResponse from "../../components/api/model/ChatRoomPhotoCardResponse";
import updateTradingPhotoCards from "../../components/api/mutations/updateTradingPhotoCards";
import useSellingPhotoCardsQuery from "../../components/api/queries/useSellingPhotoCardsQuery";
import EmptyCase from "../../components/errors/EmptyCase";
import TradingPhotoCardBoxByPhotoCard from "../../components/photocard/TradingPhotoCardBoxByPhotoCard";
import GridSkeletons from "../../components/skeletons/GridSkeletons";
import { PhotoCardBoxButton } from "../../components/tradings/PhotoCardBoxButton";
import { extractPrice } from "../../utils/TextUtil";
import { navigateReviewPath } from "../../utils/TradingHelper";
import BoxTabs from "./BoxTabs";
import CommonVerticalButtonDialog from "../../components/alerts/CommonVerticalButtonDialog";

const CounterInfoWrapper = styled.div`
  padding-top: 18px;
  padding-bottom: 12px;
`;

const Counter = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;

const PhotoCardBoxWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const PhotoCardBox = styled.div`
  border-radius: 12px;
  padding: 10px 10px 16px 16px;
  align-items: center;
`;

export default function SellingPhotoCardsTab() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [tradingStatus, setTradingStatus] = useState("ongoing");
  const [selectedTradingPhotoCard, setSelectedTradingPhotoCard] = useState();

  const [paginatedPhotoCards, setPaginatedPhotoCards] = useState();
  const [meta, setMeta] = useState();

  const [isOpenExchangeDialog, setIsOpenExchangeDialog] = useState(false);
  const [isOpenCompletedDialog, setIsOpenCompletedDialog] = useState(false);

  const { data, isFetching, fetchNextPage, hasNextPage } =
    useSellingPhotoCardsQuery(tradingStatus);

  const handlePaginatedPhotoCards = (paginatedData) => {
    const photoCards = paginatedData.pages?.flatMap((page) =>
      page.photoCards.map(
        (photoCard) => new ChatRoomPhotoCardResponse(photoCard)
      )
    );
    setPaginatedPhotoCards(photoCards);
  };

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedPhotoCards(data);
      setMeta(first(data.pages)?.meta);
    }
  }, [data]);

  const onSubTabSelected = (index) => {
    setTradingStatus(index === 0 ? "ongoing" : "completed");
  };

  const onClickCompleted = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    setIsOpenCompletedDialog(true);
  };

  const onClickToggleCategory = (tradingPhotoCard) => {
    setSelectedTradingPhotoCard(tradingPhotoCard);
    setIsOpenExchangeDialog(true);
  };

  const invalidateItems = () => {
    queryClient.invalidateQueries(["ExchangingPhotoCards"]);
    queryClient.invalidateQueries(["SellingPhotoCards"]);
  };

  const handleUpdateAPIResponse = async (response) => {
    if (response.ok) {
      setIsOpenExchangeDialog(false);
      setIsOpenCompletedDialog(false);
      invalidateItems();
      const responseBody = await response.json();
      return responseBody.tradingPhotoCard;
    } else {
      return null;
    }
  };

  const onChangedToExchange = async () => {
    if (isEmpty(selectedTradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: selectedTradingPhotoCard.tradingPhotoCardId,
      price: null,
      category: "exchange",
    });
    handleUpdateAPIResponse(response);
  };

  const onChangedToCompleted = async () => {
    if (isEmpty(selectedTradingPhotoCard)) return;

    const response = await updateTradingPhotoCards({
      tradingPhotoCardId: selectedTradingPhotoCard.tradingPhotoCardId,
      price: extractPrice(selectedTradingPhotoCard.price),
      status: "sold_out",
    });

    const responseBody = await handleUpdateAPIResponse(response);
    if (responseBody && responseBody.chatRoomsCount > 0) {
      navigate(
        `/trading_photo_cards/${selectedTradingPhotoCard.tradingPhotoCardId}/select_chat_rooms`
      );
    }
  };

  const navigateReview = (tradingPhotoCard) => {
    navigate(navigateReviewPath(tradingPhotoCard));
  };

  return (
    <TabPanel padding={0}>
      <BoxTabs
        tabLabels={[
          t("myPhotoCards.tradingStatusSelectorOngoing"),
          t("myPhotoCards.tradingStatusSelectorCompleted"),
        ]}
        onTabSelected={(index) => onSubTabSelected(index)}
      />
      {isUndefined(paginatedPhotoCards) ? (
        <>
          <CounterInfoWrapper>
            <Counter>{t("myPhotoCards.totalCount", { count: "-" })}</Counter>
          </CounterInfoWrapper>
          <GridSkeletons
            gridSize={1}
            skeletonSize={1}
            skeletonHeight={"109px"}
          />
        </>
      ) : isEmpty(paginatedPhotoCards) ? (
        <EmptyCase
          emoji={"😳"}
          title={t("myPhotoCards.sellingPhotoCardsTabEmptyCaseTitle")}
          buttonText={t("myPhotoCards.sellingPhotoCardsTabEmptyCaseButton")}
          onClickButton={() =>
            navigate("/tradings/add_trading_photo_cards?isExchange=false")
          }
        />
      ) : (
        <>
          <CounterInfoWrapper>
            <Counter>
              {t("myPhotoCards.totalCount", {
                count: meta?.pagination?.totalCount,
              })}
            </Counter>
          </CounterInfoWrapper>
          <Virtuoso
            useWindowScroll
            style={{ height: "100%" }}
            endReached={loadMore}
            overscan={480}
            data={paginatedPhotoCards}
            itemContent={(_, e) => {
              return (
                <PhotoCardBoxWrapper key={e.photoCardId}>
                  <PhotoCardBox className="border-separator-light-1 dark:border-separator-dark-1">
                    <TradingPhotoCardBoxByPhotoCard
                      photoCard={e}
                      showChatRoomsCount={true}
                      showPrice={true}
                      onClickBox={() =>
                        navigate(
                          `/trading_photo_cards/${e.tradingPhotoCardId}/my_chat_rooms`
                        )
                      }
                    />
                    <PhotoCardBoxButton
                      tradingPhotoCard={e}
                      tradingStatus={tradingStatus}
                      onClickCompleted={onClickCompleted}
                      onClickToggleCategory={onClickToggleCategory}
                      onClickNavigateReview={navigateReview}
                    />
                  </PhotoCardBox>
                </PhotoCardBoxWrapper>
              );
            }}
          />
        </>
      )}

      {/* Toggle To ExchangeCategory Dialog */}
      <CommonDialog
        isOpen={isOpenExchangeDialog}
        onClose={() => setIsOpenExchangeDialog(false)}
        title={t("myPhotoCards.chatRoomExchangeDialogTitle")}
        onClickDialogButton={onChangedToExchange}
      />

      {/* Delete TradingPhotoCard Dialog */}
      <CommonVerticalButtonDialog
        isOpen={isOpenCompletedDialog}
        onClose={() => setIsOpenCompletedDialog(false)}
        upButtonText={t("myPhotoCards.doneAndSendReview")}
        downButtonText={t("no")}
        title={t("myPhotoCards.chatRoomCompleteDialogTitle")}
        onClickDialogUpButton={onChangedToCompleted}
        onClickDialogDownButton={() => setIsOpenCompletedDialog(false)}
      />
    </TabPanel>
  );
}
