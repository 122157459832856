import React, { useEffect, useState } from "react";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";
import styled from "styled-components";

import useChatRoomsQuery from "../components/api/queries/useChatRoomsQuery";
import useNotificationsQuery from "../components/api/queries/useNotificationsQuery";
import useUserProfilesListQuery from "../components/api/queries/useUserProfilesListQuery";
import useUserQuery from "../components/api/queries/useUserQuery";
import RegularSolidButton from "../components/buttons/RegularSolidButton";
import ChatRoomV1 from "../components/chats/ChatRoomV1";
import EmptyCase from "../components/errors/EmptyCase";
import NewNotificationTabPanel from "../components/notifications/NewNotificationTabPanel";
import StickyHeader from "../components/StickyHeader";
import { Helmet } from "react-helmet-async";

const activeTabStyle = (colorMode, theme) => ({
  fontWeight: 700,
  borderBottom: "3px solid",
  borderColor:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  color:
    colorMode === "dark"
      ? theme.colors.primary.dark
      : theme.colors.primary.light,
  borderBox: "",
});

const PageWrapper = styled.div`
  padding-top: calc(env(safe-area-inset-top));
  background-attachment: scroll;
  height: 100vh;
`;

const TabPanelWrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

const ChatRoomFiltersWrapper = styled.div`
  display: flex;
  gap: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const TRADING_CATEGORY = "trading";

const MESSAGE_CATEGORIES = ["All", "Purchasing", "Selling", "Exchanging"];

export default function NotificationPageV2() {
  const theme = useTheme();
  const { colorMode } = useColorMode();
  const location = useLocation();
  const navigate = useNavigate();

  const currentQueryParams = new URLSearchParams(location.search);

  const [tabIndex, setTabIndex] = useState(
    currentQueryParams.get("tabIndex") || 0
  );
  const [paginatedChatRooms, setPaginatedChatRooms] = useState();
  const [paginatedNotifications, setPaginatedNotifications] = useState();
  const [chatRoomsCategory, setChatRoomsCategory] = useState(
    MESSAGE_CATEGORIES[0]
  );

  const user = useUserQuery();
  const { data, isFetching, fetchNextPage, hasNextPage } = useChatRoomsQuery({
    type: chatRoomsCategory,
    onlyJoined: true,
  });

  const handlePaginatedChatRooms = (paginatedData) => {
    const chatRooms = paginatedData.pages?.flatMap((page) =>
      page.chatRooms.map((chatRoom) => chatRoom)
    );
    setPaginatedChatRooms(chatRooms);
  };

  const loadMore = () => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(data)) {
      handlePaginatedChatRooms(data);
    }
  }, [data]);

  const {
    data: notificationsData,
    isFetching: isFetchingNotifications,
    fetchNextPage: fetchNextNotificationsPage,
    hasNextPage: hasNextNotificationsPage,
  } = useNotificationsQuery();

  const handlePaginatedNotifications = (paginatedData) => {
    const notifications = paginatedData.pages?.flatMap((page) =>
      page.notifications.map((notification) => notification)
    );
    setPaginatedNotifications(notifications);
  };

  const loadMoreNotifications = () => {
    if (!isFetchingNotifications && hasNextNotificationsPage) {
      fetchNextNotificationsPage();
    }
  };

  useEffect(() => {
    if (!isEmpty(notificationsData)) {
      handlePaginatedNotifications(notificationsData);
    }
  }, [notificationsData]);

  const tradingUserProfiles = useUserProfilesListQuery(TRADING_CATEGORY);
  useEffect(() => {
    if (tradingUserProfiles && tradingUserProfiles.length > 1) {
      navigate("/users/merge_profiles");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingUserProfiles]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
    navigate(`${location.pathname}?tabIndex=${index}`, { replace: true });
  };

  return (
    <PageWrapper>
      <Helmet>
        <title>{t("tabs.notifications")}</title>
        <meta
          name="theme-color"
          content={colorMode === "dark" ? "#0D0E12" : "#FFFFFF"}
        />
      </Helmet>
      <StickyHeader
        title={t("notifications.pageTitle")}
        showTitle={true}
        alwaysShowTitle={true}
        showBackButton={true}
      />
      <Tabs
        isFitted
        index={parseInt(tabIndex)}
        borderColor={"transparent"}
        colorScheme="primary"
        onChange={handleTabsChange}
        paddingTop={"calc(58px + env(safe-area-inset-top))"}
      >
        <TabList
          className="border-b-[1px] border-surface-light dark:border-surface-dark"
          paddingX={"16px"}
        >
          <Tab
            _active={""}
            _selected={activeTabStyle(colorMode, theme)}
            lineHeight={"19px"}
            paddingY={"13px"}
          >
            {t("notifications.notificationsTab")}
          </Tab>
          <Tab
            _active={""}
            _selected={activeTabStyle(colorMode, theme)}
            lineHeight={"19px"}
            paddingY={"13px"}
          >
            {t("notifications.messagesTab")}
          </Tab>
        </TabList>
        <TabPanels style={{ paddingBottom: "120px" }}>
          <TabPanel padding={0} paddingTop={"24px"}>
            <TabPanelWrapper>
              <NewNotificationTabPanel
                notifications={paginatedNotifications}
                loadMore={loadMoreNotifications}
              />
            </TabPanelWrapper>
          </TabPanel>
          <TabPanel padding={0} paddingTop={"10px"}>
            <TabPanelWrapper>
              <ChatRoomFiltersWrapper>
                {MESSAGE_CATEGORIES.map((category) => {
                  return (
                    <RegularSolidButton
                      key={category}
                      text={t(`notifications.messageCategory${category}`)}
                      filled={category === chatRoomsCategory}
                      onClicked={() => setChatRoomsCategory(category)}
                    />
                  );
                })}
              </ChatRoomFiltersWrapper>
              <MessageTabPanel
                userProfileId={user?.tradingUserProfile?.userProfileId}
                chatRooms={paginatedChatRooms}
                loadMore={loadMore}
              />
            </TabPanelWrapper>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
}

function MessageTabPanel({ userProfileId, chatRooms, loadMore }) {
  const navigate = useNavigate();
  const navigateExploreTrading = () => {
    navigate(`/tradings/photo_cards`);
  };

  return (
    <div style={{ paddingBottom: "32px" }}>
      {chatRooms && isEmpty(chatRooms) ? (
        <EmptyCase
          emoji={"🥺"}
          title={t("emptyMessages.title")}
          buttonText={t("emptyMessages.button")}
          onClickButton={() => navigateExploreTrading()}
        />
      ) : (
        <Virtuoso
          useWindowScroll
          overscan={480}
          data={chatRooms}
          endReached={loadMore}
          style={{ height: "100px" }}
          itemContent={(_, chatRoom) => (
            <div key={chatRoom.chatRoomId} style={{ paddingBottom: "12px" }}>
              <ChatRoomV1
                key={chatRoom.chatRoomId}
                userProfileId={userProfileId}
                chatRoom={chatRoom}
              />
            </div>
          )}
        />
      )}
    </div>
  );
}
