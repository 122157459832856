import { Box, Image } from "@chakra-ui/react";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { ellipsis, isEnglishOrNumber } from "../../utils/TextUtil";

export default function UserProfileButton({userProfile, onClickedButton, ...props}) {
  return (
    <Box
      className="text-center"
      display="flex"
      alignItems="center"
      justifyContent="center"
      textColor="primary"
      backgroundColor="secondary.light"
      borderRadius="52px"
      lineHeight="17px"
      minWidth="37px"
      minHeight="33px"
      height="33px"
      fontSize="14px"
      fontWeight={500}
      onClick={() => onClickedButton()}
      {...props}
  >
    {
      userProfile &&
      <Box display="flex" justifyContent="flex-end" padding={"7px 10px 7px 8px"} alignItems={"center"}>
        <Box pr={"6px"}>
          {
            userProfile?.thumbnailUrl ?
            <Image
              width={"19px"}
              height={"19px"}
              objectFit={"cover"}
              borderRadius={"full"}
              src={userProfile?.thumbnailUrl}
            /> :
            <ProfileFillIcon fill="white" width={"19px"} height={"19px"} />
          }
        </Box>
        <p>
          {
            ellipsis(
              userProfile?.nickname,
              isEnglishOrNumber(userProfile?.nickname) ? 10 : 5
            )
          }
        </p>
      </Box>
    }
  </Box>
  )
}
