import React from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { ReactComponent as ProfileFillIcon } from "../../icons/profile.fill.svg";
import { ReactComponent as CartFillIcon } from "../../icons/cart.fill.svg";
import { ReactComponent as PeopleFillIcon } from "../../icons/people.fill.svg";
import VerifiedIcon from "../icons/VerifiedIcon";
import GridSkeletons from "../skeletons/GridSkeletons";
import useReviewStatsQuery from "../api/queries/useReviewStatsQuery";

const profileBoxStyles =
  "bg-white/50 dark:bg-[rgba(34,34,34,0.2)] shadow-lg rounded-2xl py-3 pl-4 pr-3 w-full cursor-pointer";
const profileImageStyles = "relative w-12 h-12";
const profileImageWrapperStyles =
  "w-[52px] h-[52px] rounded-full bg-white flex items-center justify-center";
const profileInfoStyles = "flex flex-col gap-3";
const profileNicknameStyles =
  "text-[15px] font-semibold text-main-light-2 dark:text-main-dark-2";
const profileDescriptionStyles =
  "text-[13px] font-medium text-main-light-4 dark:text-main-dark-4";
const profileStatStyles = "flex items-center gap-2 mt-2 mb-1 mt-auto";
const statLabelStyles =
  "text-[12px] font-medium text-main-light-2 dark:text-main-dark-2";
const statValueStyles =
  "text-[12px] font-bold text-main-light-3 dark:text-main-dark-3";

const DefaultUserProfileBox = () => {
  return (
    <div className="absolute top-0 left-0 z-10">
      <svg width="48" height="48">
        <circle
          className="stroke-onTheirty-light dark:stroke-onTheirty-dark"
          cx="24"
          cy="24"
          r="22"
          fill="none"
          strokeWidth="2"
          strokeDasharray="5,5"
        />
      </svg>
    </div>
  );
};

const UserProfileList = ({ user }) => {
  const navigate = useNavigate();
  const reviewStats = useReviewStatsQuery({
    userProfileId: user?.tradingUserProfile?.userProfileId,
  });

  const ReviewScoreColor = () => {
    if (!reviewStats || !reviewStats.ratingsScore) return "#E7E7E7";

    const score = reviewStats.ratingsScore;
    return score <= 30 ? "#FF4C41" : score <= 60 ? "#FAD722" : "#2DDD28";
  };

  if (isEmpty(user)) {
    return (
      <>
        <GridSkeletons
          gridSize={1}
          skeletonSize={1}
          skeletonHeight="24px"
          marginBottom="12px"
        />
        <GridSkeletons gridSize={1} skeletonSize={1} skeletonHeight="80px" />
      </>
    );
  }

  const navigateAddUserProfile = (category) => {
    if (category === "base") {
      if (isEmpty(user.baseUserProfile)) {
        navigate("/feed/create_feed_user_profile");
      } else {
        navigate(`/feed/user_profile/${user.baseUserProfile.userProfileId}`);
      }
    } else {
      // TODO : show bottom sheet or navigate to create trading profile
      navigate(
        `/trading/user_profile/${user.tradingUserProfile.userProfileId}`
      );
    }
  };

  return (
    <div className="grid grid-cols-2 gap-3">
      <div
        className={profileBoxStyles}
        onClick={() => navigateAddUserProfile("base")}
      >
        <div className={`${profileInfoStyles} h-full`}>
          {user.baseUserProfile && (
            <div className="flex items-center justify-center gap-1 ml-auto w-fit bg-surface-light dark:bg-surface-dark px-[7px] py-[1px] rounded-full">
              <PeopleFillIcon className="w-4 h-4 fill-main-light-3 dark:fill-main-dark-3" />
              <p className="leading-[1.8] text-[11px] text-main-light-3 dark:text-main-dark-3">
                {t("social")}
              </p>
            </div>
          )}

          <div className={profileImageStyles}>
            {isEmpty(user.baseUserProfile?.thumbnailUrl) ? (
              <div>
                <DefaultUserProfileBox />
                <div className="w-12 h-12 p-3">
                  <ProfileFillIcon className="fill-onTheirty-light dark:fill-onTheirty-dark" />
                </div>
              </div>
            ) : (
              <img
                className="w-12 h-12 rounded-full object-cover"
                src={user.baseUserProfile.thumbnailUrl}
                alt="User Thumbnail"
              />
            )}
          </div>
          <div className="flex flex-col">
            <span className={profileNicknameStyles}>
              {isEmpty(user.baseUserProfile?.nickname)
                ? t("myUserProfileList.addProfile")
                : user.baseUserProfile?.nickname}
            </span>
            <span className={profileDescriptionStyles}>
              {isEmpty(user.baseUserProfile?.description)
                ? t("myUserProfileList.createPrivateProfile")
                : user.baseUserProfile?.description}
            </span>
          </div>
          <div className={profileStatStyles}>
            <span className={statLabelStyles}>{t("follower")}</span>
            <span className={statValueStyles}>
              {user.baseUserProfile?.followersCount}
            </span>
          </div>
        </div>
      </div>

      {
        <div
          className={`${profileBoxStyles} relative overflow-hidden`}
          onClick={() => navigateAddUserProfile("trading")}
        >
          <div className="absolute inset-0 w-full h-full z-0 dark:hidden">
            <div
              className="absolute inset-0 w-full h-1/3"
              style={{
                backgroundImage: `url(${user.tradingUserProfile?.thumbnailUrl})`,
                backgroundPosition: "center top",
                backgroundSize: "cover",
                filter: "blur(14px)",
              }}
            />
            <div
              className="absolute inset-0 w-full h-full"
              style={{
                background:
                  "linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%)",
              }}
            />
          </div>

          <div className={`${profileInfoStyles} relative z-10 h-full`}>
            {user.tradingUserProfile && (
              <div className="flex items-center gap-1 ml-auto w-fit bg-surface-light dark:bg-surface-dark px-[7px] py-[1px] rounded-full">
                <CartFillIcon className="w-4 h-4 fill-main-light-3 dark:fill-main-dark-3" />
                <p className="leading-[1.8] text-[11px] text-main-light-3 dark:text-main-dark-3">
                  {t("trade")}
                </p>
              </div>
            )}
            <div className={profileImageStyles}>
              {user.tradingUserProfile?.thumbnailUrl ? (
                <div className={profileImageWrapperStyles}>
                  <img
                    className="w-12 h-12 rounded-full object-cover"
                    src={user.tradingUserProfile.thumbnailUrl}
                    alt="User Thumbnail"
                  />
                </div>
              ) : (
                <div>
                  <DefaultUserProfileBox />
                  <div className="w-12 h-12 p-3">
                    <ProfileFillIcon className="fill-onTheirty-light dark:fill-onTheirty-dark" />
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col">
              <span className={profileNicknameStyles}>
                {isEmpty(user.tradingUserProfile?.nickname)
                  ? t("myUserProfileList.addProfile")
                  : user.tradingUserProfile?.nickname}
              </span>
              <span className={profileDescriptionStyles}>
              {isEmpty(user.tradingUserProfile?.description)
                ? t("myUserProfileList.tradingProfile")
                : user.tradingUserProfile?.description}
              </span>
              {user.identified && <VerifiedIcon className="w-4 h-4" />}
            </div>
            <div className={profileStatStyles}>
              <span className={statLabelStyles}>{t("TradingUserProfilePage.mannerSignal")}</span>
              <div
                className="w-[13px] h-[13px] rounded bg-white shadow-md flex items-center justify-center"
                style={{
                  boxShadow: `
                  0px 0.18px 0.71px 0.18px #00000033,
                  0px 0.53px 0.58px -0.27px #0000004D,
                  0px 0px 0.04px 0.13px #00000014,
                  0px 0px 0.04px 0.04px #0000001F
                  `,
                }}
              >
                <div
                  className="w-[7px] h-[7px] rounded-full"
                  style={{
                    backgroundColor: ReviewScoreColor(),
                    boxShadow: `0px 0.04px 0.22px 0px #00000024 inset, 0px 0px 2px 0px ${ReviewScoreColor()}`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default UserProfileList;
