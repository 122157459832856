import {
  Box,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Text,
  Textarea,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { validate } from "react-email-validator";
import { useSearchParams } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import Page from "../components/Page";
import ToastMessageBox from "../components/alerts/ToastMessageBox";
import PrimaryButton from "../components/buttons/PrimaryButton";
import SecondaryButton from "../components/buttons/SecondaryButton";
import baseUrl from "../utils/baseUrl";
import getToken from "../utils/getToken";
import { t } from "i18next";

const infoTextStyle = {
  fontSize: "13px",
  fontWeight: 400,
};

const inputStyle = {
  fontSize: "16px",
  padding: "17px 16px",
  borderRadius: "12px",
  marginTop: "0px",
  fontWeight: 600,
};

// FIXME : const hoverStyle = {
//   border: "0px",
// };

async function reportTradingPhotoCard(params) {
  return fetch(baseUrl() + "/trading_photo_card_reports", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      trading_photo_card_report: params,
    }),
  }).then((data) => data.json());
}

export default function TradingReportPage() {
  const [searchParams] = useSearchParams();

  const [code] = useState(parseInt(Math.random() * 10000000000000));
  const [content, setContent] = useState();
  const [email, setEmail] = useState();
  const [isEmailError, setIsEmailError] = useState(false);
  const [checked, setChecked] = useState();
  const [fileName, setFileName] = useState(
    t("tradingReportPage.reportAttchmentPlaceHolder")
  );
  const [submitted, setSubmitted] = useState(false);
  const toast = useToast();

  const { openFilePicker, filesContent } = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
  });

  useEffect(() => {
    if (filesContent && filesContent[0]) {
      setFileName(filesContent[0]?.name);
    }
  }, [filesContent]);

  useEffect(() => {
    setIsEmailError(!validate(email));
  }, [email]);

  const requiredAllField = () => {
    return content && email && checked;
  };

  const onSubmit = async () => {
    setSubmitted(true);
    if (isEmailError || !requiredAllField()) {
      return;
    }

    let params = {
      code: code,
      content: content,
      email: email,
      data_url: filesContent[0]?.content,
    };

    if (searchParams.has("chatRoomId")) {
      params["chat_room_id"] = searchParams.get("chatRoomId");
    }

    if (searchParams.has("tradingPhotoCardId")) {
      params["trading_photo_card_id"] = searchParams.get("tradingPhotoCardId");
    }

    if (searchParams.has("matchedTradingPhotoCardId")) {
      params["matched_trading_photo_card_id"] = searchParams.get(
        "matchedTradingPhotoCardId"
      );
    }
    const response = await reportTradingPhotoCard(params);
    if (response.success) {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox
            message={t("tradingReportPage.successToastMessage")}
          />
        ),
      });
      window.history.back();
    } else {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox
            message={t("tradingReportPage.failedToastMessage")}
          />
        ),
      });
    }
  };

  const inputTailwindStyle =
    "hover:border-none hover:bg-secondary-light dark:hover:bg-secondary-dark placeholder:text-placeholder-light dark:placeholder:text-placeholder-dark text-black dark:text-white focus:border-pure-white !bg-secondary-light dark:!bg-secondary-dark";

  return (
    <Page
      title={t("tradingReportPage.pageName")}
      showBackButton={true}
      marginTop={"-24px"}
    >
      <Flex>
        <Text
          className="text-main-light-1 dark:text-main-dark-1"
          fontSize={"26px"}
          fontWeight={700}
        >
          {t("tradingReportPage.title")}
        </Text>
      </Flex>
      <Center marginTop={"20px"}>
        <Image
          src={"/assets/icons/report_police.png"}
          width={"157px"}
          height={"157px"}
        />
      </Center>
      <Box
        className="border-[2px] border-separator-light-2 dark:border-separator-dark-2"
        borderRadius={"12px"}
      >
        <VStack style={{ padding: "16px" }}>
          <Text
            className="text-main-light-1 dark:text-main-dark-1"
            style={infoTextStyle}
          >
            {t("tradingReportPage.descriptionFirstRow")}
          </Text>
          <Text
            className="text-main-light-1 dark:text-main-dark-1"
            style={infoTextStyle}
          >
            {t("tradingReportPage.descriptionSecondRow")}
          </Text>
        </VStack>
      </Box>
      <FormControl paddingTop={"20px"}>
        <FormLabel
          className="text-main-light-1 dark:text-main-dark-1"
          paddingY={"16px"}
          marginY={0}
          fontSize={"15px"}
          fontWeight={700}
        >
          {t("tradingReportPage.reportCode")}
        </FormLabel>
        <Input
          className={inputTailwindStyle}
          disabled
          placeholder={code}
          style={inputStyle}
          _disabled={inputStyle}
        />
      </FormControl>
      <FormControl paddingTop={"20px"} isInvalid={!content && submitted}>
        <FormLabel
          className="text-main-light-1 dark:text-main-dark-1"
          paddingY={"16px"}
          marginY={0}
          fontSize={"15px"}
          fontWeight={700}
        >
          {t("tradingReportPage.reportDescription")}
        </FormLabel>
        <Textarea
          className={inputTailwindStyle}
          placeholder={t("tradingReportPage.reportDescriptionPlaceholder")}
          style={inputStyle}
          onChange={(e) => setContent(e.target.value)}
        />
        {!content && (
          <FormErrorMessage>
            {t("tradingReportPage.reportDescriptionEmptyErrorMessage")}
          </FormErrorMessage>
        )}
      </FormControl>
      <FormControl paddingTop={"20px"}>
        <FormLabel
          className="text-main-light-1 dark:text-main-dark-1"
          paddingY={"16px"}
          marginY={0}
          fontSize={"15px"}
          fontWeight={700}
        >
          {t("tradingReportPage.reportAttchment")}
        </FormLabel>
        <Input
          className={inputTailwindStyle}
          isReadOnly={true}
          placeholder={fileName}
          style={inputStyle}
          _disabled={inputStyle}
          onClick={() => openFilePicker()}
        />
      </FormControl>
      <FormControl
        paddingTop={"20px"}
        isInvalid={isEmailError && !!email && submitted}
      >
        <FormLabel
          className="text-main-light-1 dark:text-main-dark-1"
          paddingY={"16px"}
          marginY={0}
          fontSize={"15px"}
          fontWeight={700}
        >
          {t("tradingReportPage.reportUserEmail")}
        </FormLabel>
        <Input
          className={inputTailwindStyle}
          placeholder={"pocapan@gmail.com"}
          style={inputStyle}
          onChange={(e) => setEmail(e.target.value)}
        />
        {isEmailError && (
          <FormErrorMessage>
            {!email
              ? t("tradingReportPage.reportUserEmailEmptyErrorMessage")
              : t("tradingReportPage.reportUserEmailFormatErrorMessage")}
          </FormErrorMessage>
        )}
      </FormControl>
      <VStack align={"flex-start"} paddingTop={"50px"}>
        <HStack>
          <Checkbox
            value="agreed"
            onChange={(e) => setChecked(e.target.checked)}
          />
          <Text
            className="text-pure-black dark:text-pure-white"
            fontSize={"15px"}
            fontWeight={400}
          >
            {t("tradingReportPage.reportAgreement")}
          </Text>
        </HStack>
      </VStack>
      <div style={{ margin: "17px 0px" }}>
        {requiredAllField() ? (
          <PrimaryButton
            text={t("tradingReportPage.reportSubmit")}
            style={{ padding: "16px 24px" }}
            onClick={() => onSubmit()}
          />
        ) : (
          <SecondaryButton
            text={t("tradingReportPage.reportSubmit")}
            style={{ padding: "16px 24px" }}
          />
        )}
      </div>
    </Page>
  );
}
