import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import DeletePhotoCard from "../components/api/mutations/DeletePhotoCard";
import useReportedPhotoCardsQuery from "../components/api/queries/useReportedPhotoCardsQuery";
import ReportedPhotoCardItem from "../components/photocard/ReportedPhotoCardItem";
import StickyHeader from "../components/StickyHeader";
import useUserQuery from "../components/api/queries/useUserQuery";

const PageContainer = styled.div`
  margin-top: calc(54px + env(safe-area-inset-top));
`;

const ListContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: calc(2rem + env(safe-area-inset-bottom));
`;

const AddPhotoCardButton = styled.div`
  margin-top: 13px;
  border-radius: 12px;
  padding: 24px 20px;
`;

const AddPhotoCardText = styled.div`
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
`;
const DisclaimerBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 12px 24px 0px 24px;
`;

const DisclaimerRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  line-height: 17px;
`;

export default function ReportedPhotoCardsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const user = useUserQuery();
  const photoCards = useReportedPhotoCardsQuery({
    user: user,
    onlyPending: false,
  });

  const handleDelete = async (photoCardId) => {
    await DeletePhotoCard(photoCardId);
    queryClient.invalidateQueries(["reportedPhotoCards"]);
  };

  const handleReportNew = () => {
    navigate("/report-photo-card/select-artist");
  };

  return (
    <>
      <StickyHeader
        showBackButton={true}
        showTitle={true}
        alwaysShowTitle={true}
        title={t("ReportedPhotoCardsPage.title")}
      />
      <PageContainer>
        <DisclaimerBox>
          <DisclaimerRow className="text-main-light-4 dark:text-main-dark-4">
            <div>{`•`}</div>
            <div>{t("ReportedPhotoCardsPage.disclaimerRowLine1")}</div>
          </DisclaimerRow>
          <DisclaimerRow className="text-main-light-4 dark:text-main-dark-4">
            <div>{`•`}</div>
            <div>{t("ReportedPhotoCardsPage.disclaimerRowLine2")}</div>
          </DisclaimerRow>
          <DisclaimerRow className="text-main-light-4 dark:text-main-dark-4">
            <div>{`•`}</div>
            <div>{t("ReportedPhotoCardsPage.disclaimerRowLine3")}</div>
          </DisclaimerRow>
          <DisclaimerRow className="text-main-light-4 dark:text-main-dark-4">
            <div>{`•`}</div>
            <div>{t("ReportedPhotoCardsPage.disclaimerRowLine4")}</div>
          </DisclaimerRow>
        </DisclaimerBox>
        <ListContainer>
          {photoCards?.map((photoCard) => (
            <ReportedPhotoCardItem
              key={photoCard.photoCardId}
              photoCard={photoCard}
              onDelete={handleDelete}
            />
          ))}
          <AddPhotoCardButton
            className="text-primary-light dark:text-primary-dark bg-pure-white dark:bg-pure-black 
              shadow-[0px_0px_0px_1px_rgba(0,0,0,0.01),0px_0px_14px_0px_rgba(0,0,0,0.08)]"
            onClick={handleReportNew}
          >
            <AddPhotoCardText>
              {t("ReportPhotoCardEventsPage.addManually")}
            </AddPhotoCardText>
          </AddPhotoCardButton>
        </ListContainer>
      </PageContainer>
    </>
  );
}
