import React, { useState } from "react";

import {
  Avatar,
  SimpleGrid,
  Image,
  Text,
  VStack,
  WrapItem,
  useToast,
} from "@chakra-ui/react";

import ko from "date-fns/locale/ko";
import { format } from "date-fns";
import PrimaryButton from "../buttons/PrimaryButton";
import SecondaryButton from "../buttons/SecondaryButton";
import baseUrl from "../../utils/baseUrl";
import getToken from "../../utils/getToken";
import { useLongPress } from "use-long-press";
import ToastMessageBox from "../alerts/ToastMessageBox";
import FullSizeImageViewer from "./ImageViewer";
import copyTextToClipboard from "../../utils/writeTextToClipboard";
import { t } from "i18next";

const profileImageStyle = {
  width: "34px",
  height: "34px",
  borderRadius: "34px",
  background: "lightgray 50% / cover no-repeat, rgba(122, 89, 255, 0.26)",
};

const myChatMessageBox = {
  padding: "10px 18px",
  borderRadius: "20px",
  // FIXME : background: "#7A59FF",
  fontSize: "15px",
  fontWeight: 500,
  whiteSpace: "pre-wrap",
};

const othersChatMessageBox = {
  padding: "10px 18px",
  borderRadius: "20px",
  // FIXME : background: "#F2F3F6",
  fontSize: "15px",
  fontWeight: 500,
  whiteSpace: "pre-wrap",
};

const imageBoxStyle = {
  borderRadius: "14px",
};

const sentAtStyle = {
  fontWeight: 400,
  fontSize: "11px",
};

async function sendMessage(chatRoomId, content, messageType, timestamp) {
  return fetch(baseUrl() + `/chat_rooms/${chatRoomId}/chat_room_messages/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    },
    body: JSON.stringify({
      chat_room_message: {
        content: content,
        message_type: messageType,
        sent_at: timestamp,
      },
    }),
  }).then((data) => data.json());
}

export default function ChatMessage({
  chatRoom,
  message,
  isLastUnSeenMessage,
}) {
  const messageBox = (message) => {
    if (message.messageType === "notice") {
      return <NoticeBox message={message.content} />;
    } else if (message.messageType === "image") {
      return <ImageBox pictureUrl={message.content} />;
    } else if (message.messageType === "booking") {
      return (
        <BookingBox
          isSender={message.isMine}
          message={message.content}
          onClickBookingButton={(type) => onClickBookingButton(type)}
        />
      );
    } else {
      return (
        <MessageBubble isSender={message.isMine} message={message.content} />
      );
    }
  };

  const onClickBookingButton = (type) => {
    sendMessage(
      chatRoom.chatRoomId,
      type,
      "booking",
      parseInt(Date.now() / 1000)
    );
  };

  return message && message.isMine ? (
    <div className="flex flex-row-reverse gap-2">
      <div className="shrink">{messageBox(message)}</div>
      <div className="flex-none self-end">
        <div>
          {isLastUnSeenMessage && message.seenAt && (
            <Text
              className="text-main-light-4 dark:text-main-dark-4"
              style={sentAtStyle}
            >
              {t("chatRoom.read")}
            </Text>
          )}
          {message.sentAt && (
            <Text style={sentAtStyle}>
              {format(new Date(message.sentAt), "aaa hh:mm", { locale: ko })}
            </Text>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-row gap-2">
      {message.messageType !== "notice" && (
        <div className="flex-none self-end">
          {message.userProfile?.pictureUrl ? (
            <Image
              src={message.userProfile?.pictureUrl}
              style={profileImageStyle}
            />
          ) : (
            <Avatar style={profileImageStyle} />
          )}
        </div>
      )}
      <div>{messageBox(message)}</div>
      {message.messageType !== "notice" && message.sentAt && (
        <div className="flex-none self-end">
          <Text
            className="text-main-light-4 dark:text-main-dark-4"
            style={sentAtStyle}
          >
            {format(new Date(message.sentAt), "aaa hh:mm", { locale: ko })}
          </Text>
        </div>
      )}
    </div>
  );
}

function NoticeBox({ message }) {
  return (
    <WrapItem
      className="text-center bg-background-light dark:bg-background-dark text-main-light-2 dark:text-main-dark-2"
      style={othersChatMessageBox}
    >
      <Text>{message}</Text>
    </WrapItem>
  );
}

function ImageBox({ pictureUrl }) {
  const [isImageClicked, setIsImageClicked] = useState(false);
  const handleImageClick = () => {
    setIsImageClicked(!isImageClicked);
  };
  return (
    <>
      <Image
        style={imageBoxStyle}
        src={pictureUrl}
        loading="lazy"
        onClick={handleImageClick}
      />
      {isImageClicked && (
        <FullSizeImageViewer
          imageUrl={pictureUrl}
          onClosed={() => setIsImageClicked(false)}
        />
      )}
    </>
  );
}

function BookingBox({ isSender, message, onClickBookingButton }) {
  return (
    <VStack
      className={
        isSender
          ? "bg-primary-light dark:bg-primary-dark text-onPrimary"
          : "bg-background-light dark:bg-background-dark text-main-light-2 dark:text-main-dark-2"
      }
      style={isSender ? myChatMessageBox : othersChatMessageBox}
    >
      <Text>{message}</Text>
      {!isSender && (
        <SimpleGrid columns={2} width={"100%"} gap={2}>
          <PrimaryButton
            width={"100%"}
            text={t("accept")}
            onClick={() => onClickBookingButton("accepted")}
          />
          <SecondaryButton
            width={"100%"}
            text={t("declient")}
            onClick={() => onClickBookingButton("declined")}
          />
        </SimpleGrid>
      )}
    </VStack>
  );
}

function MessageBubble({ isSender, message }) {
  const toast = useToast();

  const bind = useLongPress(() => {
    copyTextToClipboard(message).then(() => {
      toast({
        duration: 1500,
        render: () => (
          <ToastMessageBox message={t("chatMessage.copyMessageToClipboard")} />
        ),
      });
    });
  });

  return (
    <WrapItem
      className={
        isSender
          ? "bg-primary-light dark:bg-primary-dark text-onPrimary"
          : "bg-background-light dark:bg-background-dark text-main-light-2 dark:text-main-dark-2"
      }
      style={isSender ? myChatMessageBox : othersChatMessageBox}
      {...bind()}
    >
      <Text>{message}</Text>
    </WrapItem>
  );
}
