import baseUrl from "../../../utils/baseUrl";
import getToken from "../../../utils/getToken";


export default async function CreateMatchedTradingChatRoom(matchedTradingPhotoCardId) {
  return fetch(baseUrl() + `/matched_trading_photo_cards/${matchedTradingPhotoCardId}/chat_room`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getToken(),
    }
  }).then((data) => data.json())
}
