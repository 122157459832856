import React, { useEffect, useState } from "react";

import Sheet from "react-modal-sheet";

import { Box, Center, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import styled from "styled-components";
import { ReactComponent as MinusIcon } from "../../icons/minus.svg";
import { ReactComponent as PlusIcon } from "../../icons/plus.svg";
import { SPRING_CONFIG } from "../../utils/bottomSheetSpringOption";
import { t } from "i18next";

const Title = styled.div`
  font-size: 17px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

const Description = styled.div`
  padding-top: 8px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;

const BoxWrapper = styled.div`
  padding-top: 36px;
  width: calc(100% - 56px);
`;
const BottomFullButton = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-top: 14px;
  min-height: 82px;
  color: white;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
`;

export default function ChangePhotoCardCollectedCountBottomSheet({
  isShowChangeCollectCountBottomSheet,
  setIsShowChangeCollectCountBottomSheet,
  currentCollectedCount,
  updatedCollectedCount,
}) {
  const [collectedCount, setCollectedCount] = useState(currentCollectedCount);

  // Initialize origin collected count
  useEffect(() => {
    setCollectedCount(currentCollectedCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowChangeCollectCountBottomSheet]);

  const confirmedCount = (collectedCount) => {
    setIsShowChangeCollectCountBottomSheet(false);
    updatedCollectedCount(collectedCount);
  };

  return (
    <Sheet
      springConfig={SPRING_CONFIG}
      detent="content-height"
      disableDrag={true}
      isOpen={isShowChangeCollectCountBottomSheet}
      onClose={() => setIsShowChangeCollectCountBottomSheet(false)}
    >
      <Sheet.Container
        className="!bg-backgroundElevated-light dark:!bg-backgroundElevated-dark"
        style={{ borderTopLeftRadius: "20px", borderTopRightRadius: "20px" }}
      >
        <Sheet.Content disableDrag={true}>
          <VStack paddingTop={"32px"} spacing={0}>
            <Title className="text-main-light-2 dark:text-main-dark-2">
              {t("photoCardDetailPage.changeCollectedBottomSheetTitle")}
            </Title>
            <Description className="text-main-light-4 dark:text-main-dark-4">
              {t("photoCardDetailPage.changeCollectedBottomSheetDescription")}
            </Description>
            <BoxWrapper>
              <Box
                borderRadius={"16px"}
                paddingX={"12px"}
                paddingY={"14px"}
                className="w-full bg-secondary-light dark:bg-secondary-dark"
              >
                <SimpleGrid columns={3}>
                  <div
                    className="flex justify-start"
                    onClick={() =>
                      collectedCount > 0
                        ? setCollectedCount(collectedCount - 1)
                        : console.log("collectedCount is zero.")
                    }
                  >
                    <MinusIcon className="fill-primary-light dark:fill-primary-dark" />
                  </div>
                  <Center>
                    <Text as="b">{collectedCount}</Text>
                  </Center>
                  <div
                    className="flex justify-end"
                    onClick={() => setCollectedCount(collectedCount + 1)}
                  >
                    <PlusIcon className="fill-primary-light dark:fill-primary-dark" />
                  </div>
                </SimpleGrid>
              </Box>
            </BoxWrapper>
          </VStack>
          <BottomFullButton
            className="bg-primary-light dark:bg-primary-dark"
            onClick={() => confirmedCount(collectedCount)}
          >
            {t("done")}
          </BottomFullButton>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onClick={() => setIsShowChangeCollectCountBottomSheet(false)}
      />
    </Sheet>
  );
}
