import React, { useEffect, useState } from "react";

import { HStack, Skeleton, useColorModeValue, Wrap } from "@chakra-ui/react";
import { t } from "i18next";
import { findIndex, isEmpty, orderBy, some } from "lodash";
import { useQuery } from "react-query";

import {
  getArtistId,
  getMemberId,
  getMemberIdAnyway,
} from "../../utils/etcUtils";
import { queryGet } from "../../utils/queryClient";
import { saveData } from "../../utils/saveData";
import RegularSolidButton from "../buttons/RegularSolidButton";

const ALL_MEMBER = { memberId: "all" };

export default function LikedMembersList({
  artistMembers,
  onChangeLikedMember,
  addEntireOption,
  showAllMember,
  showHeart = false,
}) {
  const startColor = useColorModeValue("separator.light.1", "separator.dark.1");
  const endColor = useColorModeValue("separator.light.2", "separator.dark.2");
  const primaryStartColor = useColorModeValue(
    "secondary.light",
    "secondary.dark"
  );
  const primaryEndColor = useColorModeValue("theirty.light", "theirty.dark");

  const [selectedMember, setSelectedMember] = useState(
    addEntireOption ? ALL_MEMBER : null
  );
  const artistId = getArtistId();

  const { data: memberResults } = useQuery(
    ["members", artistId],
    () => queryGet(`/artists/${artistId}/members`),
    {
      enabled: !artistMembers && !!artistId,
      select: (r) => orderBy(r.members, (m) => !m.isLiked),
    }
  );

  const members = artistMembers || memberResults;

  const onSelectedMember = (member) => {
    saveData("memberId", getMemberIdAnyway(member));
    setSelectedMember(member);
    onChangeLikedMember(member);
  };

  useEffect(() => {
    if (!members) return;
    if (!selectedMember) {
      const memberId = getMemberId();

      if (memberId) {
        const foundMemberIndex = findIndex(
          members,
          (member) => getMemberIdAnyway(member) === memberId
        );

        if (foundMemberIndex >= 0) {
          return onSelectedMember(members[foundMemberIndex]);
        }
      }
    }

    const selectedMemberExists =
      !!selectedMember &&
      some(members, { memberId: getMemberIdAnyway(selectedMember) });

    if (!selectedMemberExists) {
      if (addEntireOption) {
        onSelectedMember(ALL_MEMBER);
      } else {
        onSelectedMember(members[0]);
      }
    }
  }, [artistMembers, memberResults]);

  return (
    <>
      {!isEmpty(members) ? (
        <Wrap spacing={"8px"} paddingY={"8px"} margin={"0px!important"}>
          {addEntireOption && (
            <RegularSolidButton
              key={"all"}
              text={t("entireMembers")}
              onClicked={() => onSelectedMember(ALL_MEMBER)}
              filled={getMemberIdAnyway(selectedMember) === "all"}
            />
          )}
          {(showAllMember ? members : members.filter((m) => m.isLiked))?.map(
            (member) => {
              return (
                <RegularSolidButton
                  key={getMemberIdAnyway(member)}
                  text={
                    (showAllMember || showHeart) && member.isLiked
                      ? member.name + " ♥"
                      : member.name
                  }
                  onClicked={() => onSelectedMember(member)}
                  filled={
                    getMemberIdAnyway(selectedMember) ===
                    getMemberIdAnyway(member)
                  }
                />
              );
            }
          )}
        </Wrap>
      ) : (
        <HStack paddingY={"8px"}>
          <Skeleton
            height="33px"
            width="47px"
            borderRadius={"32px"}
            startColor={primaryStartColor}
            endColor={primaryEndColor}
          />
          <Skeleton
            height="33px"
            width="47px"
            borderRadius={"32px"}
            startColor={startColor}
            endColor={endColor}
          />
          <Skeleton
            height="33px"
            width="47px"
            borderRadius={"32px"}
            startColor={startColor}
            endColor={endColor}
          />
        </HStack>
      )}
    </>
  );
}
